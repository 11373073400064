import refs from '../refsLite'
import { getDocs } from 'firebase/firestore/lite'

export async function getSubmissionEntries(
  assignmentId,
  userId,
  callback = async (entry) => {},
) {
  const result = {}
  const entrySnapshot = await getDocs(refs.entries(assignmentId, userId))
  for (const doc of entrySnapshot.docs) {
    if (doc.exists()) {
      const item = doc.data()
      item.id = doc.id
      await callback(item)
      result[item.id] = item
    }
  }
  return result
}
