export function eventComparator(a, b) {
  if (a.eventType === 'metadata') return b.eventType === 'metadata' ? 0 : -1
  if (b.eventType === 'metadata') return a.eventType === 'metadata' ? 0 : 1

  const timeResult = a.time - b.time
  if (timeResult !== 0) return timeResult

  if (a.eventType === 'loggingStart')
    return b.eventType === 'loggingStart' ? 0 : -1
  if (b.eventType === 'loggingStart')
    return a.eventType === 'loggingStart' ? 0 : 1
  if (a.eventType === 'loggingStop')
    return b.eventType === 'loggingStop' ? 0 : 1
  if (b.eventType === 'loggingStop')
    return a.eventType === 'loggingStop' ? 0 : -1

  if (a.implicit) {
    return b.implicit ? 0 : -1
  }
  return b.implicit ? 1 : 0
}
