import { getDocs } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getUserSiteDatas2(sessionId, callback) {
  if (!callback) {
    callback = async () => {}
  }
  const items = {}
  const snap = await getDocs(refs.userSiteDatas(sessionId))
  for (const doc of snap.docs) {
    const userSiteData = doc.data()
    if (userSiteData) {
      items[userSiteData.id] = userSiteData
      await callback?.(userSiteData)
    }
  }
  return items
}
