export function urlIsLikelyAIPrompt(url) {
  try {
    let parsedURL = new URL(url)
    if (parsedURL.hostname == 'chat.openai.com') {
      return true
    }
    if (parsedURL.hostname == 'chatgpt.com') {
      return true
    }
    if (parsedURL.hostname == 'bard.google.com') {
      return true
    }
    if (parsedURL.hostname == 'gemini.google.com') {
      return true
    }
    if (parsedURL.hostname == 'you.ai') {
      return true
    }
    if (parsedURL.hostname == 'you.com') {
      return true
    }
    if (parsedURL.host.endsWith('.ai')) {
      return true
    }
  } catch (e) {}
  return false
}
