var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('node-view-wrapper', {
    staticClass: "tip-tap-pasted-image"
  }, [_c('div', {
    staticStyle: {
      "max-width": "100%"
    }
  }, [!_vm.loadingImage && !_vm.errorLoadingImage ? _c('ResizableImage', {
    attrs: {
      "width": _vm.naturalWidth,
      "height": _vm.naturalHeight,
      "src": _vm.src,
      "alt": _vm.node.attrs.alt,
      "scale": _vm.imgScale,
      "selected": _vm.selected,
      "highlighted": _vm.inTextSelection
    },
    on: {
      "resize-end": _vm.onResizeEnd
    }
  }) : _vm.loadingImage && !_vm.errorLoadingImage ? _c('v-card', {
    staticClass: "pa-2 loading-image",
    class: {
      selected: _vm.selected,
      highlighted: _vm.inTextSelection
    },
    staticStyle: {
      "background-color": "#f5f5f5"
    },
    attrs: {
      "elevation": "1",
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center pa-8"
  }, [_c('v-progress-circular', {
    attrs: {
      "size": 50,
      "width": 2,
      "indeterminate": "",
      "color": "teacherPrimary"
    }
  })], 1), _c('div', {
    staticClass: "pb-2 text-center font-weight-bold"
  }, [_vm._v("Loading Image...")])]) : _c('v-card', {
    staticClass: "pa-2 image-broken",
    class: {
      selected: _vm.selected,
      highlighted: _vm.inTextSelection
    },
    staticStyle: {
      "background-color": "#ffe3e3",
      "position": "relative"
    },
    attrs: {
      "elevation": "1",
      "tile": ""
    }
  }, [_c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "8px",
      "right": "8px"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "error"
    },
    on: {
      "click": _vm.deleteImage
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "d-flex justify-center pa-8 pb-2 pt-6"
  }, [_c('v-icon', {
    attrs: {
      "size": "80",
      "color": "error"
    }
  }, [_vm._v("link_off")])], 1), _c('div', {
    staticClass: "pb-2 text-center font-weight-bold"
  }, [_vm._v("Image not found")])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }