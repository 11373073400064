import {
  administratorRoles,
  teacherRoles,
} from '@learnics/models/src/utils/roles'
import { getSharedAssignmentsForOrgByUserRole } from './getSharedAssignmentsForOrgByUserRole'

export async function getSharedAssignmentsForOrg(
  orgId,
  userId,
  callback = async () => {},
) {
  const roles = [...teacherRoles, ...administratorRoles]
  const promises = []
  for (let i = 0; i < roles.length; i++) {
    const role = roles[i]
    await getSharedAssignmentsForOrgByUserRole(orgId, userId, role, callback)
  }
  return await Promise.all(promises)
}
