/**
 * A class to represent a Google Drive file and select bits of its metadata.
 *
 * This class contains purely Google-Drive-assigned metadata.
 */
export class GoogleDriveFile {
  static GET_FILE_FIELDS =
    'name, mimeType, kind, id, parents, owners, webViewLink, iconLink, hasThumbnail, thumbnailLink, thumbnailVersion, ownedByMe, permissions, permissionIds, exportLinks'
  static ORDERED_DRIVE_ROLES = [
    'owner',
    'organizer',
    'fileOrganizer',
    'writer',
    'commenter',
    'reader',
  ]
  /**
   * Constructor for GoogleDriveFile.
   *
   * @param data {object} the data to initialize the GoogleDriveFile with
   * @param data.id {string} the id of the file
   * @param data.kind {string} the "kind" of file
   * @param data.mimeType {string} the mime type of the file
   * @param data.name {string} the name of the file
   * @param data.ownerEmail {string} the email of the owner of the file
   * @param data.webViewLink {string} the link to view the file in a browser
   * @param data.iconLink {string} the link to the icon for the file
   * @param data.thumbnailLink {string} the link to the thumbnail for the file
   * @param data.thumbnailVersion {number} the version of the thumbnail for the file
   * @param data.exportLinks {object} the export links for the file
   * @param data.owners {object[]} the owners of the file
   * @param data.thumbnailUrl {string} the url of the thumbnail for the file
   * @param data.alternateLink {string} the alternate link for the file
   *
   */
  constructor({
    id,
    kind,
    mimeType,
    name,
    ownerEmail,
    webViewLink,
    alternateLink,
    iconLink,
    thumbnailLink,
    thumbnailUrl,
    thumbnailVersion,
    exportLinks,
    owners,
    title,
  }) {
    this.id = id
    this.kind = kind || null
    this.mimeType = mimeType || null
    this.name = name || title || null
    this.ownerEmail = ownerEmail || owners?.[0]?.emailAddress || null
    this.webViewLink = webViewLink || alternateLink || null
    this.iconLink = iconLink || null
    this.thumbnailLink = thumbnailLink || thumbnailUrl || null
    this.thumbnailVersion = thumbnailVersion || null
    this.exportLinks = exportLinks || {}
  }

  toJson() {
    return {
      id: this.id,
      kind: this.kind || null,
      mimeType: this.mimeType || null,
      name: this.name || null,
      ownerEmail: this.ownerEmail || null,
      webViewLink: this.webViewLink || null,
      iconLink: this.iconLink || null,
      thumbnailLink: this.thumbnailLink || null,
      thumbnailVersion: this.thumbnailVersion || null,
      exportLinks: JSON.parse(JSON.stringify(this.exportLinks || {})),
    }
  }
}
