class YjsInstance {
  constructor() {
    this.Y = null
    this.tiptapTransformer = null
    this.yXmlFragmentToProseMirrorRootNode = null
  }

  set(Y, tiptapTransformer, yXmlFragmentToProseMirrorRootNode) {
    this.Y = Y
    this.tiptapTransformer = tiptapTransformer
    this.yXmlFragmentToProseMirrorRootNode = yXmlFragmentToProseMirrorRootNode
  }
}

export const yjsInstance = new YjsInstance()
