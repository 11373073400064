import { loadChunks } from './loadChunks'
import { getSecrets } from './getSecrets'
import { getKeysDeleted } from './getKeysDeleted'
import { loadEventDataForSessionIds } from '@learnics/models/src/Organization/Course/Assignment/Event/utils/loadEventDataForSessionIds'

// This is merely an interface to the common loading routine in the models package.
/** @deprecated prefer `loadEventDataForSessionIds`, for 1 or more multiple sessions, with their event data combined */
export async function loadEventsAndSecrets(sessionId, unsavedEvents = []) {
  const result = await loadEventDataForSessionIds(
    [sessionId],
    {
      loadChunks,
      getSecrets,
      getKeysDeleted,
    },
    { [sessionId]: unsavedEvents },
  )
  return result.composite
}
