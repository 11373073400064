import { ResearchSource } from './ResearchSource'

/**
 * WebsiteSource is a class that holds note and annotation information about a
 * particular website.
 *
 * @extends ResearchSource
 */
export class WebsiteSource extends ResearchSource {
  static TYPE = 'website'
  constructor(yMap) {
    super(yMap)
  }

  get url() {
    return this.yMap.get('url')
  }

  set url(url) {
    this.yMap.set('url', url)
  }

  get title() {
    return this.yMap.get('title')
  }

  set title(title) {
    this.yMap.set('title', title)
  }

  get dateAccessed() {
    return this.yMap.get('dateAccessed')
  }

  set dateAccessed(dateAccessed) {
    this.yMap.set('dateAccessed', +new Date(dateAccessed))
  }
}
