import { functions } from '../coreConfig'
import { httpsCallable } from 'firebase/functions'

export async function enrollStudentInSection(sectionId, uid) {
  console.log(
    'Calling enrollStudentInSection with userId ' +
      uid +
      ' and sectionID ' +
      sectionId,
  )
  const result = await httpsCallable(
    functions,
    'enrollStudentInSection',
  )({
    uid,
    sectionId,
  })
  console.log('Enrolled student in section, result: ', result)
}
