import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { paths } from '@learnics/models/src/utils/paths'

export async function getAclsByParent(
  resourceType,
  parentType,
  parentId,
  callback = async () => {},
) {
  const result = {}
  const querySnapshot = await getDocs(
    // check if the parents_assignment array contains the noteBoardId
    query(
      refs.acls(paths[resourceType + 's']()),
      where('parents_' + parentType, 'array-contains', parentId),
    ),
  )
  for (const snap of querySnapshot.docs) {
    const acl = snap.data()
    if (acl) {
      result[snap.id] = acl
      await callback(acl)
    }
  }
  return result
}
