export function advancedJstorUrlToSearchKeyword(url) {
  try {
    const queryString = url.split('?')[1]
    const queryParams = queryString.split('&')
    const search = queryParams
      .map((s) => {
        const kv = s.split('=')
        const k = kv[0]
        const v = kv[1]
        if (
          ['q0', 'q1', 'q2', 'q3', 'q4', 'q5', 'q6'].indexOf(k) !== -1 &&
          v !== ''
        ) {
          return v
        }
        return null
      })
      .filter((v) => {
        return v !== null
      })
    return search.join(' ')
  } catch (e) {
    console.log('Unable to parse: ', url, e)
    return null
  }
}
