export async function listCourses(
  accessToken,
  courseStates = ['ACTIVE'],
  teacherId = 'me',
  studentId = null,
  pageSize = 25,
  callback = (courseWork) => console.log('courseWork: ', courseWork),
  nextPageToken = null,
) {
  let args = '?pageSize=' + pageSize + '&'
  for (let i = 0; i < courseStates.length; i++) {
    args += `courseStates=${courseStates[i]}&`
  }
  if (nextPageToken) {
    args += `pageToken=${nextPageToken}&`
  }
  if (studentId) {
    args += `studentId=${studentId}`
  } else {
    args += `teacherId=${teacherId}`
  }
  const url = `https://classroom.googleapis.com/v1/courses${args}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const res = await response.json()
  if (res.error) {
    console.log('Error getting courses: ', res.error)
    throw res.error
  } else {
    if (!res.courses) {
      console.log('No courses found in response')
      return []
    }
    const result = [...res.courses]

    res.courses.forEach((course) => callback(course))
    if (res.nextPageToken) {
      const nextCourses = await listCourses(
        accessToken,
        courseStates,
        teacherId,
        studentId,
        pageSize,
        callback,
        res.nextPageToken,
      )
      result.push(...nextCourses)
    }
    return result
  }
}
