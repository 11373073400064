import Vue from 'vue'
import { paths } from '@learnics/models/src/utils/paths'
import { getAcl } from '../../acl/getAcl'

export const state = {
  acl: {},
}

export const getters = {
  acl: (state) => state.acl,
}

export const mutations = {
  setAcl: (state, { compositeId, newVal, oldVal }) => {
    const { resourceType, resourceId } = compositeId
    if (newVal) {
      if (!state.acl[resourceType]) Vue.set(state.acl, resourceType, {})
      Vue.set(state.acl[resourceType], resourceId, newVal)
    } else {
      Vue.delete(state.acl[resourceType], resourceId)
      if (!Object.keys(state.acl[resourceType]).length)
        Vue.delete(state.acl, resourceType)
    }
  },
}

export const actions = {
  /**
   * Load an ACL into the store.
   *
   * NOTE:  This should not be used by the extension.  The extension should
   *        rely on service worker messages to update its entities, which will
   *        then cascade down to the store.
   *
   * @param state
   * @param assignment
   */
  async loadAcl(
    { state, dispatch, commit, rootState },
    args = { forced: false },
  ) {
    const { resourceType, resourceId, forced } = args
    const acl = await getAcl(paths[resourceType](resourceId))
    commit('setAcl', {
      compositeId: { resourceType, resourceId },
      newVal: acl,
    })
  },

  loadResearchNotebookAcls: async (
    { state, dispatch, commit, rootState },
    args = { forced: false },
  ) => {
    const { ids } = args
    for (const id of ids) {
      const researchNotebook = rootState.researchNotebooks.researchNotebooks[id]
      if (!researchNotebook) {
        console.error('ResearchNotebook not found: ', id)
        continue
      }

      void dispatch('loadAcl', {
        resourceType: 'researchNotebook',
        resourceId: id,
        forced: args.forced,
      })
      const noteIds = researchNotebook.getNoteIds()
      for (const noteId of noteIds) {
        void dispatch('loadAcl', {
          resourceType: 'note',
          resourceId: noteId,
          forced: args.forced,
        })
        const note = rootState.notes.notes[noteId]
        if (!note) {
          console.error('Note not found: ', noteId)
          continue
        }
        const fileIds = note.getAllBucketFileIds()
        for (const fileId of fileIds) {
          void dispatch('loadAcl', {
            resourceType: 'bucketFile',
            resourceId: fileId,
            forced: args.forced,
          })
        }
      }
    }
  },
}
