import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'
import { BasicAccessList } from '@learnics/models/src/BasicAccessList/BasicAccessList'

export async function deleteResourceRole(
  resourceType,
  resourceId,
  userId,
  role,
) {
  const result = await httpsCallable(
    functions,
    'deleteResourceRole',
  )({
    resourceType,
    resourceId,
    role,
    userId,
  })
  console.log('deleteResourceRole result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return new BasicAccessList(result.data.data)
}
