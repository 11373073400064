import { UrlNote } from './UrlNote'
import { htmlHasNonWhiteSpace } from '../../../../utils/tiptap/htmlHasNonWhiteSpace'
import { nanoid } from 'nanoid'

/**
 * UserSiteData is a class that holds note and annotation information about a particular site.
 *
 * @deprecated prefer `WebsiteSource` when it is available
 */
export class UserSiteData {
  constructor({
    id,
    sessionId,
    tagIds = [],
    notes = [],
    editingNoteId = null,
    annotationId = null,
  }) {
    this.id = id
    this.sessionId = sessionId
    this.tagIds = [...(tagIds || [])]
    this.notes = (notes || []).map((noteJson) => UrlNote.fromJson(noteJson))
    this.editingNoteId = editingNoteId || null
    this.annotationId = annotationId || null
    if (this.notes.length === 0) {
      this.notes.push(new UrlNote(nanoid(), '', [], []))
    }
    if (
      this.editingNoteId &&
      !this.notes.some((note) => note.id === this.editingNoteId)
    ) {
      // Fix broken editingNoteId links
      this.editingNoteId = null
    }
    if (!this.editingNoteId) {
      this.editingNoteId = this.notes[this.notes.length - 1].id
    }
    if (
      this.annotationId &&
      !this.notes.some((note) => note.id === this.annotationId)
    ) {
      // Fix broken annotationId links
      this.annotationId = null
    }
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {Object} The JSON representation of this state.
   */
  toJson() {
    return {
      id: this.id,
      sessionId: this.sessionId,
      tagIds: [...(this.tagIds || [])],
      notes: this.notes.map((note) => note.toJson()),
      editingNoteId: this.editingNoteId || null,
      annotationId: this.annotationId || null,
    }
  }

  isUsingTag(tagId) {
    return !!(
      this.tagIds.indexOf(tagId) >= 0 ||
      this.notes.some((note) => note.tagIds.indexOf(tagId) >= 0)
    )
  }

  isEmpty() {
    return (
      this.tagIds.length === 0 && this.notes.filter((note) => note.isEmpty())
    )
  }

  getNonImageFileIds() {
    const fileIds = new Set()
    for (const note of this.notes) {
      for (const fileId of note.fileIds) {
        fileIds.add(fileId)
      }
    }
    return fileIds
  }

  getAllBucketFileIds() {
    const fileIds = new Set()
    for (const note of this.notes) {
      for (const fileId of note.fileIds) {
        fileIds.add(fileId)
      }
      const imageFileIds = note.getImageFileIds()
      imageFileIds.forEach((fileId) => fileIds.add(fileId))
    }
    return fileIds
  }

  clean(session) {
    let editingNoteIndex = this.notes.findIndex(
      (note) => note.id === this.editingNoteId,
    )
    const newNotes = []
    for (let i = 0; i < this.notes.length; i++) {
      let note = this.notes[i]
      // Clear out broken tagId references
      note.tagIds = note.tagIds.filter((tagId) => !!session.tags[tagId])
      if (htmlHasNonWhiteSpace(note.text) || note.tagIds.length > 0) {
        // Clean out empty notes
        newNotes.push(note)
      } else if (i < editingNoteIndex) {
        editingNoteIndex--
      }
    }
    this.notes = newNotes
    // Clear out broken tagId references
    this.tagIds = this.tagIds.filter((tagId) => !!session.tags[tagId])
    if (this.notes.length > 0) {
      if (editingNoteIndex >= 0) {
        editingNoteIndex = Math.max(
          0,
          Math.min(editingNoteIndex, this.notes.length - 1),
        )
      }
    } else {
      editingNoteIndex = -1
    }
    if (editingNoteIndex >= 0) {
      this.editingNoteId = this.notes[editingNoteIndex].id
    } else {
      this.editingNoteId = null
    }
  }
}
