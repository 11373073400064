import { getGapiOauth2Token } from './getGapiOauth2Token'

/**
 * Checks to see if the user has an actively valid GAPI token with the required scopes.  If not, it will return the
 * scopes that are missing.  If the user is not logged in, it will return all the scopes.
 *
 * If the token needed to be refreshed, and was found to still be valid, it will return the refreshed token as `newGapiToken`.
 *
 * @param scopes - An array of scopes to check for
 * @param currentUserId - The current user's ID (optional)
 * @param gapiToken - The current user's gapi token (optional)
 * @returns {Promise<{missingScopes: *[]}|{missingScopes: *[], newGapiToken: *}>} - An object with the missing scopes, and optionally a new gapi token
 */
export async function validateGapiTokenForScopes(
  scopes,
  currentUserId = null,
  gapiToken = null,
) {
  const missingScopes = []
  try {
    if (currentUserId) {
      if (!gapiToken) {
        gapiToken = await getGapiOauth2Token(currentUserId)
      }
      console.log('gapiToken: ', gapiToken)
      if (gapiToken) {
        // User is logged in and has a gapi token.. check the token to see if we even need to request more scopes
        const existingScopes = new Set(gapiToken.scope.split(' '))
        console.log('existingScopes: ', existingScopes)
        for (let scope of scopes) {
          if (Array.isArray(scope)) {
            let matchesAnySubScope = false
            for (let subScope of scope) {
              matchesAnySubScope ||= existingScopes.has(subScope)
            }
            if (!matchesAnySubScope) {
              console.log('Missing scope: ', scope)
              missingScopes.push(scope)
            }
          } else if (!existingScopes.has(scope)) {
            console.log('Missing scope: ', scope)
            missingScopes.push(scope)
          }
        }
        if (missingScopes.length === 0) {
          console.log('Scopes already authorized')
          // All the scopes have already been authorized.  We don't need to do the auth flow again!

          // if the token expires before 10 seconds from now...
          if (gapiToken.expiry_date < Date.now() + 10000) {
            console.log('Token apparently expired or about to be, refreshing')
            // Token needs refreshed
            missingScopes.push(...scopes)
          }
        }
      } else {
        // User is logged in but has no gapi token.  Request the minimal amount of scopes required, as they will be
        // progressively requested as needed.
        missingScopes.push(...scopes)
      }
    } else {
      missingScopes.push(...scopes)
    }
  } catch (err) {
    console.log('Error validating gapi token for scopes', err)
    missingScopes.push(...scopes)
  }
  return {
    missingScopes: missingScopes.map((scope) => {
      if (Array.isArray(scope)) {
        return scope[0] // Return the first scope in the array, presumably the least privileged
      } else {
        return scope
      }
    }),
  }
}
