import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getAccessRequest(accessRequestId) {
  const snap = await getDoc(refs.accessRequest(accessRequestId))
  if (snap.exists()) {
    return snap.data()
  }
  return null
}
