import Vue from 'vue'

export const state = {
  oldSessions: {},
}

export const getters = {
  oldSessions: (state) => state.oldSessions,
}

export const mutations = {
  setOldSession: (state, { compositeId, newVal, oldVal }) => {
    const { id } = compositeId
    if (newVal) {
      Vue.set(state.oldSessions, id, newVal)
    } else if (state.oldSessions[id]) {
      Vue.delete(state.oldSessions, id)
    }
  },
}

export const actions = {}
