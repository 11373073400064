const visitors = {
  editingGoogleDocs: (expectation, { expectationsRc }) =>
    expectationsRc.dataMap[expectation.id]?.total || 0,
  attachments: (expectation, { expectationsRc }) =>
    expectationsRc.dataMap[expectation.id]?.total || 0,
  externalUrls(expectation, { externalUrlsRc, urlDataRc }) {
    let timeLogged
    if (externalUrlsRc.time) timeLogged = externalUrlsRc.time
    if (externalUrlsRc.top5Urls.length === 0) {
      timeLogged = 0
    } else {
      const topUrl = externalUrlsRc.top5Urls[0]
      timeLogged = urlDataRc.urlData[topUrl].time
      if (timeLogged < 500) {
        timeLogged = 0
      }
    }
    return timeLogged
  },
}

export function calculateExpectationRatioComplete(
  expectation,
  { externalUrlsRc, expectationsRc, urlDataRc, assignmentDetail },
) {
  if (!visitors[expectation.expectationType]) {
    throw new Error(`Unknown expectation type ${expectation.expectationType}`)
  }

  const timeLogged = visitors[expectation.expectationType](expectation, {
    externalUrlsRc,
    expectationsRc,
    urlDataRc,
  })

  return Math.max(
    0,
    Math.min(
      1,
      timeLogged / assignmentDetail.minExpectedTimeMs / expectation.ratio,
    ),
  )
}

export function calculateAllExpectationsRatioComplete({
  externalUrlsRc,
  expectationsRc,
  urlDataRc,
  assignmentDetail,
}) {
  const result = {}
  if (assignmentDetail.hasExpectations()) {
    assignmentDetail.expectations.forEach((expectation) => {
      result[expectation.id] = calculateExpectationRatioComplete(expectation, {
        externalUrlsRc,
        expectationsRc,
        urlDataRc,
        assignmentDetail,
      })
    })
  }

  return result
}
