import { nanoid } from 'nanoid'

/**
 * An Invitation is a record of an invitation to a user to join some resource,
 * with a given set of roles.
 *
 * Invitations may only be created via a cloud function, and may only be
 * accepted by the invited user.  The inviter may revoke the invitation at any
 * time.
 */
export class Invitation {
  constructor({
    id = nanoid(32),
    resourceType,
    resourceId,
    fromUserId,
    userIdentifiers,
    userIdentifierTypes,
    roles = ['Viewer'],
    responseDate = null,
    accepted = false,
  }) {
    this.id = id
    this.resourceType = resourceType
    this.resourceId = resourceId
    this.fromUserId = fromUserId
    this.userIdentifiers = userIdentifiers
    this.userIdentifierTypes = userIdentifierTypes
    this.roles = roles
    this.responseDate = responseDate || null
    this.accepted = !!accepted
  }

  get toUserId() {
    const uidTypeIndex = this.userIdentifierTypes.indexOf('uid')
    return uidTypeIndex >= 0 ? this.userIdentifiers[uidTypeIndex] : null
  }

  get toUserEmail() {
    const emailTypeIndex = this.userIdentifierTypes.indexOf('email')
    return emailTypeIndex >= 0 ? this.userIdentifiers[emailTypeIndex] : null
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {Object}
   */
  toJson() {
    return {
      id: this.id,
      resourceType: this.resourceType,
      resourceId: this.resourceId,
      fromUserId: this.fromUserId,
      userIdentifiers: this.userIdentifiers,
      userIdentifierTypes: this.userIdentifierTypes,
      roles: [...this.roles],
      responseDate: this.responseDate || null,
      accepted: !!this.accepted,
    }
  }
}
