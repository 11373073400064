import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

/**
 * Send a message through Firebase Cloud Messaging to all known FCM registration IDs.
 *
 * This should only be used by Learnics system administrators.
 *
 * @param {Object} data The data to send.
 * @returns {Promise<Object>} The result.
 */
export async function sendCloudMessageToAll(data) {
  const result = await httpsCallable(
    functions,
    'sendCloudMessageToAll',
  )({
    data,
  })
  console.log('CloudMessageService.sendCloudMessageToAll result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.data
}
