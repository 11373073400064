export function pullQueryStringParam(keyName, url) {
  try {
    const queryString = url.split('?')[1]
    const queryParams = queryString.split('&')
    const search = queryParams
      .map((s) => {
        const kv = s.split('=')
        const k = kv[0]
        const v = kv[1]
        if (k === keyName) {
          return v
        }
        return null
      })
      .filter((v) => {
        return v !== null
      })[0]
    if (search) {
      // Replace + with space
      return decodeURIComponent(search.replace(/\+/g, encodeURIComponent(' ')))
    }
  } catch (e) {
    console.log('Unable to parse: ', url, e)
  }
  return null
}
