import { pullQueryStringParam } from './pullQueryStringParam'
import { galeUrlToSearchKeyword } from './galeUrlToSearchKeyword'
import { advancedJstorUrlToSearchKeyword } from './advancedJstorUrlToSearchKeyword'

const processQuery = (q) => {
  return encodeURIComponent(q).replace(/%20/g, '+')
}
export const recognizedSearchEngines = {
  bing: {
    name: 'Bing',
    domain: 'https://www.bing.com',
    parsers: {
      'https://www.bing.com/search?': (url) => pullQueryStringParam('q', url),
    },
    queryToUrl: (q) => `https://www.bing.com/search?q=${processQuery(q)}`,
  },
  duckDuckGo: {
    name: 'DuckDuckGo',
    domain: 'https://duckduckgo.com',
    parsers: {
      'https://duckduckgo.com/?': (url) => pullQueryStringParam('q', url),
    },
    queryToUrl: (q) => `https://duckduckgo.com/?q=${processQuery(q)}`,
  },
  ebsco: {
    name: 'EBSCO',
    domain: 'https://essentials.ebsco.com',
    parsers: {
      'https://essentials.ebsco.com/search?': (url) =>
        pullQueryStringParam('query', url),
    },
    queryToUrl: (q) =>
      `https://essentials.ebsco.com/search?query=${processQuery(q)}`,
  },
  gale: {
    name: 'Gale',
    domain: 'https://www.gale.com',
    parsers: {
      'gale.com': (url) => galeUrlToSearchKeyword(url),
    },
    /**
     * TODO: Implement this, if needed, by breaking up this
     * gale object into multiple objects, one for each gale
     * product. For example, galeAcademicOneFile, galeInContext,
     * etc. Then, we can recognize gale as though it is multiple
     * distinct search engines, with each type of search having
     * its own queryToUrl function.
     *
     **/
    // queryToUrl: (q) => /** Return the appropriate url for the gale product search query */
  },
  google: {
    name: 'Google',
    domain: 'https://www.google.com',
    parsers: {
      'https://www.google.com/search?': (url) => pullQueryStringParam('q', url),
    },
    queryToUrl: (q) => `https://www.google.com/search?q=${processQuery(q)}`,
  },
  googleScholar: {
    name: 'Google Scholar',
    domain: 'https://scholar.google.com',
    parsers: {
      'https://scholar.google.com/scholar?': (url) =>
        pullQueryStringParam('q', url),
    },
    queryToUrl: (q) =>
      `https://scholar.google.com/scholar?q=${processQuery(q)}`,
  },
  infobase: {
    name: 'Infobase',
    domain: 'https://online.infobase.com',
    parsers: {
      'https://online.infobase.com': (url) => pullQueryStringParam('q', url),
    },
    queryToUrl: (q) =>
      `https://online.infobase.com/Auth/Index?aid=104048&itemid=WE40&articleId=${processQuery(
        q,
      )}`,
  },
  jstor: {
    name: 'JSTOR',
    domain: 'https://www.jstor.org',
    parsers: {
      'https://www.jstor.org/action/doBasicSearch': (url) =>
        pullQueryStringParam('Query', url),
      // Unsure if this is needed
      'https://jstor.org/action/doBasicSearch': (url) =>
        pullQueryStringParam('Query', url),
      'https://www.jstor.org/action/doAdvancedSearch': (url) =>
        advancedJstorUrlToSearchKeyword(url),
      // Unsure if this is needed
      'https://jstor.org/action/doAdvancedSearch': (url) =>
        advancedJstorUrlToSearchKeyword(url),
    },
    queryToUrl: (q) =>
      `https://www.jstor.org/action/doBasicSearch?Query=${processQuery(q)}`,
  },
  yahoo: {
    name: 'Yahoo',
    domain: 'https://search.yahoo.com',
    parsers: {
      'https://search.yahoo.com/search': (url) =>
        pullQueryStringParam('p', url),
    },
    queryToUrl: (q) => `https://search.yahoo.com/search?p=${processQuery(q)}`,
  },
}
