import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

import { loadRosterFromRoles } from '../user/loadRosterFromRoles'

export async function getCourseRoster(courseId) {
  let course = await getDoc(refs.course(courseId))
  if (!course.exists()) return null
  course = course.data()
  return loadRosterFromRoles(course.roles)
}
