import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getLoggerSessionsForUser(
  userId,
  callback = async () => {},
) {
  const sessions = {}
  const querySnapshot = await getDocs(
    query(refs.loggerSessions(), where('userId', '==', userId)),
  )
  for (const sessionDoc of querySnapshot.docs) {
    const session = sessionDoc.data()
    if (session) {
      sessions[sessionDoc.id] = session
      await callback(session)
    }
  }
  return sessions
}
