import { getFunctions, httpsCallable } from 'firebase/functions'

export async function createDummyStudentForTeacher(forced = false) {
  const result = await httpsCallable(
    getFunctions(),
    'createDummyStudentForTeacher',
  )({ forced })
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.customToken
}
