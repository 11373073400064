import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getAllKeysForAssignment(assignmentId) {
  try {
    const assignmentKeySnap = await getDoc(
      refs.assignmentUserKey(assignmentId, 'all'),
    )
    if (!assignmentKeySnap.exists()) return {}
    return assignmentKeySnap.data()
  } catch (e) {
    console.log('Error in getAllKeysForAssignment: ', e)
    throw e
  }
}
