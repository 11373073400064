import { ExternalUrlsExpectation } from '../Expectation/ExternalUrlsExpectation'
import { AttachmentsExpectation } from '../Expectation/AttachmentsExpectation'
import { normalizeRatios } from '../Expectation/utils/normalizeRatios'

function expectationsFromViewableAttachments(attachments) {
  const result = []
  const driveFileTypeMap = {}
  const videoAttachments = []
  const linkAttachments = []
  const formAttachments = []
  for (let attachment of attachments) {
    switch (attachment.attachmentType) {
      case 'driveFile':
      case 'sourceDriveFile':
        let driveType = attachment.driveType
        if (driveType === 'video') {
          videoAttachments.push(attachment)
        } else if (driveType === 'form') {
          formAttachments.push(attachment)
        } else {
          driveFileTypeMap[driveType] = driveFileTypeMap[driveType] || []
          driveFileTypeMap[driveType].push(attachment)
        }
        break
      case 'link':
        linkAttachments.push(attachment)
        break
      case 'form':
        formAttachments.push(attachment)
        break
      case 'youtubeVideo':
        videoAttachments.push(attachment)
        break
    }
  }
  if (videoAttachments.length > 0) {
    result.push(
      new AttachmentsExpectation({
        id: 'videos',
        label: `${
          videoAttachments.length > 1 ? videoAttachments.length + ' ' : ''
        }Video${videoAttachments.length > 1 ? 's' : ''}`,
        ratio: 1,
        attachmentIds: videoAttachments.map((a) => a.id),
      }),
    )
  }

  if (linkAttachments.length > 0) {
    result.push(
      new AttachmentsExpectation({
        id: 'link',
        label: `${
          linkAttachments.length > 1 ? linkAttachments.length + ' ' : ''
        }Assigned Website${linkAttachments.length > 1 ? 's' : ''}`,
        ratio: 1,
        attachmentIds: linkAttachments.map((a) => a.id),
      }),
    )
  }
  if (formAttachments.length > 0) {
    result.push(
      new AttachmentsExpectation({
        id: 'form',
        label: `${
          formAttachments.length > 1 ? formAttachments.length + ' ' : ''
        }Form${formAttachments.length > 1 ? 's' : ''}`,
        ratio: 1,
        attachmentIds: formAttachments.map((a) => a.id),
      }),
    )
  }

  for (let driveType in driveFileTypeMap) {
    result.push(
      new AttachmentsExpectation({
        id: driveType,
        label:
          (driveFileTypeMap[driveType].length > 1
            ? driveFileTypeMap[driveType].length + ' '
            : '') +
          driveType.charAt(0).toUpperCase() +
          driveType.slice(1) +
          (driveFileTypeMap[driveType].length > 1 ? 's' : ''),
        ratio: 1,
        attachmentIds: driveFileTypeMap[driveType].map((a) => a.id),
      }),
    )
  }
  return result
}

export function updateExpectations(
  existingExpectationArray,
  newExpectationArray,
) {
  const expectationsToUpdate = newExpectationArray.filter((newExpectation) => {
    return existingExpectationArray.find((existingExpectation) => {
      return newExpectation.id === existingExpectation.id
    })
  })
  const expectationsToAdd = newExpectationArray.filter((newExpectation) => {
    return !existingExpectationArray.find((existingExpectation) => {
      return newExpectation.id === existingExpectation.id
    })
  })

  expectationsToUpdate.forEach((newExpectation) => {
    const oldExpectation = existingExpectationArray.find((e) => {
      return e.id === newExpectation.id
    })

    newExpectation.updateFrom(oldExpectation)
  })

  const existingRatioSum = expectationsToUpdate.reduce((sum, expectation) => {
    return sum + expectation.ratio
  }, 0)

  expectationsToUpdate.forEach((expectation) => {
    expectation.ratio =
      (expectation.ratio / existingRatioSum) *
      (expectationsToUpdate.length /
        (expectationsToAdd.length + expectationsToUpdate.length))
  })

  expectationsToAdd.forEach((expectation) => {
    expectation.ratio =
      1 / (expectationsToAdd.length + expectationsToUpdate.length)
  })

  return newExpectationArray
}

/**
 * Derive a list of expectations from a list of attachments.  Give each expectation a ratio of 1.
 *
 * @param attachments an arbitrary list of attachments
 * @param {Array} [expectationsToUpdate] an optional list of existing expectations to update.  Ratio values and other options will be try to be preserved.
 * @returns {*}
 */
export function expectationsFromAttachments(
  attachments,
  expectationsToUpdate = [],
) {
  let result = []
  const editableAttachments = attachments.filter(
    (attachment) =>
      attachment.editableByStudents &&
      attachment.attachmentType !== 'sourceDriveFile',
  )
  const studentCopyAttachments = attachments.filter(
    (attachment) => attachment.attachmentType === 'sourceDriveFile',
  )

  const viewableAttachments = attachments.filter(
    (attachment) =>
      !attachment.editableByStudents &&
      attachment.attachmentType !== 'sourceDriveFile',
  )

  if (studentCopyAttachments.length > 0) {
    result.push(
      new AttachmentsExpectation({
        id: 'studentCopies',
        label: `${
          studentCopyAttachments.length > 1
            ? studentCopyAttachments.length + ' '
            : ''
        }Student Cop${studentCopyAttachments.length > 1 ? 'ies' : 'y'}`,
        ratio: 1,
        attachmentIds: studentCopyAttachments.map((a) => a.id),
      }),
    )
  }
  if (editableAttachments.length > 0) {
    result.push(
      new AttachmentsExpectation({
        id: 'editable',
        label: `${
          editableAttachments.length > 1 ? editableAttachments.length + ' ' : ''
        }Editable File${editableAttachments.length > 1 ? 's' : ''}`,
        ratio: 1,
        attachmentIds: editableAttachments.map((a) => a.id),
      }),
    )
  }

  const fromViewableAttachments =
    expectationsFromViewableAttachments(viewableAttachments)
  result.push(...fromViewableAttachments)
  result.push(new ExternalUrlsExpectation({ ratio: 1, min: 0, max: 0 }))

  return normalizeRatios(
    updateExpectations(expectationsToUpdate, normalizeRatios(result)),
  )
}
