import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'
import { loggerSessionFromJson } from '@learnics/models/src/Organization/Course/Assignment/Submission/loggerSessionFromJson'

export async function createLoggerSession(
  type = 'oneClick',
  name = 'Session 1',
  assignmentId = null,
) {
  let inResourceId = null,
    inResourceType = null
  if (assignmentId) {
    inResourceId = assignmentId
    inResourceType = 'assignment'
  }
  const result = await httpsCallable(
    functions,
    'createResource',
  )({
    data: { type, name, assignmentId },
    inResourceType,
    inResourceId,
    resourceType: 'loggerSession',
  })
  console.log('createLoggerSession result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return loggerSessionFromJson(result.data.data)
}
