import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getProductTrialsByProductId(productId) {
  const result = {}
  const querySnapshot = await getDocs(
    query(refs.productTrials(), where('productId', '==', productId)),
  )
  for (const doc of querySnapshot.docs) {
    const trial = doc.data()
    if (trial) {
      result[trial.id] = trial
    }
  }
  return result
}
