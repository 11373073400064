import Vue from "vue";

export const state = {
  droppedItem: null,
  draggingItem: null,
  draggingItemOffset: { x: 0.5, y: 0.5 },
};

export const getters = {
  draggingItem: (state) => state.draggingItem,
  droppedItem: (state) => state.droppedItem,
  draggingItemOffset: (state) => state.draggingItemOffset,
};

export const mutations = {
  setDroppedItem(state, payload) {
    Vue.set(state, "droppedItem", payload);
  },
  setDraggingItem(state, payload) {
    Vue.set(state, "draggingItem", payload);
  },
  setDraggingItemOffset(state, payload) {
    Vue.set(state, "draggingItemOffset", payload);
  },
};

export const actions = {};
