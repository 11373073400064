import { RunningCalculator } from './RunningCalculator'

/**
 * CommonRunningCalculators are designed to be used as a base class for calculations
 * across multiple logging sessions.  They are designed to be used in conjunction
 * with other RunningCalculators to provide a more complete picture of student
 * activity.
 *
 * Like with RunningCalculators, there are only a few places that will need to
 * be updated to add a new common running calculator.  First, you'll need to add
 * the new calculator to the map of calculators in the
 * `./utils/calculatorClassMap.js` file.  Then, you'll need to ensure it is added
 * into the actual commonCalculatorMap that gets passed into `transitionCalculators`.
 */
export class CommonRunningCalculator extends RunningCalculator {
  /**
   * Add an entire session's worth of data to the running calculator.  This works
   * for addition and subtraction, per the multiplier value.  Pass in a multiplier
   * of -1 to subtract the session's data and 1 to add it.
   *
   * @param sessionCalcs the session's calculators
   * @param multiplier the multiplier to apply to the session's data
   */
  addSession(sessionCalcs, sessionSecrets, multiplier) {
    if (true) throw new Error('addSession not implemented')
  }
}
