import { defaultUrlFilter } from '../../Event/RunningCalculator/utils/defaultUrlFilter'

const visitors = {
  editingGoogleDocs(urlHash, expectation, stateData) {
    return stateData.studentCopiesRc.links.has(urlHash)
  },
  externalUrls(urlHash, expectation, { secrets }) {
    return defaultUrlFilter(secrets[urlHash])
  },
  totalTime(urlHash, expectation, stateData) {
    return true
  },
  attachments(urlHash, expectation, { assignmentDetail, secrets }) {
    const attachmentsMap = assignmentDetail.attachments.reduce((acc, v) => {
      acc[v.id] = v
      return acc
    }, {})
    for (let i = 0; i < expectation.attachmentIds.length; i += 1) {
      if (
        attachmentsMap[expectation.attachmentIds[i]]?.matchesUrl(
          secrets[urlHash],
        )
      ) {
        return true
      }
    }
    return false
  },
}

function doesSiteMeetExpectation(urlHash, expectation, stateData) {
  if (!visitors[expectation.expectationType]) {
    throw new Error(`Unknown expectation type ${expectation.expectationType}`)
  }
  return visitors[expectation.expectationType](urlHash, expectation, stateData)
}

export function getMatchingExpectations(
  hashedUrl,
  { assignmentDetail, secrets, studentCopiesRc },
) {
  if (!assignmentDetail.hasExpectations()) return []
  const expectations = assignmentDetail.expectations
  let matchingExpectations = expectations.filter((expectation) =>
    doesSiteMeetExpectation(hashedUrl, expectation, {
      assignmentDetail,
      secrets,
      studentCopiesRc,
    }),
  )
  // We have to handle externalUrls specially because it's a special case.  it depends on the other expectations.
  const externalUrlsExpectation = matchingExpectations.find(
    (expectation) => expectation.expectationType === 'externalUrls',
  )
  // If we find an externalUrls expectation, and we find any other expectation, then we remove the externalUrls expectation.
  if (externalUrlsExpectation && matchingExpectations.length > 1) {
    matchingExpectations = matchingExpectations.filter(
      (expectation) => expectation.expectationType !== 'externalUrls',
    )
  }
  return matchingExpectations
}
