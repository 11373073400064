import { RunningCalculator } from './RunningCalculator'
import { calculateAllExpectationsRatioComplete } from '../../Expectation/utils/calculateExpectationRatioComplete'

/**
 * ExpectationRatiosRc stores simple ratio completion data for each expectation of an assignment.
 *
 * This allows for a single source of truth for the progress on an assignment.
 */
export class ExpectationRatiosRc extends RunningCalculator {
  ratiosComplete = {}
  constructor(storageObj) {
    super('expectationRatiosRc', ['browserRc'])
    this.ratiosComplete = JSON.parse(
      JSON.stringify(storageObj?.ratiosComplete || {}),
    )
  }

  toJson() {
    return {
      ...super.toJson(),
      ratiosComplete: JSON.parse(JSON.stringify(this.ratiosComplete)),
    }
  }

  transition(event, stateData) {
    let stateChanged = false

    const timeRange = stateData.timeRange
    if (event.time < timeRange.start || event.time > timeRange.stop)
      return stateChanged
    const newValues = calculateAllExpectationsRatioComplete(stateData)

    for (const expectation of stateData.assignmentDetail.expectations) {
      if (newValues[expectation.id] !== this.ratiosComplete[expectation.id]) {
        stateChanged = true
        this.ratiosComplete[expectation.id] = newValues[expectation.id]
      }
    }
    return stateChanged
  }

  allExpectationsCompleted(expectations) {
    for (const expectation of expectations) {
      if (
        !this.ratiosComplete[expectation.id] ||
        this.ratiosComplete[expectation.id] < 1
      ) {
        return false
      }
    }
    return true
  }

  totalRatioComplete(expectations) {
    let total = 0
    for (const expectation of expectations) {
      total += this.ratiosComplete[expectation.id] || 0
    }
    return total / expectations.length
  }
}
