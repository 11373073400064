import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function reloadUsersFromFirebaseRecord(userIds) {
  const result = await httpsCallable(
    functions,
    'reloadUserDocumentFromRecord',
  )({
    userIds,
  })
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  console.log('Reloaded users from Firebase record', result.data)
}
