/**
 * Converts a date to an ISO date string
 * @param date [Date] the date to be converted
 * @returns {string} the ISO date string
 */
export function dateToIsoDateString(date) {
  const pad = (n) => {
    return n < 10 ? '0' + n : n
  }
  return (
    date.getUTCFullYear() +
    '-' +
    pad(date.getUTCMonth() + 1) +
    '-' +
    pad(date.getUTCDate()) +
    'T' +
    pad(date.getUTCHours()) +
    ':' +
    pad(date.getUTCMinutes()) +
    ':' +
    pad(date.getUTCSeconds()) +
    'Z'
  )
}
