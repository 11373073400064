import { AbstractBrowserTabEvent } from './AbstractBrowserTabEvent'

/**
 * A class to represent a TabReplaceEvent for logging sessions.  This type of
 * event is recorded when the browser, for whatever reason internally, replaces
 * one tab with another.  This is not a user-initiated action, but rather a
 * browser-initiated action.
 *
 * We record this event only temporarily.  Ultimately we can safely remove it,
 * once the session is complete, because we can reconstruct the tab history and
 * cancel out the effect of this event in calculations after the fact.
 *
 * We save it so that we can safely stream the session to the server, without
 * having to worry about revising the tab history in unreliable service workers.
 *
 * @example
 * // Concrete initialization and usage
 *
 * // Instantiate the event on the fly, so you can use it to calculate
 * // time and other things
 * const event = new TabReplaceEvent({
 *   tabId: 123,
 *   value: 456,
 * });
 *
 * // Create a database-friendly version of the event.  This should be a
 * // clone of the JSON object that was given to the constructor, except with
 * // the id and eventType properties both set to "attachments".
 * const savableEventData = event.toJson();
 *
 * @example
 * // Abstract initialization and usage
 * import { eventFromJson } from '@learnics/models/src/Organization/Course/Assignment/Event/utils/eventFromJson'
 *
 * const databaseEvents = [{
 *   id: "some-learnics-generated-id",
 *   eventType: "tabReplace",
 *   time: 1654887559641,
 *   tabId: 123,
 *   value: 456,
 * }, {
 *     // ... some other event
 * }];
 *
 * // Instantiate an array of serialized AbstractEvent JSON objects (from
 * // the database), in order to restore their functionality.
 * const events = databaseEvents.map(
 *     event => eventFromJson(event)
 *   );
 *
 *
 * // Create a database-friendly version of an array of events.  This
 * // should be a clone of the databaseEvents array.
 * const savableEventsCopy = events.map(
 *     event => event.toJson()
 *   )
 *
 *   @inheritDoc
 */
export class TabReplaceEvent extends AbstractBrowserTabEvent {
  static COLUMNS = [...AbstractBrowserTabEvent.COLUMNS, 'value']
  /**
   * Constructor for the TabReplaceEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain TabReplaceEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {boolean} [input.implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   * @param {number} [input.tabId] the associated chrome-based tab id
   * @param {string} [input.value] the new value of the property
   *
   * @constructor
   */

  constructor({ time, implicit, tabId, url, title, value }) {
    super('tabReplace', time, false, implicit, tabId, url, title)
    this.value = value
  }

  /**
   * Convert this object into a simple Json object.
   *
   * @returns {object} a JSON object representing this object.
   */
  toJson() {
    const json = super.toJson()
    json.value = this.value
    return json
  }
}
