/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { send } from './send'
import { sendCloudMessageToAll } from './sendCloudMessageToAll'
import { sendCloudMessageToExtensions } from './sendCloudMessageToExtensions'
import { sendCloudMessageToStudents } from './sendCloudMessageToStudents'
import { sendToRegistrationIds } from './sendToRegistrationIds'

const CloudMessageService = {
  send,
  sendCloudMessageToAll,
  sendCloudMessageToExtensions,
  sendCloudMessageToStudents,
  sendToRegistrationIds,
}

export default CloudMessageService
