/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { createProductTrial } from './createProductTrial'
import { getProductTrial } from './getProductTrial'
import { getProductTrialsByProductId } from './getProductTrialsByProductId'
import { getProductTrialsByTrialType } from './getProductTrialsByTrialType'
import { getProductTrialsForUser } from './getProductTrialsForUser'

const ProductTrialService = {
  createProductTrial,
  getProductTrial,
  getProductTrialsByProductId,
  getProductTrialsByTrialType,
  getProductTrialsForUser,
}

export default ProductTrialService
