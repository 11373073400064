/**
 * A class representing a file uploaded by a user and stored
 * in storage under /{id}
 *
 * @param {Object} options - The options to create the file
 * @param {string} options.id - The id of the file
 * @param {string} options.sessionId - The id of the session the file is attached to
 * @param {string} options.signedUrl - The *TRANSIENT* signed url of the file, which is not stored in the database
 * @param {number} options.signedUrlDate - The *TRANSIENT* timestamp of the file's signed url, which is not stored in the database
 * @param {string} options.type - The type of the file
 * @param {string} options.name - The name of the file
 * @param {number} options.width - The width of the file, if it is an image (i.e. it's type is 'image/*')
 * @param {number} options.height - The height of the file, if it is an image (i.e. it's type is 'image/*')
 * @param {number} options.size - The size of the file in bytes
 * @param {string} options.uploaderId - The id of the user who uploaded the file
 * @param {number} options.dateUploaded - The timestamp of when the file was uploaded
 * @param {number|null} options.dateDeleted - The timestamp of when the file was
 *        deleted, if it was deleted.  This is a flag to indicate that the file
 *        has been deleted from its references.  If it is null, the file has not
 *        been deleted.  If it is set to a timestamp, this file should be
 *        examined using a periodic cloud function to see if it should be
 *        deleted from storage.
 */

export class BucketFile {
  constructor({
    id,
    signedUrl,
    type,
    name,
    signedUrlDate,
    width,
    height,
    size,
    uploaderId,
    dateUploaded,
    dateDeleted,
    dateCreated,
  }) {
    this.id = id
    this.signedUrl = signedUrl
    this.type = type
    this.name = name
    this.signedUrlDate = signedUrlDate
    this.width = width
    this.height = height
    this.size = size
    this.uploaderId = uploaderId
    this.dateUploaded = dateUploaded
    this.dateDeleted = dateDeleted
    this.dateCreated = dateCreated
  }

  toJson() {
    return {
      id: this.id,
      signedUrl: this.signedUrl || null,
      type: this.type || null,
      name: this.name || null,
      signedUrlDate: this.signedUrlDate || null,
      width: this.width || null,
      height: this.height || null,
      size: this.size || 0,
      uploaderId: this.uploaderId || null,
      dateUploaded: this.dateUploaded || null,
      dateDeleted: this.dateDeleted || null,
      dateCreated: this.dateCreated || null,
    }
  }

  toDbJson() {
    const json = this.toJson()
    delete json.signedUrl
    delete json.signedUrlDate
    Object.keys(json).forEach((key) => {
      if (json[key] === undefined) {
        delete json[key]
      }
    })
    return json
  }
}
