export async function getAccessTokenInfo(accessToken) {
  const url = `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${encodeURIComponent(
    accessToken,
  )}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const res = await response.json()
  console.log('getTokenInfo response: ', res)
  if (res.error) {
    console.log('Error getting tokenInfo: ', res.error)
    throw res.error
  } else {
    return res
  }
}
