import { getDocs } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function loadAssignmentKeys(assignmentId) {
  const snapshots = await getDocs(refs.assignmentUserKeys(assignmentId))
  const result = {}
  for (const doc of snapshots.docs) {
    result[doc.id] = doc.data()
  }
  return result
}
