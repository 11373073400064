export async function createCourseWork(
  accessToken,
  courseId,
  courseworkObject,
) {
  const url = `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(courseworkObject),
  })
  const res = await response.json()
  console.log('createCourseWork response: ', res)
  if (res.error) {
    console.log('Error getting courses: ', res.error)
    throw res.error
  } else {
    return res
  }
}
