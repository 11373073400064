import { loggerSessionFromJson } from '../../Organization/Course/Assignment/Submission/loggerSessionFromJson'

export class LoggerSessionFirestoreConverter {
  static toFirestore(loggerSession) {
    if (!loggerSession) {
      return null
    }
    return loggerSession.toJson()
  }

  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    if (!data) {
      return null
    }
    return loggerSessionFromJson(data)
  }
}
