import { getDoc } from 'firebase/firestore/lite'
import { issueAssignmentKey } from './issueAssignmentKey'
import refs from '../refsLite'

export async function getAssignmentKey(assignmentId, uid) {
  try {
    const assignmentKeySnap = await getDoc(
      refs.assignmentUserKey(assignmentId, uid),
    )
    const data = assignmentKeySnap.exists() && assignmentKeySnap.data()
    let key = data?.key || data?.sessionIds?.[0]
    if (!key) {
      throw new Error('User assignment key does not exist')
    }

    return key
  } catch (e) {
    return await issueAssignmentKey(assignmentId)
  }
}
