import { functions } from '../coreConfig'
import { httpsCallable } from 'firebase/functions'

/**
 * Send a bare-bones message through Firebase Cloud Messaging.
 *
 * NOTE: Dev only.
 *
 * @param {Object} message The message to send.
 * @returns {Promise<Object>} The result of the send.
 */
export async function send(message) {
  const result = await httpsCallable(
    functions,
    'sendCloudMessage',
  )({
    message,
  })
  console.log('CloudMessageService.send result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.result
}
