import { AbstractBrowserTabEvent } from './AbstractBrowserTabEvent'

/**
 * A class to represent a tab focus event.  This event combines multiple events
 * into one, and it is only concerned about the tab that is the topmost tab of
 * the currently focused window, whatever that window is and whatever that tab is.
 *
 *
 *   @inheritDoc
 */
export class TabFocusEvent extends AbstractBrowserTabEvent {
  static COLUMNS = [...AbstractBrowserTabEvent.COLUMNS]
  /**
   * Constructor for the TabFocusEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain TabFocusEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {boolean} [input.redacted] whether or not this event contains redacted data.
   * @param {boolean} [input.implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   * @param {number} [input.tabId] the associated chrome-based window id
   *
   * @constructor
   */

  constructor({ time, redacted, implicit, tabId }) {
    super('tabFocus', time, redacted, implicit, tabId)
  }
}
