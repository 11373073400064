export class UserData {
  static MAX_ITEM_IDS_TO_TRACK = 20
  timestampMaps = {}

  constructor({ uid, timestampMaps = {}, sessionCount = 0 }) {
    this.uid = uid
    this.timestampMaps = JSON.parse(JSON.stringify(timestampMaps || {}))
    this.sessionCount = sessionCount
  }

  get id() {
    return this.uid
  }

  updateItemTimestamp(timestampMapId, itemId, newTime = +new Date()) {
    if (!timestampMapId) throw new Error('timestampMapId is required')
    if (!itemId) throw new Error('itemId is required')
    this.timestampMaps[timestampMapId] ||= {}
    const timestampMap = this.timestampMaps[timestampMapId]
    const existingItemIds = Object.keys(timestampMap)
    const sortedItemIds = existingItemIds.sort(
      (a, b) => timestampMap[a] - timestampMap[b],
    )
    let wasLastSeenItemId = sortedItemIds[sortedItemIds.length - 1] === itemId
    if (!timestampMap[itemId]) {
      if (existingItemIds.length >= UserData.MAX_ITEM_IDS_TO_TRACK) {
        // the map is full, delete the oldest item IDs from it.
        const itemsToDelete =
          existingItemIds.length - UserData.MAX_ITEM_IDS_TO_TRACK + 1
        for (let i = 0; i < itemsToDelete; i++) {
          delete timestampMap[sortedItemIds[i]]
        }
      }
    }
    timestampMap[itemId] = Math.max(newTime, timestampMap[itemId] || 0)
    return !wasLastSeenItemId
  }

  toJson() {
    return {
      uid: this.uid,
      timestampMaps: JSON.parse(JSON.stringify(this.timestampMaps || {})),
      sessionCount: this.sessionCount,
    }
  }

  getSortedTimestampValues(timestampMapId) {
    const map = this.timestampMaps[timestampMapId] || {}
    return Object.keys(map).sort((a, b) => {
      return map[b] - map[a]
    })
  }
}
