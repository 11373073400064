/**
 *
 * Turn a timestamp into a locale-based date string of the format 'YYYY-MM-DD'
 *
 */
export function getLocaleDateString(timestamp) {
  const d = new Date(timestamp).toLocaleDateString('en-US')
  let dSplit = d.split('/').map((x) => (x.length === 1 ? '0' + x : x))
  return dSplit[2] + '-' + dSplit[0] + '-' + dSplit[1]
}
