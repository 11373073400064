import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getStudentCopyDriveFilesByDriveFileId(
  driveFileId,
  callback = async () => {},
) {
  const result = {}

  const querySnapshot = await getDocs(
    query(
      refs.studentCopyDriveFiles(),
      where('driveFileId', '==', driveFileId),
    ),
  )
  for (const fileDoc of querySnapshot.docs) {
    const file = fileDoc.data()
    if (file) {
      result[fileDoc.id] = file
      await callback(file)
    }
  }
  return result
}
