import { getEventMetaInfo } from './getEventMetaInfo'
import { loadUserSubmission } from '../submission/loadUserSubmission'
import { loadRunningCalculators } from './loadRunningCalculators'
import { runningCalculatorsFromSubmission } from '@learnics/models/src/Organization/Course/Assignment/Event/RunningCalculator/utils/runningCalculatorsFromSubmission'
import { calculatorClassMap } from '@learnics/models/src/Organization/Course/Assignment/Event/RunningCalculator/utils/calculatorClassMap'

export async function getBackwardCompatibleRunningCalculators(
  sessionId,
  assignment,
  assignmentDetail,
  uid,
  metaInfo = null,
  additionalCalculatorIds = [],
  commonCalculators = {},
  additionalStateData = {},
) {
  if (!metaInfo) {
    metaInfo = await getEventMetaInfo(sessionId)
  }
  let secrets, keysDeleted, keysBlacklisted, events, calculators
  if (metaInfo.hasEvents) {
    console.log('Found events... Using them to calculate logging progress!')
    const eventData = await loadRunningCalculators(
      sessionId,
      [],
      additionalCalculatorIds,
      assignment.id,
      commonCalculators,
      additionalStateData,
    )
    secrets = eventData.secrets
    keysDeleted = eventData.keysDeleted
    keysBlacklisted = eventData.keysBlacklisted
    events = eventData.events
    calculators = eventData.calculators
  } else if (uid) {
    const userSubmission = await loadUserSubmission(assignment.id, uid)
    const conversionResult = runningCalculatorsFromSubmission(
      userSubmission,
      assignment,
      assignmentDetail,
    )

    calculators = conversionResult.calculators
    secrets = conversionResult.secrets
    keysDeleted = conversionResult.keysDeleted
    keysBlacklisted = conversionResult.keysBlacklisted
  } else {
    calculators = {}
    Object.keys(calculatorClassMap).forEach((calcName) => {
      calculators[calcName] = new calculatorClassMap[calcName]()
    })
    secrets = {}
    keysDeleted = {}
    keysBlacklisted = new Set()
  }

  return {
    calculators,
    secrets,
    keysDeleted,
    keysBlacklisted,
    metaInfo,
    events,
    commonCalculators,
  }
}
