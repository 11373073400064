import Vue from 'vue'

export const state = {
  yjsProviders: {},
  yjsProvidersSaving: {},
  yjsProvidersSaved: {},
  yjsCollaborators: {},
}

export const getters = {
  yjsProviders: (state) => state.yjsProviders,
  yjsProvidersSaving: (state) => state.yjsProvidersSaving,
  yjsProvidersSaved: (state) => state.yjsProvidersSaved,
  yjsCollaborators: (state) => state.yjsCollaborators,
}

export const mutations = {
  setYjsProvider: (state, { resourceType, resourceId, newVal }) => {
    if (!state.yjsProviders[resourceType]) {
      Vue.set(state.yjsProviders, resourceType, {})
    }
    Vue.set(state.yjsProviders[resourceType], resourceId, newVal)
  },
  setYjsProviderSaving: (state, { resourceType, resourceId, newVal }) => {
    console.log(
      "Provider's saving the " + resourceType + ' {#' + resourceId + '}!',
      newVal,
    )
    if (!state.yjsProvidersSaving[resourceType]) {
      Vue.set(state.yjsProvidersSaving, resourceType, {})
    }
    Vue.set(state.yjsProvidersSaving[resourceType], resourceId, !!newVal)
  },
  setYjsProviderSaved: (state, { resourceType, resourceId, newVal }) => {
    console.log(
      "Provider's saved the " + resourceType + ' {#' + resourceId + '}!',
      newVal,
    )
    if (!state.yjsProvidersSaved[resourceType]) {
      Vue.set(state.yjsProvidersSaved, resourceType, {})
    }
    Vue.set(state.yjsProvidersSaved[resourceType], resourceId, !!newVal)
  },

  setYjsCollaborators: (state, { resourceType, resourceId, newVal }) => {
    if (!state.yjsCollaborators[resourceType]) {
      Vue.set(state.yjsCollaborators, resourceType, {})
    }
    Vue.set(state.yjsCollaborators[resourceType], resourceId, newVal)
  },
}

export const actions = {}
