import { DOMSerializer } from 'prosemirror-model'
import _ from 'lodash'
import { downgradeCustomExtensionsForElement } from './downgradeCustomExtensionsForElement'

/**
 *
 * This is a custom fragment serializer for TipTap.  It's used to adjust the
 * clipboard html to be more plain html that can be pasted into other
 * contexts.
 *
 *
 * @param schema the prose mirror schema to use
 * @param fragment the fragment to serialize
 * @param options the options to use for serialization
 * @param target the target to serialize to
 * @param getResource a synchronous function that takes a resource type and id and returns the resource
 * @return {HTMLElement | DocumentFragment} the adjusted serialized fragment
 */
//

export function downgradeCustomExtensionsToPlainHtml(
  schema,
  fragment,
  options,
  target,
  getResource = (resourceType, resourceId) => null,
) {
  // Use the built-in serializer to get the default result
  const defaultResult = DOMSerializer.fromSchema(schema).serializeFragment(
    fragment,
    options,
    target,
  )

  defaultResult.childNodes.forEach((child) => {
    // Iterate through each child node, adjusting it to be plain html without
    // any custom TipTap-specific elements.
    downgradeCustomExtensionsForElement(child, getResource)
  })
  // For debugging:
  let div = document.createElement('div')
  div.appendChild(defaultResult.cloneNode(true))
  return defaultResult
}
