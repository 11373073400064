import { colorPalette } from '../../../../utils/colorPalette'

export function createPieChartColorArray() {
  const colorGroups = [
    'mediumBlues',
    'reds',
    'darkBlues',
    'greens',
    'lightBlues',
  ]
  const colorsPerGroup = 5
  const result = []
  for (let k = 0; k < colorsPerGroup; k += 1) {
    for (let i = 0; i < colorGroups.length; i += 1) {
      result.push(
        colorPalette[colorGroups[colorGroups.length - (i + 1)]][
          colorsPerGroup - (k + 1)
        ],
      )
    }
  }
  return result
}
