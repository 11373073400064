import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function deleteResources(
  resourceType,
  resourceIds,
  fromResourceType = null,
  fromResourceId = null,
) {
  console.log(
    'deleteResources',
    resourceType,
    resourceIds,
    fromResourceType,
    fromResourceId,
  )
  const cloudFuncResult = await httpsCallable(
    functions,
    'deleteResources',
  )({
    resourceIds,
    resourceType,
    fromResourceType,
    fromResourceId,
  })
  if (!cloudFuncResult.data.success) {
    throw new Error(cloudFuncResult.data.error)
  }
}
