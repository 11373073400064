import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getOpenInvitationsForResource(
  resourceType,
  resourceId,
  callback = async () => {},
) {
  const result = {}
  const querySnapshot = await getDocs(
    query(
      refs.invitations(),
      where('resourceType', '==', resourceType),
      where('resourceId', '==', resourceId),
      where('responseDate', '==', null),
    ),
  )
  for (const snap of querySnapshot.docs) {
    const invitation = snap.data()
    if (invitation) {
      result[invitation.id] = invitation
      await callback(invitation)
    }
  }
  return result
}
