import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getLoggerSession(sessionId) {
  const snap = await getDoc(refs.loggerSession(sessionId))
  if (snap.exists()) {
    return snap.data()
  }
  return null
}
