import * as Papa from 'papaparse'
import { eventIdFromType, eventTypeClassMap } from './eventTypeMaps'

/**
 * A utility function to instantiate an Event object based on the given CSV row string.  Each row
 * is expected to being with the numerical id for the class of event, followed by a comma, followed
 * by csv values for the event's properties, in the order specified by the event class's static
 * `COLUMNS` property.
 *
 * @param {object} input the JSON object to instantiate from
 * @returns {*} an AbstractEvent object instantiated from the given JSON object
 * @throws {Error} if the given JSON object does not have a valid eventType
 */
export function csvRowFromEvent(event) {
  let eventType = event.eventType
  let EventClass = eventTypeClassMap[event.eventType]

  if (!EventClass) {
    console.warn(
      "Unrecognized event type: '" + eventType + "', using EmptyEvent",
    )
    eventType = 'empty'
    EventClass = eventTypeClassMap[eventType]
  }
  const csvRow = EventClass.COLUMNS.map((column) => {
    if (column === 'eventType') {
      return eventIdFromType(eventType)
    }
    if (event[column] === false) return ''
    if (event[column] === true) return 1
    return event[column] ?? ''
  })

  return Papa.unparse([csvRow], {
    quotes: false,
    dynamicTyping: true,
    header: false,
    columns: EventClass.COLUMNS,
  })
}
