import { LoggerSession } from './LoggerSession'

export class AssignmentLoggerSession extends LoggerSession {
  constructor({
    id,
    assignmentId,
    researchNotebookId,
    name,
    dateCreated = +new Date(),
    tags, // @deprecated - this will be removed after all sessions are successfully upgraded.  Keeping it here is a temporary fail-safe to ensure that we don't lose any tags if something goes wrong.
  }) {
    super(id, 'assignment', researchNotebookId, name, dateCreated, tags)
    this.assignmentId = assignmentId
  }

  toJson() {
    return {
      ...super.toJson(),
      assignmentId: this.assignmentId,
    }
  }
}
