import { functions } from '../coreConfig'
import { httpsCallable } from 'firebase/functions'

/** @deprecated prefer deleteLoggerSession */
export async function deleteLoggerSession2(id) {
  const result = await httpsCallable(
    functions,
    'deleteLoggerSession',
  )({
    id,
  })
  console.log('deleteLoggerSession result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
}
