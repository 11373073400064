/**
 * Assignment is class to represent the data model for an assignment.  An assignment maps an organization, course, and assignment detail together, as well as Google Classroom courses and assignments.
 *
 * @class
 */
export class Assignment {
  /**
   * Constructor for Assignment.  An assignment maps an assignment detail, organization, course,
   * section (if any), classroom course (if any), and classroom assignment (if any) together.
   *
   * @param data the data to initialize this assignment with
   * @param [data.id] {string} the id of this assignment
   * @param [data.orgId] {string} the id of the organization this assignment belongs to
   * @param [data.courseId] {string} the id of the course this assignment belongs to
   * @param [data.sectionId] {string} the id of the section this assignment belongs to, or null if none
   * @param [data.assignmentDetailId] {string} the id of the assignment detail of this assignment
   * @param [data.accessCode] {string} the access code of this assignment.
   * @param [data.roles] { { [[role]]: string[] } } the roles of this assignment.  This is a map of role strings to arrays of user ids.
   * @param [data.archived] {boolean} whether this assignment is archived
   * @param [data.name] {string} the name of this assignment
   * @param [data.keys] {string[]} the keys of this assignment, which are used to mask and anonymize student ids.  Each key corresponds to a student.  Which student is determined by another firestore document, which is private to the teacher and the individual student.
   * @param [data.created] {number} the timestamp of when this assignment was created
   * @param [data.updated] {number} the timestamp of when this assignment was last updated
   * @param [data.classroomCourseId] {string} the id of the Google Classroom course this assignment is associated with, or null if none
   * @param [data.classroomDirectLink] {string} the direct link to the Google Classroom assignment, or null if none
   * @param [data.classroomStudentWorkFolderId] {string} the id of the Google Classroom folder where student work is stored, or null if none
   * @param [data.classroomAssignmentId] {string} the id of the Google Classroom assignment, or null if none
   * @param [data.classroomState] {string} the `state` of the Google Classroom assignment, or null if none
   * @param [data.createdWithLearnics] {boolean} whether this assignment was created with Learnics (mostly applicable to just Google Classroom assignments)
   * @param [data.googleOwnerId] {string} the id of the user who owns the Google Drive folder for this assignment
   * @param [data.userSessionIdMap] { { [[userId]]: string } } a map of user ids to logger session ids, used to determine which user owns which logger session.  Note that this is not stored in Firestore, but is used in the cloud functions to determine ownership of logger sessions.
   *
   *
   * @constructor
   */
  constructor({
    id = null,
    orgId = null,
    courseId = null,
    sectionId = null,
    assignmentDetailId = null,
    classroomCourseId = null,
    classroomDirectLink = null,
    classroomStudentWorkFolderId = null,
    classroomAssignmentId = null,
    classroomState = null,
    createdWithLearnics = true,
    accessCode = null,
    roles = {},
    archived = false,
    name = null,
    keys = [],
    created = +new Date(),
    updated = +new Date(),
    googleOwnerId = null,
    userSessionIdMap = {}, // TRANSIENT - not stored in Firestore - only to be populated for administrator/instructor users.
  }) {
    this.id = id
    this.orgId = orgId
    this.courseId = courseId
    this.sectionId = sectionId
    this.assignmentDetailId = assignmentDetailId
    this.accessCode = accessCode
    this.roles = roles
    this.archived = archived
    this.name = name
    this.keys = keys
    this.created = created
    this.updated = updated || created
    this.classroomCourseId = classroomCourseId
    this.classroomDirectLink = classroomDirectLink
    this.classroomStudentWorkFolderId = classroomStudentWorkFolderId
    this.classroomAssignmentId = classroomAssignmentId
    this.classroomState = classroomState
    this.createdWithLearnics = !!createdWithLearnics
    this.googleOwnerId = googleOwnerId || null
    this.userSessionIdMap = userSessionIdMap
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      archived: !!this.archived,
      orgId: this.orgId,
      courseId: this.courseId,
      sectionId: this.sectionId || null,
      assignmentDetailId: this.assignmentDetailId,
      accessCode: this.accessCode,
      roles: this.roles,
      keys: this.keys || [],
      created: this.created,
      updated: this.updated,
      classroomCourseId: this.classroomCourseId,
      classroomDirectLink: this.classroomDirectLink,
      classroomStudentWorkFolderId: this.classroomStudentWorkFolderId,
      classroomAssignmentId: this.classroomAssignmentId,
      classroomState: this.classroomState,
      createdWithLearnics: !!this.createdWithLearnics,
      googleOwnerId: this.googleOwnerId,
      userSessionIdMap: this.userSessionIdMap || null,
    }
  }
}
