import { ResearchSource } from './ResearchSource'

/**
 * GenericSource is a class that holds note and annotation information about a
 * generic source of research.
 *
 * @extends ResearchSource
 */
export class GenericSource extends ResearchSource {
  static TYPE = 'generic'
  constructor(yMap) {
    super(yMap)
  }

  get title() {
    return this.yMap.get('title') || ''
  }
}
