import { DriveFileAttachment } from '../DriveFileAttachment'
import { LinkAttachment } from '../LinkAttachment'
import { YoutubeVideoAttachment } from '../YoutubeVideoAttachment'
import { FormAttachment } from '../FormAttachment'
import { SourceDriveFileAttachment } from '../SourceDriveFileAttachment'

/**
 * A map of attachment type strings to their corresponding classes.
 */
export const attachmentTypeClassMap = {
  sourceDriveFile: SourceDriveFileAttachment,
  driveFile: DriveFileAttachment,
  link: LinkAttachment,
  youtubeVideo: YoutubeVideoAttachment,
  form: FormAttachment,
}
