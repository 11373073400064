import { AbstractEvent } from './AbstractEvent'

/**
 *
 * A class to represent a system startup event.  If we are logging, and the system starts up, we need to mark that,
 * which is what this class does.  Time in between the last event and this one is considered system ignored time.
 *
 *   @inheritDoc
 */
export class SystemStartupEvent extends AbstractEvent {
  static COLUMNS = [...AbstractEvent.COLUMNS]

  /**
   * Constructor for the SystemStartupEvent class.  This instantiates the event object.
   *
   * @param {any} input a plain SystemStartupEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @constructor
   */

  constructor({ time }) {
    super('systemStartup', time, false, false)
  }
}
