import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'
import { nanoid } from 'nanoid'

export async function createResource(
  resourceType,
  data = { id: nanoid(32) },
  inResourceType,
  inResourceId,
) {
  console.log(
    'createResource',
    resourceType,
    data,
    inResourceType,
    inResourceId,
  )
  const cloudFuncResult = await httpsCallable(
    functions,
    'createResource',
  )({
    inResourceType,
    inResourceId,
    resourceType,
    data,
  })
  if (!cloudFuncResult.data.success) {
    throw new Error(cloudFuncResult.data.error)
  }
  return cloudFuncResult.data.data
}
