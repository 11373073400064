import { advancedGaleUrlToSearchKeyword } from './advancedGaleUrlToSearchKeyword'

export function galeUrlToSearchKeyword(urlString) {
  if (!urlString) {
    return null
  }
  try {
    const url = new URL(urlString)
    if (url.pathname === '/ps/i.do') {
      return url.searchParams.get('qt').slice(4)
    }
    if (url.pathname === '/ps/headerQuickSearch.do') {
      return url.searchParams.get('quickSearchTerm')
    }
    if (url.pathname === '/ps/basicSearch.do') {
      return url.searchParams.get('inputFieldValues[0]')
    }
    if (url.pathname === '/ps/advancedSearch.do') {
      return advancedGaleUrlToSearchKeyword(url)
    }
    if (url.pathname === '/ps/publicationSearch.do') {
      return url.searchParams.get('inputFieldValues[0]')
    }
    if (url.pathname === '/ps/subjectguide.do') {
      return url.searchParams.get('inputFieldValues[0]')
    }
  } catch (e) {
    console.log('Unable to parse: ', urlString, e)
  }
}
