import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function createProductTrial(
  productId,
  userId,
  startDate,
  endDate,
  status,
  trialType,
) {
  const cloudFuncResult = await httpsCallable(
    functions,
    'createProductTrial',
  )({
    productId,
    userId,
    startDate,
    endDate,
    status,
    trialType,
  })
  if (!cloudFuncResult.data.success) {
    throw new Error(cloudFuncResult.data.error)
  }
  return cloudFuncResult.data.data
}
