import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function markUserPresumedPaid() {
  const cloudFuncResult = await httpsCallable(
    functions,
    'markUserPresumedPaid',
  )({})
  if (!cloudFuncResult.data.success) {
    throw new Error(cloudFuncResult.data.error)
  }
  return cloudFuncResult.data.data
}
