import { mergeAttributes, Node } from '@tiptap/core'

export function createPastedImageExtension(nodeViewRenderer) {
  const opts = {
    name: 'pastedImage',

    group: 'inline',
    draggable: true,
    inline: true,

    // content: 'inline*',

    parseHTML() {
      return [
        {
          tag: 'pasted-image',
        },
      ]
    },

    renderHTML({ HTMLAttributes }) {
      return ['pasted-image', mergeAttributes(HTMLAttributes)]
    },

    addAttributes() {
      // Return an object with attribute configuration
      return {
        src: {
          default: null,
        },
        alt: {
          default: null,
        },
        title: {
          default: null,
        },
        caption: {
          default: null,
        },
        'natural-width': {
          default: 1,
        },
        'natural-height': {
          default: 1,
        },
        scale: {
          default: null,
        },
      }
    },
  }
  if (nodeViewRenderer) {
    opts.addNodeView = () => nodeViewRenderer
  }
  return Node.create(opts)
}
