import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function ensureGoogleClassroomSectionsForTeacher(
  uid,
  classroomCourseIds,
  createSections = true,
) {
  const addToSectionResult = await httpsCallable(
    functions,
    'ensureGoogleClassroomSectionsForTeacher',
  )({
    uid,
    classroomCourseIds,
    createSections,
  })
  if (!addToSectionResult.data.success) {
    throw new Error(addToSectionResult.data.error)
  }
  return addToSectionResult.data.data
}
