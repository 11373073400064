import { extractRolesMapFromFirestoreDocument } from '../roles'
import { Course } from '../../Organization/Course/Course'

export class CourseFirestoreConverter {
  static toFirestore(course) {
    if (!course) {
      return null
    }
    const storageObject = course.toJson()
    const roles = storageObject.roles
    delete storageObject.roles

    const result = {
      ...storageObject,
      ...roles,
    }

    return result
  }
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    if (!data) {
      return null
    }
    const roles = extractRolesMapFromFirestoreDocument(data)
    const id = snapshot.id
    if (data.googleClassroom && !data.classroomCourseId) {
      // Support old courses that have googleClassroom set to true and no classroomCourseId.
      // They use the same id.
      data.classroomCourseId = id
    }
    return new Course({
      id,
      ...data,
      roles,
    })
  }
}
