/**
 * Format the time in milliseconds to a string of the form "1d 1h 30m 15s" or "1h 30m" or "1d 1h" or "1d 0h 30m" or "15s" or "0s"
 * @param ms the time in milliseconds
 * @param showSeconds if true, show seconds, otherwise don't show seconds
 * @returns {string} the formatted string
 */
export function formatTimeHms(ms, showSeconds = true) {
  let isNegative = ms < 0
  ms = Math.abs(ms)
  let msInDay = 1000 * 60 * 60 * 24
  let msInHour = 1000 * 60 * 60
  let msInMinute = 1000 * 60
  let msInSecond = 1000
  let days = Math.floor(ms / msInDay)
  let hours = Math.floor((ms - days * msInDay) / msInHour)

  let minutes = Math[showSeconds ? 'floor' : 'round'](
    (ms - days * msInDay - hours * msInHour) / msInMinute,
  )
  let seconds = Math.round(
    (ms - days * msInDay - hours * msInHour - minutes * msInMinute) /
      msInSecond,
  )
  if (showSeconds) {
    if (seconds >= 60) {
      minutes++
      seconds -= 60
      if (minutes >= 60) {
        hours++
        minutes -= 60
      }
    }
  } else if (minutes >= 60) {
    hours++
    minutes -= 60
  }
  if (hours >= 24) {
    days++
    hours -= 24
  }
  let showDays = days > 0

  showSeconds =
    showSeconds && (seconds > 0 || (minutes === 0 && hours === 0 && days === 0))
  let showHours =
    hours > 0 || (showDays && (minutes > 0 || (showSeconds && seconds > 0)))
  let showMinutes =
    minutes > 0 ||
    ((showHours || showDays) && showSeconds && seconds > 0) ||
    (!showHours && !showDays && !showSeconds)

  isNegative =
    isNegative && (showDays || showHours || showSeconds || minutes > 0)
  return `${isNegative ? '-' : ''}${showDays ? days + 'd ' : ''}${
    showHours ? hours + 'h ' : ''
  }${showMinutes ? minutes + 'm ' : ''}${
    showSeconds ? seconds + 's' : ''
  }`.trim()
}

/**
 * Format the time in milliseconds to a string of the form "1:05:15" or "5:45" or "1s" or "30s" or "1:05" or "0s"
 * @param ms the time in milliseconds
 * @param showSeconds if true, show seconds, otherwise don't show seconds
 * @returns {string} the formatted string
 */
export function formatTimeNumeric(ms, showSeconds = true) {
  let isNegative = ms < 0
  ms = Math.abs(ms)
  let diff_hours = Math.floor(ms / 3600000)
  let diff_mins = Math[showSeconds ? 'floor' : 'round']((ms % 3600000) / 60000)
  let diff_secs = Math.round(((ms % 3600000) % 60000) / 1000)
  if (showSeconds) {
    if (diff_secs >= 60) {
      diff_mins++
      diff_secs -= 60
      if (diff_mins >= 60) {
        diff_hours++
        diff_mins -= 60
      }
    }
  } else if (diff_mins >= 60) {
    diff_hours++
    diff_mins -= 60
  }
  let result = ''
  if (diff_hours > 0) {
    result += diff_hours + ':'
  }
  if (diff_mins > 0 || diff_hours > 0) {
    result +=
      (diff_mins < 10 && diff_hours > 0 ? '0' : '') +
      diff_mins +
      (showSeconds ? ':' : '')
  }
  if (showSeconds && (diff_secs > 0 || diff_mins > 0 || diff_hours > 0)) {
    result +=
      (diff_secs < 10 && (diff_mins > 0 || diff_hours > 0) ? '0' : '') +
      diff_secs
    if (diff_mins === 0 && diff_hours === 0) {
      result += 's'
    }
  } else if (!showSeconds && (diff_mins > 0 || diff_hours > 0)) {
    if (diff_hours === 0) {
      result += 'm'
    }
  }
  return (isNegative ? '-' : '') + (result || (showSeconds ? '0s' : '0m'))
}
