/**
 * Site is a serializable class that contains stats for a single url for a logging session.
 */

export class Site {
  constructor({
    url,
    firstFocus,
    lastFocus,
    startTime,
    endTime,
    time,
    title,
    otherTitles,
  }) {
    this.url = url
    this.firstFocus = firstFocus ?? startTime
    this.lastFocus = lastFocus ?? endTime
    this.time = time
    this.title = title
    this.otherTitles = otherTitles || (title ? [title] : [])
  }

  combine(otherSite) {
    if (this.url !== otherSite.url) {
      throw new Error('Cannot combine sites with different urls')
    }
    this.otherTitles.push(this.title)
    this.otherTitles.push(otherSite.title)
    if (this.time < otherSite.time) {
      this.title = otherSite.title
    }
    this.firstFocus = Math.min(this.firstFocus, otherSite.firstFocus)
    this.lastFocus = Math.max(this.lastFocus, otherSite.lastFocus)
    this.time += otherSite.time
    this.otherTitles = [
      ...new Set([...this.otherTitles, ...otherSite.otherTitles]),
    ]
    if (this.otherTitles.includes(this.title)) {
      this.otherTitles = this.otherTitles.filter(
        (title) => title !== this.title,
      )
    }
  }

  /** @deprecated **/
  get startTime() {
    return new Date(this.firstFocus || 0).toISOString()
  }

  /** @deprecated **/
  get endTime() {
    return this.lastFocus
  }

  set endTime(endTime) {
    this.lastFocus = +new Date(endTime || 0)
  }

  set startTime(startTime) {
    this.firstFocus = +new Date(startTime || 0)
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {{assignmentId: string, courseId: string, organizationId: string, id: string, startTime: number, endTime: number|null, userId: string}}
   */
  toJson() {
    return {
      url: this.url,
      firstFocus: this.firstFocus,
      lastFocus: this.lastFocus,
      startTime: this.firstFocus || null,
      time: this.time,
      title: this.title,
      ...(this.otherTitles.length > 0
        ? { otherTitles: [...this.otherTitles] }
        : {}),
    }
  }

  equals(other) {
    return (
      this.url === other.url &&
      this.firstFocus === other.firstFocus &&
      this.lastFocus === other.lastFocus &&
      this.time === other.time &&
      this.title === other.title &&
      this.otherTitles.length === other.otherTitles.length &&
      this.otherTitles.every((title) => other.otherTitles.includes(title))
    )
  }

  static toFirestore(site) {
    return {
      url: site.url || '',
      time: site.time || 0,
      title: site.title || '',
      startTime: site.startTime || 0,

      firstFocus: site.firstFocus || 0,
      lastFocus: site.lastFocus || 0,

      ...(site.otherTitles && site.otherTitles.length > 0
        ? { otherTitles: [...site.otherTitles] }
        : {}),
    }
  }

  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    return new Site({
      url: data.url,
      firstFocus: data.firstFocus ?? data.startTime,
      lastFocus: data.lastFocus ?? data.endTime,
      time: data.time,
      title: data.title,
      ...(data.otherTitles ? { otherTitles: [...data.otherTitles] } : {}),
    })
  }
}
