import { nanoid } from 'nanoid'
import { yjsInstance } from '../utils/yjsInstance'
import { NoteBoardOutline } from './NoteBoardOutline'

/**
 * NoteBoard contains data that is shared between users on a NoteBoard.
 * Any collaborator may modify this data.  This currently includes a common set
 * of deleted note IDs and the outline of the NoteBoard.  Ideally any changes to
 * the data in this class will be included in any collaborative editing system
 * we implement.
 */
export class NoteBoard {
  constructor({
    id = nanoid(32),
    assignmentId = null,
    dateCreated = +new Date(),
    dateUpdated = +new Date(),
    content,
  }) {
    const Y = yjsInstance.Y
    this.id = id
    this.assignmentId = assignmentId

    this.dateCreated = dateCreated || +new Date()
    this.dateUpdated = dateUpdated || +new Date()
    this.yDoc = new Y.Doc()
    if (content) {
      Y.applyUpdate(this.yDoc, Uint8Array.from(content))
    }

    this.initializeYDoc()
  }

  get allNoteIds() {
    return [
      ...new Set([
        ...this.noteIds,
        ...this.deletedNoteIds,
        ...this.annotationNoteIds,
        ...this.outline.getNoteIds(),
      ]),
    ]
  }

  get name() {
    return this.yDoc.getText('name').toString()
  }

  get noteIds() {
    return this.yDoc.getArray('noteIds').toJSON()
  }

  get deletedNoteIds() {
    return this.yDoc.getArray('deletedNoteIds').toJSON()
  }

  get annotationNoteIds() {
    return this.yDoc.getArray('annotationNoteIds').toJSON()
  }

  get outline() {
    return new NoteBoardOutline(this.yDoc.getXmlFragment('outline'))
  }

  insertNoteId(index, noteId) {
    this.yDoc.getArray('noteIds').insert(index, [noteId])
  }

  addDeletedNoteId(noteId) {
    this.yDoc.getArray('deletedNoteIds').push([noteId])
  }

  restoreDeletedNoteId(noteId) {
    const deletedNoteIds = this.yDoc.getArray('deletedNoteIds')
    const index = deletedNoteIds.indexOf(noteId)
    if (index !== -1) {
      deletedNoteIds.delete(deletedNoteIds.indexOf(noteId), 1)
    }
  }

  restoreDeletedNoteIds(noteIds) {
    noteIds = new Set([...(noteIds || [])])

    const deletedNoteIds = this.yDoc.getArray('deletedNoteIds')
    this.yDoc.transact((tr) => {
      const deletedNoteIds2 = deletedNoteIds.toJSON()
      for (let i = deletedNoteIds2.length - 1; i >= 0; i--) {
        const deletedNoteId = deletedNoteIds2[i]
        if (noteIds.has(deletedNoteId)) {
          const index = deletedNoteIds2.indexOf(deletedNoteId)
          if (index !== -1) {
            deletedNoteIds.delete(index, 1)
          }
        }
      }
    })
  }

  toJson() {
    const Y = yjsInstance.Y
    return {
      id: this.id,
      assignmentId: this.assignmentId || null,
      content: Array.from(Y.encodeStateAsUpdate(this.yDoc)),
      dateUpdated: this.dateUpdated,
      dateCreated: this.dateCreated,
    }
  }

  initializeYDoc() {
    // Ensure that the noteboard has all the necessary fields
    this.yDoc.getText('name')
    this.yDoc.getXmlFragment('outline')
    this.yDoc.getArray('deletedNoteIds')
    this.yDoc.getArray('noteIds')
    this.yDoc.getArray('annotationNoteIds')
  }

  updateName(newName) {
    this.yDoc.getText('name').delete(0, this.yDoc.getText('name').length)
    this.yDoc.getText('name').insert(0, newName)
  }

  static shallowCopy(noteBoard) {
    const newNoteBoard = new NoteBoard({
      id: noteBoard.id,
      dateUpdated: noteBoard.dateUpdated,
      dateCreated: noteBoard.dateCreated,
      assignmentId: noteBoard.assignmentId,
    })
    newNoteBoard.yDoc = noteBoard.yDoc
    newNoteBoard.initializeYDoc() // Just in case
    return newNoteBoard
  }

  updateTagGlobally(
    researchNotebookId,
    resourceType,
    resourceId,
    index,
    newColor = null,
    newLabel = null,
    notesMap = {},
    researchNotebooksMap = {},
  ) {
    let changed = false

    let oldColor, oldLabel
    if (researchNotebookId && researchNotebooksMap[researchNotebookId]) {
      const researchNotebook = researchNotebooksMap[researchNotebookId]

      let resource
      if (resourceType === 'note') {
        resource = notesMap[resourceId]
      } else if (resourceType === 'researchSource') {
        resource = researchNotebook.sources[resourceId]
      } else {
        throw new Error('Unknown resource type: ' + resourceType)
      }

      if (!resource) {
        throw new Error(resourceType + ' #' + resourceId + ' not found')
      }
      let originalTag = resource.tags[index]
      if (!originalTag) {
        throw new Error(
          'Tag at index ' +
            index +
            ' not found in ' +
            resourceType +
            ' #' +
            resourceId,
        )
      }
      oldColor = originalTag.color
      oldLabel = originalTag.label
      changed = researchNotebook.updateTagGlobally(
        resourceType,
        resourceId,
        index,
        newColor,
        newLabel,
        notesMap,
      )
    } else if (
      resourceType === 'note' &&
      !researchNotebookId &&
      notesMap[resourceId]
    ) {
      oldColor = notesMap[resourceId].tags[index].color
      oldLabel = notesMap[resourceId].tags[index].label
      notesMap[resourceId].updateTagGloballyPreservingIndex(
        index,
        newColor,
        newLabel,
      )
    }
    const notebookIds = Object.keys(researchNotebooksMap)
    for (const notebookId of notebookIds) {
      const researchNotebook = researchNotebooksMap[notebookId]
      changed =
        researchNotebook.updateTag(
          oldColor,
          oldLabel,
          newColor,
          newLabel,
          notesMap,
        ) || changed
    }
    return changed
  }

  fullyDeleteNoteId(noteId) {
    console.log('Fully deleting noteId', noteId)
    let changed = false

    const deletedNoteIds = [...this.deletedNoteIds]
    if (deletedNoteIds.includes(noteId)) {
      console.log('\tFound noteId in deletedNoteIds')
      changed = true
      this.yDoc
        .getArray('deletedNoteIds')
        .delete(deletedNoteIds.indexOf(noteId), 1)
    }

    if (this.noteIds.includes(noteId)) {
      console.log('\tFound noteId in noteIds')
      changed = true
      this.yDoc.getArray('noteIds').delete(this.noteIds.indexOf(noteId), 1)
    }
    const deletedNotes = this.outline.deleteNotes(noteId)
    if (deletedNotes) {
      console.log('\tfound noteId in outline')
      changed = true
    }
    return changed
  }

  fullyDeleteNoteIds(noteIds) {
    for (const noteId of noteIds) {
      this.fullyDeleteNoteId(noteId)
    }
  }

  toggleAnnotationNoteId(
    noteId,
    newVal = !this.annotationNoteIds.includes(noteId),
  ) {
    const annotationNoteIds = this.yDoc.getArray('annotationNoteIds')
    if (newVal) {
      annotationNoteIds.push([noteId])
    } else {
      annotationNoteIds.delete(annotationNoteIds.indexOf(noteId), 1)
    }
  }
}
