import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getUserDocument(userId) {
  const userDoc = await getDoc(refs.user(userId))
  const docData = userDoc.data()
  if (!docData) return null
  let displayName = docData.displayName || docData.name || docData.email || ''
  let name = docData.name || docData.displayName || docData.email || ''
  return { ...docData, id: userId, uid: userId, displayName, name }
}
