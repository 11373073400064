import { setDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { ExtensionInstallation } from '@learnics/models/src/ExtensionInstallation/ExtensionInstallation'

export async function saveOrUpdateInstallation(
  id,
  extensionId,
  version,
  fcmRegistrationId,
  userId,
) {
  const installation = new ExtensionInstallation({
    id,
    extensionId,
    version,
    fcmRegistrationId,
    userId,
  })
  await setDoc(refs.extensionInstallation(id, false), installation.toJson(), {
    merge: true,
  })

  return installation
}
