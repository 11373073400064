import { functions } from '../coreConfig'
import { httpsCallable } from 'firebase/functions'

export async function deleteLoggerSession(id) {
  const result = await httpsCallable(
    functions,
    'deleteSession',
  )({
    id,
  })
  console.log('deleteSession result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
}
