import { coreTimelineFilters } from './coreTimelineFilters'
import { getMatchingExpectations } from '../../../Expectation/utils/getMatchingExpectations'

export const createExpectationFilter =
  (expectationId) =>
  ({
    browserRc,
    secrets,
    keysBlacklisted,
    keysDeleted,
    assignmentDetail,
    studentCopiesRc,
    urlFilter,
  }) => {
    if (!assignmentDetail.hasExpectations()) return false

    const expectations = assignmentDetail.expectations || []
    const expectation = (expectations || []).find(
      (expectation) => expectation.id === expectationId,
    )
    if (
      !expectation ||
      !coreTimelineFilters.focusedOnValidUrl({
        browserRc,
        secrets,
        keysBlacklisted,
        keysDeleted,
        urlFilter,
      })
    )
      return false
    let { url } = browserRc.focusedTab

    const matchingExpectations = getMatchingExpectations(url, {
      assignmentDetail,
      secrets,
      studentCopiesRc,
    })
    return matchingExpectations.some(
      (expectation) => expectation.id === expectationId,
    )
  }
