import refs from '../refsLite'
import { arrayUnion, runTransaction } from 'firebase/firestore/lite'
import { CHUNK_SIZE_LIMIT } from '@learnics/models/src/Organization/Course/Assignment/Submission/LoggingSessionChunk'
import { EventMetaInfo } from '@learnics/models/src/Organization/Course/Assignment/Submission/EventMetaInfo'
import { csvRowFromEvent } from '@learnics/models/src/Organization/Course/Assignment/Event/utils/csvRowFromEvent'
import { dbLite as db } from '../coreConfig'
import { redact } from '@learnics/models/src/utils/redact'

/**
 * Append a list of events to the event log for a user.
 *
 * @param session - The session object
 * @param eventsToSave - The list of events to save
 * @returns {Promise<void>} - A promise that resolves when the events have been saved
 */
export async function saveEvents(
  sessionId,
  eventsToSave,
  currentSessionSecrets = null,
) {
  if (eventsToSave.length === 0) {
    return
  }
  // Run in a transaction to ensure that we don't collide with other saveEvents transactions
  await runTransaction(db, async (transaction) => {
    // Assignments before this time might already have metadata and no log document, but they need a log document.
    // This can be taken out after we have converted assignments.

    const metaInfoRef = refs.eventLogMetaInfo(sessionId)
    const metaInfoSnap = await transaction.get(metaInfoRef)
    let meta = metaInfoSnap.data()
    if (!meta) {
      meta = new EventMetaInfo({})
    }

    const newEventsSizes = { [[meta.chunkCount - 1]]: meta.lastEventsSize }
    const newSecretsSizes = { [[meta.chunkCount - 1]]: meta.lastSecretsSize }
    const newChunkEvents = { [[meta.chunkCount - 1]]: [] }
    const newChunkSecrets = { [[meta.chunkCount - 1]]: {} }
    let chunkId = meta.chunkCount - 1
    let currentEventsSize = 0
    let currentSecretsSize = 0
    let eventIndex = 0
    if (!currentSessionSecrets) {
      const snap = await transaction.get(refs.secret(sessionId, chunkId + ''))
      currentSessionSecrets = snap.data() || {}
    }
    console.log('currentSessionSecrets', currentSessionSecrets)
    while (eventIndex < eventsToSave.length) {
      const event = eventsToSave[eventIndex]
      const redactedVals = event.redact(redact)
      let secrets = Object.keys(redactedVals).reduce((acc, key) => {
        // Strip out secrets that don't need to be saved.
        if (!currentSessionSecrets[key] && !newChunkSecrets[chunkId][key]) {
          acc[key] = redactedVals[key]
        }
        return acc
      }, {})
      const csvRow = csvRowFromEvent(event)
      const eventsSize = csvRow.length * 2 + 1 // These are rough estimates, overshooting things a bit

      let secretsSize =
        Object.keys(secrets).length > 0
          ? JSON.stringify(secrets).length * 2 + 1
          : 0 // These are rough estimates, overshooting things a bit
      if (
        currentEventsSize + eventsSize + meta.lastEventsSize >
          CHUNK_SIZE_LIMIT ||
        currentSecretsSize + secretsSize + meta.lastSecretsSize >
          CHUNK_SIZE_LIMIT
      ) {
        meta.chunkCount += 1
        meta.lastEventsSize = 0
        meta.lastSecretsSize = 0
        currentEventsSize = 0
        currentSecretsSize = 0
        chunkId++
        newEventsSizes[chunkId] = 0
        newSecretsSizes[chunkId] = 0
        newChunkEvents[chunkId] = []
        newChunkSecrets[chunkId] = {}
        secrets = redactedVals
        secretsSize = JSON.stringify(redactedVals).length * 2 + 1
        currentSessionSecrets = newChunkSecrets[chunkId]
      }
      newEventsSizes[chunkId] += eventsSize
      newSecretsSizes[chunkId] += secretsSize
      newChunkEvents[chunkId].push(csvRow)
      Object.assign(newChunkSecrets[chunkId], secrets)
      currentEventsSize += eventsSize
      currentSecretsSize += secretsSize
      eventIndex++
      meta.lastChunkOptimized = false
    }
    meta.lastEventsSize += currentEventsSize
    meta.lastSecretsSize += currentSecretsSize

    const chunkIds = Object.keys(newChunkEvents)
    for (let i = 0; i < chunkIds.length; i++) {
      const chunkId = chunkIds[i]
      const chunk = newChunkEvents[chunkId]
      const secrets = newChunkSecrets[chunkId]
      if (chunk.length === 0) continue
      const chunkObj = {
        id: chunkId,
        csvEvents: arrayUnion(...chunk),
        sessionId,
        optimized: false,
      }

      const chunkRef = refs.eventLog(sessionId, chunkId, false)
      transaction.set(chunkRef, chunkObj, { merge: true })
      if (Object.keys(secrets).length === 0) continue

      const secretRef = refs.secret(sessionId, chunkId)
      transaction.set(secretRef, secrets, { merge: true })
    }
    transaction.set(metaInfoRef, meta, { merge: true })
  })
}
