import refs from '../refsLite'
import { getDocs } from 'firebase/firestore/lite'

export async function getSitesForEntry(assignmentId, userId, entryId) {
  const querySnapshot = await getDocs(refs.sites(assignmentId, userId, entryId))
  const result = {}
  querySnapshot.forEach((siteDoc) => {
    result[siteDoc.id] = siteDoc.data()
  })
  return result
}
