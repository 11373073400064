import { setDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { nanoid } from 'nanoid'
import { Course } from '@learnics/models/src/Organization/Course/Course'

/** @deprecated prefer `saveCourse`, and handle the Course object yourself. */
export async function createCourse(
  userId,
  orgId,
  courseId,
  name,
  googleClassroom = false,
) {
  courseId ||= nanoid()
  const course = new Course({
    id: courseId,
    name,
    description: '',
    archived: false,
    orgId: orgId,
    createdBy: userId,
    created: +new Date(),
    googleClassroom,
    hasSections: false,
    roles: {
      Instructor: [userId],
      Learner: [],
      Administrator: [userId],
    },
  })
  console.log('Creating course... ', course)
  await setDoc(refs.course(courseId), course)
  return course
}
