import Vue from 'vue'

export const state = {
  asyncCommandStatus: {},
}

export const getters = {
  asyncCommandStatus: (state) => state.asyncCommandStatus,
}

export const mutations = {
  setAsyncCommandStatus(state, { compositeId, newVal, oldVal }) {
    const { commandId, commandType } = compositeId
    if (newVal) {
      if (!state.asyncCommandStatus[commandType]) {
        Vue.set(state.asyncCommandStatus, commandType, {})
      }
      Vue.set(state.asyncCommandStatus[commandType], commandId, newVal)
    } else {
      Vue.delete(state.asyncCommandStatus[commandType], commandId)
      if (Object.keys(state.asyncCommandStatus[commandType]).length === 0) {
        Vue.delete(state.asyncCommandStatus, commandType)
      }
    }
  },
}

export const actions = {}
