import { getAllOrganizations } from '../organization/getAllOrganizations'
import { allRoles, isAnythingIn } from '@learnics/models/src/utils/roles'
import { getCourses } from '../course/getCourses'
import { arrayUnion, updateDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { getAssignmentDetail } from '../assignment/getAssignmentDetail'
import { getAssignmentsByCourseId } from '../assignment/getAssignmentsByCourseId'

export async function copyUserRoles(oldUid, newUid) {
  console.log(
    'Attempting to copy user roles from ' + oldUid + ' to ' + newUid + '...',
  )
  try {
    console.log('Loading all organizations...')
    const allOrgs = await getAllOrganizations()

    const orgIds = Object.keys(allOrgs)
    console.log('Found ' + orgIds.length + ' organizations.')
    const matchingOrgs = {}
    for (let orgId of orgIds) {
      for (let role of allRoles) {
        const org = allOrgs[orgId]
        if (!isAnythingIn(org, oldUid)) continue
        await matchRoles(org, oldUid, newUid, refs.organization(orgId, false))
        matchingOrgs[orgId] = org
      }
    }
    const matchingOrgIds = Object.keys(matchingOrgs)
    console.log(
      'Found ' + matchingOrgIds.length + ' organizations with matching roles.',
    )

    const matchingCourses = {}
    for (let orgId of matchingOrgIds) {
      for (let role of allRoles) {
        const courses = await getCourses(orgId, oldUid, role)
        const courseIds = Object.keys(courses)
        for (let courseId of courseIds) {
          const course = courses[courseId]
          if (!isAnythingIn(course, oldUid) || matchingCourses[courseId])
            continue
          await matchRoles(course, oldUid, newUid, refs.course(courseId, false))
          matchingCourses[courseId] = course
        }
      }
    }

    const assignmentDetailsSeen = new Set()
    const matchingCourseIds = Object.keys(matchingCourses)
    console.log(
      'Found ' + matchingCourseIds.length + ' courses with matching roles.',
    )
    for (let courseId of matchingCourseIds) {
      console.log("Copying roles for course '" + courseId + "'...")
      const assignments = await getAssignmentsByCourseId(oldUid)
      const assignmentIds = Object.keys(assignments)
      console.log('Found ' + assignmentIds.length + ' assignments for course.')
      for (let assignmentId of assignmentIds) {
        console.log("Copying roles for assignment '" + assignmentId + "'...")
        const assignment = assignments[assignmentId]
        await matchRoles(
          assignment,
          oldUid,
          newUid,
          refs.assignment(assignmentId, false),
        )
        if (assignmentDetailsSeen.has(assignment.assignmentDetailId)) {
          console.log(
            "Skipping already-processed assignment detail '" +
              assignment.assignmentDetailId +
              "'...",
          )
          continue
        }
        console.log('Loading assignment detail...')
        assignmentDetailsSeen.add(assignment.assignmentDetailId)
        const assignmentDetail = await getAssignmentDetail(
          assignment.assignmentDetailId,
        )
        console.log(
          "Copying roles for assignment detail '" +
            assignment.assignmentDetailId +
            "'...",
        )
        await matchRoles(
          assignmentDetail,
          oldUid,
          newUid,
          refs.assignmentDetail(assignment.assignmentDetailId, false),
        )
      }
    }
    console.log('Done copying user roles! :)')
  } catch (err) {
    console.log('Error copying user roles', err)
    throw err
  }
}
async function matchRoles(org, oldUid, newUid, orgRef) {
  if (!isAnythingIn(org, oldUid)) return
  const updateObj = {}
  for (let role of allRoles) {
    if (
      org.roles[role]?.includes(oldUid) &&
      !org.roles[role]?.includes(newUid)
    ) {
      updateObj[role] = arrayUnion(newUid)
    }
  }
  if (JSON.stringify(updateObj) === '{}') return
  await updateDoc(orgRef, updateObj)
}
