import { OldLoggerSession } from './OldLoggerSession'

/**
 * @deprecated prefer `OneClickLoggerSession` when it is available
 */
export class OldOneClickLoggerSession extends OldLoggerSession {
  constructor({
    id,
    userId,
    name = 'Session',
    researchNotebookId, // Added for compatibility with LoggerSession
    dateCreated = +new Date(),
    tags,
    siteTags /** deprecated */,
  }) {
    super(
      id,
      'oneClick',
      name,
      researchNotebookId,
      dateCreated,
      tags || siteTags || {},
    )
    this.userId = userId
  }

  toJson() {
    return {
      ...super.toJson(),
      userId: this.userId,
    }
  }
}
