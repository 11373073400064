export async function registerCourseworkPushNotifications(
  accessToken,
  courseId,
) {
  const url = `https://classroom.googleapis.com/v1/registrations`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      feed: {
        feedType: 'COURSE_WORK_CHANGES',
        courseWorkChangesInfo: {
          courseId: courseId,
        },
      },
      cloudPubsubTopic: {
        topicName: 'projects/learnics-development/topics/coursework-changes',
      },
    }),
  })
  const res = await response.json()
  console.log('registerCourseworkPushNotifications response: ', res)
  if (res.error) {
    console.log('Error getting courses: ', res.error)
    throw res.error
  } else {
    console.log('res: ', res)
    return res
  }
}
