import refs from '../refsLite'
import { updateRoles } from '../utils/updateRoles'

export async function updateOrganizationRoles(
  orgId,
  addRolesForUsers = null,
  removeRolesForUsers = null,
) {
  console.log(
    'Setting organization roles... ',
    orgId,
    addRolesForUsers,
    removeRolesForUsers,
  )
  await updateRoles(
    refs.organization(orgId, false),
    addRolesForUsers,
    removeRolesForUsers,
  )
  console.log('Organization roles set!')
}
