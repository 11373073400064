/**
 * Filters a list of site objects to only include those that should be included in the user's bibliography.
 *
 * @param site The site object to filter
 * @returns {boolean} Whether the site should be included in the bibliography
 */
export function urlMetadataFilter(site) {
  if (site.time < 1) {
    return false
  }
  try {
    let url = new URL(site.url)
    if (url.protocol == 'chrome:') {
      return false
    }
    if (url.hostname == 'www.google.com' && url.pathname == '/search') {
      return false
    }
    if (url.hostname == 'www.google.com' && url.pathname == '/url') {
      return false
    }
    if (url.hostname == 'classroom.google.com') {
      return false
    }
    if (url.hostname == 'drive.google.com') {
      return false
    }
    if (url.hostname == 'scholar.google.com') {
      return false
    }
    if (url.hostname == 'accounts.google.com') {
      return false
    }
    if (url.hostname == 'learnics-development.firebaseapp.com') {
      return false
    }
    if (url.hostname == 'localhost') {
      return false
    }
  } catch (e) {
    return false
  }
  if (site.url.startsWith('https://docs.google.com/document/d/')) {
    return false
  }
  if (site.url.startsWith('https://mail.google.com/mail/u/')) {
    return false
  }
  if (site.url.endsWith('usp=drive_web')) {
    return false
  }

  return true
}
