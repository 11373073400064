import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { getLoggerSession } from '../logger/getLoggerSession'
import { removeMissingTagIdsFromUserSiteData } from './removeMissingTagIdsFromUserSiteData'

export async function getUserSiteData(
  sessionId,
  urlHash,
  validateTags = true,
  sessionToValidate = null,
) {
  const doc = await getDoc(refs.userSiteData(sessionId, urlHash))
  const result = doc.exists() ? doc.data() : null
  if (result && validateTags) {
    sessionToValidate ||= await getLoggerSession(sessionId)
    removeMissingTagIdsFromUserSiteData(sessionToValidate, result)
  }
  return result
}
