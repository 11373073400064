/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { deleteUserSiteDatas } from './deleteUserSiteDatas'
import { getUserSiteData } from './getUserSiteData'
import { getUserSiteDatas } from './getUserSiteDatas'
import { getUserSiteDatas2 } from './getUserSiteDatas2'
import { removeMissingTagIdsFromUserSiteData } from './removeMissingTagIdsFromUserSiteData'
import { saveUserSiteData } from './saveUserSiteData'

const LoggerUserDataService = {
  deleteUserSiteDatas,
  getUserSiteData,
  getUserSiteDatas,
  getUserSiteDatas2,
  removeMissingTagIdsFromUserSiteData,
  saveUserSiteData,
}

export default LoggerUserDataService
