import { RunningCalculator } from './RunningCalculator'
import { doUrlsMatch } from '../../Attachment/utils/doUrlsMatch'

/**
 * A RunningCalculator class to combine all student copy URLs seen into a single
 * set.  This class will take metadata events with the key 'scrapedStudentCopy'
 * and value true, and add the URL to the set of student copies.  It will also
 * look for URLs that are close enough to be considered the same student copy,
 * by comparing revealed urls to each other.
 */
export class StudentCopiesRc extends RunningCalculator {
  // A set of all links we've currently seen that match any of the known links.
  // If a student visits a link, we check it against our known links and add it to
  // this set if it matches any of them.
  links = new Set()
  // A set of known links, which may be a subset of the links set.  These are links
  // we have either directly scraped or have derived from our own student copy system.
  knownLinks = new Set()
  driveFileIds = new Set()
  addedLinksAtPriorStep = null
  removedLinksAtPriorStep = null

  constructor(storageObj) {
    super('studentCopiesRc', ['browserRc', 'externalUrlsRc', 'expectationsRc'])
    this.links = new Set(storageObj?.links || [])
    this.knownLinks = new Set(storageObj?.knownLinks || [])
    this.driveFileIds = new Set(storageObj?.driveFileIds || [])

    this.addedLinksAtPriorStep =
      (storageObj?.addedLinksAtPriorStep &&
        new Set(storageObj.addedLinksAtPriorStep)) ||
      null
    this.removedLinksAtPriorStep =
      (storageObj?.removedLinksAtPriorStep &&
        new Set(storageObj.removedLinksAtPriorStep)) ||
      null
  }

  toJson() {
    return {
      ...super.toJson(),

      links: [...this.links],
      knownLinks: [...this.knownLinks],
      driveFileIds: [...this.driveFileIds],
      addedLinksAtPriorStep: this.addedLinksAtPriorStep
        ? [...this.addedLinksAtPriorStep]
        : null,
      removedLinksAtPriorStep: this.removedLinksAtPriorStep
        ? [...this.removedLinksAtPriorStep]
        : null,
    }
  }

  transition(event, stateData) {
    let stateChanged =
      this.addedLinksAtPriorStep !== null ||
      this.removedLinksAtPriorStep !== null
    const { urlDataRc, secrets } = stateData
    this.addedLinksAtPriorStep = null
    this.removedLinksAtPriorStep = null
    const addedLinks = new Set(),
      removedKnownLinks = new Set()

    if (
      event.eventType === 'metadata' &&
      event.key === 'addStudentCopyLink' &&
      secrets[event.value] &&
      !this.knownLinks.has(event.value)
    ) {
      // Check if the link is close enough to include any of the other URLs we've seen, or vice versa.
      this.knownLinks.add(event.value)
      addedLinks.add(event.value)
      const revealedA = secrets[event.value]
      Object.keys(urlDataRc.urlData)
        .filter((url) => !this.links.has(url) && secrets[url])
        .forEach((url) => {
          const revealedB = secrets[url]
          if (doUrlsMatch(revealedA, revealedB, true)) {
            addedLinks.add(url)
            this.links.add(event.value)
          }
        })
    }
    if (
      event.eventType === 'metadata' &&
      event.key === 'addStudentCopyDriveFileId' &&
      event.value &&
      secrets[event.value] &&
      !this.driveFileIds.has(event.value)
    ) {
      this.driveFileIds.add(event.value)
      stateChanged = true
    }
    if (
      event.eventType === 'metadata' &&
      event.key === 'removeStudentCopyDriveFileId' &&
      event.value &&
      this.driveFileIds.has(event.value)
    ) {
      this.driveFileIds.delete(event.value)
      stateChanged = true
    }
    if (
      event.eventType === 'metadata' &&
      event.key === 'removeStudentCopyLink' &&
      event.value &&
      this.knownLinks.has(event.value)
    ) {
      this.knownLinks.delete(event.value)
      removedKnownLinks.add(event.value)
    }

    if (
      (event.eventType === 'tabFocus' || event.eventType === 'tabChange') &&
      event.url &&
      !this.links.has(event.url)
    ) {
      // This is a new URL we may not have seen before.
      // Check if it's close enough to any of our known urls
      // If it is, add it to the list of links
      const revealedA = secrets[event.url]
      if (!revealedA) return
      for (let link of this.knownLinks) {
        const revealedB = secrets[link]
        if (!revealedB) continue
        if (doUrlsMatch(revealedA, revealedB, true)) {
          // If it does, add it to the list of links
          this.links.add(event.url)
          addedLinks.add(event.url)
        }
      }
    }
    if (addedLinks.size > 0) {
      this.addedLinksAtPriorStep = addedLinks
      stateChanged = true
    }
    if (removedKnownLinks.size > 0) {
      const removedLinks = new Set()
      Object.keys(urlDataRc.urlData)
        .filter((url) => this.links.has(url) && secrets[url])
        .forEach((url) => {
          const revealedA = secrets[url]
          const stillMatches = [...this.knownLinks].some((link) => {
            const revealedB = secrets[link]
            return doUrlsMatch(revealedA, revealedB, true)
          })
          if (!stillMatches) {
            this.links.delete(url)
            removedLinks.add(url)
          }
        })

      if (removedLinks.size > 0) {
        this.removedLinksAtPriorStep = removedLinks
        stateChanged = true
      }
    }
    return stateChanged
  }
}
