import { eventFromJson } from '../../utils/eventFromJson'
import { LoggingStartEvent } from '../../LoggingStartEvent'
import { LoggingStopEvent } from '../../LoggingStopEvent'

/**
 * Transition a set of calculators to the next state based on the given event and stateData.
 *
 * @param event the next event in the event stream
 * @param stateData a map of state data.  This is an object containing arbitrary data fields.  It is used to complement and
 *                  inform the running calculator.  This data is passed in from the caller, so it is up to the caller
 *                  to ensure that the correct fields are present for the given RunningCalculator.
 * @param calculatorMap a map of calculators.  This is an object mapping arbitrary sets of calculator ids to
 *                      RunningCalculator objects.
 * @param commonCalculatorMap a map of calculators that are common to all sessions.  This is an object mapping arbitrary
 *                            sets of calculator ids to RunningCalculator objects.
 * @returns {{}}
 */
export function transitionCalculators(
  event,
  stateData,
  calculatorMap,
  commonCalculatorMap = {},
) {
  if (!calculatorMap.browserRc) {
    throw new Error('CalcManager.transition: browserRc is required')
  }
  if (!stateData.timeRange) {
    stateData.timeRange = { start: 0, stop: Number.MAX_SAFE_INTEGER }
  }
  if (event.eventType === 'metadata') {
    event = eventFromJson(event.toJson()) // Copy to avoid side effects with input data
    event.time = calculatorMap.browserRc.time // Set the time to the last recorded event time
  }

  if (calculatorMap.browserRc.time > event.time) {
    console.warn(
      'RunningLog.transition: event time is in the past',
      event,
      calculatorMap.browserRc,
    )
    console.warn(
      'This event will be taken into account, but it will not reverse the time of the browser state.',
    )
    console.warn(
      'This is a potential indication that the calculation is incorrect, and it should be rerun with the events in the correct order according to timestamp.',
    )
    event = eventFromJson(event.toJson()) // Copy to avoid side effects with input data
    event.time = Math.max(calculatorMap.browserRc.time, event.time) // Avoid negative time deltas while transitioning
    //TODO: If we see this warning, we should investigate why it's occurring specifically.
  }
  const calculatorsChanged = {}

  if (event.eventType === 'loggingStart' && calculatorMap.browserRc.logging) {
    console.warn(
      'RunningLog.transition: loggingStart event while already logging.  Simulating a loggingStop event at the time of the last recorded event, then continuing on.  This could be a sign of a calculation error - or the user shut down their browser mid session.',
    )
    // Force a transition to loggingStop, using the last recorded time for its event.
    // This helps ensure that if the user shuts down their browser, we won't count
    // the time between the last event and the browser shutdown as logged time.
    const changedCalcs = transitionCalculators(
      new LoggingStopEvent({
        time: calculatorMap.browserRc.time,
        implicit: false,
        value: 'calculated',
      }),
      stateData,
      calculatorMap,
      commonCalculatorMap,
    )
    Object.keys(changedCalcs).forEach((calcName) => {
      if (changedCalcs[calcName]) {
        calculatorsChanged[calcName] = true
      }
    })
  } else if (event.eventType === 'loggingStop') {
    if (!calculatorMap.browserRc.logging) {
      console.warn(
        'RunningLog.transition: loggingStop event while not logging.  Simulating a loggingStart event at the time of the loggingStop event, then continuing on.  This could be a sign of a calculation error or perhaps a bug in the logger.',
      )
      // Force a transition to loggingStart, using the event's time for its event.  This just helps math work out.
      const changedCalcs = transitionCalculators(
        new LoggingStartEvent({
          time: event.time,
          implicit: false,
          value: 'calculated',
        }),
        stateData,
        calculatorMap,
        commonCalculatorMap,
      )
      Object.keys(changedCalcs).forEach((calcName) => {
        if (changedCalcs[calcName]) {
          calculatorsChanged[calcName] = true
        }
      })
    } else if (event.value === 'onStartupStopLogging') {
      console.warn(
        'RunningLog.transition: onStartupStopLogging event detected... Discounting time leading up to it and simulating a loggingStart event at the time of the last recorded event, then continuing on.  This is a sign the user restarted their browser in the middle of logging.',
      )
      event.time = calculatorMap.browserRc.time
    }
  }
  const allCalculators = { ...calculatorMap, ...commonCalculatorMap }

  // Some calculators need to run before others do.  We'll run those first by
  // calculating a dependency map and then running the ones that have no dependencies,
  // on a loop until all calculators have been run once.
  const dependencyMap =
    constructBackwardsCalculatorDependencyMap(allCalculators)
  let readyToCalculate = [...Object.keys(dependencyMap)].filter((calcName) => {
    return dependencyMap[calcName].length === 0
  })

  // Progressively run through the calculators, making sure to run the ones that have no dependencies first.
  while (readyToCalculate.length > 0) {
    for (const calcName of readyToCalculate) {
      const calculator = allCalculators[calcName]
      try {
        let innerResult = !!calculator.transition(event, {
          ...stateData,
          ...allCalculators,
        })
        calculatorsChanged[calcName] ||= innerResult
      } catch (e) {
        console.error(
          `Error in calculator ${calcName}.  This is probably a real problem and should be resolved, but moving on to the other calculators, maybe it's an isolated error.`,
          e,
        )
      }
      delete dependencyMap[calcName]
      for (const dependencyList of Object.values(dependencyMap)) {
        const index = dependencyList.indexOf(calcName)
        if (index > -1) {
          dependencyList.splice(index, 1)
        }
      }
    }
    readyToCalculate = [...Object.keys(dependencyMap)].filter((calcName) => {
      return dependencyMap[calcName].length === 0
    })
  }
  return calculatorsChanged
}

export function constructBackwardsCalculatorDependencyMap(calculatorMap) {
  const backwardsCalculatorDependencyMap = {}
  for (const [calculatorName, calculator] of Object.entries(calculatorMap)) {
    backwardsCalculatorDependencyMap[calculatorName] ||= []
    for (const dependency of calculator.runBefore) {
      if (calculatorMap[dependency]) {
        backwardsCalculatorDependencyMap[dependency] ||= []
        backwardsCalculatorDependencyMap[dependency].push(calculatorName)
      }
    }
  }
  detectCircularDependencies(backwardsCalculatorDependencyMap)
  return backwardsCalculatorDependencyMap
}

function detectCircularDependencies(calculatorDependencyMap) {
  const calculatorNames = Object.keys(calculatorDependencyMap)
  const visited = {}
  const visiting = {}
  for (const calculatorName of calculatorNames) {
    if (!visited[calculatorName]) {
      detectCircularDependenciesHelper(
        calculatorName,
        calculatorDependencyMap,
        visited,
        visiting,
      )
    }
  }
}

function detectCircularDependenciesHelper(
  calculatorName,
  calculatorDependencyMap,
  visited,
  visiting,
) {
  if (visited[calculatorName]) return
  if (visiting[calculatorName]) {
    throw new Error(
      `Circular dependency detected at calculator '${calculatorName}'`,
    )
  }
  visiting[calculatorName] = true
  for (const dependency of calculatorDependencyMap[calculatorName]) {
    detectCircularDependenciesHelper(
      dependency,
      calculatorDependencyMap,
      visited,
      visiting,
    )
  }
  visited[calculatorName] = true
  delete visiting[calculatorName]
}
