import { send } from './send'

/**
 * Send a message through Firebase Cloud Messaging to one or more registration ids.
 *
 * NOTE: Dev-only
 *
 * @param {string[]} targetRegistrationIds The registration ids of the devices to send the message to.
 * @param {Object} data The data to send.
 * @returns {Promise<Object>} The result of the send.
 */
export async function sendToRegistrationIds(targetRegistrationIds, data) {
  const multicast = targetRegistrationIds.length > 1
  const message = {
    data,
  }

  if (multicast) {
    message.tokens = targetRegistrationIds
  } else {
    message.token = targetRegistrationIds[0]
  }
  return await send(message)
}
