export class ResourceInvitationOptions {
  users = []
  roles = []
  notifyByEmail = true

  constructor({ users = [], roles = [], notifyByEmail = true }) {
    this.users = JSON.parse(JSON.stringify(users))
    this.roles = JSON.parse(JSON.stringify(roles))
    this.notifyByEmail = !!notifyByEmail
  }

  addUserEmail(email) {
    const newUser = { email }
    for (const user of this.users) {
      if (user.email === email) {
        return
      }
    }
    this.users.push(newUser)
  }

  addUserUid(uid) {
    const newUser = { uid }
    for (const user of this.users) {
      if (user.uid === uid) {
        return
      }
    }
    this.users.push(newUser)
  }

  toJson() {
    return {
      users: JSON.parse(JSON.stringify(this.users || [])),
      roles: JSON.parse(JSON.stringify(this.roles || [])),
      notifyByEmail: this.notifyByEmail,
    }
  }

  get emails() {
    return this.users.filter((user) => user.email).map((user) => user.email)
  }

  get uids() {
    return this.users.filter((user) => user.uid).map((user) => user.uid)
  }

  hasUserWithEmail(email) {
    return this.users.some((user) => user.email === email)
  }

  clone() {
    return new ResourceInvitationOptions(this.toJson())
  }
}
