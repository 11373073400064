import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { paths } from '@learnics/models/src/utils/paths'
import { getLoggerSession } from './getLoggerSession'
import { getAcl } from '../acl/getAcl'

export async function getLoggerSessionsForResearchNotebook(
  researchNotebookId,
  callback = async () => {},
) {
  const sessions = {}
  const acl = await getAcl(paths.researchNotebook(researchNotebookId))
  const sessionIds = acl.getParents('loggerSession')
  for (const sessionId of sessionIds) {
    const session = await getLoggerSession(sessionId)
    sessions[sessionId] = session
    await callback(session)
  }
  return sessions
}
