import { AbstractAttachment } from './AbstractAttachment'

/**
 * A class to represent simple link Attachment objects.  This class is used
 * to store metadata about simple links attached to an assignment.  It is a
 * subclass of AbstractAttachment.
 *
 * @example
 * // Concrete initialization and usage
 * const concreteAttachmentData = {
 *   id: "some-learnics-based-link-attachment-id",
 *   url: "https://some-url.com",
 *   title: "some link title!",
 *   mimeType: "some-mime-type"
 * }
 *
 * // Instantiate the attachment on the fly
 * const attachment = new YoutubeVideoAttachment(concreteAttachmentData);
 *
 * // Create a database-friendly version of the LinkAttachment object.
 * // This should be a clone of the concreteAttachmentData (unless it has
 * // been changed in the meantime), except with an added attachmentType property
 * // set to "link".
 * const savableAbstractAttachmentData = attachment.toJson();
 *
 *
 * @example
 * // Abstract initialization and usage
 *
 * const abstractDatabaseAttachments = [{
 *   attachmentType: "link",
 *   id: "some-learnics-based-link-attachment-id",
 *   url: "https://some-url.com",
 *   title: "some link title!",
 *   mimeType: "some-mime-type"
 * }, {
 *     // ... some other AbstractAttachment data
 * }];
 *
 * // Instantiate an array of serialized AbstractAttachment JSON objects
 * // (from the database), in order to restore their functionality, without ever
 * // having to go through each one of them and individually do it
 * const attachments = abstractDatabaseAttachments.map(
 *     attachment => attachmentFromJson(attachment)
 *   );
 *
 * attachments.forEach((attachment) => {
 *   // Easily and concisely use it to check if a given url matches it, without
 *   // caring about the particulars
 *   const isUrlAMatch = attachment.matchesUrl(url);
 * });
 *
 * // Create a database-friendly version of an array of attachments.  This should
 * // be a clone of the abstractDatabaseAttachments array (unless it has been
 * // changed in the meantime).
 * const savableAbstractAttachmentsCopy = attachments.map(
 *     attachment => attachment.toJson()
 *   );
 *
 * @inheritDoc
 */
export class LinkAttachment extends AbstractAttachment {
  /**
   * Constructor for a LinkAttachment object.
   *
   * @param data {object} the data to initialize the FormAttachment with
   * @param data.id {string} the Learnics-based id of the attachment
   * @param data.title {string} the title of the link, if any
   * @param data.url {string} the url of the link
   * @param data.iconUrl {string} the url of the icon to use for the link, if any
   * @param data.mimeType {string} the mime type of the link, if any
   * @param data.matchExactly {boolean} whether or not to be generous to the student when matching URLs against this attachment
   */
  constructor({
    id,
    title,
    url,
    iconUrl = null,
    mimeType = null,
    matchExactly = false,
  }) {
    super('link', id, title, url)
    this.iconUrl = iconUrl
    this.mimeType = mimeType
    this.matchExactly = matchExactly
  }

  get icon() {
    return 'fa-link'
  }

  toJson() {
    const json = super.toJson()
    json.iconUrl = this.iconUrl
    json.mimeType = this.mimeType
    json.matchExactly = this.matchExactly
    return json
  }

  toClassroomMaterial() {
    return {
      link: {
        url: this.url,
        thumbnailUrl: this.iconUrl,
        title: this.title,
      },
    }
  }
}
