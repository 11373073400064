import { RunningCalculator } from './RunningCalculator'

/**
 * SubmissionsRc stores submission dates for assignments
 */
export class SubmissionsRc extends RunningCalculator {
  dates = []
  constructor(storageObj) {
    super('submissionsRc', ['browserRc'])
    this.dates = JSON.parse(JSON.stringify(storageObj?.dates || []))
  }

  toJson() {
    return {
      ...super.toJson(),
      dates: JSON.parse(JSON.stringify(this.dates)),
    }
  }

  transition(event, stateData) {
    const timeRange = stateData.timeRange
    if (event.time < timeRange.start || event.time > timeRange.stop)
      return false
    if (
      event.eventType === 'assignmentSubmit' &&
      this.dates.indexOf(event.time) === -1 &&
      (this.dates.length === 0 ||
        event.time - this.dates[this.dates.length - 1] > 5000)
    ) {
      this.dates.splice(this.dates.length, 0, event.time)
      return true
    }
  }
}
