import { AttachmentsExpectation } from './AttachmentsExpectation'

/**
 * A class to represent an EditingGoogleDocsExpectation for an assignment.  This
 * type of expectation requires that the student spend time editing 1 or more
 * Google Docs files.  We assume that if the student has the logger running, and
 * they're editing a Google Docs file, that they're editing the file for the
 * purpose of the research assignment.  This frees us from having to track the
 * student's Google Docs file metadata until AFTER submission and gives the
 * student the benefit of the doubt.
 *
 * There is only ever one EditingGoogleDocsExpectation per assignment at the
 * current time.
 *
 * @example
 * // Concrete initialization and usage
 * const concreteExpectationData = {
 *   ratio: 0.23,
 * }
 *
 * // Instantiate the expectation on the fly, so you can use it to calculate time
 * // and other things
 * const expectation = new EditingGoogleDocsExpectation(concreteExpectationData);
 *
 * // Create a database-friendly version of the expectation.  This should be a
 * // clone of the concreteExpectationData, except with the id and expectationType
 * // properties both set to "editingGoogleDocs".
 * const savableExpectationData = expectation.toJson();
 *
 * @example
 * // Abstract initialization and usage
 *
 * const databaseExpectations = [{
 *   id: "editingGoogleDocs",
 *   expectationType: "editingGoogleDocs",
 *   ratio: 0.8,
 * }, {
 *     // ... some other expectation
 * }];
 *
 * // Instantiate an array of serialized AbstractExpectation JSON objects (from
 * // the database), in order to restore their functionality.
 * const expectations = databaseExpectations.map(
 *    expectation => expectationFromJson(expectation)
 *  );
 *
 * // Create a database-friendly version of an array of expectations.  This
 * // should be a clone of the databaseExpectations array.
 * const savableExpectationsCopy = expectations.map(
 *     expectation => expectation.toJson()
 *   )
 *
 * @inheritDoc
 */
export class EditingGoogleDocsExpectation extends AttachmentsExpectation {
  /**
   * Constructor for the EditingGoogleDocsExpectation class.
   *
   * @param {any} input a plain EditingGoogleDocsExpectation JSON object
   * @param {number} input.ratio the ratio of total time expected for this
   * expectation.
   */
  constructor({ ratio, attachmentIds }) {
    super({
      id: 'editingGoogleDocs',
      label: `Editing Assigned Doc${attachmentIds?.length > 1 ? 's' : ''}`,
      ratio,
      attachmentIds,
    })
    this.expectationType = 'editingGoogleDocs'
  }
}
