import { researchSourceTypeClassMap } from './researchSourceTypeClassMap'

export function researchSourceFromYMap(yMap) {
  const type = yMap.get('type')
  const SourceClass = researchSourceTypeClassMap[type]
  if (!SourceClass) {
    throw new Error(`Unknown research source type: ${type}`)
  }
  return new SourceClass(yMap)
}
