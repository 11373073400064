import { RunningCalculator } from './RunningCalculator'

/**
 * DomainDataRc is a RunningCalculator class that calculates Domain times/visits
 * for a given logging session.
 *
 */
export class DomainDataRc extends RunningCalculator {
  dataMap = {}
  delta = {}

  constructor(storageObj) {
    super('domainDataRc', [
      'browserRc',
      'externalUrlsRc',
      'expectationsRc',
      'expectationRatiosRc',
      'studentCopiesRc',
      'urlTimelinesRc',
      'commonDomainDataRc',
    ])
    this.dataMap = Object.keys(storageObj?.dataMap || {}).reduce((acc, key) => {
      acc[key] = {
        time: storageObj.dataMap[key].time || 0,
        urls: new Set(storageObj.dataMap[key].urls || []),
      }
      return acc
    }, {})
    this.delta = JSON.parse(JSON.stringify(storageObj?.delta || {}))
  }

  toJson() {
    return {
      ...super.toJson(),
      dataMap: Object.keys(this.dataMap).reduce((acc, key) => {
        acc[key] = {
          time: this.dataMap[key].time,
          urls: [...this.dataMap[key].urls],
        }
        return acc
      }, {}),
      delta: JSON.parse(JSON.stringify(this.delta || {})),
    }
  }

  transition(event, stateData) {
    let changed = Object.keys(this.delta || {}).length > 0
    this.delta = {}

    const timeRange = stateData.timeRange
    if (event.time < timeRange.start || event.time > timeRange.stop)
      return changed
    const { secrets, urlDataRc } = stateData
    const updatedUrls = Object.keys(urlDataRc.updatedUrlsAtPriorStep || {})
    for (let i = 0; i < updatedUrls.length; i++) {
      const url = updatedUrls[i]
      const revealedUrl = secrets[url]
      if (!revealedUrl) continue
      const revealedUrlDomain = new URL(revealedUrl).hostname

      const oldVal = urlDataRc.updatedUrlsAtPriorStep[url]
      const newVal = urlDataRc.urlData[url]
      if (!newVal || newVal.time === oldVal?.time) continue

      const newTime = newVal.time || 0
      const oldTime = oldVal?.time || 0

      changed = true

      if (!this.dataMap[revealedUrlDomain]) {
        this.dataMap[revealedUrlDomain] = {
          time: 0,
          urls: new Set(),
        }
      }
      const domainStats = this.dataMap[revealedUrlDomain]
      if (!domainStats.urls.has(url)) {
        this.delta[revealedUrlDomain] ||= {}
        this.delta[revealedUrlDomain].addUrls ||= []
        this.delta[revealedUrlDomain].addUrls.push(url)
        domainStats.urls.add(url)
      }
      if (newTime !== oldTime) {
        this.delta[revealedUrlDomain] ||= {}
        this.delta[revealedUrlDomain].time = newTime - oldTime
        domainStats.time += newTime - oldTime
      }
    }
    return changed
  }
}
