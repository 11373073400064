import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

import { loadRosterFromRoles } from '../user/loadRosterFromRoles'

export async function getSectionRoster(sectionId) {
  let section = await getDoc(refs.section(sectionId))
  if (!section.exists()) return null
  section = section.data()
  return loadRosterFromRoles(section.roles)
}
