import { listCourses } from './listCourses'

export async function getCourseFromClassroomUrl(
  accessToken,
  courseUrl,
  courses = null,
) {
  if (!courses) {
    try {
      courses = await listCourses(accessToken)
    } catch (err) {
      console.log('Error getting courses from classroom URL', err)
      return null
    }
  }
  const splitRegex = /(https?:\/\/)(.*?)(\/.*)/
  const endOfUrl = splitRegex.exec(courseUrl).pop()
  const possibilities = endOfUrl.split('/').filter((i) => {
    return i.length > 3
  })
  const courseIdentifiers = possibilities.reverse()

  let course = null
  for (let i = 0; i < courseIdentifiers.length && !course && courses; i++) {
    course = courses.find(
      (c) => c.alternateLink.indexOf(courseIdentifiers[i]) >= 0,
    )
  }
  return course
}
