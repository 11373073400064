import { CommonRunningCalculator } from './CommonRunningCalculator'

/**
 * CommonSearchWordFrequenciesRc stores frequency information about multiple
 * students' searches and uses it to generate a word cloud.
 */
export class CommonSearchWordFrequenciesRc extends CommonRunningCalculator {
  constructor(storageObj) {
    super('commonSearchWordFrequenciesRc', ['browserRc'])
    const dataMap = storageObj?.dataMap || {}
    this.dataMap = JSON.parse(JSON.stringify(dataMap))
  }

  toJson() {
    return {
      ...super.toJson(),
      dataMap: JSON.parse(JSON.stringify(this.dataMap || {})),
    }
  }

  transition(event, stateData) {
    const { urlDataRc, searchWordFrequenciesRc, secrets } = stateData
    let stateChanged = this.lastUpdate !== null
    this.lastUpdate = null

    Object.keys(searchWordFrequenciesRc.lastUpdate || {}).forEach((word) => {
      const dv =
        (searchWordFrequenciesRc.dataMap[word] || 0) -
        (searchWordFrequenciesRc.lastUpdate[word] || 0)
      if (dv === 0) return

      this.dataMap[word] ??= 0
      this.lastUpdate ||= {}
      this.lastUpdate[word] ??= this.dataMap[word]
      this.dataMap[word] += dv
      stateChanged = true
      if (this.dataMap[word] === 0) delete this.dataMap[word]
    })

    return stateChanged
  }

  addSession(sessionCalcs, sessionSecrets, multiplier) {
    const { searchWordFrequenciesRc } = sessionCalcs
    for (const searchTerm of Object.keys(
      searchWordFrequenciesRc?.dataMap || {},
    )) {
      this.dataMap[searchTerm] ??= 0
      this.dataMap[searchTerm] +=
        searchWordFrequenciesRc.dataMap[searchTerm] * multiplier
      if (this.dataMap[searchTerm] <= 0) {
        delete this.dataMap[searchTerm]
      }
    }
  }

  toWordCloudDataSet() {
    return Object.keys(this.dataMap)
      .sort((a, b) => this.dataMap[b] - this.dataMap[a])
      .slice(0, 60)
      .map((word) => [word, this.dataMap[word]])
  }
}
