import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getOrganizations(
  userId,
  role,
  callback = async () => {},
) {
  const orgs = {}
  const querySnapshot = await getDocs(
    query(refs.organizations(), where(role, 'array-contains', userId)),
  )
  for (const orgDoc of querySnapshot.docs) {
    const org = orgDoc.data()
    if (org) {
      orgs[orgDoc.id] = org
      await callback(org)
    }
  }
  return orgs
}
