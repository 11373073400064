import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getCourses(
  orgId,
  userId,
  role = 'Learner',
  callback = async () => {},
) {
  const courses = {}
  const querySnapshot = await getDocs(
    query(
      refs.courses(),
      where('orgId', '==', orgId),
      where('archived', '!=', true),
      where(role, 'array-contains', userId),
    ),
  )
  for (const courseDoc of querySnapshot.docs) {
    const course = courseDoc.data()
    if (course) {
      courses[courseDoc.id] = course
      await callback(course)
    }
  }
  return courses
}
