import { isActive } from '@tiptap/core'
import { Highlight } from '@tiptap/extension-highlight'

export const CustomHighlight = Highlight.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Shift-h': (event) => {
        if (isActive(event.editor.state, 'highlight')) {
          event.editor.commands.unsetHighlight()
        } else {
          event.editor.commands.setHighlight({
            color: localStorage.getItem('selectedHighlightColor') || '#FFFF99',
          })
        }
        return true
      },
    }
  },
}).configure({
  multicolor: true,
  HTMLAttributes: {
    class: 'highlighted-text',
  },
})
