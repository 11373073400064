import Vue from 'vue'
import * as Y from 'yjs'
import { ResearchNotebook } from '@learnics/models/src/ResearchNotebook/ResearchNotebook'
import { getResearchNotebook } from '../../researchNotebook/getResearchNotebook'

export const state = {
  researchNotebooks: {},
}

export const getters = {
  researchNotebooks: (state) => state.researchNotebooks,
}

export const mutations = {
  setResearchNotebook: (
    state,
    { compositeId, newVal, oldVal, forced, refresh = true },
  ) => {
    const { id } = compositeId
    if (newVal) {
      if (forced || !state.researchNotebooks[id]) {
        Vue.set(state.researchNotebooks, id, newVal)
      } else {
        const notebook = state.researchNotebooks[id]
        if (notebook !== newVal) {
          // No need to actually update the notebook unless the YDoc has changed.
          Y.applyUpdate(notebook.yDoc, Y.encodeStateAsUpdate(newVal.yDoc))
        }

        if (refresh) {
          Vue.set(
            state.researchNotebooks,
            id,
            ResearchNotebook.shallowCopy(notebook),
          )
        }
      }
    } else {
      Vue.delete(state.researchNotebooks, id)
    }
  },
}

export const actions = {
  // Refresh the notebook by replacing it with the current value in the store,
  // which will kick vue change detection into gear.
  refreshResearchNotebook: ({ state, commit }, { id }) => {
    commit('setResearchNotebook', {
      compositeId: { id },
      newVal: state.researchNotebooks[id],
      refresh: true,
    })
  },

  /**
   * Load one or more ResearchNotebooks into the store.
   *
   * NOTE:  This should not be used by the extension.  The extension should
   *        rely on service worker messages to update its entities, which will
   *        then cascade down to the store.
   *
   * @param state
   * @param assignment
   */
  async loadResearchNotebooks(
    { state, dispatch, commit, rootState },
    args = { forced: false },
  ) {
    const ids = args.ids || []

    for (const id of ids) {
      await dispatch('loadResearchNotebook', { id, forced: args.forced })
      // If we have the NoteBoard already, don't load it again
    }
  },

  /**
   * Load a ResearchNotebook into the store.
   *
   * NOTE:  This should not be used by the extension.  The extension should
   *        rely on service worker messages to update its entities, which will
   *        then cascade down to the store.
   *
   * @param state
   * @param assignment
   */

  async loadResearchNotebook(
    { state, commit, rootState },
    args = { forced: false },
  ) {
    const { id } = args
    const researchNotebook = state.researchNotebooks[id]
    if (!researchNotebook || args.forced) {
      const notebook = await getResearchNotebook(id)
      if (notebook) {
        commit('setResearchNotebook', {
          compositeId: { id },
          newVal: notebook,
          forced: args.forced,
        })
      }
    }
  },
}
