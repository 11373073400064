import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { getCourse } from './getCourse'

export async function getCoursesByClassroomCourseId(
  classroomCourseId,
  callback = async () => {},
) {
  const result = {}
  const querySnapshot = await getDocs(
    query(refs.courses(), where('classroomCourseId', '==', classroomCourseId)),
  )
  for (const courseDoc of querySnapshot.docs) {
    const course = courseDoc.data()
    if (course) {
      result[course.id] = course
      await callback(course)
    }
  }
  if (Object.keys(result).length === 0) {
    const oldCourse = await getCourse(classroomCourseId)
    if (oldCourse && oldCourse.googleClassroom && !oldCourse.hasSections) {
      result[oldCourse.id] = oldCourse
      await callback(oldCourse)
    }
  }

  return result
}
