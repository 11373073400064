/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { createCourse } from './createCourse'
import { createCourseWork } from './createCourseWork'
import { ensureClassroomDriveFileAttachments } from './ensureClassroomDriveFileAttachments'
import { getCourseFromClassroomUrl } from './getCourseFromClassroomUrl'
import { listCourseWork } from './listCourseWork'
import { listCourses } from './listCourses'
import { registerCourseworkPushNotifications } from './registerCourseWorkPushNotifications'
import { updateCourseWork } from './updateCourseWork'

const GoogleClassroomService = {
  createCourse,
  createCourseWork,
  ensureClassroomDriveFileAttachments,
  getCourseFromClassroomUrl,
  listCourseWork,
  listCourses,
  registerCourseworkPushNotifications,
  updateCourseWork,
}

export default GoogleClassroomService
