import Vue from 'vue'
import * as Y from 'yjs'
import { NoteBoard } from '@learnics/models/src/NoteBoard/NoteBoard'
import { getNoteBoard } from '../../noteBoard/getNoteBoard'
import ResourceService from '../../resource'
import AclService from '../../acl'
import {
  getRevealedSites,
  paths,
  redact,
  WebsiteSource,
} from '@learnics/models/src'
import { nanoid } from 'nanoid'

export const state = {
  noteBoards: {},
  noteBoardUsers: {},
  currentNoteBoardId: null,
  editingNoteBoardNoteIds: {},
  selectedNoteBoardNoteIds: {},
  filteredNoteBoardUserIds: {},
  noteBoardTruncation: {
    lines: 5,
    length: 500,
  },
}

export const getters = {
  noteBoards: (state) => state.noteBoards,
  noteBoardUsers: (state) => state.noteBoardUsers,
  currentNoteBoardId: (state) => state.currentNoteBoardId,
  editingNoteBoardNoteIds: (state) => state.editingNoteBoardNoteIds,

  currentNoteBoard: (state) => {
    return state.noteBoards[state.currentNoteBoardId]
  },
  selectedNoteBoardNoteIds: (state) => state.selectedNoteBoardNoteIds,
  filteredNoteBoardUserIds: (state) => state.filteredNoteBoardUserIds,

  currentNoteBoardTags: (state, getters, rootState, rootGetters) => {
    const noteBoardId = getters.currentNoteBoardId
    if (!noteBoardId) return {}
    const userIds = Object.keys(state.noteBoardUsers[noteBoardId] || {})
    const result = {}
    for (const userId of userIds) {
      const noteBoardUser = state.noteBoardUsers[noteBoardId][userId]

      for (let researchNotebookId of noteBoardUser.researchNotebookIds) {
        const notebook =
          rootState.researchNotebooks.researchNotebooks[researchNotebookId]
        if (!notebook) continue
        notebook.createTagFrequencyMap(rootState.notes.notes, result)
      }
    }
    return result
  },

  currentNoteBoardSites: (state, getters, rootState, rootGetters) => {
    const noteBoardId = getters.currentNoteBoardId
    if (!noteBoardId) return {}
    const userIds = Object.keys(state.noteBoardUsers[noteBoardId] || {})
    const result = {}
    for (const userId of userIds) {
      const noteBoardUser = state.noteBoardUsers[noteBoardId][userId]
      for (let researchNotebookId of noteBoardUser.researchNotebookIds) {
        const notebook =
          rootState.researchNotebooks.researchNotebooks[researchNotebookId]
        if (!notebook) continue
        const sessionId = notebook.sessionId
        const runningCalculators =
          rootState.eventLogs.runningCalculators[sessionId]
        if (!runningCalculators) {
          continue
        }
        const secrets = rootState.eventLogs.secrets[sessionId]
        if (!secrets) {
          continue
        }
        const revealedSites = getRevealedSites(runningCalculators, secrets)
        for (let site of revealedSites) {
          if (!result[site.url]) {
            result[site.url] = site
          } else {
            result[site.url].combine(site)
          }
        }
      }
    }
    return result
  },
  noteBoardTruncation: (state) => state.noteBoardTruncation,
}

export const mutations = {
  addFilteredNoteBoardUserId: (state, { noteBoardId, userId }) => {
    if (!state.filteredNoteBoardUserIds[noteBoardId])
      Vue.set(state.filteredNoteBoardUserIds, noteBoardId, [])
    if (!state.filteredNoteBoardUserIds[noteBoardId].includes(userId)) {
      state.filteredNoteBoardUserIds[noteBoardId].splice(0, 0, userId)
    }
  },

  removeFilteredNoteBoardUserId: (state, { noteBoardId, userId }) => {
    if (state.filteredNoteBoardUserIds[noteBoardId]) {
      state.filteredNoteBoardUserIds[noteBoardId].splice(
        state.filteredNoteBoardUserIds[noteBoardId].indexOf(userId),
        1,
      )
    }
  },

  setNoteBoardTruncation: (state, { lines, length }) => {
    state.noteBoardTruncation = { lines, length }
  },
  setNoteBoard: (
    state,
    { compositeId, newVal, oldVal, forced, refresh = false },
  ) => {
    const { id } = compositeId
    if (newVal) {
      if (forced || !state.noteBoards[id]) {
        Vue.set(state.noteBoards, id, newVal)
      } else {
        const noteBoard = state.noteBoards[id]
        if (newVal !== noteBoard) {
          // No need to actually update the note board unless the YDoc has changed.
          Y.applyUpdate(noteBoard.yDoc, Y.encodeStateAsUpdate(newVal.yDoc))
        }
        if (refresh) {
          Vue.set(state.noteBoards, id, NoteBoard.shallowCopy(noteBoard))
        }
      }
    } else Vue.delete(state.noteBoards, id)
  },

  setNoteBoardUser: (state, { compositeId, newVal, oldVal }) => {
    const { userId, noteBoardId } = compositeId
    if (newVal) {
      if (!state.noteBoardUsers[noteBoardId])
        Vue.set(state.noteBoardUsers, noteBoardId, {})
      Vue.set(state.noteBoardUsers[noteBoardId], userId, newVal)
    } else {
      if (state.noteBoardUsers[noteBoardId])
        Vue.delete(state.noteBoardUsers[noteBoardId], userId)
      if (Object.keys(state.noteBoardUsers[noteBoardId]).length === 0)
        Vue.delete(state.noteBoardUsers, noteBoardId)
    }
  },

  setCurrentNoteBoardId(state, payload) {
    state.currentNoteBoardId = payload
  },

  setEditingNoteBoardNoteId(state, { noteBoardId, noteId }) {
    if (noteId) {
      Vue.set(state.editingNoteBoardNoteIds, noteBoardId, noteId)
    } else {
      Vue.delete(state.editingNoteBoardNoteIds, noteBoardId)
    }
  },

  setSelectedNoteBoardNoteIds(state, { noteBoardId, noteIds }) {
    Vue.set(state.selectedNoteBoardNoteIds, noteBoardId, [...noteIds])
  },
}

export const actions = {
  // Refresh the noteBoard by replacing it with the current value in the store,
  // which will kick vue change detection into gear.
  refreshNoteBoard: ({ commit, state, rootState }, { id }) => {
    commit('setNoteBoard', {
      compositeId: { id },
      newVal: state.noteBoards[id],
      refresh: true,
    })
  },

  /**
   * Load one or more NoteBoards into the store.
   *
   * NOTE:  This should not be used by the extension.  The extension should
   *        rely on service worker messages to update its entities, which will
   *        then cascade down to the store.
   *
   * @param state
   * @param assignment
   */
  async loadNoteBoards(
    { state, dispatch, commit, rootState },
    args = { forced: false },
  ) {
    const ids = args.ids || []

    for (const id of ids) {
      await dispatch('loadNoteBoard', { id, forced: args.forced })
      // If we have the NoteBoard already, don't load it again
    }
  },

  /**
   * Load a NoteBoard into the store.
   *
   * NOTE:  This should not be used by the extension.  The extension should
   *        rely on service worker messages to update its entities, which will
   *        then cascade down to the store.
   *
   * @param state
   * @param assignment
   */

  async loadNoteBoard({ state, commit, rootState }, args = { forced: false }) {
    const { id } = args
    const noteBoard = state.noteBoards[id]
    if (!noteBoard || args.forced) {
      const noteBoard = await getNoteBoard(id)
      if (noteBoard) {
        commit('setNoteBoard', {
          compositeId: { id },
          newVal: noteBoard,
          forced: args.forced,
        })
      }
    }
  },

  async createNoteBoard(
    { commit, dispatch, rootState },
    { userId, assignmentId },
  ) {
    const noteBoardId = nanoid(32)
    const user = rootState.users.users[userId]
    const userName = user.name || user.displayName
    let name
    if (userName) {
      name = `${userName}’s Note Board`
    } else {
      name = 'New Note Board'
    }
    const noteBoard = await ResourceService.createResource(
      'noteBoard',
      { id: noteBoardId, name },
      assignmentId ? 'assignment' : null,
      assignmentId,
    )
    commit('setNoteBoard', {
      compositeId: { id: noteBoardId },
      newVal: new NoteBoard(noteBoard),
    })

    const acl = await AclService.getAcl(paths.noteBoard(noteBoardId))
    console.log('Created note board', noteBoard)
    commit('setAcl', {
      compositeId: { resourceType: 'noteBoard', resourceId: noteBoardId },
      newVal: acl,
    })

    return noteBoardId
  },
}
