import { DriveFileAttachment } from '../DriveFileAttachment'
import { FormAttachment } from '../FormAttachment'
import { LinkAttachment } from '../LinkAttachment'
import { SourceDriveFileAttachment } from '../SourceDriveFileAttachment'
import { YoutubeVideoAttachment } from '../YoutubeVideoAttachment'

/**
 * Converts a Google Classroom "Material" into a Learnics "Attachment".
 *
 * @param material the Google Classroom "Material" to convert
 * @returns {YoutubeVideoAttachment|FormAttachment|SourceDriveFileAttachment|LinkAttachment|DriveFileAttachment} the converted Learnics "Attachment"
 */
export function attachmentFromClassroomMaterial(material) {
  if (material.driveFile) {
    if (material.driveFile.shareMode === 'STUDENT_COPY') {
      return new SourceDriveFileAttachment({
        driveFileId: material.driveFile.driveFile.id,
        title: material.driveFile.driveFile.title,
        url: material.driveFile.driveFile.alternateLink,
        iconUrl: material.driveFile.driveFile.thumbnailUrl,
      })
    } else {
      return new DriveFileAttachment({
        driveFileId: material.driveFile.driveFile.id,
        title: material.driveFile.driveFile.title,
        url: material.driveFile.driveFile.alternateLink,
        iconUrl: material.driveFile.driveFile.thumbnailUrl,
      })
    }
  } else if (material.link) {
    return new LinkAttachment({
      title: material.link.title,
      url: material.link.url,
      iconUrl: material.link.thumbnailUrl,
    })
  } else if (material.youtubeVideo) {
    return new YoutubeVideoAttachment({
      title: material.youtubeVideo.title,
      url: material.youtubeVideo.alternateLink,
      youtubeId: material.youtubeVideo.id,
    })
  } else if (material.form) {
    return new FormAttachment({
      formUrl: material.form.formUrl,
      responseUrl: material.form.responseUrl,
      title: material.form.title,
    })
  } else {
    throw new Error('Invalid material type: "' + material.type + '"')
  }
}
