import { Timeline } from './utils/Timeline'
import { RunningCalculator } from './RunningCalculator'
import { coreTimelineFilters } from './utils/coreTimelineFilters'

/**
 * CoreRc stores basic timelines for a logging session.  We use this to
 * track the overall logging time, unfocused time, focused time, idle time, etc.
 *
 * We store enough data to calculate both the total time for each of these metrics,
 * along with a 2-dimensional timeline for each of these metrics.
 */
export class CoreRc extends RunningCalculator {
  static KEYS = [
    'total',
    'unfocused',
    'focused',
    'idle',
    'deleted',
    'blacklisted',
    'systemIgnored',
    'searching',
    'focusedOnValidUrl',
  ]

  dataMap = {}
  constructor(storageObj) {
    super('coreRc', ['browserRc'])
    CoreRc.KEYS.forEach((key) => {
      this.dataMap[key] = new Timeline(storageObj?.[key])
    })
  }

  toJson() {
    return {
      ...super.toJson(),
      ...CoreRc.KEYS.reduce((acc, key) => {
        acc[key] = this.dataMap[key].toJson()
        return acc
      }, {}),
    }
  }

  transition(event, stateData) {
    let stateChanged = false
    const timeRange = stateData.timeRange
    if (event.time < timeRange.start || event.time > timeRange.stop)
      return stateChanged
    const browserTime = Math.max(timeRange.start, stateData.browserRc.time)
    const eventTime = Math.min(timeRange.stop, event.time)
    if (eventTime - browserTime <= 0) return stateChanged
    CoreRc.KEYS.forEach((key) => {
      if (coreTimelineFilters[key](stateData)) {
        try {
          this.dataMap[key].increment(browserTime, eventTime)
          stateChanged = true
        } catch (e) {
          console.error(
            'Error incrementing core timeline for key',
            key,
            'with event',
            event,
            'and stateData',
            stateData,
            e,
          )
        }
      }
    })
    return stateChanged
  }
}
