import { generateJSON } from '@tiptap/html'
import { activeTipTapExtensions } from './activeTipTapExtensions'

export function getTextFromHtml(
  html,
  extensions = activeTipTapExtensions.get(),
) {
  if (!html) return ''
  return getTextFromNode(generateJSON(html, extensions)).trim()
}

export function getTextFromNode(node) {
  if (!node) return ''
  return getText(node).trim()
}
function getText(jsonNode) {
  let result = ''

  if (jsonNode.type === 'text') {
    result += jsonNode.text
  } else if (jsonNode.content?.length > 0) {
    result += jsonNode.content
      .map(
        (child, i) =>
          (jsonNode.type === 'bulletList' ? '\t* ' : '') +
          (jsonNode.type === 'orderedList'
            ? `\t${i + jsonNode.attrs.start}. `
            : '') +
          getText(child),
      )
      .join('')
  }
  if (jsonNode.type === 'paragraph') {
    result += '\n'
  }
  return result
}
