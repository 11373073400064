import { UrlData } from './utils/UrlData'
import { RunningCalculator } from './RunningCalculator'
import { coreTimelineFilters } from './utils/coreTimelineFilters'
import { defaultUrlFilter } from './utils/defaultUrlFilter'

/**
 * UrlDataRc is a RunningCalculator class that calculates (URL, title) pair times
 * for a given logging session.
 *
 * It also acts as a store for MetadataEvent key/value pairs for a given URL.
 *
 * Since there might be a lot of metadata, the calculator is provided with a
 * list of keys to track.  This list uses a default list of keys, but can be
 * adjusted after instantiation.
 *
 */

const DEFAULT_KEYS_TO_TRACK = [
  'extractedPdfTitle',
  'title',
  // 'scrapedStudentCopy',
  // 'scrapedAttachment',
]
export class UrlDataRc extends RunningCalculator {
  urlData = {}
  metadataKeysToTrack

  // If a url is updated, we store it here for a single step, for other calculators to use in their calculations.
  updatedUrlsAtPriorStep = null

  constructor(storageObj) {
    super('urlDataRc', [
      'browserRc',
      'externalUrlsRc',
      'expectationsRc',
      'expectationRatiosRc',
      'studentCopiesRc',
      'urlTimelinesRc',
      'domainDataRc',
      'searchesRc',
    ])
    this.metadataKeysToTrack = [
      ...(storageObj?.metadataKeysToTrack || DEFAULT_KEYS_TO_TRACK),
    ]
    this.urlData = Object.keys(storageObj?.urlData || {}).reduce((acc, key) => {
      acc[key] = new UrlData(storageObj.urlData[key])
      return acc
    }, {})
    this.updatedUrlsAtPriorStep =
      (storageObj?.updatedUrlsAtPriorStep &&
        Object.keys(storageObj.updatedUrlsAtPriorStep).reduce((acc, key) => {
          acc[key] =
            storageObj.updatedUrlsAtPriorStep[key] &&
            new UrlData(storageObj.updatedUrlsAtPriorStep[key])
          return acc
        }, {})) ||
      null
  }

  toJson() {
    return {
      ...super.toJson(),
      metadataKeysToTrack: [...this.metadataKeysToTrack],
      urlData: Object.keys(this.urlData).reduce((acc, key) => {
        acc[key] = this.urlData[key].toJson()
        return acc
      }, {}),
      updatedUrlsAtPriorStep:
        (this.updatedUrlsAtPriorStep &&
          Object.keys(this.updatedUrlsAtPriorStep).reduce((acc, key) => {
            acc[key] = this.updatedUrlsAtPriorStep[key]?.toJson() || null
            return acc
          }, {})) ||
        null,
    }
  }

  transition(event, stateData) {
    let stateChanged = this.updatedUrlsAtPriorStep !== null
    this.updatedUrlsAtPriorStep = null
    const { secrets, keysDeleted, keysBlacklisted, browserRc, urlFilter } =
      stateData
    if (
      event.eventType === 'metadata' &&
      this.metadataKeysToTrack.includes(event.key)
    ) {
      const oldData =
        (this.urlData[event.url] &&
          new UrlData(this.urlData[event.url].toJson())) ||
        null

      if (!this.metadataKeysToTrack.includes(event.key)) return
      this.urlData[event.url] ||= new UrlData()
      this.urlData[event.url].metadata[event.key] = event.value
      this.updatedUrlsAtPriorStep = {}
      this.updatedUrlsAtPriorStep[event.url] = oldData
      stateChanged = true
    }
    const timeRange = stateData.timeRange

    const browserTime = Math.max(timeRange.start, browserRc.time)
    const eventTime = Math.min(timeRange.stop, event.time)
    if (
      event.time >= timeRange.start &&
      event.time <= timeRange.stop &&
      eventTime - browserTime > 0 &&
      coreTimelineFilters.focusedOnValidUrl({
        browserRc,
        secrets,
        keysDeleted,
        keysBlacklisted,
        urlFilter,
      })
    ) {
      const tab = browserRc.focusedTab
      const url = tab?.url
      const title = tab?.title || url
      const oldData =
        (this.urlData[url] && new UrlData(this.urlData[url].toJson())) || null

      this.urlData[url] ||= new UrlData()
      this.urlData[url].titleTimes[title] ||= 0
      this.urlData[url].titleTimes[title] += eventTime - browserTime
      this.updatedUrlsAtPriorStep ||= {}
      if (!this.updatedUrlsAtPriorStep[url])
        this.updatedUrlsAtPriorStep[url] = oldData
      stateChanged = true
    }

    return stateChanged
  }

  toRevealedSitesArray(secrets, urls = null) {
    const result = []
    urls ||= Object.keys(this.urlData)
    urls
      .filter(
        (url) =>
          this.urlData[url]?.title &&
          secrets[url] &&
          secrets[this.urlData[url].title],
      )
      .forEach((url) => {
        const title = this.urlData[url].title
        if (!secrets[url] || !secrets[title]) return
        result.push(this.urlData[url].toSite(url, secrets))
      })

    return result
      .filter(
        (site) =>
          Math.round(site.time / 1000) > 0 && defaultUrlFilter(site.url),
      )
      .sort((a, b) => b.time - a.time)
  }

  collectHashesForDeletion(hashedUrls) {
    // This class ONLY stores hashes.  We need to collect all hashes related to
    // these urls (and only these urls) and return them.

    const result = new Set()
    const urlsToRemove = Object.keys(this.urlData).filter((url) =>
      hashedUrls.includes(url),
    )
    urlsToRemove.forEach((url) => {
      const urlData = this.urlData[url]
      result.add(url)
      Object.keys(urlData.titleTimes).forEach((title) => result.add(title))
    })

    // Now we need to make sure that any of the hashes we're about to remove are
    // not also related to some other URL that we DON'T want to delete data out of.

    Object.keys(this.urlData)
      .filter((url) => !urlsToRemove.includes(url))
      .forEach((url) => {
        const urlData = this.urlData[url]
        Object.keys(urlData.titleTimes).forEach((title) => {
          if (result.has(title)) {
            result.delete(title)
          }
        })
      })

    // Now we have a good list of hashes to remove that are related to these urls.
    return result
  }
}
