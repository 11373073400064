import { AbstractBrowserTabEvent } from './AbstractBrowserTabEvent'

/**
 * A class to represent a MetadataEvent for logging sessions.
 * This type of event is recorded when metadata is retrieved for a page.
 */
export class MetadataEvent extends AbstractBrowserTabEvent {
  static COLUMNS = [
    ...AbstractBrowserTabEvent.COLUMNS,
    'url',
    'key',
    'value',
    'confidence',
  ]
  /**
   * Constructor for the Metadata class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain Metadata JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {number} [input.tabId] the associated chrome-based tab id
   * @param {boolean} [input.redacted] whether or not this event contains redacted data.
   * @param {boolean} [input.implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   * @param {string} [input.url] the new url of the tab.  This may be null if it's not needed.
   * @param {string} [input.key]
   * @param {string} [input.value]
   * @param {number} [input.confidence]
   * @constructor
   **/

  constructor({
    eventType = 'metadata', // This property is here only for backwards compatibility with the old PdfTitleExtractionEvent
    time,
    redacted,
    implicit,
    tabId,
    url,
    key,
    value,
    confidence,
    title = null, // This property is here for backwards compatibility with the old PdfTitleExtractionEvent
  }) {
    super('metadata', time, redacted, implicit, tabId)
    this.url = url
    this.key = key
    this.value = value
    this.confidence = confidence

    if (eventType === 'pdfTitleExtraction') {
      this.key = 'extractedPdfTitle'
      this.value = title
    }
  }

  toJson() {
    const result = super.toJson()
    if (this.url) {
      result.url = this.url
    }
    if (this.key) {
      result.key = this.key
    }
    if (this.value) {
      result.value = this.value
    }
    if (this.confidence) {
      result.confidence = this.confidence
    }
    return result
  }

  redactHelper(hashFunction, result, k) {
    if (this[k] && typeof this[k] === 'string') {
      const redactedValue = hashFunction(this[k])
      result[redactedValue] = this[k]
      this[k] = redactedValue
    }
  }

  redact(hashFunction) {
    const result = super.redact(hashFunction)
    if (this.redacted) return result

    this.redactHelper(hashFunction, result, 'url')
    this.redactHelper(hashFunction, result, 'value')

    this.redacted = true

    return result
  }

  reveal(secrets) {
    if (!this.redacted) return
    if (!secrets)
      throw new Error('Cannot reveal secrets without a secrets object')

    const isStringValue = this.value && typeof this.value === 'string'

    const urlRevealed = !this.url || this.url in secrets
    const valueRevealed = !isStringValue || this.value in secrets

    if (urlRevealed && valueRevealed) {
      this.url &&= secrets[this.url]
      if (isStringValue) {
        this.value = secrets[this.value]
      }
      this.redacted = false
    }
  }

  redactedValues() {
    if (!this.redacted) {
      return []
    }
    const values = []
    if (this.url) {
      values.push(this.url)
    }
    if (this.value && typeof this.value === 'string') {
      values.push(this.value)
    }

    return values
  }
}
