import { mergeAttributes, Node } from '@tiptap/core'

export function createBucketFileExtension(nodeViewRenderer) {
  const opts = {
    name: 'bucketFile',

    group: 'inline',
    draggable: true,
    inline: true,

    // content: 'inline*',

    parseHTML() {
      return [
        {
          tag: 'bucket-file',
        },
      ]
    },

    renderHTML({ HTMLAttributes }) {
      return ['bucket-file', mergeAttributes(HTMLAttributes)]
    },

    addAttributes() {
      // Return an object with attribute configuration
      return {
        'file-id': {
          default: null,
        },
        alt: {
          default: null,
        },
        title: {
          default: null,
        },
        scale: {
          default: 1,
        },
        caption: {
          default: null,
        },
        uploading: {
          default: false,
        },
        'percent-complete': {
          default: 0,
        },
      }
    },
  }
  if (nodeViewRenderer) {
    opts.addNodeView = () => nodeViewRenderer
  }
  return Node.create(opts)
}
