import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function inviteUsersToResource(
  resourceType,
  resourceId,
  invitationOptions,
) {
  const { users, roles, notifyByEmail } = invitationOptions
  console.log('inviteUsersToResource', users, resourceType, resourceId)
  const cloudFuncResult = await httpsCallable(
    functions,
    'inviteUsersToResource',
  )({
    resourceType,
    resourceId,
    users,
    roles,
    notifyByEmail,
  })
  if (!cloudFuncResult.data.success) {
    throw new Error(cloudFuncResult.data.error)
  }
  return cloudFuncResult.data.data
}
