import refs from '../refsLite'
import { updateRoles } from '../utils/updateRoles'

export async function updateSectionRoles(
  sectionId,
  addRolesForUsers = null,
  removeRolesForUsers = null,
) {
  console.log(
    'Setting section roles... ',
    sectionId,
    addRolesForUsers,
    removeRolesForUsers,
  )
  await updateRoles(
    refs.section(sectionId, false),
    addRolesForUsers,
    removeRolesForUsers,
  )
  console.log('Section roles set!')
}
