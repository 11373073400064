import { updateDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { archiveAssignment } from '../assignment/archiveAssignment'
import { getAssignmentsByCourseId } from '../assignment/getAssignmentsByCourseId'
import { archiveSection } from '../section/archiveSection'
import { getSectionsByCourseId } from '../section/getSectionsByCourseId'

export async function archiveCourse(
  courseId,
  archive = true,
  applyDownward = false,
) {
  await updateDoc(refs.course(courseId, false), {
    archived: archive,
  })

  if (applyDownward) {
    const assignments = await getAssignmentsByCourseId(courseId)
    const assignmentIds = Object.keys(assignments)
    for (const assignmentId of assignmentIds) {
      await archiveAssignment(assignmentId, archive)
    }

    try {
      const sections = await getSectionsByCourseId(courseId)
      const sectionIds = Object.keys(sections)
      for (const sectionId of sectionIds) {
        try {
          await archiveSection(sectionId, archive, true)
        } catch (e) {
          console.error('There was an error archiving section', sectionId, e)
        }
      }
    } catch (e) {
      console.error(
        'There was an error getting sections for course',
        courseId,
        e,
      )
    }
  }
}
