import { dateToIsoDateString } from './dateToIsoDateString'
import { htmlHasText } from '../../../../utils/tiptap/htmlHasText'
import { getTextFromHtml } from '../../../../utils/tiptap/getTextFromHtml'

/**
 *
 * @param assignmentDetail [AssignmentDetail] the assignment to be formatted
 */
export function assignmentToClassroomPostData(
  assignment,
  assignmentDetail,
  baseUrl = 'https://app.learnics.com',
) {
  const materials = [
    {
      link: {
        url: `${baseUrl}/assignment/${assignment.id.slice(-7).toUpperCase()}`,
      },
    },
  ]
  const courseWorkObject = {
    courseId: assignment.classroomCourseId,
    title: assignmentDetail.name,
    materials,
    workType: 'ASSIGNMENT',
    state:
      assignmentDetail.attachments.find(
        (attachment) => attachment.attachmentType === 'sourceDriveFile',
      ) || assignmentDetail.scheduleDate
        ? 'DRAFT'
        : assignment.classroomState || assignmentDetail.state,
  }
  let description = ''
  if (htmlHasText(assignmentDetail.description)) {
    description = getTextFromHtml(assignmentDetail.description)
  }
  courseWorkObject.description = description

  if (assignmentDetail.dueDate) {
    const jsDate = new Date(assignmentDetail.dueDate)

    courseWorkObject.dueDate = {
      year: jsDate.getUTCFullYear(),
      month: jsDate.getUTCMonth() + 1,
      day: jsDate.getUTCDate(),
    }
    courseWorkObject.dueTime = {
      hours: jsDate.getUTCHours(),
      minutes: jsDate.getUTCMinutes(),
      seconds: jsDate.getUTCSeconds(),
    }
  }

  if (
    assignmentDetail.scheduleDate &&
    assignmentDetail.scheduleDate > +new Date() + 1000 * 30
  ) {
    courseWorkObject.scheduledTime = dateToIsoDateString(
      new Date(assignmentDetail.scheduleDate),
    )
  }
  if (assignmentDetail.points) {
    courseWorkObject.maxPoints = assignmentDetail.points
  }

  courseWorkObject.materials = courseWorkObject.materials.concat(
    assignmentDetail.attachments.map((attachment) =>
      attachment.toClassroomMaterial(),
    ),
  )
  return courseWorkObject
}
