import { updateDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function updatePreferredCitationFormatForSection(
  sectionId,
  newFormat,
) {
  await updateDoc(refs.section(sectionId, false), {
    preferredCitationFormat: newFormat,
  })
}
