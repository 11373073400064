import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getSections(
  courseId,
  userId,
  role = 'Learner',
  callback = async () => {},
) {
  const sections = {}
  const querySnapshot = await getDocs(
    query(
      refs.sections(),
      where('courseId', '==', courseId),
      where('archived', '!=', true),
      where(role, 'array-contains', userId),
    ),
  )
  for (const sectionDoc of querySnapshot.docs) {
    const section = sectionDoc.data()
    if (section) {
      sections[sectionDoc.id] = section
      await callback(section)
    }
  }
  return sections
}
