import { CommonRunningCalculator } from './CommonRunningCalculator'

/**
 * CommonDomainDataRc is a RunningCalculator class that calculates Domain times/urls
 * across one or more logging sessions.
 *
 */
export class CommonDomainDataRc extends CommonRunningCalculator {
  dataMap = {}
  constructor(storageObj) {
    super('commonDomainDataRc', ['browserRc'])
    this.dataMap = Object.keys(storageObj?.dataMap || {}).reduce((acc, key) => {
      acc[key] = {
        time: storageObj.dataMap[key].time || 0,
        visits: storageObj.dataMap[key].visits || 0,
      }
      return acc
    }, {})
  }

  toJson() {
    return {
      ...super.toJson(),
      dataMap: Object.keys(this.dataMap).reduce((acc, key) => {
        acc[key] = {
          time: this.dataMap[key].time,
          visits: this.dataMap[key].visits,
        }
        return acc
      }, {}),
    }
  }

  transition(event, stateData) {
    let changed = false
    const { secrets, domainDataRc } = stateData
    const fullDelta = domainDataRc.delta
    const domainDeltas = Object.keys(fullDelta || {})
    for (let i = 0; i < domainDeltas.length; i++) {
      const domain = domainDeltas[i]
      const domainDelta = fullDelta[domain]
      if (!this.dataMap[domain]) {
        changed = true
        this.dataMap[domain] ||= {
          time: 0,
          visits: 0,
        }
      }
      if (domainDelta.addUrls?.length > 0) {
        changed = true
        this.dataMap[domain].visits += domainDelta.addUrls.length
      }
      if (domainDelta.removeUrls?.length > 0) {
        changed = true
        this.dataMap[domain].visits -= domainDelta.addUrls.length
      }
      if (domainDelta.time) {
        changed = true
        this.dataMap[domain].time += domainDelta.time
      }
    }
    return changed
  }

  addSession(sessionCalcs, sessionSecrets, multiplier) {
    const { domainDataRc } = sessionCalcs
    const domains = Object.keys(domainDataRc?.dataMap || {})
    for (const domain of domains) {
      const domainData = domainDataRc.dataMap[domain]
      if (
        multiplier < 0 &&
        (!this.dataMap[domain] ||
          domainData.time === 0 ||
          !domainData.urls ||
          domainData.urls.size === 0)
      ) {
        continue // Nothing to subtract
      }
      this.dataMap[domain] ||= {
        time: 0,
        visits: 0,
      }
      this.dataMap[domain].time += domainData.time * multiplier
      this.dataMap[domain].visits += domainData.urls.size * multiplier
      if (this.dataMap[domain].time <= 0) {
        delete this.dataMap[domain]
      }
    }
  }
}
