import { activeTipTapExtensions } from '../utils/tiptap/activeTipTapExtensions'
import { yjsInstance } from '../utils/yjsInstance'
import { getBucketFileIdsFromContent } from '../utils/tiptap/getBucketFileIdsFromHtml'
import { htmlHasNonWhiteSpace } from '../utils/tiptap/htmlHasNonWhiteSpace'
import { tipTapNodeHasContent } from '../utils/tiptap/tipTapNodeHasContent'
import { tipTapNodeHasText } from '../utils/tiptap/tipTapNodeHasText'
import { Tag } from '../Tag/Tag'
import { nanoid } from 'nanoid'
import { TagArray } from '../Tag/TagArray'
import { getSchema } from '@tiptap/core'
import { generateHTML } from '@tiptap/html'
import { truncateTipTapNode } from '../utils/tiptap/truncateTipTapNode'
import { getTextFromNode } from '../utils'

/**
 * Note is a class for defining a bit of tiptap content with an array of tags
 * and an array of fileIds.
 */
export class Note {
  constructor({
    id = nanoid(32),
    dateCreated = +new Date(),
    dateUpdated = +new Date(),
    content,
  }) {
    const Y = yjsInstance.Y
    this.id = id
    this.dateUpdated = dateUpdated || +new Date()
    this.dateCreated = dateCreated || +new Date()
    this.yDoc = new Y.Doc({})
    if (content) {
      Y.applyUpdate(this.yDoc, Uint8Array.from(content))
    }
    this.initializeYDoc()
  }

  get tagArray() {
    return new TagArray(this.yDoc.getArray('tags'))
  }

  get content() {
    return this.yDoc.getXmlFragment('content').toJSON()
  }

  get fileIds() {
    return this.yDoc.getArray('fileIds').toJSON()
  }

  toJson() {
    return {
      id: this.id,
      dateUpdated: this.dateUpdated || +new Date(),
      dateCreated: this.dateCreated || +new Date(),
      content: Array.from(yjsInstance.Y.encodeStateAsUpdate(this.yDoc)),
    }
  }

  initializeYDoc() {
    // Ensure that the note has all the necessary fields
    this.yDoc.getArray('tags')
    this.yDoc.getArray('fileIds')
    this.yDoc.getXmlFragment('content')
  }

  get tags() {
    const result = []
    this.yDoc.getArray('tags').forEach((tagMap) => {
      result.push(new Tag(tagMap))
    })
    return result
  }

  isEmpty() {
    return (
      !htmlHasNonWhiteSpace(this.content) &&
      this.tags.length === 0 &&
      this.fileIds.length === 0
    )
  }

  deleteFileId(fileId) {
    const index = this.fileIds.indexOf(fileId)
    if (index >= 0) {
      this.yDoc.getArray('fileIds').delete(index, 1)
    }
  }

  deleteTag(color, label) {
    return this.tagArray.deleteTag(color, label)
  }

  deleteTagByIndex(index) {
    return this.tagArray.deleteTagByIndex(index)
  }

  replaceTag(index, color, label) {
    return this.tagArray.swapTag(index, color, label)
  }

  addFileId(fileId) {
    this.yDoc.getArray('fileIds').unshift([fileId])
  }

  updateTag(oldColor, oldLabel, newColor = null, newLabel = null) {
    return this.tagArray.updateTag(oldColor, oldLabel, newColor, newLabel)
  }

  updateTagGloballyPreservingIndex(index, newColor = null, newLabel = null) {
    return this.tagArray.updateTagGloballyPreservingIndex(
      index,
      newColor,
      newLabel,
    )
  }

  isUsingTag(color, label) {
    return !!this.tags.some(
      (tag) =>
        tag.color.trim() === color.trim() && tag.label.trim() === label.trim(),
    )
  }

  isUsingAnyTag(tags) {
    return tags.some((tag) => this.isUsingTag(tag.color, tag.label))
  }

  getAllBucketFileIds() {
    const fileIds = new Set()

    for (const fileId of this.fileIds) {
      fileIds.add(fileId)
    }

    const imageFileIds = this.getImageBucketFileIds()
    for (const fileId of imageFileIds) {
      fileIds.add(fileId)
    }
    return fileIds
  }

  getImageBucketFileIds() {
    return getBucketFileIdsFromContent(this.getContentAsJson())
  }

  addTag(color, label, index) {
    this.tagArray.addTag(color, label, index)
  }

  createTagFrequencyMap(result = {}) {
    return this.tagArray.createTagFrequencyMap(result)
  }

  clear() {
    this.yDoc.getArray('tags').delete(0, this.tags.length)
    this.yDoc.getArray('fileIds').delete(0, this.fileIds.length)
    const content = this.yDoc.getXmlFragment('content')
    content.delete(0, content.length)
  }

  static shallowCopy(note) {
    const copiedNote = new Note({
      id: note.id,
      dateUpdated: note.dateUpdated,
      dateCreated: note.dateCreated,
    })
    copiedNote.yDoc = note.yDoc
    copiedNote.initializeYDoc() // Just in case
    return copiedNote
  }

  getTruncatedContentAsHtml(maxCharacters, maxBlocks) {
    const jsonContent = this.getContentAsJson()
    const truncated = truncateTipTapNode(jsonContent, maxCharacters, maxBlocks)
    return generateHTML(truncated, activeTipTapExtensions.get())
  }

  needsTruncation(maxCharacters, maxBlocks) {
    const jsonContent = this.getContentAsJson()

    const html = generateHTML(jsonContent, activeTipTapExtensions.get())
    const truncatedJson = truncateTipTapNode(
      jsonContent,
      maxCharacters,
      maxBlocks,
    )
    const truncatedHtml = generateHTML(
      truncatedJson,
      activeTipTapExtensions.get(),
    )
    if (html !== truncatedHtml) {
      return true
    }
  }

  getContentAsPlainText() {
    return getTextFromNode(
      this.getContentAsJson(),
      activeTipTapExtensions.get(),
    )
  }

  getContentAsHtml() {
    return generateHTML(this.getContentAsJson(), activeTipTapExtensions.get())
  }
  getContentAsJson() {
    return yjsInstance
      .yXmlFragmentToProseMirrorRootNode(
        this.yDoc.getXmlFragment('content'),
        getSchema(activeTipTapExtensions.get()),
      )
      .toJSON()
  }

  hasContent() {
    return tipTapNodeHasContent(this.getContentAsJson())
  }

  hasText() {
    return tipTapNodeHasText(this.getContentAsJson())
  }

  containsString(q) {
    const content = this.getContentAsPlainText()
    return content.toLowerCase().includes(q.toLowerCase())
  }
}
