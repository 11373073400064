import Vue from 'vue'
import InvitationService from '../../invitation'

export const state = {
  invitations: {},
  invitationsLoaded: false,
  invitationsLoading: false,
  invitationsForResourceLoaded: {},
  invitationsForResourceLoading: {},
}

export const getters = {
  invitations: (state) => state.invitations,
  invitationsLoaded: (state) => state.invitationsLoaded,
  invitationsLoading: (state) => state.invitationsLoading,
  invitationsForResourceLoaded: (state) => state.invitationsForResourceLoaded,
  invitationsForResourceLoading: (state) => state.invitationsForResourceLoading,
}

export const mutations = {
  setInvitation: (state, { compositeId, newVal, oldVal }) => {
    const { id } = compositeId
    if (newVal) {
      Vue.set(state.invitations, id, newVal)
    } else if (state.invitations[id]) {
      Vue.delete(state.invitations, id)
    }
  },
  setInvitationsLoaded: (state, newVal) => {
    state.invitationsLoaded = !!newVal
  },
  setInvitationsLoading: (state, newVal) => {
    state.invitationsLoading = !!newVal
  },
  setInvitationsForResourceLoaded: (
    state,
    { resourceType, resourceId, newVal },
  ) => {
    if (!state.invitationsForResourceLoaded[resourceType]) {
      Vue.set(state.invitationsForResourceLoaded, resourceType, {})
    }
    Vue.set(
      state.invitationsForResourceLoaded[resourceType],
      resourceId,
      !!newVal,
    )
  },
  setInvitationsForResourceLoading: (
    state,
    { resourceType, resourceId, newVal },
  ) => {
    if (!state.invitationsForResourceLoading[resourceType]) {
      Vue.set(state.invitationsForResourceLoading, resourceType, {})
    }
    Vue.set(
      state.invitationsForResourceLoading[resourceType],
      resourceId,
      !!newVal,
    )
  },
}

export const actions = {
  async loadInvitationsForResource(
    { commit, state },
    args = { forced: false, resourceType: null, resourceId: null },
  ) {
    const { resourceType, resourceId, forced } = args
    if (!resourceType || !resourceId) {
      throw new Error(
        'No resourceType or resourceId provided to loadInvitationsForResource',
      )
    }
    if (
      state.invitationsForResourceLoaded[resourceType]?.[resourceId] &&
      !forced
    ) {
      return
    }
    commit('setInvitationsForResourceLoading', {
      resourceType,
      resourceId,
      newVal: true,
    })

    try {
      const invitations = await InvitationService.getOpenInvitationsForResource(
        resourceType,
        resourceId,
      )
      const invitationIds = Object.keys(invitations)

      for (const id of invitationIds) {
        commit('setInvitation', {
          compositeId: { id },
          newVal: invitations[id],
        })
      }
      commit('setInvitationsForResourceLoaded', {
        resourceType,
        resourceId,
        newVal: true,
      })
    } catch (error) {
      console.error('Error loading invitationsForResource:', error)
    } finally {
      commit('setInvitationsForResourceLoading', {
        resourceType,
        resourceId,
        newVal: false,
      })
    }
  },
  async loadInvitations(
    { commit, state },
    args = { forced: false, uid: null, email: null },
  ) {
    const { uid, email, forced } = args
    if ((state.invitationsLoaded || state.invitationsLoading) && !forced) {
      return
    }
    if (!uid) {
      throw new Error('No uid provided to loadInvitations')
    }
    commit('setInvitationsLoading', true)

    try {
      const invitations = await InvitationService.getInvitationsForUser(
        uid,
        email,
      )
      const invitationIds = Object.keys(invitations)

      for (const id of invitationIds) {
        commit('setInvitation', {
          compositeId: { id },
          newVal: invitations[id],
        })
      }
    } catch (error) {
      console.error('Error loading invitations:', error)
    } finally {
      commit('setInvitationsLoaded', true)
      commit('setInvitationsLoading', false)
    }
  },
}
