import { arrayRemove, arrayUnion, updateDoc } from 'firebase/firestore/lite'

export async function updateRoles(
  resourceRef,
  addRolesForUsers = null,
  removeRolesForUsers = null,
) {
  let addUpdate = null
  const rolesToAdd = Object.keys(addRolesForUsers || {})
  for (const role of rolesToAdd) {
    const uids = addRolesForUsers[role]
    if (uids.length > 0) {
      addUpdate ||= {}
      addUpdate[role] = arrayUnion(...uids)
    }
  }
  if (addUpdate) {
    addUpdate.updated = +new Date()
    await updateDoc(resourceRef, addUpdate)
  }

  let removeUpdate = null
  const rolesToRemove = Object.keys(removeRolesForUsers || {})
  for (const role of rolesToRemove) {
    const uids = removeRolesForUsers[role]
    if (uids.length > 0) {
      removeUpdate ||= {}
      removeUpdate[role] = arrayRemove(...uids)
    }
  }
  if (removeUpdate) {
    removeUpdate.updated = +new Date()
    await updateDoc(resourceRef, removeUpdate)
  }
}
