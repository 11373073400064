import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getAccessRequests(
  resourceType,
  resourceId,
  userId,
  callback = async () => {},
) {
  const result = {}
  let q = []
  if (resourceType && resourceId) {
    q.push(where('resourceType', '==', resourceType))
    q.push(where('resourceId', '==', resourceId))
  }
  if (userId) {
    q.push(where('userId', '==', userId))
  }
  const querySnapshot = await getDocs(query(refs.accessRequests(), ...q))
  for (const snap of querySnapshot.docs) {
    const obj = snap.data()
    if (obj) {
      result[obj.id] = obj
      await callback(obj)
    }
  }
  return result
}
