import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getInvitation(invitationId) {
  const snap = await getDoc(refs.invitation(invitationId))
  if (snap.exists()) {
    return snap.data()
  }
  return null
}
