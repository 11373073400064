import { CommonRunningCalculator } from './CommonRunningCalculator'

/**
 * CommonSearchesRc stores search terms, the date they were used, what search
 * engine was used, and the frequency of usage for each search term across
 * multiple students.
 */
export class CommonSearchesRc extends CommonRunningCalculator {
  dataMap = {}
  lastUpdate = null
  constructor(storageObj) {
    super('commonSearchesRc', ['browserRc'])
    const dataMap = storageObj?.dataMap || {}
    this.dataMap = JSON.parse(JSON.stringify(dataMap))
    this.lastUpdate = JSON.parse(JSON.stringify(storageObj?.lastUpdate || null))
  }

  toJson() {
    return {
      ...super.toJson(),
      dataMap: JSON.parse(JSON.stringify(this.dataMap || {})),
      lastUpdate: JSON.parse(JSON.stringify(this.lastUpdate || null)),
    }
  }

  transition(event, stateData) {
    const { urlDataRc, searchesRc, secrets } = stateData
    let stateChanged = this.lastUpdate !== null
    this.lastUpdate = null

    Object.keys(searchesRc.lastUpdate || {}).forEach((dateString) => {
      Object.keys(searchesRc.lastUpdate[dateString]).forEach((engine) => {
        ;[...searchesRc.lastUpdate[dateString][engine]].forEach((search) => {
          this.dataMap[dateString] ||= {}
          this.dataMap[dateString][engine] ||= {}
          this.dataMap[dateString][engine][search] ??= 0

          this.lastUpdate ||= {}
          this.lastUpdate[dateString] ||= {}
          this.lastUpdate[dateString][engine] ||= {}
          this.lastUpdate[dateString][engine][search] ??=
            this.dataMap[dateString][engine][search] ?? 0
          this.dataMap[dateString][engine][search] += 1
          stateChanged = true
        })
      })
    })

    return stateChanged
  }

  addSession(sessionCalcs, sessionSecrets, multiplier) {
    const { searchesRc } = sessionCalcs
    for (const dateString of Object.keys(searchesRc?.dataMap || {})) {
      const engineMap = searchesRc.dataMap[dateString]
      for (const engine of Object.keys(engineMap)) {
        const searches = engineMap[engine]
        for (const search of searches) {
          if (
            multiplier < 0 &&
            (!this.dataMap[dateString] ||
              !this.dataMap[dateString]?.[engine] ||
              !this.dataMap[dateString]?.[engine]?.[search])
          ) {
            continue
          }
          this.dataMap[dateString] ||= {}
          this.dataMap[dateString][engine] ||= {}
          this.dataMap[dateString][engine][search] ||= 0
          this.dataMap[dateString][engine][search] += multiplier
          if (this.dataMap[dateString][engine][search] <= 0) {
            delete this.dataMap[dateString][engine][search]
            if (Object.keys(this.dataMap[dateString][engine]).length === 0) {
              delete this.dataMap[dateString][engine]
              if (Object.keys(this.dataMap[dateString]).length === 0) {
                delete this.dataMap[dateString]
              }
            }
          }
        }
      }
    }
  }
}
