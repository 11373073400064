import { AbstractExpectation } from './AbstractExpectation'

/**
 * A class to represent an AttachmentsExpectation for an assignment.  This type
 * of expectation requires that the student spend time on 1 or more attachments
 * associated with the assignment.
 *
 * @example
 * // Concrete initialization and usage
 * const concreteExpectationData = {
 *   id: "some-learnics-generated-id",
 *   ratio: 0.25,
 *   attachmentIds: ["some-attachment-id", "another-attachment-id"]
 * }
 *
 * // Instantiate the expectation on the fly, so you can use it to calculate
 * // time and other things
 * const expectation = new AttachmentsExpectation(concreteExpectationData);
 *
 * // Create a database-friendly version of the expectation.  This should be a
 * // clone of the concreteExpectationData, except with the id and expectationType
 * // properties both set to "attachments".
 * const savableExpectationData = expectation.toJson();
 *
 * @example
 * // Abstract initialization and usage
 *
 * const databaseExpectations = [{
 *   id: "some-learnics-generated-id",
 *   expectationType: "attachments",
 *   ratio: 0.2,
 *   attachmentIds: ["some-attachment-id", "another-attachment-id"]
 * }, {
 *     // ... some other expectation
 * }];
 *
 * // Instantiate an array of serialized AbstractExpectation JSON objects (from
 * // the database), in order to restore their functionality.
 * const expectations = databaseExpectations.map(
 *     expectation => expectationFromJson(expectation)
 *   );
 *
 * // Create a database-friendly version of an array of expectations.  This
 * // should be a clone of the databaseExpectations array.
 * const savableExpectationsCopy = expectations.map(
 *     expectation => expectation.toJson()
 *   )
 *
 *   @inheritDoc
 */
export class AttachmentsExpectation extends AbstractExpectation {
  /**
   * Constructor for the AttachmentsExpectation class.  This instantiates the
   * expectation object.
   *
   * @param {any} input a plain AttachmentsExpectation JSON object
   * @param {string|undefined|null} input.id the Learnics-based id of the expectation
   * @param {number} input.ratio the ratio of total time expected for this
   * expectation.
   * @param {string[]} input.attachmentIds The ids of the attachments required
   * to meet this expectation
   * @param {string} input.label The label of this expectation.
   * @constructor
   */
  constructor({ id, ratio, attachmentIds, label = 'Attachments' }) {
    super('attachments', id, ratio)
    this.labelValue = label
    this.attachmentIds = attachmentIds
  }

  toJson() {
    const json = super.toJson()
    json.attachmentIds = this.attachmentIds
    json.label = this.labelValue
    return json
  }

  get label() {
    return this.labelValue
  }
}
