import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getAccessRequestsForResource(
  resourceType,
  resourceId,
  callback = async () => {},
) {
  const result = {}
  const querySnapshot = await getDocs(
    query(
      refs.accessRequests(),
      where('resourceType', '==', resourceType),
      where('resourceId', '==', resourceId),
    ),
  )
  for (const snap of querySnapshot.docs) {
    const obj = snap.data()
    if (obj) {
      result[obj.id] = obj
      await callback(obj)
    }
  }
  return result
}
