/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { backFillSessionAssignmentKeys } from './backFillSessionAssignmentKeys'
import { createLoggerSession } from './createLoggerSession'
import { deleteLoggerSession } from './deleteLoggerSession'
import { deleteLoggerSession2 } from './deleteLoggerSession2'
import { deleteSensitiveData } from './deleteSensitiveData'
import { downgradeLoggerSessions } from './downgradeLoggerSessions'
import { generateAssignmentCsv } from './generateAssignmentCsv'
import { generateEventsCsv } from './generateEventsCsv'
import { generateRunningCalculators } from './generateRunningCalculators'
import { getAllKeysDeleted } from './getAllKeysDeleted'
import { getAllLoggerSessions } from './getAllLoggerSessions'
import { getAllSecrets } from './getAllSecrets'
import { getBackwardCompatibleRunningCalculators } from './getBackwardCompatibleRunningCalculators'
import { getEventMetaInfo } from './getEventMetaInfo'
import { getKeysDeleted } from './getKeysDeleted'
import { getLoggerSession } from './getLoggerSession'
import { getLoggerSessionsForResearchNotebook } from './getLoggerSessionsForResearchNotebook'
import { getLoggerSessionsForUser } from './getLoggerSessionsForUser'
import { getSecrets } from './getSecrets'
import { getStateDataForSession } from './getStateDataForSession'
import { loadAllSecrets } from './loadAllSecrets'
import { loadChunk } from './loadChunk'
import { loadChunks } from './loadChunks'
import { loadEventDataForSessionIds } from './loadEventDataForSessionIds'
import { loadEventsAndSecrets } from './loadEventsAndSecrets'
import { loadRunningCalculators } from './loadRunningCalculators'
import { migrateLoggerSession } from './migrateLoggerSession'
import { saveEvents } from './saveEvents'
import { saveLoggerSession } from './saveLoggerSession'
import { upgradeLoggerSessions } from './upgradeLoggerSessions'

const LoggerService = {
  backFillSessionAssignmentKeys,
  createLoggerSession,
  deleteLoggerSession,
  deleteLoggerSession2,
  deleteSensitiveData,
  downgradeLoggerSessions,
  generateAssignmentCsv,
  generateEventsCsv,
  generateRunningCalculators,
  getAllKeysDeleted,
  getAllLoggerSessions,
  getAllSecrets,
  getBackwardCompatibleRunningCalculators,
  getEventMetaInfo,
  getKeysDeleted,
  getLoggerSession,
  getLoggerSessionsForResearchNotebook,
  getLoggerSessionsForUser,
  getSecrets,
  getStateDataForSession,
  loadAllSecrets,
  loadChunk,
  loadChunks,
  loadEventDataForSessionIds,
  loadEventsAndSecrets,
  loadRunningCalculators,
  migrateLoggerSession,
  saveEvents,
  saveLoggerSession,
  upgradeLoggerSessions,
}

export default LoggerService
