import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function loadRosterFromRoles(rolesMap) {
  const roster = {}
  const roleStrings = Object.keys(rolesMap)
  for (let role of roleStrings) {
    const users = {}
    for (let uid of rolesMap[role]) {
      const doc = await getDoc(refs.user(uid))
      users[doc.id] = { ...doc.data(), id: uid, uid }
    }
    roster[role] = users
  }
  return roster
}
