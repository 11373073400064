import { loadEventDataForSessionIds } from './loadEventDataForSessionIds'
import { eventComparator } from './eventComparator'
import { calculatorClassMap } from '../RunningCalculator/utils/calculatorClassMap'
import { transitionCalculators } from '../RunningCalculator/utils/transitionCalculators'
import { EmptyEvent } from '../EmptyEvent'

export async function generateRunningCalculators(
  sessionIds,
  dataLoader = {
    loadChunks: async (sessionId) => [],
    getSecrets: async (sessionId, chunkId) => ({}),
    getKeysDeleted: async (sessionId, chunkId) => ({}),
  },
  commonCalculators = {},
  runningCalculatorIds = [],
  additionalStateData = {},
  unsavedEvents = {},
) {
  let stateData = {
    timeRange: {
      // Feel free to override this
      start: 0,
      stop: Number.MAX_SAFE_INTEGER,
    },
    ...additionalStateData,
  }
  let { composite, sessions } = await loadEventDataForSessionIds(
    sessionIds,
    dataLoader,
    unsavedEvents,
  )

  const result = {
    composite: await generateCalculatorsFromEventData(
      composite,
      { ...stateData, sessionIds },
      runningCalculatorIds,
      commonCalculators,
    ),
    sessions: {},
  }
  if (sessionIds.length > 1) {
    for (const sessionId of sessionIds) {
      result.sessions[sessionId] = await generateCalculatorsFromEventData(
        sessions[sessionId],
        { ...stateData, sessionIds: [sessionId] },
        runningCalculatorIds,
        {}, // Only the composite should transition common calculators
      )
    }
  } else {
    result.sessions[sessionIds[0]] = result.composite
  }
  return result
}

export async function generateCalculatorsFromEventData(
  eventData,
  stateData = {},
  runningCalculatorIds = [],
  commonCalculators = {},
) {
  let { events, secrets, keysDeleted, keysBlacklisted } = eventData
  events.sort(eventComparator)

  const firstEvent = events.find((event) => event.eventType !== 'metadata')
  const lastEvent = events[events.length - 1]

  const timeRange = stateData.timeRange || { start: 0, stop: Infinity }
  let start = timeRange?.start || 0
  let stop = timeRange?.stop || Infinity
  if (firstEvent && lastEvent) {
    let emptyEvents = []
    if (start >= firstEvent.time && start <= lastEvent.time) {
      emptyEvents.push(new EmptyEvent({ time: start - 1 }))
      emptyEvents.push(new EmptyEvent({ time: start }))
    }
    if (stop >= firstEvent.time && stop <= lastEvent.time) {
      emptyEvents.push(new EmptyEvent({ time: stop }))
    }
    if (emptyEvents.length > 0) {
      emptyEvents.forEach((event) => {
        events.push(event)
      })
      events.sort(eventComparator)
    }
  }

  const calculators = {}
  for (const calcId of runningCalculatorIds) {
    calculators[calcId] = new calculatorClassMap[calcId]({})
  }
  if (!calculators.browserRc)
    throw new Error('browserRc must be included in the calculator map')
  calculators.browserRc.time = firstEvent?.time || +new Date()

  if (!secrets) {
    secrets = {}
  }
  if (!keysDeleted) {
    keysDeleted = {}
  }
  if (!keysBlacklisted) {
    keysBlacklisted = new Set()
  }
  for (const event of events) {
    transitionCalculators(
      event,
      { ...stateData, secrets, keysDeleted, keysBlacklisted },
      calculators,
      commonCalculators,
    )
  }

  return {
    events,
    secrets,
    keysDeleted,
    keysBlacklisted,
    calculators,
    commonCalculators,
  }
}
