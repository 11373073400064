import { UrlCategoryDataBaseNode } from './UrlCategoryDataBaseNode'

/**
 * CommonUrlCategoryDataNode extends UrlCategoryDataBaseNode to add a time integer property
 * to the tree node. This is used to track the total time spent on a category of urls.
 *
 * @extends UrlCategoryDataBaseNode
 */
export class CommonUrlCategoryDataNode extends UrlCategoryDataBaseNode {
  time = 0
  constructor(storageObj) {
    super(storageObj)
    this.time = storageObj?.time || 0
  }

  toJson() {
    return {
      ...super.toJson(),
      time: this.time || 0,
    }
  }
  add(dataNode, sessionSecrets, multiplier = 1, path = []) {
    this.time += dataNode.time * multiplier
    if (multiplier > 1) {
      for (let url of dataNode.urls) {
        this.urls.add(url)
      }
    }
    const childCategories = Object.keys(dataNode.children)
    for (let i = 0; i < childCategories.length; i++) {
      const category = childCategories[i]
      const categoryArray = category.split('/')
      this.ensureCategoryForUrl(categoryArray)
      const existingCategories = Object.keys(this.children).map((c) =>
        c.split('/'),
      )

      let foundChildCategory = null
      for (let i = 0; i < existingCategories.length; i++) {
        if (existingCategories[i][0] === categoryArray[0]) {
          foundChildCategory = existingCategories[i].join('/')
          break
        }
      }
      if (!foundChildCategory) {
        console.warn(
          'Could not find child category',
          categoryArray,
          existingCategories,
        )
      } else {
        this.children[foundChildCategory].add(
          dataNode.children[category],
          sessionSecrets,
          multiplier,
        )
      }
    }
  }

  get totalTime() {
    return (
      this.time +
      Object.values(this.children).reduce((acc, child) => {
        return acc + child.totalTime
      }, 0)
    )
  }

  constructNode(nodeJson = {}) {
    return new CommonUrlCategoryDataNode(nodeJson)
  }
}
