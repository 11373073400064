import { AbstractEvent } from './AbstractEvent'

/**
 * A class to represent an abstract browser tab event.  Every event has a timestamp,
 * an eventType, a tabId, and an optional tabUrl, set of properties.
 *
 *
 * @abstract
 */
export class AbstractBrowserTabEvent extends AbstractEvent {
  static COLUMNS = [...AbstractEvent.COLUMNS, 'tabId']
  /**
   * Abstract constructor for the abstract event.
   * @param {string} eventType the eventType of this event.
   * @param {number} time the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {number} [tabId] the associated chrome-based tab id
   * @param {boolean} [redacted] whether or not this event contains redacted data.
   * @param {boolean} [implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   */
  constructor(eventType, time, redacted, implicit, tabId) {
    super(eventType, time, redacted, implicit)
    this.tabId = tabId + ''
    if (this.constructor === AbstractBrowserTabEvent) {
      throw new Error(
        'AbstractTabEvent objects may not be directly instantiated.  You probably want to use one of the subclasses.',
      )
    }
  }

  /**
   * Convert this event to a plain JSON object.
   * @returns {object}
   */
  toJson() {
    let json = super.toJson()
    json.tabId = this.tabId
    return json
  }
}
