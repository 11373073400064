import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getUserDocumentsById(userIds, callback = async () => {}) {
  const userDocs = []
  for (const userId of userIds) {
    const userDocSnap = await getDoc(refs.user(userId))
    if (userDocSnap.exists()) {
      const doc = { ...userDocSnap.data(), id: userId, uid: userId }
      userDocs.push(doc)
      await callback(doc)
    }
  }
  return userDocs
}
