export async function updateCourseWork(
  accessToken,
  courseId,
  courseworkId,
  updateMask,
  courseworkObject,
) {
  const url = `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${courseworkId}?updateMask=${encodeURIComponent(
    updateMask,
  )}`
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(courseworkObject),
  })
  const res = await response.json()
  console.log('updateCourseWork response: ', res)
  if (res.error) {
    console.log('Error updating courseWork: ', res.error)
    throw res.error
  } else {
    return res
  }
}
