import { updateDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { getAcl } from './getAcl'

export async function toggleAclLocked(path) {
  const acl = await getAcl(path)
  console.log('Acl was locked', acl.locked)
  acl.locked = !acl.locked
  console.log('Locking: ', acl.locked, path)
  await updateDoc(refs.acl(path, false), { locked: !!acl.locked })
  return acl
}
