/**
 * Return true if the two urls are to be considered pointing at the same resource,
 * optionally ignoring the hash.  This has a special case for Google Docs/drive urls,
 * where it will try to match the document id and type.
 */
export function doUrlsMatch(url1, url2, ignoreHash = false) {
  let a = null
  try {
    a = new URL(url1)
  } catch (e) {}
  let b = null
  try {
    b = new URL(url2)
  } catch (e) {}

  if (!a || !b) {
    // if either url is null or undefined...
    return !a && !b // no real comparison is needed, so return true if both are null or undefined
  }

  const originPlusPathA = a.origin + a.pathname
  const originPlusPathB = b.origin + b.pathname

  const patterns = [
    /https?:\/\/(?:docs|drive)\.google\.com\/(.*)\/(?:u\/\d+\/d\/)([a-zA-Z0-9-_]+).*/,
    /https?:\/\/(?:docs|drive)\.google\.com\/([a-zA-Z0-9-_]+)\/(?:d\/)([a-zA-Z0-9-_]+).*/,
  ]
  let match1 = null
  let match2 = null
  for (const pattern of patterns) {
    match1 ||= originPlusPathA.match(pattern)
    match2 ||= originPlusPathB.match(pattern)
  }
  if (match1 && match2) {
    const documentType1 = match1[1]
    const documentId1 = match1[2]
    const documentType2 = match2[1]
    const documentId2 = match2[2]

    if (documentType1 === documentType2 && documentId1 === documentId2) {
      return true
    }
  }

  // wrap the urls in a URL object to programmatically parse and access their properties in a standardized way, so
  // that we can compare them without worrying about things like trailing slashes, trimming, etc.
  if (a.hostname.startsWith('www.')) {
    a.hostname = a.hostname.substring(4)
  }
  if (b.hostname.startsWith('www.')) {
    b.hostname = b.hostname.substring(4)
  }

  return (
    a.protocol === b.protocol &&
    a.host === b.host &&
    a.pathname === b.pathname &&
    // Treat hashes like part of the pathname, as they are often used for anchors and historically in the routing
    // of single-page app frameworks
    (!ignoreHash || a.hash === b.hash)
  )
}
