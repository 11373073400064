/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { archiveSection } from './archiveSection'
import { enrollStudentInSection } from './enrollStudentInSection'
import { ensureGoogleClassroomSectionsForTeacher } from './ensureGoogleClassroomSectionsForTeacher'
import { ensureStudentSectionEnrollment } from './ensureStudentSectionEnrollment'
import { getAllAssignableSectionsForInstructor } from './getAllAssignableSectionsForInstructor'
import { getAllSectionsForCourse } from './getAllSectionsForCourse'
import { getSection } from './getSection'
import { getSectionRoster } from './getSectionRoster'
import { getSections } from './getSections'
import { getSectionsByClassroomCourseId } from './getSectionsByClassroomCourseId'
import { getSectionsByCourseId } from './getSectionsByCourseId'
import { getSectionsByOrgId } from './getSectionsByOrgId'
import { saveSection } from './saveSection'
import { updatePreferredCitationFormatForSection } from './updatePreferredCitationFormatForSection'
import { updateSection } from './updateSection'
import { updateSectionName } from './updateSectionName'
import { updateSectionRoles } from './updateSectionRoles'

const SectionService = {
  archiveSection,
  enrollStudentInSection,
  ensureGoogleClassroomSectionsForTeacher,
  ensureStudentSectionEnrollment,
  getAllAssignableSectionsForInstructor,
  getAllSectionsForCourse,
  getSection,
  getSectionRoster,
  getSections,
  getSectionsByClassroomCourseId,
  getSectionsByCourseId,
  getSectionsByOrgId,
  saveSection,
  updatePreferredCitationFormatForSection,
  updateSection,
  updateSectionName,
  updateSectionRoles,
}

export default SectionService
