import { Bytes } from 'firebase/firestore'

export function createYDocFirestoreConverter(klass) {
  return {
    toFirestore(instance) {
      if (!instance) {
        return null
      }
      const result = instance.toJson()
      result.content = Bytes.fromUint8Array(Uint8Array.from(result.content))
      delete result.id
      return result
    },

    fromFirestore(snapshot, options) {
      const data = snapshot.data(options)
      if (!data) {
        return null
      }
      data.content = Array.from(data.content.toUint8Array())
      data.id = snapshot.id
      return new klass(data)
    },
  }
}
