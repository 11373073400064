import refs from '../refsLite'
import { serverTimestamp, setDoc } from 'firebase/firestore/lite'
import { nanoid } from 'nanoid'

export async function saveError2(
  error,
  message,
  path = null,
  column = null,
  line = null,
  source = 'VueApp',
  stack = null,
  installation = null,
  context = {},
) {
  const id = +new Date() + nanoid()
  console.warn(
    'Logging error at /errors/' + id,
    '\n',
    error,
    '\n',
    stack,
    '\n',
    path,
    '\n',
    column,
    ':',
    line,
  )
  await setDoc(refs.error(id), {
    id,
    error,
    source,
    message,
    path,
    column,
    line,
    created: serverTimestamp(),
    stack,
    installation: installation?.toJson?.() || installation || null,
    context,
  })
}
