import { RunningCalculator } from './RunningCalculator'
import { urlToSearchKeyword } from '../../../../../utils/search/urlToSearchKeyword'

/**
 * SearchesRc stores search terms, the date they were used, and what search
 * engine was used for an individual logging session
 */
export class SearchesRc extends RunningCalculator {
  dataMap = {}
  lastUpdate = null
  constructor(storageObj) {
    super('searchesRc', [
      'browserRc',
      'externalUrlsRc',
      'expectationsRc',
      'expectationRatiosRc',
      'studentCopiesRc',
      'urlTimelinesRc',
      'commonSearchesRc',
      'commonSearchWordFrequenciesRc',
      'searchWordFrequenciesRc',
    ])
    this.lastUpdate =
      (storageObj?.lastUpdate &&
        Object.keys(storageObj.lastUpdate).reduce((acc, dateString) => {
          acc[dateString] ||= {}
          Object.keys(storageObj.lastUpdate[dateString]).forEach((engine) => {
            acc[dateString][engine] ||= new Set()
            storageObj.lastUpdate[dateString][engine].forEach((search) => {
              acc[dateString][engine].add(search)
            })
          })
          return acc
        }, {})) ||
      null
    this.dataMap =
      (storageObj?.dataMap &&
        Object.keys(storageObj.dataMap).reduce((acc, dateString) => {
          acc[dateString] ||= {}
          Object.keys(storageObj.dataMap[dateString]).forEach((engine) => {
            acc[dateString][engine] ||= new Set()
            storageObj.dataMap[dateString][engine].forEach((search) => {
              acc[dateString][engine].add(search)
            })
          })
          return acc
        }, {})) ||
      {}
  }

  toJson() {
    return {
      ...super.toJson(),
      dataMap: Object.keys(this.dataMap).reduce((acc, dateString) => {
        acc[dateString] ||= {}
        Object.keys(this.dataMap[dateString]).forEach((engine) => {
          acc[dateString][engine] = [...this.dataMap[dateString][engine]]
        })
        return acc
      }, {}),
      lastUpdate:
        (this.lastUpdate &&
          Object.keys(this.lastUpdate).reduce((acc, dateString) => {
            acc[dateString] ||= {}
            Object.keys(this.lastUpdate[dateString]).forEach((engine) => {
              acc[dateString][engine] = [...this.lastUpdate[dateString][engine]]
            })
            return acc
          }, {})) ||
        null,
    }
  }

  transition(event, stateData) {
    const { urlDataRc, secrets, timeRange } = stateData
    let stateChanged = this.lastUpdate !== null
    this.lastUpdate = null
    if (event.time < timeRange.start || event.time > timeRange.stop)
      return stateChanged

    if (urlDataRc.updatedUrlsAtPriorStep) {
      Object.keys(urlDataRc.updatedUrlsAtPriorStep).forEach((url) => {
        const oldVal = urlDataRc.updatedUrlsAtPriorStep[url]
        const newVal = urlDataRc.urlData[url]

        if (secrets[url] && newVal?.time >= 500 && (oldVal?.time || 0) < 500) {
          const result = urlToSearchKeyword(secrets[url], true)

          if (!result) return
          const { search, engine } = result

          const dateString = new Date(event.time).toISOString().split('T')[0]
          this.dataMap[dateString] ||= {}
          this.dataMap[dateString][engine] ||= new Set()
          this.lastUpdate ||= {}
          this.lastUpdate[dateString] ||= {}
          this.lastUpdate[dateString][engine] ||= new Set()
          this.lastUpdate[dateString][engine].add(search)
          this.dataMap[dateString][engine].add(search)
          stateChanged = true
        }
      })
    }
    return stateChanged
  }
}
