const typePrefix = 'application/vnd.google-apps.'
const urlPrefix = 'https://docs.google.com/'

/**
 * A mapping of Google Drive file mime types to their corresponding URL prefixes.
 * @type {Object<string, string>}
 */
let urlMap = {}
urlMap[`${typePrefix}document`] = `${urlPrefix}document/d/`
urlMap[`${typePrefix}spreadsheet`] = `${urlPrefix}spreadsheets/d/`
urlMap[`${typePrefix}presentation`] = `${urlPrefix}presentation/d/`
urlMap[`${typePrefix}drawing`] = `${urlPrefix}drawings/d/`
urlMap[`${typePrefix}form`] = `${urlPrefix}forms/d/`
urlMap[`${typePrefix}script`] = 'https://script.google.com/d/'
urlMap[`${typePrefix}map`] = `${urlPrefix}maps/d/`
urlMap[`${typePrefix}photo`] = `${urlPrefix}file/d/`
urlMap[`${typePrefix}file`] = `${urlPrefix}file/d/`
urlMap[`${typePrefix}folder`] = 'https://drive.google.com/drive/folders/'

export function mimeTypeFromUrl(url) {
  return Object.keys(urlMap).find((mimeType) =>
    url.startsWith(urlMap[mimeType]),
  )
}

/**
 *
 * @type {Object<string, string>}
 */
let iconMap = {}
iconMap[`${typePrefix}document`] = 'fa-file-alt'
iconMap[`${typePrefix}spreadsheet`] = 'fa-table'
iconMap[`${typePrefix}presentation`] = 'fa-file-powerpoint'
iconMap[`${typePrefix}drawing`] = 'fa-drafting-compass'
iconMap[`${typePrefix}form`] = 'fa-file-contract'
iconMap[`${typePrefix}script`] = 'fa-file-code'
iconMap[`${typePrefix}map`] = 'fa-map-marker-alt'
iconMap[`${typePrefix}photo`] = 'fa-file-image'
iconMap[`${typePrefix}file`] = 'fa-file'
iconMap[`${typePrefix}folder`] = 'fa-folder'
export const driveFileMimeTypeUrlMap = urlMap
export const driveFileMimeTypeIconMap = iconMap
