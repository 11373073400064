import sha256 from 'crypto-js/sha256'
import { csvRowFromEvent } from './csvRowFromEvent'
import { preprocessStoredEvents } from '../RunningCalculator/utils/preprocessStoredEvents'
import { eventComparator } from './eventComparator'
import { eventFromCsvRow } from './eventFromCsvRow'

export async function loadEventDataForSessionIds(
  sessionIds,
  dataLoader = {
    loadChunks: async (sessionId) => [],
    getSecrets: async (sessionId, chunkId) => ({}),
    getKeysDeleted: async (sessionId, chunkId) => ({}),
  },
  unsavedEvents = {},
) {
  if (!Array.isArray(sessionIds)) {
    sessionIds = [sessionIds]
  }
  const sessionsMap = {}

  const unsavedSecrets = Object.keys(unsavedEvents).reduce((acc, sessionId) => {
    const events = unsavedEvents[sessionId]
    acc[sessionId] = events.reduce(
      (acc, event) =>
        Object.assign(
          acc,
          event.redact((s) => sha256(s).toString()),
        ),
      {},
    )

    return acc
  }, {})
  const allSecrets = {}
  for (const sessionId of sessionIds) {
    allSecrets[sessionId] = {}
    Object.assign(allSecrets[sessionId], unsavedSecrets[sessionId])
  }
  const allKeysDeleted = {}
  //TODO: Get an actual blacklist here.
  const allKeysBlacklisted = new Set()
  let allEvents = new Set(
    Object.keys(unsavedEvents).reduce((acc, sessionId) => {
      return acc.concat(unsavedEvents[sessionId].map(csvRowFromEvent))
    }, []),
  )
  for (const sessionId of sessionIds) {
    const chunks = await dataLoader.loadChunks(sessionId)
    if (sessionIds.length > 1) {
      sessionsMap[sessionId] = {
        secrets: {},
        keysDeleted: {},
        keysBlacklisted: new Set(),
        events: [],
      }
    }
    let sessionEvents = new Set()
    if (sessionIds.length > 1) {
      if (unsavedEvents[sessionId]) {
        sessionsMap[sessionId].secrets = unsavedSecrets[sessionId]
        sessionEvents = new Set(unsavedEvents[sessionId].map(csvRowFromEvent))
      }
    }

    for (const chunk of chunks) {
      const chunkSecrets = await dataLoader.getSecrets(sessionId, chunk.id)
      Object.assign(allSecrets, chunkSecrets)
      if (sessionIds.length > 1) {
        Object.assign(sessionsMap[sessionId].secrets, chunkSecrets)
      }
      const keyData = await dataLoader.getKeysDeleted(sessionId, chunk.id)

      Object.keys(keyData).forEach((key) => {
        allKeysDeleted[key] = Math.max(allKeysDeleted[key] || 0, keyData[key])
      })
      if (sessionIds.length > 1) {
        Object.keys(keyData).forEach((key) => {
          sessionsMap[sessionId].keysDeleted[key] = Math.max(
            sessionsMap[sessionId].keysDeleted[key] || 0,
            keyData[key],
          )
        })
      }
      for (const csvEvent of chunk.csvEvents) {
        allEvents.add(csvEvent)
      }
      if (sessionIds.length > 1) {
        for (const csvEvent of chunk.csvEvents) {
          sessionEvents.add(csvEvent)
        }
      }
    }
    if (sessionIds.length > 1) {
      sessionsMap[sessionId].events = preprocessStoredEvents(
        [...sessionEvents].map(eventFromCsvRow).sort(eventComparator),
      )
    }
  }
  const composite = {
    events: preprocessStoredEvents(
      [...allEvents].map(eventFromCsvRow).sort(eventComparator),
    ),
    secrets: allSecrets,
    keysDeleted: allKeysDeleted,
    keysBlacklisted: allKeysBlacklisted,
  }
  if (sessionIds.length === 1) {
    sessionsMap[sessionIds[0]] = composite
  }
  const result = {
    composite,
    sessions: sessionsMap,
  }
  return result
}
