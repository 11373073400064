import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

/**
 * Refresh the OAuth2 token for the currently logged-in user.  This should be
 * called when the token is about to expire or has expired.  It will refresh
 * the token and save it to the user's document, without any user interaction
 * required.
 *
 * @returns {Promise<any>} The new, refreshed OAuth2 token data for the user.  This
 *                         should be good for another 60 minutes.
 */
export async function refreshGapiOauth2AccessToken() {
  const result = await httpsCallable(
    functions,
    'refreshGapiOauth2AccessToken',
  )({})
  console.log('refreshGapiOauth2AccessToken result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.tokens
}
