import { doc, getDoc } from 'firebase/firestore/lite'
import { dbLite as db } from '../coreConfig'
import { getAccessCodeData } from './getAccessCodeData'
import { paths } from '@learnics/models/src/utils/paths'

/**
 * @deprecated
 */
export async function getResearchScoreObject(assignmentId) {
  const accessCodeData = await getAccessCodeData(assignmentId)
  if (!accessCodeData) return null
  const learnicsResearchScoreSnap = await getDoc(
    doc(db, paths.assignment(assignmentId) + '/learnicsScore/score'),
  )
  if (!learnicsResearchScoreSnap.exists()) return null
  return learnicsResearchScoreSnap.data()
}
