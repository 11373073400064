import { generateJSON } from '@tiptap/html'
import { activeTipTapExtensions } from './activeTipTapExtensions'

export function htmlHasNonWhiteSpace(html) {
  if (!html) return false
  return contentHasNonWhiteSpace(
    generateJSON(html, activeTipTapExtensions.get()),
  )
}
export function contentHasNonWhiteSpace(jsonNode) {
  if (jsonNode.type === 'text') {
    return jsonNode.text.trim().length > 0
  }
  if (['listItem', 'bucketFile', 'pastedImage'].includes(jsonNode.type)) {
    return true
  }
  if (jsonNode.content && jsonNode.content.length > 0) {
    return jsonNode.content.some((child) => contentHasNonWhiteSpace(child))
  }
  return false
}
