/**
 * A utility function to instantiate a Expectation object based on the given JSON object.
 *
 * @param {object} input the JSON object to instantiate from
 * @returns {*} an AbstractExpectation object instantiated from the given JSON object
 * @throws {Error} if the given JSON object does not have a valid expectationType
 */
export function normalizeRatios(expectations) {
  if (expectations.length <= 0) return expectations
  const total = expectations.reduce((acc, wedge) => acc + wedge.ratio, 0)
  expectations.forEach((wedge) => {
    wedge.ratio = wedge.ratio / total
  })
  return expectations
}
