import { UrlData } from './utils/UrlData'
import { RunningCalculator } from './RunningCalculator'
import { coreTimelineFilters } from './utils/coreTimelineFilters'
import { defaultUrlFilter } from './utils/defaultUrlFilter'

/**
 * CommonUrlDataRc is a RunningCalculator class that calculates (URL, title) pair times
 * across multiple logging sessions.
 *
 * It also acts as a store for MetadataEvent key/value pairs for a given URL.
 *
 * Since there might be a lot of metadata, the calculator is provided with a
 * list of keys to track.  This list uses a default list of keys, but can be
 * adjusted after instantiation.
 *
 */

const DEFAULT_KEYS_TO_TRACK = [
  'extractedPdfTitle',
  'title',
  // 'scrapedStudentCopy',
  // 'scrapedAttachment',
]
export class CommonUrlDataRc extends RunningCalculator {
  urlData = {}
  metadataKeysToTrack

  // If a url is updated, we store it here for a single step, for other calculators to use in their calculations.
  updatedUrlsAtPriorStep = null

  constructor(storageObj) {
    super('commonUrlDataRc', [
      'browserRc',
      'externalUrlsRc',
      'expectationsRc',
      'expectationRatiosRc',
      'studentCopiesRc',
      'urlTimelinesRc',
      'domainDataRc',
      'searchesRc',
    ])
    this.metadataKeysToTrack = [
      ...(storageObj?.metadataKeysToTrack || DEFAULT_KEYS_TO_TRACK),
    ]
    this.urlData = Object.keys(storageObj?.urlData || {}).reduce((acc, key) => {
      acc[key] = new UrlData(storageObj.urlData[key])
      return acc
    }, {})
    this.updatedUrlsAtPriorStep =
      (storageObj?.updatedUrlsAtPriorStep &&
        Object.keys(storageObj.updatedUrlsAtPriorStep).reduce((acc, key) => {
          acc[key] =
            storageObj.updatedUrlsAtPriorStep[key] &&
            new UrlData(storageObj.updatedUrlsAtPriorStep[key])
          return acc
        }, {})) ||
      null
  }

  toJson() {
    return {
      ...super.toJson(),
      metadataKeysToTrack: [...this.metadataKeysToTrack],
      urlData: Object.keys(this.urlData).reduce((acc, key) => {
        acc[key] = this.urlData[key].toJson()
        return acc
      }, {}),
      updatedUrlsAtPriorStep:
        (this.updatedUrlsAtPriorStep &&
          Object.keys(this.updatedUrlsAtPriorStep).reduce((acc, key) => {
            acc[key] = this.updatedUrlsAtPriorStep[key]?.toJson() || null
            return acc
          }, {})) ||
        null,
    }
  }

  transition(event, stateData) {
    let stateChanged = this.updatedUrlsAtPriorStep !== null
    this.updatedUrlsAtPriorStep = null
    const {
      secrets,
      keysDeleted,
      keysBlacklisted,
      browserRc,
      timeRange,
      urlFilter,
    } = stateData
    if (
      event.eventType === 'metadata' &&
      this.metadataKeysToTrack.includes(event.key)
    ) {
      const url = secrets[event.url]
      if (url) {
        const oldData =
          (this.urlData[url] && new UrlData(this.urlData[url].toJson())) || null

        if (!this.metadataKeysToTrack.includes(event.key)) return
        this.urlData[url] ||= new UrlData()
        const value = secrets[event.value]
        this.urlData[url].metadata[event.key] = value
        this.updatedUrlsAtPriorStep = {}
        this.updatedUrlsAtPriorStep[url] = oldData
      }
    }
    if (event.time >= timeRange.start && event.time <= timeRange.stop) {
      const browserTime = Math.max(timeRange.start, browserRc.time)
      const eventTime = Math.min(timeRange.stop, event.time)
      if (
        eventTime - browserTime > 0 &&
        coreTimelineFilters.focusedOnValidUrl({
          browserRc,
          secrets,
          keysDeleted,
          keysBlacklisted,
          urlFilter,
        })
      ) {
        const tab = browserRc.focusedTab
        const url = secrets[tab?.url]
        const title = secrets[tab?.title || url]

        const oldData =
          (this.urlData[url] && new UrlData(this.urlData[url].toJson())) || null

        this.urlData[url] ||= new UrlData()
        this.urlData[url].titleTimes[title] ||= 0
        this.urlData[url].titleTimes[title] += eventTime - browserTime
        this.updatedUrlsAtPriorStep ||= {}
        if (!this.updatedUrlsAtPriorStep[url])
          this.updatedUrlsAtPriorStep[url] = oldData
      }
    }

    return stateChanged
  }

  toRevealedSitesArray(urls = null) {
    const result = []
    urls ||= Object.keys(this.urlData)

    urls.forEach((url) => {
      result.push(this.urlData[url].toSite(url))
    })

    return result
      .filter(
        (site) =>
          Math.round(site.time / 1000) > 0 && defaultUrlFilter(site.url),
      )
      .sort((a, b) => b.time - a.time)
  }

  addSession(sessionCalcs, sessionSecrets, multiplier) {
    const { urlDataRc } = sessionCalcs
    const urlData = urlDataRc?.urlData

    for (const hashedUrl of Object.keys(urlData)) {
      if (!sessionSecrets[hashedUrl]) continue
      const url = sessionSecrets[hashedUrl]
      if (!this.urlData[url]) {
        this.urlData[url] = new UrlData()
      }
      const revealed = new UrlData(urlData[hashedUrl].toJson())
      revealed.reveal(sessionSecrets)
      if (multiplier > 0) {
        this.urlData[url].add(revealed)
      } else {
        this.urlData[url].subtract(revealed)
      }
    }
  }
}
