import { AbstractAttachment } from './Attachment/AbstractAttachment'
import { LinkAttachment } from './Attachment/LinkAttachment'
import { AbstractExpectation } from './Expectation/AbstractExpectation'
import { ExternalUrlsExpectation } from './Expectation/ExternalUrlsExpectation'
import { expectationFromJson } from './Expectation/utils/expectationFromJson'
import { ExpectedTime } from './ExpectedTime'
import { GoogleClassroomOptions } from './GoogleClassroomOptions'
import { BibliographyOptions } from './BibliographyOptions'
import { StudentOptions } from './StudentOptions'
import { Assignment } from './Assignment'
import { getMatchingExpectations } from './Expectation/utils/getMatchingExpectations'
import { StudentCopiesRc } from './Event/RunningCalculator/StudentCopiesRc'
import { redact } from '../../../utils/redact'
import { attachmentFromJson } from './Attachment/utils/attachmentFromJson'

/**
 * AssignmentDetail is class to represent the data model for an Assignment, for use across multiple courses.  It
 * contains all the information about an assignment, without any course-specific information.
 */
export class AssignmentDetail {
  /**
   * Constructor for AssignmentDetail.
   *
   * @param data {object} the data to initialize the AssignmentDetail with
   * @param [data.id] {string} the id of the AssignmentDetail
   * @param [data.name] {string} the name of the AssignmentDetail
   * @param [data.description] {string} the description of the AssignmentDetail
   * @param [data.created] {number} the date of creation of the AssignmentDetail
   * @param [data.updated] {number} the date of last update of the AssignmentDetail
   * @param [data.dueDate] {number} the due date of the AssignmentDetail, if any
   * @param [data.createdBy] {string} the id of the creator of the AssignmentDetail
   * @param [data.scheduleDate] {number} the schedule date of the AssignmentDetail, if any
   * @param [data.points] {number} the number of points the AssignmentDetail is worth
   * @param [data.archived] {boolean} whether the AssignmentDetail is archived
   * @param [data.expectedTime] {ExpectedTime} the expected time of the AssignmentDetail, if any
   * @param [data.attachments] {AbstractAttachment[]} the attachments of the AssignmentDetail, if any
   * @param [data.expectations] {AbstractExpectation[]} the expectations of the AssignmentDetail, if any
   * @param [data.studentOptions] {StudentOptions} the logger options for the AssignmentDetail
   * @param [data.classroomData] {GoogleClassroomOptions} the Google Classroom metadata of the AssignmentDetail, if any - deprecated
   * @param [data.googleClassroom] {boolean} whether the AssignmentDetail is integrated with Google Classroom
   * @param [data.bibliographyOptions] {BibliographyOptions} the bibliography metadata of the AssignmentDetail, if any
   * @param [data.state] {'DRAFT'|'PUBLISHED'|'ARCHIVED'} the state of the AssignmentDetail - deprecated
   * @param [data.allowClassCharts] {boolean} whether class charts are allowed
   * @param [data.allowDelete] {boolean} whether the AssignmentDetail can be deleted
   * @param [data.allowPauseLogging] {boolean} whether the AssignmentDetail can have logging paused
   * @param [data.allowCustomSubmissions] {boolean} whether the AssignmentDetail allows students to link custom drive file submissions to their assignment
   * @param [data.linkAttachments] {AbstractAttachment[]} the link attachments of the AssignmentDetail, if any
   * @param [data.recommendedDurationMinutes] {number} the duration in minutes of recommended time for the AssignmentDetail
   * @param [data.outline] {string} the outline of the AssignmentDetail, in html format, if any
   * @param [data.assignmentIds] {string[]} the ids of the assignments that are associated with this AssignmentDetail
   * @param [data.roles] {any} the roles of this AssignmentDetail.  This is a map of role strings to arrays of user ids.
   * @param [assignments] {any} This is a **transient** property that is used to store Assignment objects associated with this AssignmentDetail.
   */
  constructor(
    {
      id = null,
      expectedTime = { min: null, max: null },
      createdBy = null,
      dueDate = null,
      created = +new Date(),
      updated = +new Date(),
      scheduleDate = null,
      name = '',
      archived = false,
      state = 'PUBLISHED',
      attachments = [],
      points = null,
      description = '',
      expectations = [
        new ExternalUrlsExpectation({ ratio: 1, min: 0, max: 0 }).toJson(),
      ],
      studentOptions = null,
      googleClassroom = false,
      classroomData,
      bibliographyOptions,
      allowPauseLogging,
      allowDelete,
      allowClassCharts,
      allowCustomSubmissions,
      linkAttachments,
      recommendedDurationMinutes,
      outline,
      assignmentIds,
      roles = {},
    },
    assignments = null,
  ) {
    this.id = id
    this.created = created?.toDate ? +created.toDate() : created
    this.updated =
      (updated?.toDate ? +updated.toDate() : updated) || this.created
    this.createdBy = createdBy
    this.dueDate = dueDate?.toDate ? +dueDate.toDate() : dueDate
    this.scheduleDate = scheduleDate?.toDate
      ? +scheduleDate.toDate()
      : scheduleDate
    this.expectedTime = new ExpectedTime(expectedTime)
    this.attachments = attachments?.map(attachmentFromJson) || null
    this.expectations = expectations.map(expectationFromJson)
    this.points = points
    this.state = state
    this.name = name?.trim() || ''
    this.archived = archived
    this.description = description?.trim() || ''
    this.googleClassroom = !!googleClassroom || !!classroomData

    this.studentOptions = new StudentOptions(studentOptions || {})
    if (typeof allowPauseLogging !== 'undefined') {
      this.studentOptions.allowPauseLogging = allowPauseLogging
    }
    if (typeof allowDelete !== 'undefined') {
      this.studentOptions.allowDelete = allowDelete
    }
    if (typeof allowClassCharts !== 'undefined') {
      this.studentOptions.allowClassCharts = allowClassCharts
    }
    this.allowCustomSubmissions = !!allowCustomSubmissions

    if (classroomData) {
      this.classroomData = new GoogleClassroomOptions(classroomData)
    }
    if (bibliographyOptions) {
      this.bibliographyOptions = new BibliographyOptions(bibliographyOptions)
    }
    if (linkAttachments && !this.attachments) {
      this.attachments = linkAttachments.map(
        (url) => new LinkAttachment({ url, title: url }),
      )
    }
    if (recommendedDurationMinutes) {
      this.expectedTime = new ExpectedTime({
        min: recommendedDurationMinutes * 60,
        max: recommendedDurationMinutes * 60,
      })
    }
    if (outline) {
      this.outline = outline
    }
    assignmentIds ||= []
    this.assignmentIds = [...assignmentIds]
    this.roles = JSON.parse(JSON.stringify(roles))

    if (assignments) {
      this.assignments = {}
      Object.keys(assignments).forEach((assignmentId) => {
        this.assignments[assignmentId] = new Assignment(
          assignments[assignmentId].toJson(),
        )
      })
    }
  }

  /**
   * @type {boolean} Whether pausing is allowed
   * @returns {boolean} whether pausing is allowed
   */
  get allowPauseLogging() {
    return this.studentOptions.allowPauseLogging
  }

  /**
   * @param {boolean} allowPause whether pausing is allowed
   * @returns {void}
   */
  set allowPauseLogging(allowPause) {
    this.studentOptions.allowPauseLogging = allowPause
  }

  /**
   * @returns {boolean} whether deleting is allowed
   */
  get allowDelete() {
    return this.studentOptions.allowDelete
  }

  /**
   * @param {boolean} allowDelete whether deleting is allowed
   * @returns {void}
   */
  set allowDelete(allowDelete) {
    this.studentOptions.allowDelete = allowDelete
  }

  /**
   * @returns {boolean} whether class charts are allowed
   */
  get allowClassChart() {
    return this.studentOptions.allowClassChart
  }

  /**
   * @param {boolean} allowClassChart whether class charts are allowed
   * @returns {void}
   */
  set allowClassChart(allowClassChart) {
    this.studentOptions.allowClassChart = allowClassChart
  }

  /**
   * @returns {*|number} the recommended duration of the AssignmentDetail in minutes
   */
  get recommendedDurationMinutes() {
    let expectedTime = this.expectedTime.min || this.expectedTime.max || null
    if (expectedTime) expectedTime /= 60
    return expectedTime
  }

  /**
   * @returns {AbstractAttachment[]} the non-source-drive-file attachments of the AssignmentDetail
   */
  get linkAttachments() {
    return (
      this.attachments
        ?.filter(
          (attachment) => attachment.attachmentType !== 'sourceDriveFile',
        )
        .map((attachment) => attachment.url) || []
    )
  }

  /**
   * @returns {*} this AssignmentDetail in plain JSON form
   */
  toJson() {
    const result = {
      id: this.id,
      created: this.created,
      updated: this.updated,
      createdBy: this.createdBy,
      dueDate: this.dueDate,
      state: this.state,
      scheduleDate: this.scheduleDate,
      expectedTime: this.expectedTime.toJson(),
      points: this.points,
      name: this.name,
      assignmentIds: [...this.assignmentIds],
      roles: JSON.parse(JSON.stringify(this.roles)),
      archived: this.archived,
      description: this.description,
      expectations: this.expectations.map((expectation) =>
        expectation.toJson(),
      ),
      googleClassroom: !!this.googleClassroom,
      studentOptions: this.studentOptions.toJson(),
      allowPauseLogging: this.studentOptions.allowPauseLogging, // TODO: Remove once the student extension is updated
      allowDelete: this.studentOptions.allowDelete, // TODO: Remove once the student extension is updated
      allowCustomSubmissions: this.allowCustomSubmissions,
    }
    if (this.bibliographyOptions) {
      result.bibliographyOptions = this.bibliographyOptions.toJson()
    }
    if (this.classroomData) {
      result.classroomData = this.classroomData.toJson()
    }
    if (this.attachments) {
      result.attachments = this.attachments.map((attachment) =>
        attachment.toJson(),
      )
    }
    if (this.outline) {
      result.outline = this.outline
    }
    return result
  }

  upgradeClassroomAssignmentIfNeeded(assignment) {
    if (!this.classroomData) {
      return
    }
    assignment.classroomAssignmentId = this.classroomData.id || null
    assignment.classroomCourseId = this.classroomData.courseId || null
    assignment.classroomDirectLink = this.classroomData.directLink || null
    assignment.classroomStudentWorkFolderId =
      this.classroomData.studentWorkFolderId || null
    assignment.createdWithLearnics = !!this.classroomData.createdWithLearnics
  }

  /**
   * @param url {string}
   * @param {{
   *   secrets: object,
   *   studentCopiesRc: StudentCopiesRc,
   * }}
   * @param scrapedStudentCopyUrls {string[]}
   * @returns {boolean} whether the AssignmentDetail matches the given url
   */
  matchesUrl(url, { secrets, studentCopiesRc }, scrapedStudentCopyUrls = []) {
    const hashedUrl = redact(url)
    for (let studentCopyUrl of scrapedStudentCopyUrls) {
      if (studentCopyUrl.includes(url) || url.includes(studentCopyUrl))
        return true
    }
    studentCopiesRc ||= new StudentCopiesRc()

    const attachments = this.attachments || []
    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i].matchesUrl(url)) return true
    }
    if (!this.hasExpectations()) return false
    const matchingExpectations = getMatchingExpectations(hashedUrl, {
      assignmentDetail: this,
      secrets,
      studentCopiesRc,
    }).filter((expectation) => expectation.expectationType !== 'externalUrls')
    return matchingExpectations.length > 0
  }

  /**
   * @returns {false|null}
   */
  hasExpectations() {
    return (
      this.expectations?.filter((e) => e.ratio > 0).length > 0 &&
      (this.expectedTime?.min || this.expectedTime?.max)
    )
  }

  /**
   * @returns {number} the minimum expected time in milliseconds
   */
  get minExpectedTimeMs() {
    return (this.expectedTime.min || this.expectedTime.max || 0) * 1000
  }

  /**
   * @returns {boolean} whether the AssignmentDetail is past due
   */
  get pastDue() {
    if (this.dueDate) {
      return +new Date() > this.dueDate
    } else {
      return false
    }
  }
}
