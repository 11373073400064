import {
  activeTipTapExtensions,
  tipTapNodeHasContent,
  tipTapNodeHasText,
  yjsInstance,
} from '../utils'
import { generateHTML } from '@tiptap/html'
import { getSchema } from '@tiptap/core'

/**
 * NoteBoardOutline represents a node in a Note-based outline, in tiptap
 * XML format.
 *
 * This class simple wraps a Y.XmlFragment and provides some utility methods
 * specific to NoteBoard outlines.
 *
 * To access the Y library, use the yjsInstance singleton.
 */
export class NoteBoardOutline {
  constructor(yXmlFragment) {
    if (!yXmlFragment) {
      throw new Error('yXmlFragment is required')
    }
    this.yXmlFragment = yXmlFragment
  }

  getNoteIds(xmlNode = this.yXmlFragment, result = new Set()) {
    const Y = yjsInstance.Y
    let nChildren = xmlNode.length || 0
    for (let i = 0; i < nChildren; i++) {
      const child = xmlNode.get(i)
      if (child.nodeName === 'noteBoardNote') {
        const noteId = child.getAttribute('note-id')
        result.add(noteId)
      } else if (!(child instanceof Y.XmlText)) {
        this.getNoteIds(child, result)
      }
    }
    return result
  }

  getHtml(extensions = activeTipTapExtensions.get()) {
    return generateHTML(this.getJson(extensions), extensions)
  }
  getJson(extensions = activeTipTapExtensions.get()) {
    return yjsInstance
      .yXmlFragmentToProseMirrorRootNode(
        this.yXmlFragment,
        getSchema(extensions),
      )
      .toJSON()
  }

  hasContent(extensions = activeTipTapExtensions.get()) {
    // TODO: This could be optimized by merely traversing the XML tree
    return tipTapNodeHasContent(this.getJson(extensions))
  }

  hasText(extensions = activeTipTapExtensions.get()) {
    // TODO: This could be optimized by merely traversing the XML tree
    return tipTapNodeHasText(this.getJson(extensions))
  }

  deleteNotes(noteIds, xmlNode = this.yXmlFragment) {
    let changed = false
    noteIds = new Set([...(noteIds || [])])
    if (noteIds.size === 0) return changed

    let nChildren = xmlNode.length || 0
    for (let i = 0; i < nChildren; i++) {
      const child = xmlNode.get(i)
      if (child.nodeName === 'noteBoardNote') {
        const noteId = child.getAttribute('note-id')
        if (noteIds.has(noteId)) {
          xmlNode.delete(i, 1)
          i--
          nChildren--
          changed = true
        }
      } else if (!(child instanceof yjsInstance.Y.XmlText)) {
        const innerChanged = this.deleteNotes(noteIds, child)
        if (innerChanged) changed = true
      }
    }
    return changed
  }
}
