import { updateDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

/**
 * A function to archive a single Assignment.  Note that this will only archive this one Assignment, not the
 * AssignmentDetail it is associated with, and not any other Assignments for the same AssignmentDetail.
 *
 * @param {string} assignmentId The id of the AssignmentDetail to archive.
 * @param {boolean} archive Whether to archive or un-archive the AssignmentDetail, defaults to true.
 **/
export async function archiveAssignment(assignmentId, archive = true) {
  console.log(`Archiving Assignment ${assignmentId}`)
  await updateDoc(refs.assignment(assignmentId, false), {
    archived: !!archive,
  })
}
