import { DOMSerializer } from 'prosemirror-model'
import _ from 'lodash'

export function downgradeCustomExtensionsForElement(
  element,
  getResource = (resourceType, resourceId) => null,
) {
  const tagName = _.camelCase(_.toLower(element.tagName))
  const handler = handlers[tagName]
  if (handler) {
    handler(element, getResource)
  } else {
    element.childNodes.forEach((child) => {
      downgradeCustomExtensionsForElement(child, getResource)
    })
  }
  return element
}

const handlers = {
  text: (element, getResource) => {},
  bucketFile: (element, getResource) => {
    const fileId = element.getAttribute('file-id')

    const bucketFile = getResource('bucketFile', fileId)
    if (bucketFile && bucketFile.type.startsWith('image/')) {
      let oldElement = element
      element = oldElement.ownerDocument.createElement('img')
      let scale = oldElement.getAttribute('scale')
      if (scale) {
        scale = parseFloat(scale)
        if (isNaN(scale)) {
          scale = 1
        }
      } else {
        scale = 1
      }

      element.setAttribute('data-learnics-resource-type', 'bucketFile')
      element.setAttribute('data-learnics-bucket-file-id', fileId)
      element.setAttribute('scale', scale)
      element.setAttribute('src', bucketFile.signedUrl)
      element.setAttribute('alt', bucketFile.name)
      element.setAttribute('title', bucketFile.name)
      const naturalWidth = bucketFile.width

      element.style.width = `${naturalWidth * scale}px`
      element.style.height = `auto`

      oldElement.parentNode.replaceChild(element, oldElement)
    } else {
      console.warn('Bucket file not found')
    }
  },
  pastedImage: (element, getResource) => {
    // If the image got pasted in from somewhere else, it's safe to assume it won't
    // be signed, so we need to replace it with an img tag that is more widely
    // recognized than our custom tag.
    let oldElement = element
    let newElement = oldElement.ownerDocument.createElement('img')
    let scale = oldElement.getAttribute('scale')
    if (scale) {
      scale = parseFloat(scale)
      if (isNaN(scale)) {
        scale = 1
      }
    } else {
      scale = 1
    }

    newElement.setAttribute('scale', scale)
    newElement.setAttribute('src', oldElement.getAttribute('src'))
    const alt = oldElement.getAttribute('alt')
    if (alt) {
      newElement.setAttribute('alt', alt)
    }
    const title = oldElement.getAttribute('title')
    if (title) {
      newElement.setAttribute('title', oldElement.getAttribute('title'))
    }
    const naturalWidth = parseFloat(oldElement.getAttribute('natural-width'))

    newElement.style.width = `${naturalWidth * scale}px`
    newElement.style.height = `auto`

    oldElement.parentNode.replaceChild(newElement, oldElement)
  },
  noteBoardNote: (element, getResource) => {
    console.log('Found a noteBoardNote')
    const noteId = element.getAttribute('note-id')

    const note = getResource('note', noteId)
    if (!note) {
      console.warn('Note not found')
    } else {
      element.innerHTML = note.getContentAsHtml()
      // Recursively adjust the content of the note in search of bucketFiles
      for (let i = 0; i < element.childNodes.length; i++) {
        downgradeCustomExtensionsForElement(element.childNodes[i], getResource)
      }
    }
  },
}
