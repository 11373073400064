import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function requestAccessToResource(resourceType, resourceId, roles) {
  console.log('requestAccessToResource', resourceType, resourceId, roles)
  const cloudFuncResult = await httpsCallable(
    functions,
    'requestAccessToResource',
  )({
    resourceType,
    resourceId,
    roles,
  })
  if (!cloudFuncResult.data.success) {
    throw new Error(cloudFuncResult.data.error)
  }
}
