import { getAssignmentsByGoogleClassroomAssignmentId } from './getAssignmentsByGoogleClassroomAssignmentId'

export async function getAssignmentByGoogleClassroomAssignmentId(
  classroomCourseId,
  classroomCourseWorkId,
) {
  // Query for ALL potential assignments.  There should hopefully only ever be
  // one, but this handles the case in which there are more than one.
  let existingAssignments = await getAssignmentsByGoogleClassroomAssignmentId(
    classroomCourseId,
    classroomCourseWorkId,
  )
  // Sort by updated or created date.
  const existingAssignmentIds = Object.keys(existingAssignments).sort(
    (a, b) =>
      (existingAssignments[b].updated || existingAssignments[b].created || 0) -
      (existingAssignments[a].updated || existingAssignments[a].created || 0),
  )

  // Just return one, if there are more than one.
  return existingAssignmentIds.length > 0
    ? existingAssignments[existingAssignmentIds[0]]
    : null
}
