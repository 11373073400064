export function isColorLight(color) {
  if (!color) {
    return false
  }
  if (color.startsWith('#')) {
    color = color.slice(1)
  }
  const red = parseInt(color.slice(0, 2), 16)
  const green = parseInt(color.slice(2, 4), 16)
  const blue = parseInt(color.slice(4, 6), 16)
  const relativeLuminance = (red * 0.299 + green * 0.587 + blue * 0.114) / 255
  const threshold = 0.6 // You can adjust this value as desired
  return relativeLuminance > threshold
}
