import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function downgradeLoggerSessions(sessionIds, userId) {
  const result = await httpsCallable(
    functions,
    'downgradeLoggerSessions',
  )({ sessionIds, userId })
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.data
}
