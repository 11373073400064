import { eventComparator } from '../../utils/eventComparator'
import { AssignmentSubmitEvent } from '../../AssignmentSubmitEvent'
import { MetadataEvent } from '../../MetadataEvent'

/**
 * Process an array of events, primarily for the purposes of supporting older
 * event types and configurations, and normalizing the data to the latest
 * event types and configurations, with minimal variation, so everything can
 * just assume the latest event types and configurations.
 *
 * This also handles the case of unrecognized events, which will be converted
 * into 'empty' events, and a warning will be logged.  This is an indicator
 * that the system is not up-to-date with the new event type, and it needs to be
 * added into the event type map or that the event has been removed.
 */
export function preprocessStoredEvents(events) {
  const result = []
  for (let i = 0; i < events.length; i++) {
    let event = events[i]
    // Support the old method of 'loggingStop' event types being used for submissions.
    if (
      event.eventType === 'loggingStop' &&
      (event.value === 'classroomTurnIn' || event.value === 'learnicsTurnIn')
    ) {
      // Just add an 'assignmentSubmit' event with the value set to true.
      result.push(new AssignmentSubmitEvent({ value: true, time: event.time }))
    }
    // Support the old 'scrapedStudentCopy' metadata key, which was used before.
    // This is simply considered an 'addStudentCopyLink' event with the URL as the value.
    if (event.eventType === 'metadata' && event.key === 'scrapedStudentCopy') {
      // Replace the old event with a new one.
      event = new MetadataEvent({
        time: event.time,
        key: 'addStudentCopyLink',
        value: event.url,
      })
      event.redacted = true
    }
    // We can just ignore empty events if they are in between other events.
    if (event.eventType !== 'empty' || i === events.length - 1 || i === 0) {
      result.push(event)
    }
  }
  return result.sort(eventComparator)
}
