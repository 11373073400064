import refs from '../refsLite'
import { setDoc } from 'firebase/firestore/lite'

/**
 * Append a list of events to the event log for a user.
 *
 * @param session - The session object
 * @returns {Promise<void>} - A promise that resolves when the session has been saved
 */
export async function saveLoggerSession(loggerSession) {
  const ref = refs.loggerSession(loggerSession.id)
  await setDoc(ref, loggerSession)
  return loggerSession
}
