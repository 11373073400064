import { getLocaleDateString } from '../../../../../../utils/getLocaleDateString'

/**
 * A class to represent a single timeline of some metric.  To advance the timeline
 * forward, call `increment` with a start and stop time.  This will throw an error if
 * the start time is less than the last stop time, or if the start time is equal to the
 * last stop time.  This will also throw an error if the start time is less than the
 * last stop time recorded.
 *
 * When stored, the timeline is stored as an offset and a list of start and stop times.
 *
 * The offset is the first start time.  All start and stop times are stored as
 * the difference between the time and the offset.  This is done to save space, but
 * programmatically, the offset is added back to the start and stop times when the
 * timeline is loaded.
 */
export class Timeline {
  startTimes = []
  stopTimes = []
  constructor(storageObj = { offset: 0, startTimes: [], stopTimes: [] }) {
    const offset = storageObj?.offset || 0
    this.startTimes = [...(storageObj?.startTimes || [])].map(
      (time) => time + offset,
    )
    this.stopTimes = [...(storageObj?.stopTimes || [])].map(
      (time) => time + offset,
    )
  }
  toJson() {
    const offset = this.startTimes?.[0] || 0
    const startTimes = this.startTimes?.map((time) => time - offset) || []
    const stopTimes = this.stopTimes?.map((time) => time - offset) || []
    return { offset, startTimes, stopTimes }
  }
  get total() {
    let total = 0
    for (let i = 0; i < this.startTimes.length; i++) {
      total += this.stopTimes[i] - this.startTimes[i]
    }
    return total
  }

  /**
   * Increment this timeline forward, adding a new start and stop time.  This will throw an error if the start time is
   * less than the last stop time, or if the start time is equal to the last stop time.  This will also throw an error
   * if the start time is less than the last stop time recorded.
   *
   * @param startTime
   * @param stopTime
   */
  increment(startTime, stopTime) {
    if (!startTime || !stopTime) {
      throw new Error(
        'Timeline.increment: startTime and stopTime must be defined',
      )
    }
    if (startTime > stopTime) {
      throw new Error(
        'Timeline.increment: startTime must be less than or equal to stopTime',
      )
    }
    if (this.startTimes.length === 0) {
      this.startTimes.splice(this.startTimes.length, 0, startTime)
      this.stopTimes.splice(this.stopTimes.length, 0, stopTime)
      return
    }
    const lastRecordedStopTime = this.stopTimes[this.stopTimes.length - 1]
    if (startTime < lastRecordedStopTime) {
      throw new Error(
        'Timeline.increment: startTime must be greater than or equal to the last stopTime',
      )
    }
    if (startTime === lastRecordedStopTime) {
      this.stopTimes.splice(this.stopTimes.length - 1, 1, stopTime)
      return
    }
    if (
      this.startTimes[this.startTimes.length - 1] ===
      this.stopTimes[this.stopTimes.length - 1]
    ) {
      // Don't need to store 0 length timelines
      this.startTimes.splice(this.startTimes.length - 1, 1)
      this.stopTimes.splice(this.stopTimes.length - 1, 1)
    }
    this.startTimes.splice(this.startTimes.length, 0, startTime)
    this.stopTimes.splice(this.stopTimes.length, 0, stopTime)
  }

  getLocaleDateStrings() {
    const dates = []
    for (let i = 0; i < this.startTimes.length; i++) {
      let startDate = getLocaleDateString(this.startTimes[i])
      let endDate = getLocaleDateString(this.stopTimes[i])

      let currentDate = this.startTimes[i]
      if (dates[dates.length - 1] !== startDate) {
        dates.push(startDate)
      }
      while (getLocaleDateString(currentDate) !== endDate) {
        currentDate += 24 * 60 * 60 * 1000
        if (dates[dates.length - 1] !== getLocaleDateString(currentDate)) {
          dates.push(getLocaleDateString(currentDate))
        }
      }
    }
    return dates
  }
}
