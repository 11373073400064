export const resourceTypes = [
  'organization',
  'course',
  'section',
  'assignment',
  'researchNotebook',
  'noteBoard',
  'assignmentDetail',
  'loggerSession',
  'note',
  'bucketFile',
]

export function extractLinksMapFromFirestoreDocument(prefix, data) {
  return resourceTypes.reduce((acc, resourceType) => {
    acc[resourceType] = data[prefix + resourceType] || []
    return acc
  }, {})
}

export const resourceTypeLabels = {
  organization: 'Organization',
  course: 'Course',
  section: 'Section',
  assignment: 'Assignment',
  assignmentDetail: 'Assignment',
  researchNotebook: 'Research Notebook',
  noteBoard: 'Note Board',
  loggerSession: 'Logger Session',
  note: 'Note',
  bucketFile: 'Bucket File',
}
