import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getSecrets(sessionId, chunkId) {
  const snap = await getDoc(refs.secret(sessionId, chunkId))
  if (snap.exists()) {
    return JSON.parse(JSON.stringify(snap.data()))
  }
  return {}
}
