/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { getInstallation } from './getInstallation'
import { getInstallationsByExtensionId } from './getInstallationsByExtensionId'
import { getInstallationsByUserId } from './getInstallationsByUserId'
import { registerInstallation } from './registerInstallation'
import { saveOrUpdateInstallation } from './saveOrUpdateInstallation'
import { unregisterInstallation } from './unregisterInstallation'
import { updateInstallation } from './updateInstallation'

const ExtensionInstallationService = {
  getInstallation,
  getInstallationsByExtensionId,
  getInstallationsByUserId,
  registerInstallation,
  saveOrUpdateInstallation,
  unregisterInstallation,
  updateInstallation,
}

export default ExtensionInstallationService
