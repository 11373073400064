import refs from '../refsLite'
import { setDoc } from 'firebase/firestore/lite'

export async function saveResourceYDocDebug(
  resourceType,
  resourceId,
  yDocJson,
) {
  await setDoc(refs[`${resourceType}Debug`](resourceId), yDocJson)
}
