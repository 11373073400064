import { allRoles } from '../utils/roles'

/**
 * BasicAccessList is a class modifiable only by the backend API.  It acts a
 * generic linking and access control list.  It is used to store user roles and
 * links between resources.
 *
 * There are 3 types of links:
 *
 * 1. Parents: A set of resource identifiers for other resources that are
 *             parents of a given resource.  All resources have parents except
 *             for a few "root" resources.  For example, a course may have an
 *             organization as a parent, but an organization will not have a
 *             parent.  Likewise, a LoggerSession may or may not have an
 *             assignment as a parent.
 *
 * 2. Relations: A set of resource identifiers for other resources that are
 *               related in some way to a given resource. This is a generic,
 *               two-way link.  Each relation link is expected to be stored
 *               in both resources.
 *
 * 3. Children: A set of resource identifiers for other resources that are
 *              children of a given resource.  Not all resources will have
 *              children stored in their ACL.  Some resources may simply rely on
 *              querying all possible children to calculate its set of children.
 *
 * Children links are not guaranteed to be on every ACL.  For some child/parent
 * relationships, you may be forced to query across all potential child
 * resources, using the parent's ID in the given parent list of the child.
 *
 * For example, the access list for a course may link it to one or more
 * organizations, but to determine all of the courses in an organization, you
 * must query all courses to find the organization in the parent list of each
 * course.  The organization's ACL will not contain a list of all courses.  This
 * is to prevent the ACL from growing too large.
 *
 * Parents and children have a cascading relationship.  If a parent resource is
 * deleted, all of its children who are orphaned as a result will be deleted.
 * If a child resource is deleted, the child is removed from its parent's access list.
 *
 * If a child has multiple parents, deleting one parent does not delete the
 * child.  Orphan children are only deleted when all parents have been deleted.
 *
 * Unlike parents and children, relations are not cascading.  Deleting a resource
 * severs all relation links for the resource, but the related resources are not
 * deleted alongside the resource.
 *
 */
export class BasicAccessList {
  constructor({
    roles = {},
    children = {},
    parents = {},
    relations = {},
    publicData = {},
    locked = false,
  }) {
    this.roles = Object.keys(roles).reduce((acc, role) => {
      acc[role] = new Set([...roles[role]])
      return acc
    }, {})
    this.children = Object.keys(children).reduce((acc, link) => {
      acc[link] = new Set([...children[link]])
      return acc
    }, {})
    this.parents = Object.keys(parents).reduce((acc, link) => {
      acc[link] = new Set([...parents[link]])
      return acc
    }, {})
    this.relations = Object.keys(relations).reduce((acc, relation) => {
      acc[relation] = new Set([...relations[relation]])
      return acc
    }, {})

    this.publicData = JSON.parse(JSON.stringify(publicData || {}))
    this.locked = !!locked
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {Object}
   */
  toJson() {
    const linksToJson = (linkType) => {
      return Object.keys(this[linkType] || {})
        .filter((resourceType) => this[linkType][resourceType].size > 0)
        .reduce((acc, resourceType) => {
          acc[resourceType] = [...this[linkType][resourceType]]
          return acc
        }, {})
    }
    return {
      roles: linksToJson('roles'),
      children: linksToJson('children'),
      parents: linksToJson('parents'),
      relations: linksToJson('relations'),
      publicData: JSON.parse(JSON.stringify(this.publicData)),
      locked: !!this.locked,
    }
  }

  addRole(roleType, uid) {
    if (this.hasRole(roleType, uid)) {
      return false
    }
    this.roles[roleType] ||= new Set()
    this.roles[roleType].add(uid)
    return true
  }

  addRoles(roleTypes, uid) {
    let changed = false
    ;[...roleTypes].forEach((roleType) => {
      if (this.addRole(roleType, uid)) {
        changed = true
      }
    })
    return changed
  }

  hasAtLeastOneRole(roleTypes, uid) {
    return roleTypes.some((roleType) => this.hasRole(roleType, uid))
  }
  hasRole(roleType, uid) {
    return uid && this.roles[roleType]?.has(uid)
  }

  getRoleIds(roleType) {
    return this.roles[roleType] ? [...this.roles[roleType]] : []
  }

  deleteRole(roleType, uid) {
    this.roles[roleType]?.delete(uid)
    if (this.roles[roleType]?.size === 0) {
      delete this.roles[roleType]
    }
  }

  hasAnyRoles(uid = null, rolesArray = null) {
    if (!rolesArray) {
      rolesArray = [...allRoles]
    }
    for (let role of rolesArray) {
      if (!uid && this.roles[role] && this.roles[role].size > 0) {
        return true
      }
      if (uid && this.roles[role]?.has(uid)) {
        return true
      }
    }
    return false
  }

  addLink(linkType, resourceType, resourceId) {
    this[linkType][resourceType] ||= new Set()
    this[linkType][resourceType].add(resourceId)
  }

  addChild(resourceType, resourceId) {
    this.addLink('children', resourceType, resourceId)
  }

  addChildren(resourceType, resourceIds) {
    resourceIds.forEach((resourceId) => {
      this.addChild(resourceType, resourceId)
    })
  }

  addRelation(resourceType, resourceId) {
    this.addLink('relations', resourceType, resourceId)
  }

  hasParent(resourceType, resourceId) {
    return this.hasLink('parents', resourceType, resourceId)
  }

  hasChild(resourceType, resourceId) {
    return this.hasLink('children', resourceType, resourceId)
  }

  hasRelation(resourceType, resourceId) {
    return this.hasLink('relations', resourceType, resourceId)
  }

  addParent(resourceType, resourceId) {
    this.addLink('parents', resourceType, resourceId)
  }

  hasLink(linkType, resourceType = null, resourceId = null) {
    if (resourceType && resourceId) {
      return this[linkType]?.[resourceType]?.has(resourceId)
    } else if (resourceType) {
      return this[linkType]?.[resourceType]?.size > 0
    } else {
      return Object.keys(this[linkType] || {}).some(
        (resourceType) => this[linkType][resourceType].size > 0,
      )
    }
  }

  hasChildren(resourceType = null) {
    return this.hasLink('children', resourceType)
  }

  hasParents(resourceType = null) {
    return this.hasLink('parents', resourceType)
  }

  hasRelations(resourceType = null) {
    return this.hasLink('relations', resourceType)
  }

  hasAnyChildren() {
    return this.hasChildren()
  }

  hasAnyParents() {
    return this.hasParents()
  }

  hasAnyRelations() {
    return this.hasRelations()
  }

  getLinks(linkType, resourceType = null) {
    if (!resourceType) return this[linkType] || {}
    return this[linkType]?.[resourceType] || new Set()
  }

  getChildren(resourceType = null) {
    return this.getLinks('children', resourceType)
  }

  getParents(resourceType = null) {
    return this.getLinks('parents', resourceType)
  }

  getRelations(resourceType = null) {
    return this.getLinks('relations', resourceType)
  }

  deleteLink(linkType, resourceType = null, resourceId = null) {
    if (!resourceType) {
      this[linkType] = {}
      return
    }
    if (!resourceId) {
      delete this[linkType]?.[resourceType]
      return
    }
    this[linkType]?.[resourceType]?.delete(resourceId)
    if (this[linkType]?.[resourceType]?.size === 0) {
      delete this[linkType][resourceType]
    }
  }

  deleteChild(resourceType, resourceId) {
    this.deleteLink('children', resourceType, resourceId)
  }

  deleteChildren(resourceType, resourceIds) {
    resourceIds.forEach((resourceId) => {
      this.deleteChild(resourceType, resourceId)
    })
  }

  deleteParent(resourceType, resourceId) {
    this.deleteLink('parents', resourceType, resourceId)
  }

  deleteRelation(resourceType, resourceId) {
    this.deleteLink('relations', resourceType, resourceId)
  }

  deleteAllChildren() {
    this.deleteLink('children')
  }

  deleteAllParents() {
    this.deleteLink('parents')
  }

  deleteAllRelations() {
    this.deleteLink('relations')
  }

  getUsersWithRole(roleType = null) {
    const result = new Set()
    if (!roleType) {
      Object.keys(this.roles).forEach((roleType) => {
        this.roles[roleType].forEach((uid) => result.add(uid))
      })
    } else {
      this.roles[roleType]?.forEach((uid) => result.add(uid))
    }
    return result
  }

  getRolesForUser(uid) {
    return new Set(
      Object.keys(this.roles).filter((roleType) =>
        this.roles[roleType].has(uid),
      ),
    )
  }
}
