import {
  deleteDoc,
  getDoc,
  getDocs,
  setDoc,
  writeBatch,
} from 'firebase/firestore/lite'
import refs from '../refsLite'
import { dbLite as db } from '../coreConfig'
import { OldAssignmentLoggerSession } from '@learnics/models/src/Organization/Course/Assignment/Submission/OldAssignmentLoggerSession'
import { saveLoggerSession } from './saveLoggerSession'
import { getEventMetaInfo } from './getEventMetaInfo'

/**
 * This function is in place to migrate old event data to the new logger session collection.
 *
 * It is presumably no longer needed, but may be useful in the future.
 * @deprecated
 */
export async function migrateLoggerSession(
  sessionId,
  assignmentId,
  deleteOldMetaInfo = true,
) {
  const snap = await getDoc(refs.loggerSession(sessionId))

  let sessionFound = null
  if (snap.exists()) {
    sessionFound = snap.data()
  }
  if (sessionFound) {
    const oldEventMetaInfoSnap = await getDoc(
      refs.oldEventLogMetainfo(
        assignmentId || sessionFound.assignmentId,
        sessionId,
      ),
    )
    const oldEventMetaInfo = oldEventMetaInfoSnap.data()
    if (!oldEventMetaInfo) {
      return sessionFound // If there is no old event meta info, then we can just return the session.
    }
    const newEventMetaInfo = await getEventMetaInfo(sessionId)
    if (
      newEventMetaInfo.chunkCount >= oldEventMetaInfo.chunkCount &&
      (newEventMetaInfo.lastEventsSize >= oldEventMetaInfo.lastEventsSize ||
        newEventMetaInfo.lastSecretsSize >= oldEventMetaInfo.lastSecretsSize)
    ) {
      if (deleteOldMetaInfo) {
        await deleteDoc(refs.oldEventLogMetainfo(assignmentId, sessionId))
      }
      return sessionFound
    } // otherwise we need to update the session's event data in the database.
  }

  // This code is for migrating old events to the new logger session collection.
  const eventSnaps = await getDocs(refs.oldEvents(assignmentId, sessionId))
  if (eventSnaps.empty) {
    if (deleteOldMetaInfo) {
      await deleteDoc(refs.oldEventLogMetainfo(assignmentId, sessionId))
    }
    // No old events found.  Just make a new session and save it.
    const newSession = new OldAssignmentLoggerSession({
      assignmentId,
      id: sessionId,
    })
    await saveLoggerSession(newSession)
    return newSession
  }

  const session = new OldAssignmentLoggerSession({
    assignmentId,
    id: sessionId,
  })
  // Code below is for migrating old events to the new logger session collection.
  // Unless some code is written to migrate all the old events in the same
  // fashion that's happening here, this code should not be deleted.
  await setDoc(refs.loggerSession(sessionId), session)
  let eventDocs = {}
  for (const snap of eventSnaps.docs) {
    if (snap.exists()) {
      eventDocs[snap.id] = snap.data()
    }
  }
  const secretSnapshots = await getDocs(
    refs.oldSecrets(assignmentId, sessionId),
  )
  let secretDocs = {}
  for (const snap of secretSnapshots.docs) {
    if (snap.exists()) {
      secretDocs[snap.id] = snap.data()
    }
  }
  const keysDeletedSnapshots = await getDocs(
    refs.oldKeysDeleteds(assignmentId, sessionId),
  )
  let keysDeletedDocs = {}
  for (const snap of keysDeletedSnapshots.docs) {
    if (snap.exists()) {
      keysDeletedDocs[snap.id] = snap.data()
    }
  }
  const batch = writeBatch(db)
  Object.keys(eventDocs).forEach((eventId) => {
    const eventRef = refs.eventLog(session.id, eventId, false)
    batch.set(eventRef, eventDocs[eventId])
  })
  Object.keys(secretDocs).forEach((secretId) => {
    const secretRef = refs.secret(session.id, secretId)
    batch.set(secretRef, secretDocs[secretId])
  })
  Object.keys(keysDeletedDocs).forEach((keyDeletedId) => {
    const keyDeletedRef = refs.keysDeleted(session.id, keyDeletedId)
    batch.set(keyDeletedRef, keysDeletedDocs[keyDeletedId])
  })
  await batch.commit()
  if (deleteOldMetaInfo) {
    // Only the student-link app should do this.
    await deleteDoc(refs.oldEventLogMetainfo(assignmentId, sessionId))
  }
  return session
}
