import { getEventMetaInfo } from './getEventMetaInfo'
import { getSecrets } from './getSecrets'

export async function loadAllSecrets(sessionId) {
  const secrets = {}

  const meta = await getEventMetaInfo(sessionId)

  let chunkIndex = 0
  while (chunkIndex < meta.chunkCount) {
    const chunkSecrets = await getSecrets(sessionId, chunkIndex)
    Object.assign(secrets, chunkSecrets)
    chunkIndex++
  }
  return secrets
}
