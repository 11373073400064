import { nanoid } from 'nanoid'
import { doUrlsMatch } from './utils/doUrlsMatch'

/**
 * An abstract class for `-Attachment` objects.  AbstractAttachments store metadata about any and all kinds of files or
 * URLs or objects attached to an assignment. AbstractAttachment is the base class for all Attachment objects, and may
 * not be instantiated directly.  Instead, you can use {@link attachmentFromJson} to create an Attachment object from a
 * JSON object.
 *
 */
export class AbstractAttachment {
  /**
   * Constructor for AbstractAttachment.  This may only be called by
   * subclasses of AbstractAttachment through the use of super().
   *
   * @param {string} attachmentType The attachmentType of this attachment.
   * @param {string} [id] The Learnics-based id of this attachment, how we track
   * it in our database to avoid collisions.
   * @param {string} [title] The name of this attachment, in plain text.
   * @param {string} [url] The url of this attachment
   */
  constructor(attachmentType, id = nanoid(), title, url) {
    this.id = id
    this.title = title
    this.attachmentType = attachmentType
    this.url = url
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
      this.url = 'https://' + this.url
    }
    if (this.constructor === AbstractAttachment) {
      throw new Error(
        'AbstractAttachment objects may not be directly instantiated',
      )
    }
  }

  /**
   * @returns {boolean} true if this attachment equals another attachment, false otherwise
   */

  equals(attachment) {
    return (
      attachment?.attachmentType === this.attachmentType &&
      (this.id === attachment.id || this.url === attachment.url)
    )
  }

  /**
   * Generate a simple JSON representation of this object.
   */
  toJson() {
    let url = this.url
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    return {
      id: this.id,
      title: this.title,
      attachmentType: this.attachmentType,
      url: url,
    }
  }

  /**
   * Check if this attachment matches a given url.
   *
   * @param {string} url the url to check against
   * @returns {boolean} true if this attachment matches the url, false otherwise
   */
  matchesUrl(url) {
    return doUrlsMatch(this.url, url, true)
  }

  /**
   * A simple utility function to try and be forgiving to the student in terms
   * of matching their url to the url of an attachment.  Perhaps too much so?
   * More testing is required.
   *
   * @param {string} url1 the first url to check against
   * @param {string} url2 the second url to check against
   * @returns {boolean} true if the urls match, false otherwise
   */

  /** @deprecated prefer {@link doUrlsMatch} */
  static areUrlsMostlyEqual(url1, url2, ignoreHash = false) {
    return doUrlsMatch(url1, url2, ignoreHash)
  }
}
