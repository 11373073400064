import { AbstractEvent } from './AbstractEvent'

/**
 * A class to represent a ScrollEvent for logging sessions.  This type of
 * event is recorded when the user switches tabs in the browser.
 *
 * @example
 * // Concrete initialization and usage
 *
 * // Instantiate the event on the fly, so you can use it to calculate
 * // time and other things
 * const event = new ScrollEvent({
 *   x: 102,
 *   y: 234
 * });
 *
 * // Create a database-friendly version of the event.  This should be a
 * // clone of the JSON object that was given to the constructor, except with
 * // the id and eventType properties both set to "attachments".
 * const savableEventData = event.toJson();
 *
 * @example
 * // Abstract initialization and usage
 * import { eventFromJson } from '@learnics/models/src/Organization/Course/Assignment/Event/utils/eventFromJson'
 *
 * const databaseEvents = [{
 *   id: "some-learnics-generated-id",
 *   eventType: "scroll",
 *   time: 1654887559641,
 *   x: 123,
 *   y: 345
 * }, {
 *     // ... some other event
 * }];
 *
 * // Instantiate an array of serialized AbstractEvent JSON objects (from
 * // the database), in order to restore their functionality.
 * const events = databaseEvents.map(
 *     event => eventFromJson(event)
 *   );
 *
 *
 * // Create a database-friendly version of an array of events.  This
 * // should be a clone of the databaseEvents array.
 * const savableEventsCopy = events.map(
 *     event => event.toJson()
 *   )
 *
 *   @inheritDoc
 */
export class ScrollEvent extends AbstractEvent {
  static COLUMNS = [...AbstractEvent.COLUMNS, 'x', 'y']
  /**
   * Constructor for the ScrollEvent class.  This instantiates the
   * event object.
   *
   *
   * @param {any} input a plain ScrollEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {boolean} [input.implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   * @param {number} [input.x] the x value
   * @param {string} [input.y] the y value
   *
   * @constructor
   */

  constructor({ time, implicit, x, y }) {
    super('scroll', time, false, implicit)
    this.x = x
    this.y = y
  }

  /**
   * Convert this object into a simple Json object.
   *
   * @returns {object} a JSON object representing this object.
   */
  toJson() {
    const json = super.toJson()
    json.x = this.x
    json.y = this.y
    return json
  }
}
