import { Editor, getAttributes } from '@tiptap/core'
import { htmlHasNonWhiteSpace } from '@learnics/models/src/utils/tiptap/htmlHasNonWhiteSpace'
import { enhancePlainHtmlWithCustomExtensions } from '@learnics/models/src/utils/tiptap/enhancePlainHtmlWithCustomExtensions'
import { downgradeCustomExtensionsToPlainHtml } from '@learnics/models/src/utils/tiptap/downgradeCustomExtensionsToPlainHtml'
import { dropPoint } from 'prosemirror-transform'

export class CustomTextEditor {
  hasNonWhiteSpace = false
  editor
  value
  buttonState
  lastModified = -1
  dateCreated = +Date.now()
  tabHandler = (event) => {}
  dropHandler = null
  pasteHandler = null

  lastActiveListType = null
  // An async function that takes a file and schema and optionally returns a node.
  // If it returns a node, the node will be inserted into the editor.
  uploadHandler
  // A simple synchronous function that takes a fileId and returns a BucketFile object.
  resourceRetriever = (resourceType, resourceId) => null
  // A boolean indicating whether images are enabled in the editor.
  filesEnabled = false

  // A set of functions that will be called when an event is emitted from vue
  // components that are used in the editor.
  eventListeners = new Set()

  constructor(id, userFilesBucketName, textValue, extensions) {
    this.id = id
    this.userFilesBucketName = userFilesBucketName
    this.value = textValue
    this.hasNonWhiteSpace = htmlHasNonWhiteSpace(textValue)
    const editorOptions = {
      content: this.value,
      extensions,
      onUpdate: () => {
        const html = this.editor.getHTML()
        if (this.value !== html) {
          this.lastModified = +Date.now()
          this.hasNonWhiteSpace = htmlHasNonWhiteSpace(html)
          this.value = html
          this.eventListeners.forEach((listener) => {
            listener('update-text', { text: html })
          })
        }
      },
      onTransaction: () => {
        this.refreshButtonState()
      },
      editorProps: {
        handleDrop: (view, event, slice, moved) => {
          const dropHandlerResult = !!this.dropHandler?.(
            view,
            event,
            slice,
            moved,
          )
          if (dropHandlerResult) {
            return true
          }
          const learnicsComponent = [...event.dataTransfer.items].some(
            (item) => item.type === 'learnics-drop-data',
          )

          if (learnicsComponent) {
            const data = JSON.parse(
              event.dataTransfer.getData('learnics-drop-data'),
            )
            const coordinates = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            })
            const { state } = view
            let point = dropPoint(state.doc, coordinates.pos, slice)
            void this.onLearnicsItemDrop(view, data, point || 0)
            return true
          }
          if (
            this.filesEnabled &&
            !moved &&
            event.dataTransfer &&
            event.dataTransfer.files &&
            event.dataTransfer.files[0]
          ) {
            const { schema } = view.state
            const coordinates = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            })
            void this.onExternalFilesDrop(
              view,
              event.dataTransfer.files,
              coordinates.pos,
            )

            return true
          }

          return false
        },
        handleKeyDown: (view, event) => {
          // This is just a helper to make it easier to tab out of the editor,
          // using the arrow keys.
          if (event.key === 'ArrowDown') {
            const { state } = view
            const { doc, selection } = state
            const { $from, $to } = selection
            const isAtEnd =
              $to.pos === doc.content.size - 1 && $from.pos === $to.pos
            if (isAtEnd) {
              this.tabHandler(1)
              return true
            }
          } else if (event.key === 'ArrowUp') {
            const { state } = view
            const { selection } = state
            const { $from, $to } = selection
            const isAtStart = $from.pos === 1 && $to.pos === 1
            if (isAtStart) {
              this.tabHandler(-1)
              return true
            }
          }
          return false
        },

        handlePaste: (view, event, slice) => {
          const pasteHandlerResult = !!this.pasteHandler?.(view, event, slice)
          if (pasteHandlerResult) {
            return true
          }
          if (!this.filesEnabled) {
            return false
          }
          const files = Array.from(event.clipboardData?.files || [])

          if (files.length > 0) {
            void this.onExternalFilesDrop(view, files)
            return true
          }

          // For debugging, to see what's in the clipboard:
          // const items = Array.from(event.clipboardData?.items || [])
          // const htmlItems = items.filter((item) => item.type === 'text/html')
          // if (htmlItems.length > 0) {
          //   htmlItems[0].getAsString((html) => {
          //     console.log('Got html from clipboard: ', html)
          //   })
          // }
          return false
        },
        transformPastedHTML: (html) => {
          return enhancePlainHtmlWithCustomExtensions(
            html,
            [...this.editor.options.extensions],
            this.userFilesBucketName,
          )
        },
        clipboardSerializer: {
          serializeFragment: (fragment, options, target) => {
            return downgradeCustomExtensionsToPlainHtml(
              this.editor.schema,
              fragment,
              options,
              target,
              this.resourceRetriever,
            )
          },
        },
      },
    }
    this.editor = new Editor(editorOptions)
    this.editor.__learnicsId = id
    this.buttonState = this.calculateButtonState()
  }

  async onExternalFilesDrop(view, files, insertionPosition) {
    const schema = view && view.state && view.state.schema
    if (!this.uploadHandler) {
      throw new Error('No upload handler set')
    }
    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      try {
        const nodeJson = await this.uploadHandler(file)
        if (nodeJson) {
          let transaction
          const node = schema.nodes[nodeJson.type].create(nodeJson.attrs)
          if (!insertionPosition) {
            transaction = view.state.tr.replaceSelectionWith(node)
          } else {
            transaction = view.state.tr.insert(insertionPosition, node)
          }
          view.dispatch(transaction)
        }
      } catch (error) {
        // Swallow it, the upload handler should show an error message
        console.error('Error handling dropped file: ', error)
        console.warn('Ignoring the error and moving on to the next file')
      }
    }
  }

  async onLearnicsItemDrop(view, nodeJson, insertionPosition) {
    // Note: *Some* kind of timeout is required here because of a collision with
    // sortable.js libraries and tiptap libraries.  The timeout allows time for
    // the sortable.js library to finish its work before the tiptap library
    // tries to insert a new node into the editor.
    await new Promise((resolve) => setTimeout(resolve, 10))
    const schema = view && view.state && view.state.schema
    try {
      let transaction
      const node = schema.nodes[nodeJson.type].create(nodeJson.attrs)
      transaction = view.state.tr.insert(insertionPosition, node)
      view.dispatch(transaction)
    } catch (error) {
      // Swallow it, the upload handler should show an error message
      console.error('Error handling dropped file: ', error)
      console.warn('Ignoring the error and moving on to the next file')
    }
  }

  addEventListener(listener) {
    this.eventListeners.add(listener)
    return () => {
      if (this.eventListeners.has(listener)) {
        this.eventListeners.delete(listener)
      }
    }
  }

  setContent(value) {
    this.editor.commands.setContent(value, false)
  }

  refreshButtonState() {
    const newState = this.calculateButtonState()
    let shouldUpdate = !this.buttonState
    if (!this.buttonState) {
      shouldUpdate = true
    }
    const buttonIds = Object.keys(newState)
    for (let i = 0; !shouldUpdate && i < buttonIds.length; i++) {
      const buttonId = buttonIds[i]
      const newButton = newState[buttonId]
      const oldButton = this.buttonState[buttonId]
      const newKeys = Object.keys(newButton).sort()
      const oldKeys = Object.keys(oldButton).sort()
      if (newKeys.length !== oldKeys.length) {
        shouldUpdate = true
      }
      for (let j = 0; !shouldUpdate && j < newKeys.length; j++) {
        if (
          newKeys[j] !== oldKeys[j] ||
          newButton[newKeys[j]] !== oldButton[oldKeys[j]]
        ) {
          shouldUpdate = true
        }
      }
    }

    if (newState.bulletList.active) {
      this.lastActiveListType = 'bulletList'
      shouldUpdate = true
    } else if (newState.orderedList.active) {
      this.lastActiveListType = 'orderedList'
      shouldUpdate = true
    }

    if (shouldUpdate) {
      this.buttonState = newState
      return true
    } else {
      return false
    }
  }

  calculateButtonState() {
    let newState = {
      undo: {},
      redo: {},
      bold: {},
      underline: {},
      italic: {},
      strike: {},
      bulletList: {},
      orderedList: {},
      highlight: {},
    }
    let canUndo = !!this.editor.can().undo?.()
    let canRedo = !!this.editor.can().redo?.()
    newState.undo.disabled = !canUndo
    newState.redo.disabled = !canRedo

    const regularButtons = [
      'bold',
      'underline',
      'italic',
      'strike',
      'bulletList',
      'orderedList',
      'highlight',
    ]
    for (let i = 0; i < regularButtons.length; i++) {
      const button = regularButtons[i]

      newState[button].active = !!this.editor.isActive(button)
    }
    if (newState['highlight'].active) {
      const highlightAttributes = getAttributes(this.editor.state, 'highlight')
      newState['highlight'].color = highlightAttributes.color
    }
    return newState
  }

  insertContent(content) {
    this.editor.chain().focus().insertContent(content).run()
  }

  focus() {
    this.editor.commands.focus()
  }
  focusEnd() {
    this.editor.commands.focus('end')
  }
  toggle(buttonId, data) {
    if (buttonId === 'redo' || buttonId === 'undo') {
      this.editor.commands[buttonId]()
    } else {
      const capitalized = buttonId.charAt(0).toUpperCase() + buttonId.slice(1)
      this.editor.chain().focus()['toggle' + capitalized](data).run()
    }
  }
  destroy() {
    this.editor.destroy()
  }

  // Emit an event upward to whoever is using this editor (ultimately
  // using vue's $emit).  This can be used by custom extensions to emit
  // events that will eventually make it up to the parent component of
  // the editor.
  emitEvent(eventName, eventData) {
    this.eventListeners.forEach((listener) => {
      listener(eventName, eventData)
    })
  }

  updateUser(user) {
    this.editor.commands.updateUser(user)
  }
}
