import { isColorLight } from '../utils/isColorLight'
import { nanoid } from 'nanoid'

/**
 * Tag is a class for defining a generic tag, which is a colored label.  While
 * a tag also has an ID, this ID is not used for comparison purposes, and is
 * only used to uniquely identify a tag within a note for a better UX.  It
 * should not be used to identify a tag across notes.  Tags are defined by a
 * label and a color, not an ID.  If you need to compare tags, you should
 * compare their label and color.  Nor do you need to worry about assigning
 * the ID of a tag, as it will be automatically assigned if it is not already.
 */
export class Tag {
  static DEFAULT_COLOR = '#ff4949'
  static COLORS = [
    '#ff4949',
    '#ffcd49',
    '#49ff6c',
    '#49d9ff',
    '#4982ff',
    '#7a49ff',
    '#ff49a8',
  ]
  constructor(yMap) {
    if (!yMap) {
      throw new Error('yMap is required')
    }
    this.yMap = yMap
    this.initializeYMap()
  }

  initializeYMap() {
    if (!this.yMap.has('id')) {
      this.yMap.set('id', nanoid())
    }
    if (!this.yMap.has('label')) {
      this.yMap.set('label', '')
    }
    if (!this.yMap.has('color')) {
      this.yMap.set('color', Tag.DEFAULT_COLOR)
    }
  }

  get id() {
    return this.yMap.get('id')
  }

  get label() {
    return (this.yMap.get('label') || '').trim()
  }

  get color() {
    return (this.yMap.get('color') || Tag.DEFAULT_COLOR).trim()
  }

  update(newColor = null, newLabel = null) {
    let changed = false
    if (newColor !== null && newColor.trim() !== this.color) {
      this.yMap.set('color', newColor.trim())
      changed = true
    }
    if (newLabel !== null && newLabel.trim() !== this.label) {
      this.yMap.set('label', newLabel.trim())
      changed = true
    }
    return changed
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {{id: string, label: string, color: string}}
   */
  toJson() {
    return {
      label: this.label,
      color: this.color,
    }
  }

  isColorLight() {
    return isColorLight(this.color || Tag.DEFAULT_COLOR)
  }

  equals(other) {
    return (
      this.label === other?.label?.trim() &&
      (this.color || Tag.DEFAULT_COLOR) === (other?.color || Tag.DEFAULT_COLOR)
    )
  }

  toString() {
    return this.color + '\t' + this.label
  }
}
