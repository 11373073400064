import { AttachmentsExpectation } from '../AttachmentsExpectation'
import { ExternalUrlsExpectation } from '../ExternalUrlsExpectation'
import { EditingGoogleDocsExpectation } from '../EditingGoogleDocsExpectation'

/**
 * A mapping of AbstractExpectation types to their corresponding classes.
 */
export const expectationTypeClassMap = {
  attachments: AttachmentsExpectation,
  externalUrls: ExternalUrlsExpectation,
  editingGoogleDocs: EditingGoogleDocsExpectation,
}
