import Vue from 'vue'
import { getBucketFile } from '../../bucketFile/getBucketFile'
import { getDownloadUrl } from '../../cloudStorage/getDownloadUrl'

export const state = {
  userFilesBucketName: null,
  bucketFiles: {},
  bucketFileBlobUrls: {},
  uploadedBucketFileIds: new Set(), // A set of IDs of files that have been uploaded in this particular session.
}

export const getters = {
  bucketFiles: (state) => state.bucketFiles,
  bucketFileBlobUrls: (state) => state.bucketFileBlobUrls,
  userFilesBucketName: (state) => state.userFilesBucketName,
  uploadedBucketFileIds: (state) => state.uploadedBucketFileIds,
}

export const mutations = {
  addUploadedBucketFileId: (state, fileId) => {
    const newSet = new Set(state.uploadedBucketFileIds)
    newSet.add(fileId)
    Vue.set(state, 'uploadedBucketFileIds', newSet)
  },
  setBucketFile: (state, { compositeId, newVal, oldVal }) => {
    const { id } = compositeId
    if (newVal) {
      Vue.set(state.bucketFiles, id, newVal)
    } else if (state.bucketFiles[id]) {
      Vue.delete(state.bucketFiles, id)
    }
  },
  setBucketFileBlobUrl: (state, { fileId, signedUrl, blobUrl }) => {
    if (!state.bucketFileBlobUrls[fileId]) {
      Vue.set(state.bucketFileBlobUrls, fileId, {})
    }
    Vue.set(state.bucketFileBlobUrls[fileId], signedUrl, blobUrl)
  },
  setUserFilesBucketName: (state, newVal) => {
    state.userFilesBucketName = newVal
  },
  setUploadingBucketFile: (state, { fileId, newVal }) => {
    Vue.set(state.uploadingBucketFiles, fileId, newVal)
  },
}

export const actions = {
  async loadBucketFileBlobUrl({ commit, state }, bucketFile) {
    const { id, signedUrl, type } = bucketFile
    if (state.bucketFileBlobUrls[id]?.[signedUrl]) {
      return state.bucketFileBlobUrls[id][signedUrl]
    }
    if (!signedUrl) throw new Error('No signedUrl')
    let url
    if (bucketFile.type.startsWith('image')) {
      const blob = await fetch(bucketFile.signedUrl).then((response) =>
        response.blob(),
      )
      url = URL.createObjectURL(blob)
    } else {
      url = bucketFile.signedUrl
    }
    commit('setBucketFileBlobUrl', {
      fileId: id,
      signedUrl,
      blobUrl: url,
    })
  },

  /**
   * Load one or more bucket files into the store.
   *
   * @param state
   * @param assignment
   */
  async loadBucketFiles(
    { state, dispatch, commit, rootState },
    args = { forced: false },
  ) {
    const ids = args.ids || []

    for (const id of ids) {
      await dispatch('loadBucketFile', { id, forced: args.forced })
      // If we have the NoteBoard already, don't load it again
    }
  },

  /**
   * Load a Bucket File into the store.
   *
   * @param state
   * @param assignment
   */

  async loadBucketFile(
    { state, dispatch, commit, rootState },
    args = { forced: false },
  ) {
    const { id } = args
    const bucketFile = state.bucketFiles[id]
    if (!bucketFile || args.forced) {
      const bucketFile = await getBucketFile(id)
      if (bucketFile) {
        commit('setBucketFile', {
          compositeId: { id },
          newVal: bucketFile,
        })
      }
    }
    await dispatch('refreshBucketFileSignedUrl', {
      id,
    })
  },

  async refreshBucketFileSignedUrl({ state, commit, rootState }, args) {
    const bucketName = state.userFilesBucketName
    if (!bucketName) {
      throw new Error(
        'No userFilesBucketName set.  This should be set at the start of the app.',
      )
    }
    const { id } = args
    const bucketFile = state.bucketFiles[id]
    if (
      bucketFile?.dateUploaded &&
      (!bucketFile.signedUrl ||
        bucketFile.signedUrlDate < Date.now() - 1000 * 60 * 60)
    ) {
      const fileId = bucketFile.id
      try {
        bucketFile.signedUrl = await getDownloadUrl(bucketName, fileId)
        bucketFile.signedUrlDate = +new Date()
      } catch (error) {
        console.error('Failed to refresh bucket file signed url:', error)
      }
    }
  },
}
