import { TabFocusEvent } from '../TabFocusEvent'
import { WindowBlurEvent } from '../WindowBlurEvent'
import { TabChangeEvent } from '../TabChangeEvent'
import { IdleStateChangeEvent } from '../IdleStateChangeEvent'
import { RunningCalculator } from './RunningCalculator'
import { AbstractEvent } from '../AbstractEvent'

function getDefaultState() {
  return {
    focusedTabId: null,
    time: +new Date(),
    tabs: {},
    idle: 'active',
    logging: false,
  }
}

/**
 * A RunningCalculator class to represent the state of the student's browser
 * while iterating through their event logs.  This class is integral to all
 * running calculators, as it holds the last time value seen and tracks the
 * current tab and idle state.
 *
 * NOTE: The tabs map here should be seen as only for use by the browserRc
 * calculator, and other calculators should not rely on it to signify anything
 * other than an internal cache to store metadata for the focused tab id.
 */

export class BrowserRc extends RunningCalculator {
  /**
   * Construct a new BrowserRc object.
   *
   * @param input {object} the data to initialize the browserRc with
   * @param [input.time] {number} the current time in the event log
   * @param [input.focusedTabId] {string} the id of the currently focused tab
   * @param [input.tabs] {object} an object mapping tab ids to metadata objects with the current url and title of the tab
   * @param [input.idle] {string} the idle state of the browser - either `"active"`, `"locked"`, or `"idle"`
   * @param [input.logging] {boolean} whether the student is logging (almost always true)
   *
   */
  constructor(input = getDefaultState()) {
    super('browserRc', [])
    const { time, focusedTabId, tabs, idle, logging } = input
    this.time = time || +new Date()
    this.focusedTabId = focusedTabId || null
    this.tabs = tabs || {}
    this.idle = idle || 'active'
    this.logging = !!logging
  }

  toJson() {
    return {
      ...super.toJson(),
      time: this.time,
      focusedTabId: this.focusedTabId,
      tabs: JSON.parse(JSON.stringify(this.tabs)),
      idle: this.idle,
      logging: !!this.logging,
    }
  }

  /**
   * Convert this BrowserState object into an array of implicit events.
   *
   * @returns {AbstractEvent[]} a set of implicit events that represent the state of the browser
   */
  toEvents() {
    const result = []

    for (const tabId in this.tabs) {
      const tab = this.tabs[tabId]
      result.push(
        new TabChangeEvent({
          tabId: tabId,
          time: this.time,
          ...tab,
        }),
      )
    }
    if (this.focusedTabId) {
      result.push(
        new TabFocusEvent({
          tabId: this.focusedTabId,
          time: this.time,
          ...this.tabs[this.focusedTabId],
        }),
      )
    } else {
      result.push(
        new WindowBlurEvent({
          tabId: null,
          time: this.time,
        }),
      )
    }

    result.push(
      new IdleStateChangeEvent({
        time: this.time,
        value: this.idle,
      }),
    )

    result.forEach((event) => (event.implicit = true))

    return result
  }

  transition(event, {}) {
    let stateChanged = !!this.visit[event.eventType]?.call(this, event)
    if (!this.visit[event.eventType])
      console.warn(
        'No transition for event type',
        event.eventType,
        'in BrowserState...',
      )
    stateChanged ||= this.time !== event.time
    this.time = event.time

    return stateChanged
  }

  get active() {
    return this.idle === 'active'
  }

  get focused() {
    return !!this.focusedTabId
  }

  get focusedTab() {
    return this.focusedTabId && this.tabs[this.focusedTabId]
  }
  visit = {
    empty(event) {},
    assignmentSubmit(event) {},
    metadata(event) {},
    paste(event) {},
    idleStateChange(event) {
      if (this.idle !== event.value) {
        this.idle = event.value
        return true
      }
    },
    loggingStart(event) {
      const defaultState = getDefaultState()
      const changed =
        this.logging !== true ||
        this.focusedTabId !== defaultState.focusedTabId ||
        JSON.stringify(this.tabs) !== '{}' ||
        this.idle !== defaultState.idle
      if (!changed) return false
      this.logging = true
      this.focusedTabId = defaultState.focusedTabId
      this.tabs = defaultState.tabs
      this.idle = defaultState.idle
      return true
    },

    loggingStop(event) {
      if (this.logging !== false) {
        this.logging = false
        return true
      }
    },
    mouseMove(event) {},

    scroll(event) {},

    tabChange(event) {
      this.tabs[event.tabId] ||= {}
      const tab = this.tabs[event.tabId]
      let stateChanged = false
      if (typeof event.url !== 'undefined') {
        stateChanged ||= tab.url !== event.url
        tab.url = event.url
      }
      if (typeof event.title !== 'undefined') {
        stateChanged ||= tab.title !== event.title
        tab.title = event.title
      }
      return stateChanged
    },

    tabFocus(event) {
      let stateChanged = this.visit.tabChange.call(this, event)
      stateChanged ||= this.focusedTabId !== event.tabId
      this.focusedTabId = event.tabId
      return stateChanged
    },

    windowBlur(event) {
      if (this.focusedTabId !== null) {
        this.focusedTabId = null
        return true
      }
    },
  }
}
