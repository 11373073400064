import { SessionTag } from './SessionTag'

/**
 * @deprecated prefer `LoggerSession` when it is available
 */
export class OldLoggerSession {
  constructor(
    id,
    type,
    name,
    researchNotebookId,
    dateCreated = +new Date(),
    tags = {},
  ) {
    this.id = id
    this.type = type
    this.name = name || ''
    this.researchNotebookId = researchNotebookId // Added for compatibility with LoggerSession
    this.dateCreated = dateCreated
    this.tags = {}
    Object.keys(tags || {}).forEach((id) => {
      const tag = tags[id]
      if (tag) {
        this.tags[id] = new SessionTag(tag.id, tag.label, tag.color)
      }
    })
    this.version = null // This is for some small compatibility with LoggerSession
  }

  toJson() {
    const result = {
      name: this.name || '',
      id: this.id,
      type: this.type,
      dateCreated: this.dateCreated,
      tags: Object.keys(this.tags).reduce((acc, key) => {
        acc[key] = this.tags[key].toJson()
        return acc
      }, {}),
      version: null,
      researchNotebookId: this.researchNotebookId || null,
    }
    return result
  }

  compareVersion(version) {
    if (version === null) {
      return 0
    }
    return -1
  }
  isAtLeastVersion(version) {
    return version === null
  }
  isLessThanVersion(version) {
    return version !== null
  }
  isVersion(version) {
    return version === null
  }
}
