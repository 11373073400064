import { getDocs } from 'firebase/firestore/lite'
import { User } from '@learnics/models/src/User/User'
import refs from '../refsLite'

export async function getUsersIndex() {
  const userIndexChunks = await getDocs(refs.usersIndexChunks())
  const users = {}
  for (const chunk of userIndexChunks.docs) {
    const chunkData = chunk.data()
    if (chunkData && chunkData.users) {
      for (const user of chunkData.users) {
        users[user.uid] = { ...user, id: user.uid }
      }
    }
  }

  return Object.keys(users).map((uid) => new User(users[uid]))
}
