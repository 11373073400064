import { Timeline } from './utils/Timeline'
import { RunningCalculator } from './RunningCalculator'
import { createAttachmentFilter } from './utils/createAttachmentFilter'

/**
 * AttachmentsRc stores timelines for a list of attachments for an
 * assignment.  We use this to track both the overall time spent on each
 * attachment, along with providing 2-dimensional timelines for each attachment.
 */
export class AttachmentsRc extends RunningCalculator {
  dataMap = {}

  constructor(storageObj) {
    super('attachmentsRc', ['browserRc'])
    const dataMap = storageObj?.dataMap || {}

    Object.keys(dataMap).forEach((attachmentId) => {
      this.dataMap[attachmentId] = {
        timeline: new Timeline(dataMap[attachmentId].timeline),
        urls: [...dataMap[attachmentId].urls],
      }
    })
  }

  toJson() {
    return {
      ...super.toJson(),
      ...Object.keys(this.dataMap).reduce(
        (acc, key) => {
          acc.dataMap[key] = {
            timeline: this.dataMap[key].timeline.toJson(),
            urls: [...this.dataMap[key].urls],
          }
          return acc
        },
        { dataMap: {} },
      ),
    }
  }

  transition(event, stateData) {
    const { assignmentDetail, browserRc, timeRange } = stateData
    let stateChanged = false
    if (event.time < timeRange.start || event.time > timeRange.stop)
      return stateChanged
    const browserTime = Math.max(timeRange.start, stateData.browserRc.time)
    const eventTime = Math.min(timeRange.stop, event.time)
    if (eventTime - browserTime < 0) return stateChanged
    for (const attachment of assignmentDetail.attachments) {
      this.dataMap[attachment.id] ||= {
        timeline: new Timeline(),
        urls: [],
      }
      if (createAttachmentFilter(attachment.id)(stateData)) {
        let { url } = browserRc.focusedTab
        const urls = this.dataMap[attachment.id].urls
        if (urls.indexOf(url) === -1) {
          urls.splice(0, 0, url)
          stateChanged = true
        }

        try {
          this.dataMap[attachment.id].timeline.increment(browserTime, eventTime)
          stateChanged = true
        } catch (e) {
          console.error(
            'Error incrementing attachment timeline for attachment',
            attachment,
            'with event',
            event,
            'and stateData',
            stateData,
            e,
          )
        }
      }
    }
    return stateChanged
  }
}
