import { nanoid } from 'nanoid'
import { researchSourceFromYMap } from './researchSourceFromYMap'
import { yjsInstance } from '../utils/yjsInstance'

/**
 * ResearchNotebook is a collaborative data structure for storing research sources.
 */
export class ResearchNotebook {
  constructor({
    id = nanoid(32),
    sessionId,
    dateUpdated = +new Date(),
    content,
  }) {
    const Y = yjsInstance.Y
    this.sessionId = sessionId || null
    this.id = id
    this.yDoc = new Y.Doc()
    this.dateUpdated = dateUpdated || +new Date()
    if (content) {
      Y.applyUpdate(this.yDoc, Uint8Array.from(content))
    }
    // Ensure that the notebook has all the necessary fields

    this.initializeYDoc()
  }

  get sourceIds() {
    return Array.from(this.yDoc.getMap('sources').keys())
  }
  get sources() {
    const result = {}
    const sourcesMap = this.yDoc.getMap('sources')
    for (const sourceId of this.sourceIds) {
      result[sourceId] = researchSourceFromYMap(sourcesMap.get(sourceId))
    }
    return result
  }

  get creatingNoteIds() {
    return { ...this.yDoc.getMap('creatingNoteIds').toJSON() }
  }

  creatingNoteId(noteId) {
    this.yDoc.getMap('creatingNoteIds').set(noteId, +new Date())
  }

  createdNoteId(noteId) {
    this.yDoc.getMap('creatingNoteIds').delete(noteId)
  }

  isCreatingNoteId(noteId) {
    return !!this.creatingNoteIds[noteId]
  }

  toJson() {
    const Y = yjsInstance.Y
    return {
      id: this.id,
      sessionId: this.sessionId || null,
      content: Array.from(Y.encodeStateAsUpdate(this.yDoc)),
      dateUpdated: this.dateUpdated || +new Date(),
    }
  }

  initializeYDoc() {
    // Ensure that the notebook has all the necessary fields
    this.yDoc.getMap('sources')
    this.yDoc.getMap('creatingNoteIds')
  }

  toAnnotationsMap() {
    const result = {}
    const sources = this.sources
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      if (source.annotationNoteId) {
        //TODO: NOTE THIS IS ASSUMING ALL SOURCES ARE WEBSITE SOURCES
        // If we add other types of sources, we will need to handle this
        // differently, by using the ID of the source as the key
        // instead of the URL, and then adjusting the code that uses this
        // to look up the URL from the source ID
        result[source.url] = source.annotationNoteId
      }
    }
    return result
  }

  getSourcesByUrl(url) {
    const result = []
    const sources = this.sources
    const sourceIds = this.sourceIds
    for (const sourceId of sourceIds) {
      const source = sources[sourceId]
      if (source.url === url) {
        result.push(source)
      }
    }
    return result
  }

  getSourcesByNoteId(noteId) {
    const sources = this.sources
    const sourceIds = this.sourceIds
    const result = []
    for (const sourceId of sourceIds) {
      const source = sources[sourceId]
      if (source.noteIds.includes(noteId)) {
        result.push(source)
      }
    }
    return result
  }

  deleteTag(color, label, notesMap = {}) {
    let changed = false
    const sources = this.sources
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      const changedSource = source.deleteTag(color, label, notesMap)
      if (changedSource) {
        changed = true
      }
    }
    return changed
  }

  getNoteIds() {
    const sources = this.sources
    let result = new Set()
    this.sourceIds.forEach((sourceId) => {
      const source = sources[sourceId]
      const noteIds = source.noteIds
      noteIds.forEach((noteId) => {
        result.add(noteId)
      })
    })
    return result
  }

  updateTag(
    oldColor,
    oldLabel,
    newColor = null,
    newLabel = null,
    notesMap = {},
  ) {
    let changed = false
    const sources = this.sources
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      const changedSource = source.updateTag(
        oldColor,
        oldLabel,
        newColor,
        newLabel,
        notesMap,
      )
      if (changedSource) {
        changed = true
      }
    }
    return changed
  }

  updateTagGlobally(
    resourceType,
    resourceId,
    index,
    newColor = null,
    newLabel = null,
    notesMap = {},
  ) {
    let changed = false

    let originalTag
    let resource
    if (resourceType === 'note') {
      resource = notesMap[resourceId]
    } else if (resourceType === 'researchSource') {
      resource = this.sources[resourceId]
    } else {
      throw new Error('Unknown resource type: ' + resourceType)
    }

    if (!resource) {
      throw new Error(resourceType + ' #' + resourceId + ' not found')
    }
    originalTag = resource.tags[index]
    if (!originalTag) {
      throw new Error(
        'Tag at index ' +
          index +
          ' not found in ' +
          resourceType +
          ' #' +
          resourceId,
      )
    }
    const oldColor = originalTag.color
    const oldLabel = originalTag.label
    const changedResource = resource.updateTagGloballyPreservingIndex(
      index,
      newColor,
      newLabel,
    )
    if (changedResource) {
      changed = true
    }

    const sources = this.sources
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      const changedSource = source.updateTag(
        oldColor,
        oldLabel,
        newColor,
        newLabel,
        notesMap,
      )
      if (changedSource) {
        changed = true
      }
    }
    return changed
  }

  createTagFrequencyMap(notesMap = {}, result = {}) {
    const sources = this.sources
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      source.createTagFrequencyMap(notesMap, result)
    }

    return result
  }

  getAllTags(notesMap = {}) {
    const tagFrequencyMap = this.createTagFrequencyMap(notesMap)
    const colors = Object.keys(tagFrequencyMap)
    const result = []
    for (const color of colors) {
      const labels = Object.keys(tagFrequencyMap[color])
      for (const label of labels) {
        result.push({ color, label })
      }
    }
    return result
  }

  hasAnyNotes() {
    const sources = this.sources
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      if (source.hasAnyNotes()) {
        return true
      }
    }
    return false
  }
  hasAnyTags(notesMap = {}) {
    const sources = this.sources
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      if (source.hasAnyTags(notesMap)) {
        return true
      }
    }
    return false
  }
  hasMultipleTags(notesMap = {}) {
    const sources = this.sources
    let count = 0
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      count += source.countTags(notesMap)
      if (count > 1) {
        return true
      }
    }
    return false
  }

  countTags(notesMap = {}) {
    const sources = this.sources
    let count = 0
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      count += source.countTags(notesMap)
    }
    return count
  }

  countNotes() {
    const sources = this.sources
    let count = 0
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      count += source.noteIds.length
    }
    return count
  }

  deleteSource(sourceId) {
    this.yDoc.getMap('sources').delete(sourceId)
  }

  addSource(source) {
    this.yDoc.getMap('sources').set(source.id, source.yMap)
  }

  deleteSources(sourceIds) {
    this.yDoc.transact(() => {
      for (const sourceId of sourceIds) {
        this.deleteSource(sourceId)
      }
    })
  }

  static shallowCopy(researchNotebook) {
    const newNotebook = new ResearchNotebook({
      id: researchNotebook.id,
      dateUpdated: researchNotebook.dateUpdated,
      sessionId: researchNotebook.sessionId,
    })
    newNotebook.yDoc = researchNotebook.yDoc
    newNotebook.initializeYDoc() // Just in case
    return newNotebook
  }

  deleteNoteId(noteId) {
    let changed = false
    const sources = this.sources
    for (const sourceId of this.sourceIds) {
      const source = sources[sourceId]
      const innerChanged = source.deleteNoteId(noteId)
      if (innerChanged) {
        changed = true
      }
    }
    return changed
  }
}
