import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from '@tiptap/pm/view'

// This is a custom extension to apply an `in-text-selection="true"` attribute
// to non-text nodes that are within the current text selection. This is useful
// for styling non-text nodes that are within a text selection, such as images
// or embeds.  This might not be the best way to do this, but it does seem to
// work, allowing our custom elements to be styled appropriately when they are
// within a text selection.
export const InTextSelection = Extension.create({
  name: 'in-text-selection',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('in-text-selection'),
        props: {
          decorations: ({ doc, selection }) => {
            const { anchor, head } = selection
            const decorations = []

            const left = Math.min(anchor, head)
            const right = Math.max(anchor, head)

            doc.descendants((node, pos) => {
              if (node.isText) {
                return
              }

              const isCurrent =
                anchor !== head && pos >= left && pos + node.nodeSize <= right

              if (isCurrent) {
                decorations.push(
                  Decoration.node(pos, pos + node.nodeSize, {
                    'in-text-selection': true,
                  }),
                )
              }
            })
            return DecorationSet.create(doc, decorations)
          },
        },
      }),
    ]
  },
})
