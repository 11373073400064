import { AbstractEvent } from './AbstractEvent'

/**
 * A class to represent an empty browser event.  This is used to represent a time period where no events were recorded.
 *
 * This event should not factor into any calculations except to move the time forward for the current state.  It should
 * not be saved to the database, either.  It can be used in calculations, though.
 */
export class EmptyEvent extends AbstractEvent {
  static COLUMNS = [...AbstractEvent.COLUMNS]
  /**
   * Constructor for the EmptyEvent class.  This instantiates the
   * event object.
   *
   *
   * @param {any} input a plain EmptyEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   *
   * @constructor
   */

  constructor({ time }) {
    super('empty', time, false, true)
  }
}
