import { AbstractExpectation } from './AbstractExpectation'

/**
 * A class to represent an ExternalUrlsExpectation for an assignment.  This type of expectation requires that the student spend time on external websites.  External websites include all URLs not attached to the assignment.  In other words, all the URLs that don't match with any of the assignment's attachments.
 *
 * @example
 * // Concrete initialization and usage
 * const concreteExpectationData = {
 *   id: "externalUrls",
 *   ratio: 0.1,
 *   min: 2,
 *   max: 10,
 * }
 *
 * // Instantiate the expectation on the fly, so you can use it to calculate time
 * // and other things
 * const expectation = new ExternalUrlsExpectation(concreteExpectationData);
 *
 * // Create a database-friendly version of the expectation.  This should be a
 * // clone of the concreteExpectationData, except with the expectationType
 * // property set to "externalUrls".
 * const savableExpectationData = expectation.toJson();
 *
 *
 * @example
 * // Abstract initialization and usage
 *
 * const databaseExpectations = [{
 *   id: "externalUrls",
 *   type: "externalUrls",
 *   ratio: 0.2,
 *   min: 2,
 *   max: 10,
 * }, {
 *     // ... some other expectation
 * }];
 *
 * // Instantiate an array of serialized AbstractExpectation JSON objects (from
 * // the database), in order to restore their functionality.
 * const expectations = databaseExpectations.map(
 *     expectation => expectationFromJson(expectation)
 *   );
 *
 * // Create a database-friendly version of an array of expectations.  This
 * // should be a clone of the databaseExpectations array.
 * const savableExpectationsCopy = expectations.map(
 *     expectation => expectation.toJson()
 *   );
 *
 * @inheritDoc
 */
export class ExternalUrlsExpectation extends AbstractExpectation {
  /**
   * Constructor for the ExternalUrlsExpectation class.
   *
   * @param {any} input a plain ExternalUrlsExpectation JSON object
   * @param {number} input.ratio the ratio of total time expected for this
   * expectation.
   * @param {number} input.min The minimum number of external website urls
   * required to meet this expectation
   * @param {number} input.max The maximum number of external website urls
   * required to meet this expectation
   * @constructor
   */
  constructor({ ratio, min, max }) {
    super('externalUrls', 'externalUrls', ratio)
    this.min = min
    this.max = max
  }

  toJson() {
    const json = super.toJson()
    json.min = this.min
    json.max = this.max
    return json
  }

  updateFrom(other) {
    super.updateFrom(other)
    this.min = other.min
    this.max = other.max
  }

  get label() {
    let prefix = ''
    let suffix = ''
    if (this.min) {
      prefix += `${this.min} `
      suffix = this.min > 1 ? 's' : ''
      if (this.max > this.min) {
        prefix += ` to ${this.max} `
        suffix = 's'
      }
    } else if (this.max) {
      prefix += `${this.max} `
      suffix = this.max > 1 ? 's' : ''
    } else {
      suffix += 's'
    }
    return prefix + 'External URL' + suffix
  }
}
