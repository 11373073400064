import { AbstractBrowserTabEvent } from './AbstractBrowserTabEvent'

/**
 * A class to represent a TabChangeEvent for logging sessions.  This type of
 * event is recorded when the user switches tabs in the browser.
 *
 * @example
 * // Concrete initialization and usage
 *
 * // Instantiate the event on the fly, so you can use it to calculate
 * // time and other things
 * const event = new TabChangeEvent({
 *   url: "google.com"
 * });
 *
 * // Create a database-friendly version of the event.  This should be a
 * // clone of the JSON object that was given to the constructor, except with
 * // the id and eventType properties both set to "attachments".
 * const savableEventData = event.toJson();
 *
 * @example
 * // Abstract initialization and usage
 * import { eventFromJson } from '@learnics/models/src/Organization/Course/Assignment/Event/utils/eventFromJson'
 *
 * const databaseEvents = [{
 *   id: "some-learnics-generated-id",
 *   eventType: "tabChange",
 *   time: 1654887559641,
 * }, {
 *     // ... some other event
 * }];
 *
 * // Instantiate an array of serialized AbstractEvent JSON objects (from
 * // the database), in order to restore their functionality.
 * const events = databaseEvents.map(
 *     event => eventFromJson(event)
 *   );
 *
 *
 * // Create a database-friendly version of an array of events.  This
 * // should be a clone of the databaseEvents array.
 * const savableEventsCopy = events.map(
 *     event => event.toJson()
 *   )
 *
 *   @inheritDoc
 */
export class TabChangeEvent extends AbstractBrowserTabEvent {
  static COLUMNS = [...AbstractBrowserTabEvent.COLUMNS, 'title', 'url']
  /**
   * Constructor for the TabChangeEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain TabChangeEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {number} [input.tabId] the associated chrome-based tab id
   * @param {boolean} [input.redacted] whether or not this event contains redacted data.
   * @param {boolean} [input.implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   * @param {string} [input.url] the new url of the tab.  This may be null if it's not needed.
   * @param {string} [input.title] the new title of the tab  This may be null if it's not needed.
   *
   * @constructor
   */

  constructor({ time, redacted, implicit, tabId, url, title }) {
    super('tabChange', time, redacted, implicit, tabId)
    if (url) {
      this.url = url
    }
    if (title) {
      this.title = title
    }
  }

  toJson() {
    const result = super.toJson()
    if (this.title) {
      result.title = this.title
    }
    if (this.url) {
      result.url = this.url
    }
    return result
  }

  redact(hashFunction) {
    const result = super.redact(hashFunction)
    if (this.redacted) return result

    if (this.url) {
      const redactedUrl = hashFunction(this.url)
      result[redactedUrl] = this.url
      this.url = redactedUrl
    }
    if (this.title) {
      const redactedTitle = hashFunction(this.title)
      result[redactedTitle] = this.title
      this.title = redactedTitle
    }
    this.redacted = true

    return result
  }

  reveal(secrets) {
    if (!this.redacted) return
    if (!secrets)
      throw new Error('Cannot reveal secrets without a secrets object')

    const urlRevealed = !this.url || this.url in secrets
    const titleRevealed = !this.title || this.title in secrets

    if (urlRevealed && titleRevealed) {
      // Redaction is all or nothing.  If only a title or only a url is provided, we do not redact anything,
      // even if it's valid.
      this.url = this.url ? secrets[this.url] : null
      this.title = this.title ? secrets[this.title] : null
      this.redacted = false
    }
  }

  redactedValues() {
    if (!this.redacted) {
      return []
    }
    const values = []
    if (this.url) {
      values.push(this.url)
    }
    if (this.title) {
      values.push(this.title)
    }
    return values
  }
}
