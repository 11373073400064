import { activeTipTapExtensions } from "@learnics/models/src/utils/tiptap/activeTipTapExtensions";
import { InTextSelection } from "@learnics/models/src/utils/tiptap/InTextSelectionExtension";
import { BucketFile } from "@learnics/services/src/vue/components/RichTextEditor/customExtensions/BucketFileExtension";
import { Image } from "@tiptap/extension-image";
import { PastedImage } from "@learnics/services/src/vue/components/RichTextEditor/customExtensions/PastedImageExtension";
import { CustomDropCursor } from "@learnics/models/src/utils/tiptap/CustomDropCursorExtension";
import { store } from "@/store";
import { Slice } from "prosemirror-model";

export function initializeCustomTipTapExtensions() {
  activeTipTapExtensions.add(BucketFile);
  activeTipTapExtensions.add(InTextSelection);
  activeTipTapExtensions.add(Image);
  activeTipTapExtensions.add(PastedImage);
  activeTipTapExtensions.add(
    CustomDropCursor.configure({
      width: 4,
      color: "#6a8bad",
      getDraggingSlice: (schema) => {
        const draggingItem = store.getters.draggingItem;
        if (draggingItem && schema.nodes[draggingItem.type]) {
          return Slice.fromJSON(schema, {
            content: [JSON.parse(JSON.stringify(draggingItem))],
          });
        }
        return null;
      },
    })
  );
}
