import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Paragraph from '@tiptap/extension-paragraph'
import Heading from '@tiptap/extension-heading'
import TextAlign from '@tiptap/extension-text-align'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import History from '@tiptap/extension-history'
import Placeholder from '@tiptap/extension-placeholder'
import { CustomHighlight } from './CustomHighlightExtension'
import HardBreak from '@tiptap/extension-hard-break'

// Prefer activeTipTapExtensions.get().  This is only the default list of extensions.
// Applications may call activeTipTapExtensions.add() to add more extensions, along
// with a few other methods to manipulate the list - see the activeTipTapExtensions
// file for more information.
export const tipTapExtensions = [
  Document,
  Paragraph,
  Text,
  Bold,
  Italic,
  Underline,
  Strike,
  BulletList,
  OrderedList,
  // Heading,
  // TextAlign,
  ListItem,
  History,
  HardBreak,
  // Dropcursor.configure({
  //   width: 2,
  //   color: '#476582',
  // }),
  Placeholder.configure({
    placeholder: 'Begin typing here...',
  }),
  CustomHighlight,
  // Link,
]
