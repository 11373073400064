import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getAllSharedAssignmentsByRole(
  userId,
  role,
  callback = async () => {},
) {
  const assignments = {}
  const querySnapshot = await getDocs(
    query(
      refs.assignments(),
      where('archived', '!=', true),
      where(role, 'array-contains', userId),
    ),
  )
  for (const assignmentDoc of querySnapshot.docs) {
    const assignment = assignmentDoc.data()
    if (assignment) {
      assignments[assignment.id] = assignment
      await callback(assignment)
    }
  }
  return assignments
}
