import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getOpenInvitationsForUser(
  uid,
  email,
  callback = async () => {},
) {
  const ids = []
  if (uid) ids.push(uid)
  if (email) ids.push(email)
  const result = {}
  const querySnapshot = await getDocs(
    query(
      refs.invitations(),
      where('userIdentifiers', 'array-contains-any', ids),
      where('responseDate', '==', null),
    ),
  )
  for (const snap of querySnapshot.docs) {
    const invitation = snap.data()
    if (invitation) {
      result[invitation.id] = invitation
      await callback(invitation)
    }
  }
  return result
}
