import { LoggingSessionChunk } from '@learnics/models/src/Organization/Course/Assignment/Submission/LoggingSessionChunk'
import { loadChunk } from './loadChunk'
import { getEventMetaInfo } from './getEventMetaInfo'

export async function loadChunks(sessionId) {
  const meta = await getEventMetaInfo(sessionId)

  const chunks = []
  let chunkIndex = 0
  while (chunkIndex < meta.chunkCount) {
    const chunk =
      (await loadChunk(sessionId, chunkIndex)) ||
      new LoggingSessionChunk({
        id: chunkIndex,
        csvEvents: [],
        sessionId,
        optimized: true,
        size: 0,
      })

    chunks.push(chunk)
    chunkIndex++
  }

  return chunks
}
