import { DriveFileAttachment } from './DriveFileAttachment'

/**
 * SourceDriveFileAttachment is class to represent Google Drive File
 * Attachment objects that act as source files, copied for each student in
 * the classroom.  For these kinds of attachments, we must store the drive file
 * metadata for each student's copy of the drive file, rather than being able to
 * use the source drive file.
 *
 * SourceDriveFileAttachment is a subclass of DriveFileAttachment,
 * adding only an array of drive files copied from it.
 *
 * @example
 * // Concrete initialization and usage
 * const concreteAttachmentData = [
 *   {
 *     id: "some-learnics-based-source-drive-file-id",
 *     title: "some-drive-file-title",
 *     alternateLink: "https://drive.google.com/file/d/some-learnics-based-source-drive-file-id/view",
 *     mimeType: "application/vnd.google-apps.file"
 *     driveFileId: "some-drive-file-id",
 *   },
 *   {
 *     // ... some other DriveFileAttachment data
 *   }],
 *   {
 *     // ... some other AbstractAttachment data
 *   }
 * ]
 *
 * // Instantiate the attachment on the fly
 * const attachment = new DriveFileAttachment(concreteAttachmentData);
 *
 * // Create a database-friendly version of the SourceDriveFileAttachment object.
 * // This should be a clone of the concreteAttachmentData (unless it has
 * // been changed in the meantime), except with an added attachmentType property
 * // set to "sourceDriveFile".
 * const savableAbstractAttachmentData = attachment.toJson();
 *
 * @example
 * // Abstract initialization and usage
 *
 * const abstractDatabaseAttachments = [{
 *     type: "sourceDriveFile",
 *     id: "some-learnics-based-source-drive-file-id",
 *     title: "some-drive-file-title",
 *     alternateLink: "https://drive.google.com/file/d/some-learnics-based-source-drive-file-id/view",
 *     mimeType: "application/vnd.google-apps.file"
 *     driveFileId: "some-drive-file-id",
 *   {
 *       // ... some other DriveFileAttachment data
 *   }],
 *
 *  {
 *     // ... some other AbstractAttachment data
 * }];
 *
 * // Instantiate an array of serialized AbstractAttachment JSON objects
 * // (from the database), in order to restore their functionality, without ever
 * // having to go through each one of them and individually do it
 * const attachments = abstractDatabaseAttachments.map(
 *     attachment => attachmentFromJson(attachment)
 *   )
 *
 * attachments.forEach((attachment) => {
 *   // Easily and concisely use it to check if a given url matches it, without
 *   // caring about the particulars
 *   const isUrlAMatch = attachment.matchesUrl(url);
 * });
 *
 * // Create a database-friendly version of an array of attachments.  This should
 * // be a clone of the abstractDatabaseAttachments array (unless it has been
 * // changed in the meantime).
 * const savableAbstractAttachmentsCopy = attachments.map(
 *     attachment => attachment.toJson()
 *   );
 *
 * @inheritDoc
 */
export class SourceDriveFileAttachment extends DriveFileAttachment {
  /**
   * Constructor for a SourceDriveFileAttachment object.
   *
   * @param {object} input - a plain SourceDriveFileAttachment JSON object
   * @param {string} [input.id] the Learnics-based id of the attachment
   * @param {string} [input.title] the title of the file
   * @param {string} [input.driveFileId] the id of the file on Google Drive
   * @param {number} [input.duration] the duration, in seconds (for audio and video files)
   * @param {string} [input.driveType] the type of the file, per Google Drive
   * @param {string} [input.url] a URL linking to this file
   * @param {string} [input.alternateLink] the url to the file on Google Drive, if any
   * @param {string} [input.mimeType] the mime type of the file, if any
   * @param {string[]} [input.ownerEmails] the emails of the owners of the file
   * @param {string} [input.iconUrl] the url to the icon of the file, per Google Drive
   * @param {string} [input.embedUrl] the url to the embeddable version of the file, per Google Drive
   *
   */
  constructor({
    id,
    title,
    driveFileId,
    driveType = 'file',
    url,
    iconUrl,
    embedUrl,
    duration,
    alternateLink,
    mimeType,
    ownerEmails = [],
  }) {
    super({
      id,
      title,
      driveFileId,
      driveType,
      url,
      iconUrl,
      embedUrl,
      duration,
      alternateLink,
      mimeType,
      ownerEmails,
    })
    this.attachmentType = 'sourceDriveFile'
  }

  toJson() {
    const json = super.toJson()
    json.attachmentType = this.attachmentType
    return json
  }

  /**
   * SourceDriveFileAttachments are essentially "Make a copy" attachments.  They are
   * calculated by scraping the page for urls, and are never matched, because
   * they are entirely distinct copies of this file rather than this file.
   */
  matchesUrl(url) {
    return false
  }

  toClassroomMaterial() {
    return {
      driveFile: {
        driveFile: {
          id: this.driveFileId,
        },
        shareMode: 'STUDENT_COPY',
      },
    }
  }
}
