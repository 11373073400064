import { loadEventDataForSessionIds as loadData } from '@learnics/models/src/Organization/Course/Assignment/Event/utils/loadEventDataForSessionIds'
import { loadChunks } from './loadChunks'
import { getSecrets } from './getSecrets'
import { getKeysDeleted } from './getKeysDeleted'

// This is merely an interface to the common loading routine in the models package.
export async function loadEventDataForSessionIds(
  sessionIds,
  unsavedEvents = {},
) {
  if (!Array.isArray(sessionIds)) {
    sessionIds = [sessionIds]
  }
  return loadData(
    sessionIds,
    {
      loadChunks,
      getSecrets,
      getKeysDeleted,
    },
    unsavedEvents,
  )
}
