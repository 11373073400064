import Vue from 'vue'
import AccessRequestService from '../../accessRequest'

export const state = {
  accessRequests: {},
  accessRequestsLoaded: false,
  accessRequestsLoading: false,
  accessRequestsForResourceLoaded: {},
  accessRequestsForResourceLoading: {},
}

export const getters = {
  accessRequests: (state) => state.accessRequests,
  accessRequestsLoaded: (state) => state.accessRequestsLoaded,
  accessRequestsLoading: (state) => state.accessRequestsLoading,
  accessRequestsForResourceLoaded: (state) =>
    state.accessRequestsForResourceLoaded,
  accessRequestsForResourceLoading: (state) =>
    state.accessRequestsForResourceLoading,
}

export const mutations = {
  setAccessRequest: (state, { compositeId, newVal, oldVal }) => {
    const { id } = compositeId
    if (newVal) {
      Vue.set(state.accessRequests, id, newVal)
    } else if (state.accessRequests[id]) {
      Vue.delete(state.accessRequests, id)
    }
  },
  setAccessRequestsLoaded: (state, newVal) => {
    state.accessRequestsLoaded = !!newVal
  },
  setAccessRequestsLoading: (state, newVal) => {
    state.accessRequestsLoading = !!newVal
  },
  setAccessRequestsForResourceLoaded: (
    state,
    { resourceType, resourceId, newVal },
  ) => {
    if (!state.accessRequestsForResourceLoaded[resourceType]) {
      Vue.set(state.accessRequestsForResourceLoaded, resourceType, {})
    }
    Vue.set(
      state.accessRequestsForResourceLoaded[resourceType],
      resourceId,
      !!newVal,
    )
  },
  setAccessRequestsForResourceLoading: (
    state,
    { resourceType, resourceId, newVal },
  ) => {
    if (!state.accessRequestsForResourceLoading[resourceType]) {
      Vue.set(state.accessRequestsForResourceLoading, resourceType, {})
    }
    Vue.set(
      state.accessRequestsForResourceLoading[resourceType],
      resourceId,
      !!newVal,
    )
  },
}

export const actions = {
  async loadAccessRequestsForResource(
    { commit, state },
    args = { forced: false, resourceType: null, resourceId: null },
  ) {
    const { resourceType, resourceId, forced } = args
    if (!resourceType || !resourceId) {
      throw new Error(
        'No resourceType or resourceId provided to loadAccessRequestsForResource',
      )
    }
    if (
      (state.accessRequestsForResourceLoaded[resourceType]?.[resourceId] ||
        state.accessRequestsForResourceLoading[resourceType]?.[resourceId]) &&
      !forced
    ) {
      return
    }
    commit('setAccessRequestsForResourceLoading', {
      resourceType,
      resourceId,
      newVal: true,
    })

    try {
      const accessRequests =
        await AccessRequestService.getAccessRequestsForResource(
          resourceType,
          resourceId,
        )
      const requestIds = Object.keys(accessRequests)

      for (const id of requestIds) {
        commit('setAccessRequest', {
          compositeId: { id },
          newVal: accessRequests[id],
        })
      }
      commit('setAccessRequestsForResourceLoaded', {
        resourceType,
        resourceId,
        newVal: true,
      })
    } catch (error) {
      console.error('Error loading accessRequestsForResource:', error)
    } finally {
      commit('setAccessRequestsForResourceLoading', {
        resourceType,
        resourceId,
        newVal: false,
      })
    }
  },
  async loadAccessRequests(
    { commit, state },
    args = { forced: false, uid: null },
  ) {
    const { uid, forced } = args
    if (
      (state.accessRequestsLoaded || state.accessRequestsLoading) &&
      !forced
    ) {
      return
    }
    if (!uid) {
      throw new Error('No uid provided to loadAccessRequests')
    }
    commit('setAccessRequestsLoading', true)

    try {
      const accessRequests =
        await AccessRequestService.getAccessRequestsForUser(uid)
      const requestIds = Object.keys(accessRequests)

      for (const id of requestIds) {
        commit('setAccessRequest', {
          compositeId: { id },
          newVal: accessRequests[id],
        })
      }
    } catch (error) {
      console.error('Error loading accessRequests:', error)
    } finally {
      commit('setAccessRequestsLoaded', true)
      commit('setAccessRequestsLoading', false)
    }
  },
}
