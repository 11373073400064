import { doc, setDoc, updateDoc } from 'firebase/firestore/lite'
import { dbLite as db } from '../coreConfig'
import { AssignmentDetailFirestoreConverter } from '@learnics/models/src/utils/converters/AssignmentDetailFirestoreConverter'

/** @deprecated This is only here during the conversion on Friday March 24, 2023. By April, it can safely be removed. */
export async function saveOldAssignment(assignment, assignmentDetail) {
  const oldPath = `organizations/${assignment.orgId}/courses/${assignment.courseId}/assignments/${assignment.id}`
  await setDoc(
    doc(db, oldPath).withConverter(AssignmentDetailFirestoreConverter),
    assignmentDetail,
  )
  await updateDoc(doc(db, oldPath), { ...assignment.toJson() })
}
