/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { addTeacherToAssignmentDetail } from './addTeacherToAssignmentDetail'
import { archiveAssignment } from './archiveAssignment'
import { archiveAssignmentDetail } from './archiveAssignmentDetail'
import { ensureInstructorAccessByAssignment } from './ensureInstructorAccessByAssignment'
import { getAccessCodeData } from './getAccessCodeData'
import { getAllKeysForAssignment } from './getAllKeysForAssignment'
import { getAllSharedAssignments } from './getAllSharedAssignments'
import { getAllSharedAssignmentsByRole } from './getAllSharedAssignmentsByRole'
import { getAssignment } from './getAssignment'
import { getAssignmentByGoogleClassroomAssignmentId } from './getAssignmentByGoogleClassroomAssignmentId'
import { getAssignmentDetail } from './getAssignmentDetail'
import { getAssignmentDetailRoster } from './getAssignmentDetailRoster'
import { getAssignmentKey } from './getAssignmentKey'
import { getAssignmentSessionLink } from './getAssignmentSessionLink'
import { getAssignmentSessionLinks } from './getAssignmentSessionLinks'
import { getAssignments } from './getAssignments'
import { getAssignmentsByCourseId } from './getAssignmentsByCourseId'
import { getAssignmentsByGoogleClassroomAssignmentId } from './getAssignmentsByGoogleClassroomAssignmentId'
import { getAssignmentsBySectionId } from './getAssignmentsBySectionId'
import { getResearchScoreObject } from './getResearchScoreObject'
import { getSharedAssignmentsForOrg } from './getSharedAssignmentsForOrg'
import { getSharedAssignmentsForOrgByUserRole } from './getSharedAssignmentsForOrgByUserRole'
import { issueAssignmentKey } from './issueAssignmentKey'
import { issueAssignmentKeys } from './issueAssignmentKeys'
import { loadAssignmentKeys } from './loadAssignmentKeys'
import { saveAssignment } from './saveAssignment'
import { saveAssignmentDetail } from './saveAssignmentDetail'
import { saveOldAssignment } from './saveOldAssignment'
import { updateAssignment } from './updateAssignment'
import { updateAssignmentDetailRoles } from './updateAssignmentDetailRoles'
import { updateAssignmentRoles } from './updateAssignmentRoles'

const AssignmentService = {
  addTeacherToAssignmentDetail,
  archiveAssignment,
  archiveAssignmentDetail,
  ensureInstructorAccessByAssignment,
  getAccessCodeData,
  getAllKeysForAssignment,
  getAllSharedAssignments,
  getAllSharedAssignmentsByRole,
  getAssignment,
  getAssignmentByGoogleClassroomAssignmentId,
  getAssignmentDetail,
  getAssignmentDetailRoster,
  getAssignmentKey,
  getAssignmentSessionLink,
  getAssignmentSessionLinks,
  getAssignments,
  getAssignmentsByCourseId,
  getAssignmentsByGoogleClassroomAssignmentId,
  getAssignmentsBySectionId,
  getResearchScoreObject,
  getSharedAssignmentsForOrg,
  getSharedAssignmentsForOrgByUserRole,
  issueAssignmentKey,
  issueAssignmentKeys,
  loadAssignmentKeys,
  saveAssignment,
  saveAssignmentDetail,
  saveOldAssignment,
  updateAssignment,
  updateAssignmentDetailRoles,
  updateAssignmentRoles,
}

export default AssignmentService
