import { functions } from '../coreConfig'
import { httpsCallable } from 'firebase/functions'

export async function sendResourceInviteEmail(
  resourceType,
  resourceId,
  inviterUid,
  roles,
  uid = null,
  email = null,
) {
  const result = await httpsCallable(
    functions,
    'resourceInviteEmail',
  )({
    resourceType,
    resourceId,
    inviterUid,
    uid,
    email,
    roles,
  })
  console.log('sendResourceInviteEmail result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
}
