import { compareVersions } from 'compare-versions'
import { SessionTag } from '../Organization/Course/Assignment/Submission/SessionTag'

export class LoggerSession {
  constructor(
    id,
    type,
    researchNotebookId,
    name,
    dateCreated = +new Date(),
    tags, // @deprecated - this will be removed after all sessions are successfully upgraded.  Keeping it here is a temporary fail-safe to ensure that we don't lose any tags if something goes wrong.
    version = '0.0.1',
  ) {
    this.id = id
    this.type = type
    this.name = name || ''
    this.dateCreated = dateCreated
    this.version = version
    this.researchNotebookId = researchNotebookId
    if (tags) {
      // @deprecated - this if block will be removed after all sessions are
      // successfully upgraded to support collaborative editing.
      this.tags = {}
      Object.keys(tags).forEach((id) => {
        const tag = tags[id]
        if (tag) {
          this.tags[id] = new SessionTag(tag.id, tag.label, tag.color)
        }
      })
    }
  }

  toJson() {
    const result = {
      name: this.name || '',
      id: this.id,
      type: this.type,
      dateCreated: this.dateCreated,
      version: this.version,
      researchNotebookId: this.researchNotebookId,
    }
    if (this.tags) {
      // @deprecated - this if block will be removed after all sessions are
      // successfully upgraded to support collaborative editing.
      result.tags = Object.keys(this.tags).reduce((acc, key) => {
        acc[key] = this.tags[key].toJson()
        return acc
      }, {})
    }
    return result
  }

  compareVersion(version) {
    return compareVersions(this.version, version)
  }
  isAtLeastVersion(version) {
    return this.compareVersion(version) >= 0
  }
  isLessThanVersion(version) {
    return this.compareVersion(version) < 0
  }
  isVersion(version) {
    return this.compareVersion(version) === 0
  }
}
