import { runTransaction } from 'firebase/firestore/lite'
import { EventMetaInfo } from '@learnics/models/src/Organization/Course/Assignment/Submission/EventMetaInfo'
import { dbLite as db } from '../coreConfig'
import refs from '../refsLite'

export async function deleteSensitiveData(sessionId, hashes) {
  const deleteTime = +new Date()
  // Run in a transaction to ensure that we don't collide with other activity
  await runTransaction(db, async (transaction) => {
    const metaInfoRef = refs.eventLogMetaInfo(sessionId)
    const metaInfoSnap = await transaction.get(metaInfoRef)
    let meta = metaInfoSnap.data() || new EventMetaInfo({})

    if (meta.lastEventsSize === 0) {
      return
    }

    const chunks = []
    for (let i = 0; i < meta.chunkCount; i++) {
      const secretsRef = refs.secret(sessionId, i + '')
      const secretDoc = await transaction.get(secretsRef)
      const secrets = secretDoc.data() || {}
      chunks.push({ secrets, secretsRef })
    }

    for (let i = 0; i < chunks.length; i++) {
      const { secrets, secretsRef } = chunks[i]
      let needsUpdate = false
      hashes.forEach((hash) => {
        if (secrets[hash]) {
          needsUpdate = true
          delete secrets[hash]
        }
      })
      if (needsUpdate) {
        transaction.set(secretsRef, secrets, { merge: false })
        const keysDeletedRef = refs.keysDeleted(sessionId, i + '')

        const updateObj = {}
        hashes.forEach((hash) => {
          updateObj[hash] = deleteTime
        })
        transaction.set(keysDeletedRef, updateObj, { merge: true })
      }
    }
    transaction.set(metaInfoRef, meta, { merge: false })
  })
}
