import { redact } from '../../../../../../utils/redact'

export function getRevealedSites(calculators, secrets, urls = null) {
  const sites =
    calculators?.urlDataRc?.toRevealedSitesArray(secrets, urls) || []
  for (let i = 0; i < sites.length; i++) {
    const site = sites[i]
    const url = redact(site.url)
    const timeline = calculators?.urlTimelinesRc?.dataMap[url]
    if (!timeline) continue
    const { startTimes, stopTimes } = timeline
    site.time = timeline.total
    site.firstFocus = startTimes[0]
    site.lastFocus = stopTimes[stopTimes.length - 1]
  }
  return sites
}
