import { getSection } from './getSection'
import { enrollStudentInSection } from './enrollStudentInSection'

export async function ensureStudentSectionEnrollment(sectionId, uid) {
  console.log(
    'Ensuring student section enrollment for user ' +
      uid +
      ' in section ' +
      sectionId +
      '...',
  )
  try {
    const section = await getSection(sectionId)

    if (section?.roles?.Learner?.includes(uid)) {
      console.log(
        'User ' + uid + ' is already enrolled in section ' + sectionId + '.',
      )
      return
    } else {
      throw new Error('User is not enrolled in section')
    }
  } catch (e) {
    console.log(
      'Error getting section: ',
      e,
      ', proceeding to enroll student in section.',
    )
    await enrollStudentInSection(sectionId, uid)
  }
}
