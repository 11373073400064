import { redact } from '../utils/redact'

/**
 * A class to represent a student-linked Google Drive file for an assignment.
 */
export class StudentCopyDriveFile {
  /**
   * Constructor for StudentCopyDriveFile.
   *
   * @param data {object} the data to initialize the StudentCopyDriveFileLink with
   * @param data.assignmentId {string} the Learnics-based id of the assignment associated with the file
   * @param data.attachmentId {string} the Learnics-based id of the attachment associated with the file, if any
   * @param data.driveFileId {string} the Google-Drive-assigned id of the file
   * @param data.userId {string} the Learnics-based id of the student user associated with the file
   * @param data.created {number} the timestamp of when this object was created
   *
   */
  constructor({
    assignmentId,
    attachmentId,
    driveFileId,
    userId,
    created = +new Date(),
  }) {
    this.driveFileId = driveFileId
    this.assignmentId = assignmentId
    this.userId = userId
    this.attachmentId = attachmentId || null
    this.created = created
  }
  get id() {
    return StudentCopyDriveFile.getId(
      this.assignmentId,
      this.userId,
      this.driveFileId,
    )
  }
  static getId(assignmentId, userId, driveFileId) {
    // Take the hash of the assignmentId + userId + driveFileId,
    // ensuring that the same file is always used for the same assignment - user - file triplet
    return redact(assignmentId + userId + driveFileId)
  }

  toJson() {
    return {
      id: this.id,
      assignmentId: this.assignmentId,
      driveFileId: this.driveFileId,
      userId: this.userId,
      attachmentId: this.attachmentId || null,
      created: this.created,
    }
  }
}
