import { getDocs } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getUsers() {
  const users = []
  const snaps = await getDocs(refs.users())
  snaps.forEach((doc) => {
    const user = doc.data()
    users.push({
      ...user,
      uid: doc.id,
    })
  })
  return users
}
