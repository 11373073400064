import { OldLoggerSession } from './OldLoggerSession'

/**
 * @deprecated prefer `AssignmentLoggerSession` when it is available
 */
export class OldAssignmentLoggerSession extends OldLoggerSession {
  constructor({
    id,
    assignmentId,
    name,
    researchNotebookId, // Added for compatibility with LoggerSession
    dateCreated = +new Date(),
    tags,
    siteTags /** deprecated */,
  }) {
    super(
      id,
      'assignment',
      name,
      researchNotebookId,
      dateCreated,
      tags || siteTags || {},
    )
    this.assignmentId = assignmentId
  }

  toJson() {
    return {
      ...super.toJson(),
      assignmentId: this.assignmentId,
    }
  }
}
