import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

/**
 * Get all courses for an organization.  This is a function that should only be
 * used by administrators, as it will return all courses for the organization.
 *
 * @param orgId the organization ID
 * @param callback a callback function that will be called for each course as it is retrieved
 * @param includeArchived whether to include archived courses - defaults to false
 * @param queryOnArchivedProperty whether to query on the archived property - defaults to true.  If false, the `includeArchived` parameter will be ignored, and the `archived` property will not be queried on.
 * @returns {Promise<{}>} a map of course IDs to course objects
 */
export async function getAllCoursesForOrganization(
  orgId,
  callback = async (course) => {},
  includeArchived = false,
  queryOnArchivedProperty = true,
) {
  const courses = {}
  const constraints = [where('orgId', '==', orgId)]
  if (queryOnArchivedProperty) {
    constraints.push(where('archived', '==', includeArchived))
  }
  const querySnapshot = await getDocs(query(refs.courses(), ...constraints))
  for (const courseDoc of querySnapshot.docs) {
    const course = courseDoc.data()
    if (course) {
      courses[courseDoc.id] = course
      await callback(course)
    }
  }
  return courses
}
