import { AbstractAttachment } from './AbstractAttachment'
import {
  driveFileMimeTypeIconMap,
  driveFileMimeTypeUrlMap,
} from './utils/driveFileMimeTypeUrlMap'

/**
 * A class to represent Google Drive File Attachment objects.  This class is
 * used to store metadata about Google Drive files attached to an assignment.
 * It is a subclass of AbstractAttachment.
 *
 *
 * @example
 * // Concrete initialization and usage
 * const concreteAttachmentData = {
 *   id: "some-learnics-based-drive-file-id",
 *   title: "some-drive-file-title",
 *   alternateLink: "https://drive.google.com/file/d/some-learnics-based-drive-file-id/view",
 *   mimeType: "application/vnd.google-apps.file"
 *   driveFileId: "some-drive-file-id",
 * }
 *
 * // Instantiate the attachment on the fly
 * const attachment = new DriveFileAttachment(concreteAttachmentData);
 *
 * // Create a database-friendly version of the DriveFileAttachment object.
 * // This should be a clone of the concreteAttachmentData (unless it has
 * // been changed in the meantime), except with an added attachmentType property
 * // set to "driveFile".
 * const savableAbstractAttachmentData = attachment.toJson();
 *
 * @example
 * // Abstract initialization and usage
 *
 * const abstractDatabaseAttachments = [{
 *   type: "driveFile",
 *   id: "some-learnics-based-drive-file-id",
 *   title: "some-drive-file-title",
 *   alternateLink: "https://drive.google.com/file/d/some-learnics-based-drive-file-id/view",
 *   mimeType: "application/vnd.google-apps.file"
 *   driveFileId: "some-drive-file-id",
 * }, {
 *     // ... some other AbstractAttachment data
 * }];
 *
 * // Instantiate an array of serialized AbstractAttachment JSON objects
 * // (from the database), in order to restore their functionality, without ever
 * // having to go through each one of them and individually do it
 * const attachments = abstractDatabaseAttachments.map(
 *     attachment => attachmentFromJson(attachment)
 *   )
 *
 * attachments.forEach((attachment) => {
 *   // Easily and concisely use it to check if a given url matches it, without
 *   // caring about the particulars
 *   const isUrlAMatch = attachment.matchesUrl(url);
 * });
 *
 * // Create a database-friendly version of an array of attachments.  This should
 * // be a clone of the abstractDatabaseAttachments array (unless it has been
 * // changed in the meantime).
 * const savableAbstractAttachmentsCopy = attachments.map(
 *     attachment => attachment.toJson()
 *   );
 *
 * @inheritDoc
 */
export class DriveFileAttachment extends AbstractAttachment {
  /**
   * Constructor for a DriveFileAttachment object.
   *
   * @param {object} input - a plain DriveFileAttachment JSON object
   * @param {string} [input.id] the Learnics-based id of the attachment or null to generate one
   * @param {string} [input.title] the title of the file
   * @param {string} input.driveFileId the id of the file on Google Drive
   * @param {number} [input.duration] the duration, in seconds (for audio and video files)
   * @param {string} input.url a URL linking to this file
   * @param {string} [input.alternateLink] the url to the file on Google Drive, if any
   * @param {string} [input.mimeType] the mime type of the file, if any
   * @param {boolean} [input.editableByStudents] true if the file is editable by students
   * @param {string[]} [input.ownerEmails] the emails of the owners of the file
   * @param {string} [input.driveType] the type of the file, per Google Drive
   * @param {string} [input.iconUrl] the url to the icon of the file, per Google Drive
   * @param {string} [input.embedUrl] the url to the embeddable version of the file, per Google Drive
   */
  constructor({
    id,
    title,
    url,
    driveFileId,
    iconUrl = null,
    embedUrl = null,
    alternateLink = null,
    mimeType = null,
    duration = 0,
    driveType = 'file',
    editableByStudents = false,
    ownerEmails = [],
  }) {
    super('driveFile', id, title, url)
    this.driveType = driveType || 'file'
    this.iconUrl = iconUrl || null
    this.embedUrl = embedUrl || null
    this.driveFileId = driveFileId || null
    this.alternateLink = alternateLink || null
    this.duration = duration || 0
    this.mimeType = mimeType || null
    this.editableByStudents = editableByStudents || false
    this.ownerEmails = ownerEmails || []
  }

  /**
   * @returns {boolean} true if this attachment equals another attachment, false otherwise
   */

  equals(attachment) {
    return (
      attachment?.attachmentType === this.attachmentType &&
      (this.id === attachment.id || this.driveFileId === attachment.driveFileId)
    )
  }

  get icon() {
    return driveFileMimeTypeIconMap[this.mimeType]
  }

  toJson() {
    const json = super.toJson()
    json.driveType = this.driveType
    json.iconUrl = this.iconUrl
    json.embedUrl = this.embedUrl
    json.driveFileId = this.driveFileId
    json.alternateLink = this.alternateLink
    json.duration = this.duration
    json.mimeType = this.mimeType
    json.editableByStudents = this.editableByStudents
    json.ownerEmails = [...this.ownerEmails]
    return json
  }

  /**
   * Returns true if the given url matches any known url of this Google Drive
   * file.
   *
   * @param {string} url the url to check
   * @returns {boolean} true if the url matches any known url of this Google
   * Drive file, false otherwise
   */
  matchesUrl(url) {
    return (
      super.matchesUrl(url) ||
      (this.alternateLink &&
        AbstractAttachment.areUrlsMostlyEqual(url, this.alternateLink, true)) ||
      (this.mimeType &&
        driveFileMimeTypeUrlMap[this.mimeType] &&
        AbstractAttachment.areUrlsMostlyEqual(
          url,
          `${driveFileMimeTypeUrlMap[this.mimeType]}${this.driveFileId}`,
          true,
        )) ||
      false
    )
  }

  toClassroomMaterial() {
    if (this.mimeType === 'application/vnd.google-apps.form') {
      return {
        link: {
          url: this.url,
        },
      }
    }
    return {
      driveFile: {
        driveFile: {
          id: this.driveFileId,
        },
        shareMode: this.editableByStudents ? 'EDIT' : 'VIEW',
      },
    }
  }
}
