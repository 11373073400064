import { RunningCalculator } from './RunningCalculator'
import { searchEngineStopWords } from '../../../../../utils/search/searchEngineStopWords'

/**
 * SearchWordFrequenciesRc stores frequency information about a
 * student's searches and uses it to generate a word cloud.
 */
export class SearchWordFrequenciesRc extends RunningCalculator {
  dataMap = {}
  lastUpdate = null
  constructor(storageObj) {
    super('searchWordFrequenciesRc', [
      'browserRc',
      'externalUrlsRc',
      'expectationsRc',
      'expectationRatiosRc',
      'studentCopiesRc',
      'urlTimelinesRc',
      'commonSearchesRc',
      'commonSearchWordFrequenciesRc',
    ])
    const dataMap = storageObj?.dataMap || {}
    this.dataMap = JSON.parse(JSON.stringify(dataMap))
  }

  toJson() {
    return {
      ...super.toJson(),
      dataMap: JSON.parse(JSON.stringify(this.dataMap || {})),
    }
  }

  transition(event, stateData) {
    const { urlDataRc, searchesRc, secrets } = stateData
    let stateChanged = this.lastUpdate !== null
    this.lastUpdate = null

    Object.keys(searchesRc.lastUpdate || {}).forEach((dateString) => {
      Object.keys(searchesRc.lastUpdate[dateString]).forEach((engine) => {
        ;[...searchesRc.lastUpdate[dateString][engine]].forEach((search) => {
          const dv = 1
          const words = this.processSearchTerm(search)

          if (words.length === 0) return
          for (let i = 0; i < words.length; i++) {
            const word = words[i]
            this.dataMap[word] ??= 0
            this.lastUpdate ||= {}
            this.lastUpdate[word] ??= this.dataMap[word]
            this.dataMap[word] += dv
            stateChanged = true
            if (this.dataMap[word] === 0) delete this.dataMap[word]
          }
        })
      })
    })

    return stateChanged
  }

  processSearchTerm(term) {
    const words = []
    term = term
      .toLowerCase()
      .replace(/[_-]/g, ' ')
      .replace(/[^\w\s]/g, ' ')
      .replace(/\s+/g, ' ')
    const split = term.split(' ')
    for (let i = 0; i < split.length; i++) {
      const word = split[i].trim().toLowerCase()
      if (!word || word.length < 2 || searchEngineStopWords.has(word)) continue

      words.push(word)
    }
    return words
  }

  toWordCloudDataSet() {
    return Object.keys(this.dataMap)
      .sort((a, b) => this.dataMap[b] - this.dataMap[a])
      .slice(0, 60)
      .map((word) => [word, this.dataMap[word]])
  }
}
