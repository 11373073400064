import { AbstractEvent } from './AbstractEvent'

/**
 * A class to represent a MouseMoveEvent for logging sessions.  This type of
 * event is recorded when the user's mouse movements are picked up by a document-
 * wide event listener.
 */
export class MouseMoveEvent extends AbstractEvent {
  static COLUMNS = [...AbstractEvent.COLUMNS, 'x', 'y']
  /**
   * Constructor for the MouseMoveEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain MouseMoveEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {boolean} [input.implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   * @param {number} [input.x] the x coordinate of the mouse
   * @param {string} [input.y] the y value of the mouse position
   *
   * @constructor
   */

  constructor({ time, implicit, x, y }) {
    super('mouseMove', time, false, implicit)
    this.x = x
    this.y = y
  }

  /**
   * Convert this object into a simple Json object.
   *
   * @returns {object} a JSON object representing this object.
   */
  toJson() {
    const json = super.toJson()
    json.x = this.x
    json.y = this.y
    return json
  }
}
