import { Tag } from '../Tag/Tag'

/**
 * NoteBoardUser contains the data that is readable for all users on a
 * NoteBoard, but that must be modified via cloud function.  Here, we store
 * each user's session IDs, which are used to determine which sessions they have
 * shared with collaborators on the NoteBoard.  By storing this data in a
 * separate class, we can allow users to securely specify which of their
 * sessions they want to share with collaborators, and which they want to keep
 * private.  Without this, it would be possible for a collaborator to share a
 * session that another user did not want to share, which would be a violation
 * of privacy.
 *
 * To modify this data, however, a user must make a cloud function request,
 * which will verify that the user is allowed to make the change and that the
 * change is valid (i.e. that the user actually owns the sessions in question
 * and that they're not trying to modify others' session ID arrays).
 *
 * This document is relied upon by cloud functions to determine which sessions
 * a user has shared with collaborators, and to automatically grant access to
 * others based on this data, so it should only be modified by cloud functions.
 */
export class NoteBoardUser {
  constructor({ userId, researchNotebookIds = [], color = Tag.DEFAULT_COLOR }) {
    this.userId = userId
    this.researchNotebookIds = JSON.parse(
      JSON.stringify(researchNotebookIds || []),
    )
    this.color = color
  }

  toJson() {
    return {
      userId: this.userId,
      researchNotebookIds: [...(this.researchNotebookIds || [])],
      color: this.color || Tag.DEFAULT_COLOR,
    }
  }
}
