export function middleTruncateText(text, maxLength = 1000, pieceRatio = 0.5) {
  if (!text || text.length == 0) {
    return ''
  }
  if (0 >= pieceRatio || pieceRatio > 1) {
    pieceRatio = 0.5
  }
  if (text.length <= maxLength) {
    return text
  }
  const ellipses = '...'
  // Math.floor is used in both cases below to ensure that we don't go over the maxLength.
  // You may end up with strings that are truncated to one character shorter than maxLength.
  const firstPartLength = Math.floor((maxLength - ellipses.length) * pieceRatio)
  const lastPartLength = Math.floor(
    (maxLength - ellipses.length) * (1 - pieceRatio),
  )
  return (
    text.slice(0, firstPartLength) +
    ellipses +
    text.slice(text.length - lastPartLength)
  )
}
