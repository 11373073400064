import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

/**
 * Get all sections for a course.
 *
 * @param courseId the course ID
 * @param callback a callback function that will be called for each section as it is retrieved
 * @param includeArchived whether to include archived sections - defaults to false
 * @param queryOnArchivedProperty whether to query on the archived property - defaults to true.  If false, the `includeArchived` parameter will be ignored, and the `archived` property will not be queried on.
 * @returns {Promise<{}>} a map of section IDs to section objects
 */
export async function getAllSectionsForCourse(
  courseId,
  callback = async (course) => {},
  includeArchived = false,
  queryOnArchivedProperty = true,
) {
  const sections = {}
  const constraints = [where('courseId', '==', courseId)]
  if (queryOnArchivedProperty) {
    constraints.push(where('archived', '==', includeArchived))
  }
  const querySnapshot = await getDocs(query(refs.sections(), ...constraints))
  for (const sectionDoc of querySnapshot.docs) {
    const section = sectionDoc.data()
    if (section) {
      sections[sectionDoc.id] = section
      await callback(section)
    }
  }
  return sections
}
