import { updateDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

/** @deprecated prefer updateCourse */
export async function updateCourseName(courseId, newName) {
  await updateDoc(refs.course(courseId, false), {
    name: newName,
    updated: +new Date(),
  })
}
