import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function updateUserDocument(userId, data) {
  let result, error
  try {
    result = await httpsCallable(
      functions,
      'updateUserDocument',
    )({ uid: userId, data })
  } catch (e) {
    error = e
  }
  console.log('updateUserDocument result: ', result)
  if (!result.data.success) {
    error = new Error(result.data.error)
  }
  if (error) {
    console.log('Error updating user: ', error)
    throw error
  }
  return result.data.data
}
