import { AbstractExpectation } from '../Expectation/AbstractExpectation'
import { AttachmentsExpectation } from '../Expectation/AttachmentsExpectation'
import { EditingGoogleDocsExpectation } from '../Expectation/EditingGoogleDocsExpectation'
import { ExternalUrlsExpectation } from '../Expectation/ExternalUrlsExpectation'
import { normalizeRatios } from '../Expectation/utils/normalizeRatios'
import { updateExpectations } from './expectationsFromAttachments'

/**
 * Derive a simpler list of expectations from a list of attachments.  Give each expectation a ratio of 1.  This should be
 * used until the student extension can be updated to handle the more complex expectations.
 *
 * @param attachments an arbitrary list of attachments
 * @param {AbstractExpectation[]} [expectationsToUpdate] an optional list of existing expectations to update.  Ratio values and other options will be try to be preserved.
 * @returns {*}
 */
export function expectationsFromAttachmentsSimple(
  attachments,
  expectationsToUpdate = [],
) {
  const studentCopyAttachments = attachments.filter(
    (attachment) => attachment.attachmentType === 'sourceDriveFile',
  )
  const editingGoogleDocs = new EditingGoogleDocsExpectation({
    ratio: 1,
    attachmentIds: studentCopyAttachments.map((attachment) => attachment.id),
  })
  const externalUrls = new ExternalUrlsExpectation({ ratio: 1, min: 0, max: 0 })
  const recommendedUrlAttachmentIds = attachments
    .filter((attachment) => attachment.attachmentType !== 'sourceDriveFile')
    .map((attachment) => attachment.id)
  const attachmentsExpectation = new AttachmentsExpectation({
    id: 'recommendedUrls',
    ratio: 1,
    label: `${
      recommendedUrlAttachmentIds.length > 1
        ? recommendedUrlAttachmentIds.length + ' '
        : ''
    }Recommended URL${recommendedUrlAttachmentIds.length > 1 ? 's' : ''}`,
    attachmentIds: recommendedUrlAttachmentIds,
  })

  const result = []

  if (editingGoogleDocs.attachmentIds.length > 0) {
    result.push(editingGoogleDocs)
  }
  if (attachmentsExpectation.attachmentIds.length > 0) {
    result.push(attachmentsExpectation)
  }
  result.push(externalUrls)

  return normalizeRatios(
    updateExpectations(expectationsToUpdate, normalizeRatios(result)),
  )
}
