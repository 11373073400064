/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { getAccessTokenInfo } from './getAccessTokenInfo'
import { getGapiOauth2Token } from './getGapiOauth2Token'
import { linkGoogleAccount } from './linkGoogleAccount'
import { refreshGapiOauth2AccessToken } from './refreshGapiOauth2AccessToken'
import { unlinkGoogleAccount } from './unlinkGoogleAccount'
import { validateGapiTokenForScopes } from './validateGapiTokenForScopes'

const GapiOauth2Service = {
  getAccessTokenInfo,
  getGapiOauth2Token,
  linkGoogleAccount,
  refreshGapiOauth2AccessToken,
  unlinkGoogleAccount,
  validateGapiTokenForScopes,
}

export default GapiOauth2Service
