import { calculatorClassMap } from './calculatorClassMap'
import { UrlData } from './UrlData'
import { Timeline } from './Timeline'
import { redact } from '../../../../../../utils/redact'
import { getMatchingExpectations } from '../../../Expectation'
import { StudentCopiesRc } from '../StudentCopiesRc'

export function runningCalculatorsFromSubmission(
  userSubmission,
  assignment,
  assignmentDetail,
) {
  const stateData = {
    assignment,
    assignmentDetail,
  }
  const entries = userSubmission.entries
  const sites = userSubmission.sites
  const secrets = calculateSecrets(userSubmission)

  const calcs = {}
  Object.keys(calculatorClassMap).forEach((calcName) => {
    calcs[calcName] = new calculatorClassMap[calcName]()
  })

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i]
    const startStopTimes = entry.startStopTimes.map((ts) => +new Date(ts))
    calcs.submissionsRc.dates.push(+new Date(entry.created))
    for (let j = 0; j < startStopTimes.length; j += 2) {
      calcs.coreRc.dataMap.total.startTimes.push(startStopTimes[j])
      calcs.coreRc.dataMap.total.stopTimes.push(startStopTimes[j + 1])
    }
  }

  for (let i = 0; i < sites.length; i++) {
    processSite(calcs, secrets, sites[i], assignment, assignmentDetail)
  }

  return {
    calculators: calcs,
    keysDeleted: [],
    keysBlacklisted: new Set(),
    secrets,
  }
}

function processSite(calcs, secrets, site, assignment, assignmentDetail) {
  const title = redact(site.title)
  const url = redact(site.url)

  const urlDataMap = calcs.urlDataRc.urlData
  urlDataMap[url] ||= new UrlData()
  urlDataMap[url].titleTimes[title] ||= 0
  urlDataMap[url].titleTimes[title] += site.time * 1000
  let matchedAttachment = false
  const attachments = assignmentDetail?.attachments || []
  for (const attachment of attachments) {
    if (attachment.matchesUrl(site.url)) {
      calcs.attachmentsRc.dataMap[attachment.id] ||= {
        timeline: new Timeline({ offset: +new Date() }),
        urls: [],
      }
      const timeline = calcs.attachmentsRc.dataMap[attachment.id].timeline
      if (timeline.startTimes.length === 0) {
        timeline.startTimes.push(0)
        timeline.stopTimes.push(0)
      }
      timeline.stopTimes[timeline.stopTimes.length - 1] += site.time * 1000
      matchedAttachment = true
    }
  }
  if (assignmentDetail.hasExpectations()) {
    const matchingExpectations = getMatchingExpectations(url, {
      assignmentDetail,
      secrets,
      studentCopiesRc: calcs.studentCopiesRc || new StudentCopiesRc(),
    })
    matchingExpectations.forEach((expectation) => {
      calcs.expectationsRc.dataMap[expectation.id] ||= new Timeline({
        offset: +new Date(),
      })

      const timeline = calcs.expectationsRc.dataMap[expectation.id]
      if (timeline.startTimes.length === 0) {
        timeline.startTimes.push(0)
        timeline.stopTimes.push(0)
      }
      timeline.stopTimes[timeline.stopTimes.length - 1] += site.time * 1000
    })
  }
}

function calculateSecrets(userSubmission) {
  const entries = userSubmission.entries
  const sites = userSubmission.sites
  const secrets = {}

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i]
    const entrySites = entry.sites
    for (let j = 0; j < entrySites.length; j++) {
      const site = entrySites[j]

      const title = redact(site.title)
      const url = redact(site.url)
      secrets[title] = site.title
      secrets[url] = site.url
    }
  }

  for (let i = 0; i < sites.length; i++) {
    const site = sites[i]
    const title = redact(site.title)
    const url = redact(site.url)
    secrets[title] = site.title
    secrets[url] = site.url
  }

  return secrets
}
