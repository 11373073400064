import { functions } from '../coreConfig'
import { httpsCallable } from 'firebase/functions'

export async function enrollStudentInCourse(courseId, uid) {
  console.log(
    'Calling enrollStudentInCourse with userId ' +
      uid +
      ' and courseId ' +
      courseId,
  )
  const result = await httpsCallable(
    functions,
    'enrollStudentInCourse',
  )({
    uid,
    courseId,
  })
  console.log('Enrolled student in course, result: ', result)
}
