/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { createResource } from './createResource'
import { deleteResourceRole } from './deleteResourceRole'
import { deleteResources } from './deleteResources'
import { getResource } from './getResource'
import { resourceLoaders } from './resourceLoaders'
import { resourceAclLoaders } from './resourceLoaders'
import { saveResourceYDocDebug } from './saveResourceYDocDebug'
import { sendResourceInviteEmail } from './sendResourceInviteEmail'
import { updateResourceRoles } from './updateResourceRoles'

const ResourceService = {
  createResource,
  deleteResourceRole,
  deleteResources,
  getResource,
  resourceLoaders,
  resourceAclLoaders,
  saveResourceYDocDebug,
  sendResourceInviteEmail,
  updateResourceRoles,
}

export default ResourceService
