import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function respondToAccessRequest(accessRequestId, roles) {
  if (!accessRequestId) throw new Error('accessRequestId is required')
  const cloudFuncResult = await httpsCallable(
    functions,
    'respondToInvitationOrAccessRequest',
  )({
    accessRequestId,
    roles: [...(roles || [])],
  })
  if (!cloudFuncResult.data.success) {
    throw new Error(cloudFuncResult.data.error)
  }
}
