import { nanoid } from 'nanoid'

/**
 * A class to represent an abstract expectation for an assignment. This class is
 * used to store data about expectations attached to an assignment. It is the
 * base class for all Expectation objects, and may not be instantiated directly.
 * Instead, you can use @see{expectationFromJson} to create a Expectation object
 * from a JSON object.
 */
export class AbstractExpectation {
  /**
   * The type of this AbstractExpectation.
   * @type {string}
   */

  /**
   * Abstract constructor for the abstract expectation.
   * @param {string} expectationType the expectationType of this expectation.
   * @param {string|undefined|null} id the Learnics-based id of the expectation.
   * @param {number} ratio the ratio of total time expected for this expectation.
   */
  constructor(expectationType, id, ratio) {
    this.expectationType = expectationType
    this.id = id || nanoid()
    this.ratio = ratio
    if (this.constructor === AbstractExpectation) {
      throw new Error(
        'AbstractExpectation objects may not be directly instantiated.  You probably want to use one of the subclasses.',
      )
    }
  }

  /**
   * Convert this expectation to a plain JSON object.
   * @returns {any}
   */
  toJson() {
    return {
      expectationType: this.expectationType,
      id: this.id,
      ratio: this.ratio,
    }
  }

  updateFrom(other) {
    this.ratio = other.ratio
  }

  /** *************************** Abstract functions **************************** */

  /**
   * @returns {string} a human-readable title for this expectation.
   */
  get label() {
    return 'Implement this function in the subclass'
  }
}
