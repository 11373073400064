import { getRunningCalculatorIdsForSession } from '@learnics/models/src/utils/getRunningCalculatorIdsForSession'
import { getStateDataForSession } from './getStateDataForSession'
import { getLoggerSession } from './getLoggerSession'
import { generateRunningCalculators } from '../logger/generateRunningCalculators'

/** @deprecated prefer `generateRunningCalculators` */
export async function loadRunningCalculators(
  sessionId,
  unsavedEvents = [],
  additionalCalculatorIds = [],
  assignmentId = null,
  commonCalculators = {},
  additionalStateData = {},
) {
  let session = await getLoggerSession(sessionId)
  let stateData = await getStateDataForSession(session)
  let runningCalcIds = getRunningCalculatorIdsForSession(
    session,
    stateData.assignment,
    stateData.assignmentDetail,
  )
  runningCalcIds.push(...additionalCalculatorIds)
  runningCalcIds = [...new Set(runningCalcIds)]
  const result = await generateRunningCalculators(
    [sessionId],
    commonCalculators,
    runningCalcIds,
    { ...stateData, ...additionalStateData },
    { [sessionId]: unsavedEvents },
  )
  return result.composite
}
