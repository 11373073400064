export async function listCourseWork(
  accessToken,
  courseId,
  courseWorkStates = ['PUBLISHED', 'DRAFT'],
  pageSize = 25,
  callback = (courseWork) => console.log('courseWork: ', courseWork),
  pageToken = null,
) {
  let args = '?pageSize=' + pageSize + '&'
  for (let i = 0; i < courseWorkStates.length; i++) {
    args += `courseWorkStates=${courseWorkStates[i]}&`
  }
  if (pageToken) {
    args += `pageToken=${pageToken}&`
  }
  args += `pageSize=25`
  const url = `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork${args}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const res = await response.json()
  console.log('listCourseWork response: ', res)
  if (res.error) {
    console.log('Error listing courseWork: ', res.error)
    throw res.error
  } else {
    if (!res.courseWork) {
      console.log('No courseWork found in response')
      return []
    }
    res.courseWork.forEach((courseWork) => callback(courseWork))
    const result = []
    result.push(...res.courseWork)
    if (res.nextPageToken) {
      const nextCourseWork = await listCourseWork(
        accessToken,
        courseId,
        courseWorkStates,
        pageSize,
        callback,
        res.nextPageToken,
      )
      result.push(...nextCourseWork)
    }
    return result
  }
}
