export function advancedGaleUrlToSearchKeyword(url) {
  let currentField = 0
  let currentOperator = 0
  let s = ''
  let moreInput = true
  while (moreInput) {
    let fieldName = 'inputFieldValues[' + currentField + ']'
    let field = url.searchParams.get(fieldName)
    if (field) {
      let operator = url.searchParams.get('operators[' + currentOperator + ']')
      if (operator && currentField !== 0) {
        s += operator + ' '
      }
      s += field + ' '
    } else {
      moreInput = false
    }
    currentField++
    currentOperator++
  }
  return s.trim()
}
