import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function linkGoogleAccount(targetUserId) {
  const result = await httpsCallable(
    functions,
    'linkGoogleAccount',
  )({ targetUserId })
  console.log('linkGoogleAccount result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
}
