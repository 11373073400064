import { expectationTypeClassMap } from './expectationTypeClassMap'

/**
 * A utility function to instantiate an Expectation object based on the given JSON object.
 *
 * @param {object} input the JSON object to instantiate from
 * @returns {*} an AbstractExpectation object instantiated from the given JSON object
 * @throws {Error} if the given JSON object does not have a valid expectationType
 */
export function expectationFromJson(input) {
  let ExpectationClass = expectationTypeClassMap[input.expectationType]
  if (input.id === 'editingGoogleDocs') {
    ExpectationClass = expectationTypeClassMap['editingGoogleDocs'] // Bridge to fix existing broken assignments, remove this if block after 2023-01-01 at the earliest
  }
  if (!ExpectationClass) {
    throw new Error(`Unknown expectation type: ${input.expectationType}`)
  }
  return new ExpectationClass(input)
}
