import { extractRolesMapFromFirestoreDocument } from '../roles'
import { Section } from '../../Section/Section'

export class SectionFirestoreConverter {
  static toFirestore(section) {
    if (!section) {
      return null
    }
    const storageObject = section.toJson()
    const roles = storageObject.roles
    delete storageObject.roles

    const result = {
      ...storageObject,
      ...roles,
    }
    return result
  }
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    if (!data) {
      return null
    }
    const roles = extractRolesMapFromFirestoreDocument(data)
    const id = snapshot.id
    return new Section({
      id,
      ...data,
      roles,
    })
  }
}
