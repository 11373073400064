import { RunningCalculator } from './RunningCalculator'
import { getMatchingExpectations } from '../../Expectation/utils/getMatchingExpectations'

/**
 * A RunningCalculator class to track the top 5 external URL hashes, as well as
 * a simple running total across all external URL hashes with times over 30 seconds.
 *
 * The time is stored as a simple integer, and the top 5 URLs are stored as an array
 * of hashes.  To get more data than that, you can use the URL hash to look up the
 * URL data in the urlDataRc.
 */
export class ExternalUrlsRc extends RunningCalculator {
  time = 0

  top5Urls = []

  constructor(storageObj) {
    super('externalUrlsRc', ['browserRc', 'expectationRatiosRc'])
    this.time = storageObj?.time || 0
    this.top5Urls = [...(storageObj?.top5Urls || [])]
  }

  toJson() {
    return {
      ...super.toJson(),
      time: this.time,
      top5Urls: this.top5Urls,
    }
  }

  transition(event, stateData) {
    const { secrets, assignmentDetail, urlDataRc, studentCopiesRc, timeRange } =
      stateData

    if (
      !urlDataRc.updatedUrlsAtPriorStep ||
      event.time < timeRange.start ||
      event.time > timeRange.stop
    ) {
      return
    }
    let stateChanged = false
    Object.keys(urlDataRc.updatedUrlsAtPriorStep).forEach((url) => {
      const oldData = urlDataRc.updatedUrlsAtPriorStep[url]
      const newData = urlDataRc.urlData[url]
      stateChanged =
        this.transitionUrl(
          url,
          oldData,
          newData,
          secrets,
          assignmentDetail,
          urlDataRc,
          studentCopiesRc,
        ) || stateChanged
    })
    return stateChanged
  }

  transitionUrl(
    url,
    oldData,
    newData,
    secrets,
    assignmentDetail,
    urlDataRc,
    studentCopiesRc,
  ) {
    let stateChanged = false

    const isMatch =
      newData &&
      isExternalUrl(url, { secrets, studentCopiesRc, assignmentDetail })

    studentCopiesRc?.addedLinksAtPriorStep?.forEach((addedLink) => {
      // Remove the link from the set temporarily, so we don't double-count it
      studentCopiesRc.links.delete(addedLink)
    })
    studentCopiesRc?.removedLinksAtPriorStep?.forEach((removedLink) => {
      // Add the link back into the set temporarily, so we don't double-count it
      studentCopiesRc.links.add(removedLink)
    })

    const wasMatch =
      oldData &&
      isExternalUrl(url, { secrets, studentCopiesRc, assignmentDetail })
    studentCopiesRc?.addedLinksAtPriorStep?.forEach((addedLink) => {
      // Add the link back to the set
      studentCopiesRc.links.add(addedLink)
    })
    studentCopiesRc?.removedLinksAtPriorStep?.forEach((removedLink) => {
      // Remove the link from the set
      studentCopiesRc.links.delete(removedLink)
    })

    if (!isMatch) {
      if (wasMatch) {
        stateChanged = oldData.time >= 30000
        if (stateChanged) {
          this.time -= oldData.time || 0
        }
      }
      if (this.top5Urls.includes(url)) {
        this.top5Urls = this.top5Urls.filter((top5Url) => top5Url !== url)
        stateChanged = true
      }
      return stateChanged
    }

    const oldTime = oldData?.time || 0
    const newTime = newData.time

    const oldTop5Urls = [...this.top5Urls]
    const newTop5Urls = [...new Set([...this.top5Urls, url])]
      .sort(
        (a, b) =>
          (urlDataRc.urlData[b]?.time || 0) - (urlDataRc.urlData[a]?.time || 0),
      )
      .slice(0, 5)
    const top5Changed =
      oldTop5Urls.length !== newTop5Urls.length ||
      oldTop5Urls.some((url, index) => url !== newTop5Urls[index])
    stateChanged ||= top5Changed

    if (top5Changed) {
      this.top5Urls = newTop5Urls
    }

    if (oldTime < 30000 || !wasMatch) {
      if (newTime >= 30000) {
        this.time += newTime
        stateChanged = true
      }
    } else {
      this.time += newTime - oldTime
      stateChanged = true
    }
    return stateChanged
  }
}

function isExternalUrl(url, { secrets, studentCopiesRc, assignmentDetail }) {
  const matchingExpectations = getMatchingExpectations(url, {
    assignmentDetail,
    secrets,
    studentCopiesRc,
  })
  const externalUrlsExpectation = matchingExpectations.find((expectation) => {
    return expectation.expectationType === 'externalUrls'
  })

  if (!externalUrlsExpectation) {
    return
  }
  return externalUrlsExpectation
}
