import { assignmentToClassroomPostData } from './assignmentToClassroomPostData'

/** @deprecated - drop the 2 and prefer calculateClassroomPatchFieldMask */
export function calculateClassroomPatchFieldMask2(
  oldAssignment,
  oldAssignmentDetail,
  newAssignment,
  newAssignmentDetail,
  baseUrl = 'https://app.learnics.com',
) {
  return calculateClassroomPatchFieldMask(
    oldAssignment,
    oldAssignmentDetail,
    newAssignment,
    newAssignmentDetail,
    baseUrl,
  )
}
export function calculateClassroomPatchFieldMask(
  oldAssignment,
  oldAssignmentDetail,
  newAssignment,
  newAssignmentDetail,
  baseUrl = 'https://app.learnics.com',
) {
  let updateMask = ''

  const oldPostData = assignmentToClassroomPostData(
    oldAssignment,
    oldAssignmentDetail,
    baseUrl,
  )
  const newPostData = assignmentToClassroomPostData(
    newAssignment,
    newAssignmentDetail,
    baseUrl,
  )

  const eligibleFields = [
    'title',
    'description',
    'state',
    'dueDate',
    'dueTime',
    'maxPoints',
    'scheduledTime',
    'submissionModificationMode',
    'topicId',
  ]
  for (const field of eligibleFields) {
    if (oldPostData[field] !== newPostData[field]) {
      updateMask += field + ','
    } else if (
      typeof oldPostData[field] === 'object' &&
      typeof newPostData[field] === 'object'
    ) {
      for (const subField of Object.keys(oldPostData[field])) {
        if (oldPostData[field][subField] !== newPostData[field][subField]) {
          updateMask += field + '.' + subField + ','
        }
      }
    }
  }
  return updateMask.slice(0, -1).trim()
}
