import { updateDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { archiveAssignment } from '../assignment/archiveAssignment'
import { getAssignmentsBySectionId } from '../assignment/getAssignmentsBySectionId'

export async function archiveSection(
  sectionId,
  archive = true,
  applyDownward = false,
) {
  await updateDoc(refs.section(sectionId, false), {
    archived: archive,
  })
  if (applyDownward) {
    const assignments = await getAssignmentsBySectionId(sectionId)
    const assignmentIds = Object.keys(assignments)
    for (const assignmentId of assignmentIds) {
      await archiveAssignment(assignmentId, archive)
    }
  }
}
