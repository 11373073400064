/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { copyUserRoles } from './copyUserRoles'
import { createDummyStudentForTeacher } from './createDummyStudentForTeacher'
import { getCustomToken } from './getCustomToken'
import { getCustomToken2 } from './getCustomToken2'
import { getUserData } from './getUserData'
import { getUserDocument } from './getUserDocument'
import { getUserDocumentsById } from './getUserDocumentsById'
import { getUsers } from './getUsers'
import { getUsersIndex } from './getUsersIndex'
import { loadRosterFromRoles } from './loadRosterFromRoles'
import { markUserPresumedPaid } from './markUserPresumedPaid'
import { rebuildUsersIndex } from './rebuildUsersIndex'
import { reloadUsersFromFirebaseRecord } from './reloadUsersFromFirebaseRecord'
import { saveUserData } from './saveUserData'
import { setUserClaims } from './setUserClaims'
import { updateUserData } from './updateUserData'
import { updateUserDocument } from './updateUserDocument'

const UserService = {
  copyUserRoles,
  createDummyStudentForTeacher,
  getCustomToken,
  getCustomToken2,
  getUserData,
  getUserDocument,
  getUserDocumentsById,
  getUsers,
  getUsersIndex,
  loadRosterFromRoles,
  markUserPresumedPaid,
  rebuildUsersIndex,
  reloadUsersFromFirebaseRecord,
  saveUserData,
  setUserClaims,
  updateUserData,
  updateUserDocument,
}

export default UserService
