export function getIconFromMimeType(mimeType) {
  if (!mimeType) {
    return 'mdi-file-document-alert'
  }

  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  let classes = {
    // Media
    image: 'mdi-file-image',
    audio: 'mdi-microphone-variant',
    video: 'mdi-file-video',
    // Documents
    'application/pdf': 'mdi-file-pdf-box', // This icon isn't great. We should find a better one.
    'application/msword': 'mdi-file-word',
    'application/vnd.ms-word': 'mdi-file-word',
    'application/vnd.oasis.opendocument.text': 'mdi-file-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml':
      'mdi-file-word',
    'application/vnd.ms-excel': 'mdi-file-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml':
      'mdi-file-excel',
    'application/vnd.oasis.opendocument.spreadsheet': 'mdi-file-excel',
    'application/vnd.ms-powerpoint': 'mdi-file-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml':
      'mdi-file-powerpoint',
    'application/vnd.oasis.opendocument.presentation': 'mdi-file-powerpoint',
    'text/plain': 'mdi-file-document',
    'text/html': 'mdi-language-html5',
    'application/json': 'mdi-file-code',
    // Archives
    'application/gzip': 'mdi-folder-zip',
    'application/zip': 'mdi-folder-zip',
  }

  const keys = Object.keys(classes)
  for (let i = 0; i < keys.length; i++) {
    if (mimeType.search(keys[i]) === 0) {
      return classes[keys[i]]
    }
  }
  return 'mdi-file'
}
