import { Tag } from './Tag'
import { yjsInstance } from '../utils/yjsInstance'

/**
 * A wrapper for a Y.Array of Tag objects.
 */
export class TagArray {
  yArray
  constructor(yArray) {
    if (!yArray) {
      throw new Error('yArray is required')
    }
    this.yArray = yArray
  }

  isEmpty() {
    return this.yArray.length === 0
  }

  get(index) {
    return new Tag(this.yArray.get(index))
  }

  swapTag(index, newColor, newLabel) {
    this.yArray.delete(index, 1)
    const tagMap = new yjsInstance.Y.Map()
    this.yArray.insert(index, [tagMap])
    tagMap.set('color', newColor)
    tagMap.set('label', newLabel)
    for (let i = this.yArray.length - 1; i >= 0; i--) {
      if (i !== index) {
        const tag = this.yArray.get(i)
        if (tag.color === newColor.trim() && tag.label === newLabel.trim()) {
          this.yArray.delete(i, 1)
        }
      }
    }
    return true
  }

  updateTagGloballyPreservingIndex(index, newColor, newLabel) {
    let changed = false
    let i = 0
    const tagToPreserve = this.get(index)
    newColor ||= tagToPreserve.color
    newLabel ||= tagToPreserve.label
    while (i < this.yArray.length) {
      const tag = this.get(i)
      if (i === index) {
        if (tag.color !== newColor.trim() || tag.label !== newLabel.trim()) {
          changed = true
        }
        tag.update(newColor, newLabel)
        i++
      } else if (
        tag.color === newColor.trim() &&
        tag.label === newLabel.trim()
      ) {
        changed = true
        this.yArray.delete(i, 1)
        if (i < index) {
          index--
        }
      } else {
        i++
      }
    }
    return changed
  }

  updateTag(oldColor, oldLabel, newColor = null, newLabel = null) {
    if (oldColor === null || oldLabel === null) {
      console.warn('oldColor and oldLabel must be provided')
      return false
    }
    if (newColor !== null && newColor === oldColor) {
      newColor = null
    }
    if (newLabel !== null && newLabel === oldLabel) {
      newLabel = null
    }
    if (newColor === null && newLabel === null) {
      console.warn('newColor or newLabel must be provided')
      return false
    }

    let changed = false

    for (let i = 0; i < this.yArray.length; i++) {
      const tag = new Tag(this.yArray.get(i))

      if (
        tag.color.trim() === oldColor.trim() &&
        tag.label.trim() === oldLabel.trim()
      ) {
        const changedTag = tag.update(newColor, newLabel)
        if (changedTag) {
          changed = true
        }
      }
    }

    const cleanedAnyDuplicates = this.cleanDuplicates()
    if (cleanedAnyDuplicates) {
      changed = true
    }
    return changed
  }

  cleanDuplicates() {
    let changed = false
    const tagFrequencyMap = this.createTagFrequencyMap()
    const colors = Object.keys(tagFrequencyMap)
    for (let i = 0; i < colors.length; i++) {
      const color = colors[i]
      const labels = Object.keys(tagFrequencyMap[color])
      for (let j = 0; j < labels.length; j++) {
        const label = labels[j]
        if (tagFrequencyMap[color][label] > 1) {
          changed = this.cleanDuplicate(color, label) || changed
        }
      }
    }
    return changed
  }
  cleanDuplicate(newColor, newLabel) {
    let changed = false
    let indicesToDelete = []
    for (let i = 0; i < this.yArray.length; i++) {
      const tag = this.get(i)
      if (tag.color === newColor.trim() && tag.label === newLabel.trim()) {
        indicesToDelete.unshift(i)
      }
    }
    // Delete all but the first one, which is the rightmost tag.  Deleting
    // from the rightmost tag to the leftmost tag ensures that the indices
    // remain valid and makes for a simpler implementation.
    for (let i = 1; i < indicesToDelete.length; i++) {
      this.yArray.delete(indicesToDelete[i], 1)
      changed = true
    }
    return changed
  }
  deleteTag(color, label) {
    let changed = false
    for (let i = this.yArray.length - 1; i >= 0; i--) {
      const tagMap = this.yArray.get(i)
      const tag = new Tag(tagMap)
      if (
        tag.color.trim() === color.trim() &&
        tag.label.trim() === label.trim()
      ) {
        this.yArray.delete(i, 1)
        changed = true
      }
    }
    return changed
  }

  deleteTagByIndex(index) {
    if (index < 0 || index >= this.yArray.length) {
      return false
    }
    this.yArray.delete(index, 1)
    return true
  }

  createTagFrequencyMap(result = {}) {
    for (let i = 0; i < this.yArray.length; i++) {
      const tag = new Tag(this.yArray.get(i))
      const color = tag.color.trim()
      const label = tag.label.trim()
      result[color] ||= {}
      result[color][label] ||= 0
      result[color][label]++
    }
    return result
  }

  addTag(color, label, index) {
    const tagMap = new yjsInstance.Y.Map()
    tagMap.set('color', color)
    tagMap.set('label', label)

    // Delete any existing tag with the same color and label first.
    let i = 0
    while (i < this.yArray.length) {
      const tag = this.get(i)
      if (tag.color === color.trim() && tag.label === label.trim()) {
        this.yArray.delete(i, 1)
        if (i < index) {
          index--
        }
      } else {
        i++
      }
    }
    this.yArray.insert(index, [tagMap])
  }
}
