import { formatTimeHms } from '../../../utils/timeFormatter'

export class ExpectedTime {
  /**
   *
   * @param data {object} the data to initialize the ExpectedTime with
   * @param data.min {number} the minimum time in seconds
   * @param data.max {number} the maximum time in seconds
   *
   **/

  constructor({ min = null, max = null }) {
    this.min = min
    this.max = max
  }

  toJson() {
    return {
      min: this.min,
      max: this.max,
    }
  }

  displayMin() {
    return this.min ? formatTimeHms(this.min * 1000) : 'None'
  }
  displayMax() {
    return this.max ? formatTimeHms(this.max * 1000) : 'None'
  }

  displayTimeAtRatio(ratio, displaySimpleTime = false) {
    if (this.min && this.max) {
      if (this.min === this.max) return formatTimeHms(this.min * ratio * 1000)
      return (
        formatTimeHms(this.min * ratio * 1000) +
        ' to ' +
        formatTimeHms(this.max * ratio * 1000)
      )
    } else if (this.min) {
      return `${displaySimpleTime ? '' : 'At least '}${formatTimeHms(
        this.min * ratio * 1000,
      )}`
    } else if (this.max) {
      return `${formatTimeHms(this.max * ratio * 1000)}${
        displaySimpleTime ? '' : ' or less'
      }`
    } else {
      return ''
    }
  }

  displayTotalTime(displaySimpleTime = false) {
    return this.displayTimeAtRatio(1, displaySimpleTime)
  }
}
