import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getSectionsByOrgId(
  orgId,
  userId,
  role = 'Learner',
  callback = async () => {},
) {
  const sections = {}
  const querySnapshot = await getDocs(
    query(
      refs.sections(),
      where('orgId', '==', orgId),
      where('archived', '!=', true),
      where(role, 'array-contains', userId),
    ),
  )
  for (const snap of querySnapshot.docs) {
    const section = snap.data()
    if (section) {
      sections[snap.id] = section
      await callback(section)
    }
  }
  return sections
}
