var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-2 pt-2 bucket-file",
    class: {
      selected: _vm.selected,
      highlighted: _vm.highlighted
    },
    staticStyle: {
      "position": "relative",
      "padding-bottom": "1.5em",
      "width": "170px"
    }
  }, [_c('div', [_c('div', {
    staticClass: "d-flex justify-center mb-2"
  }, [_c('div', {
    staticClass: "file-icon transition-swing",
    on: {
      "dblclick": _vm.openFile
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "60",
      "color": "teacherPrimary"
    }
  }, [_vm._v(_vm._s(_vm.fileIcon))])], 1)])]), _c('div', {
    staticClass: "file-icon-label transition-swing",
    attrs: {
      "title": _vm.bucketFile ? _vm.bucketFile.name : 'File not found'
    }
  }, [_vm._v(" " + _vm._s(_vm.bucketFile ? _vm.middleTruncateText(_vm.bucketFile.name, 25, 2 / 3) : 'File not found') + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }