import { LoggingStopEvent } from '../LoggingStopEvent'
import { LoggingStartEvent } from '../LoggingStartEvent'
import { IdleStateChangeEvent } from '../IdleStateChangeEvent'
import { WindowBlurEvent } from '../WindowBlurEvent'
import { TabChangeEvent } from '../TabChangeEvent'
import { TabFocusEvent } from '../TabFocusEvent'
import { MouseMoveEvent } from '../MouseMoveEvent'
import { ScrollEvent } from '../ScrollEvent'
import { TabReplaceEvent } from '../TabReplaceEvent'
import { SystemStartupEvent } from '../SystemStartupEvent'
import { EmptyEvent } from '../EmptyEvent'
import { MetadataEvent } from '../MetadataEvent'
import { AssignmentSubmitEvent } from '../AssignmentSubmitEvent'
import { PasteEvent } from '../PasteEvent'
import { CopyEvent } from '../CopyEvent'

/**
 * A mapping of AbstractEvent types to their corresponding classes.
 */
export const eventTypeClassMap = {
  idleStateChange: IdleStateChangeEvent,
  loggingStart: LoggingStartEvent,
  loggingStop: LoggingStopEvent,
  tabChange: TabChangeEvent,
  windowBlur: WindowBlurEvent,
  tabFocus: TabFocusEvent,
  mouseMove: MouseMoveEvent,
  scroll: ScrollEvent,
  tabReplace: TabReplaceEvent,
  systemStartup: SystemStartupEvent,
  empty: EmptyEvent,
  pdfTitleExtraction: MetadataEvent,
  metadata: MetadataEvent,
  assignmentSubmit: AssignmentSubmitEvent,
  paste: PasteEvent,
  copy: CopyEvent,
}

/**
 * A mapping of AbstractEvent types to their corresponding shortened identifier.
 */
export const eventTypeIdMap = {
  // Committed to these particular values
  idleStateChange: 0,
  loggingStart: 1,
  loggingStop: 2,
  tabChange: 3,
  windowBlur: 4,
  tabFocus: 5,
  assignmentSubmit: 6,
  // <--- Empty space :) --->  Feel free to add more events here
  tabReplace: 8,
  // <--- Empty space :) --->  Feel free to add more events here
  systemStartup: 10,
  empty: 11,
  pdfTitleExtraction: 12,
  metadata: 13,
  paste: 14,
  copy: 15,
  // Unused but good potential events in the future, not committed to these particular values though
  mouseMove: 100,
  scroll: 101,
}

export function eventTypeFromId(id) {
  return Object.keys(eventTypeIdMap).find(
    (key) => eventTypeIdMap[key] + '' === id + '',
  )
}

export function eventIdFromType(type) {
  return eventTypeIdMap[type]
}

export function calculateCsvColumns(events) {
  const columns = new Set()
  events.forEach((event) => {
    let EventClass = eventTypeClassMap[event.eventType]
    if (!EventClass) {
      console.warn(
        "Unrecognized event type: '" + event.eventType + "', using EmptyEvent",
      )
      EventClass = eventTypeClassMap['empty']
    }
    EventClass.COLUMNS.forEach((column) => {
      columns.add(column)
    })
  })
  return [...columns].sort()
}

export function allCsvColumns() {
  const columns = new Set()
  Object.keys(eventTypeClassMap).forEach((eventType) => {
    eventTypeClassMap[eventType].COLUMNS.forEach((column) => {
      columns.add(column)
    })
  })
  return [...columns].sort()
}
