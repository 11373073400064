import refs from '../refsLite'
import { updateRoles } from '../utils/updateRoles'

export async function updateAssignmentRoles(
  assignmentId,
  addRolesForUsers = null,
  removeRolesForUsers = null,
) {
  console.log(
    'Setting Assignment roles... ',
    assignmentId,
    addRolesForUsers,
    removeRolesForUsers,
  )
  await updateRoles(
    refs.assignment(assignmentId, false),
    addRolesForUsers,
    removeRolesForUsers,
  )
  console.log('Assignment roles set!')
}
