import { RunningCalculator } from './RunningCalculator'
import { Timeline } from './utils/Timeline'

/**
 * UrlTimelinesRc is a simple RunningCalculator class that calculates
 * (URL, Timeline) pairs for a given logging session.
 *
 */
export class UrlTimelinesRc extends RunningCalculator {
  dataMap = {}
  constructor(storageObj) {
    super('urlTimelinesRc', ['browserRc'])
    this.dataMap = Object.keys(storageObj?.dataMap || {}).reduce((acc, key) => {
      acc[key] = new Timeline(storageObj.dataMap[key])
      return acc
    }, {})
  }

  toJson() {
    return {
      ...super.toJson(),
      dataMap: Object.keys(this.dataMap).reduce((acc, key) => {
        acc[key] = this.dataMap[key].toJson()
        return acc
      }, {}),
    }
  }

  transition(event, stateData) {
    const { urlDataRc, browserRc, timeRange } = stateData
    let stateChanged = false
    if (event.time < timeRange.start || event.time > timeRange.stop)
      return stateChanged
    if (urlDataRc.updatedUrlsAtPriorStep && browserRc.time !== event.time) {
      const updatedUrls = Object.keys(urlDataRc.updatedUrlsAtPriorStep)
      for (const url of updatedUrls) {
        const newVal = urlDataRc.urlData[url]
        const oldVal = urlDataRc.updatedUrlsAtPriorStep[url]
        if (newVal.time !== oldVal?.time) {
          this.dataMap[url] ||= new Timeline()
          stateChanged = true
          this.dataMap[url].increment(browserRc.time, event.time)
        }
      }
    }
    return stateChanged
  }
}
