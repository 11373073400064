export async function createCourse(
  accessToken,
  aliasId,
  courseName,
  sectionName,
) {
  const url = `https://classroom.googleapis.com/v1/courses`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      name: courseName,
      section: sectionName,
      description: 'Created with Learnics',
      ownerId: 'me',
    }),
  })
  const res = await response.json()
  console.log('createCourse response: ', res)
  if (res.error) {
    console.log('Error creating course: ', res.error)
    throw res.error
  } else {
    return res
  }
}
