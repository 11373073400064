import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getAccessCodeData(code) {
  if (code.length !== 7) throw new Error(`Invalid access code "${code}"`)
  console.log(`Getting access code data for "${code}"...`)
  const accessCodeSnap = await getDoc(refs.accessCode(code.toUpperCase()))
  const data = accessCodeSnap.data()
  if (!data) {
    return null
  }

  // Conversion and normalization.
  let {
    accessCode,
    assignmentId,
    orgId,
    courseId,
    assignment,
    organization,
    course,
  } = data
  if (!accessCode) {
    accessCode = code.toUpperCase()
  }
  if (!assignmentId) {
    assignmentId = assignment.id
  }
  if (!orgId) {
    orgId = organization.id
  }
  if (!courseId) {
    courseId = course.id
  }
  return { accessCode, assignmentId, orgId, courseId }
}
