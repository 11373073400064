import { extractRolesMapFromFirestoreDocument } from '../roles'
import { Organization } from '../../Organization/Organization'

export class OrganizationFirestoreConverter {
  static toFirestore(organization) {
    if (!organization) {
      return null
    }
    const storageObject = organization.toJson()
    const roles = storageObject.roles
    delete storageObject.roles

    return {
      ...storageObject,
      ...roles,
    }
  }
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    if (!data) {
      return null
    }
    const roles = extractRolesMapFromFirestoreDocument(data)
    const id = snapshot.id
    return new Organization({
      ...data,
      id,
      roles,
    })
  }
}
