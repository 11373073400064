import { AbstractAttachment } from './AbstractAttachment'
import { driveFileMimeTypeIconMap } from './utils/driveFileMimeTypeUrlMap'

/**
 * A class to represent a FormAttachment object.  This class is used to store
 * metadata about forms attached to an assignment.  It is a subclass of
 * AbstractAttachment.
 *
 * @example
 * // Concrete initialization and usage
 * const concreteAttachmentData = {
 *   id: "some-learnics-based-form-attachment-id",
 *   formUrl: "https://some-form-url.com/my-form",
 *   responseUrl: "https://some-form-response-url.com/my-form-response",
 *   title: "some form title!",
 * }
 *
 * // Instantiate the attachment on the fly
 * const attachment = new FormAttachment(concreteAttachmentData);
 *
 * // Create a database-friendly version of the FormAttachment object.
 * // This should be a clone of the concreteAttachmentData (unless it has
 * // been changed in the meantime), except with an added attachmentType property
 * // set to "form".
 * const savableAbstractAttachmentData = attachment.toJson();
 *
 * @example
 * // Abstract initialization and usage
 *
 * const abstractDatabaseAttachments = [{
 *   attachmentType: "form",
 *   id: "some-learnics-based-form-attachment-id",
 *   formUrl: "https://some-form-url.com/my-form",
 *   responseUrl: "https://some-form-response-url.com/my-form-response",
 *   title: "some form title!",
 * }, {
 *     // ... some other AbstractAttachment data
 * }];
 *
 * // Instantiate an array of serialized AbstractAttachment JSON objects
 * // (from the database), in order to restore their functionality, without ever
 * // having to go through each one of them and individually do it
 * const attachments = abstractDatabaseAttachments.map(
 *     attachment => attachmentFromJson(attachment)
 *   )
 *
 * attachments.forEach((attachment) => {
 *   // Easily and concisely use it to check if a given url matches it, without
 *   // caring about the particulars
 *   const isUrlAMatch = attachment.matchesUrl(url);
 * });
 *
 * // Create a database-friendly version of an array of attachments.  This should
 * // be a clone of the abstractDatabaseAttachments array (unless it has been
 * // changed in the meantime).
 * const savableAbstractAttachmentsCopy = expectations.map(
 *     attachment => attachment.toJson()
 *   );
 *
 * @inheritDoc
 */
export class FormAttachment extends AbstractAttachment {
  /**
   * Constructor for a FormAttachment object.
   *
   * @param data {object} the data to initialize the FormAttachment with
   * @param data.id {string} the Learnics-based id of the attachment
   * @param data.title {string} the title of the form, if any
   * @param data.formUrl {string} the url of the form
   * @param data.responseUrl {string} the url to the form response
   */
  constructor({ id, title, formUrl, responseUrl }) {
    super('form', id, title, formUrl)
    this.responseUrl = responseUrl
  }

  /**
   * @returns {boolean} true if this attachment equals another attachment, false otherwise
   */

  equals(attachment) {
    return (
      attachment?.attachmentType === this.attachmentType &&
      (this.id === attachment.id ||
        this.url === attachment.url ||
        this.responseUrl === attachment.responseUrl)
    )
  }

  get icon() {
    return driveFileMimeTypeIconMap['application/vnd.google-apps.form']
  }

  toJson() {
    const json = super.toJson()
    json.responseUrl = this.responseUrl
    return json
  }

  /**
   * Returns true if the given url matches the url of the form, or its response url
   * @param {string} url the url to check
   * @returns {boolean} true if the url matches the url of the form, or its response url
   */
  matchesUrl(url) {
    return (
      (this.url && AbstractAttachment.areUrlsMostlyEqual(url, this.url)) ||
      (this.responseUrl &&
        AbstractAttachment.areUrlsMostlyEqual(url, this.responseUrl)) ||
      false
    )
  }

  toClassroomMaterial() {
    return {
      link: {
        url: this.url,
      },
    }
  }
}
