import { db } from './coreConfig'
import { collection, doc, Timestamp } from 'firebase/firestore'
import { getRefs } from './getRefs'

/**
 * refs.js is a helper file to store functions returning refs for everything we do with the Firestore library.
 *
 * Since service workers in extensions can't always use the full Firestore library, we try to default to using the
 * Firestore Lite library instead, but we need to be able to use the full library in some cases. This file provides
 * references to the full library, and the refsLite.js file provides references to the lite library.
 *
 * We should use the lite library whenever possible, to avoid incompatibility with service workers.
 */

export default getRefs(db, doc, collection, Timestamp)
