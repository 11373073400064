import * as coreConfig from './coreConfig'
import { getAnalytics } from 'firebase/analytics'

// We export analytics here for systems that support it (i.e. not a Chrome extension service worker).
export const analytics = getAnalytics(coreConfig.app)
export const auth = coreConfig.auth
export const db = coreConfig.db
export const dbLite = coreConfig.dbLite
export const functions = coreConfig.functions
export const app = coreConfig.app
