import { extractRolesMapFromFirestoreDocument } from '../roles'
import { Assignment } from '../../Organization/Course/Assignment/Assignment'

export class AssignmentFirestoreConverter {
  static toFirestore(assignment) {
    if (!assignment) {
      return null
    }
    const storageObject = assignment.toJson()
    const roles = storageObject.roles
    delete storageObject.roles
    delete storageObject.userSessionIdMap // delete the mapping of users to sessions (if it is set), as this is stored elsewhere

    return {
      ...storageObject,
      ...roles,
    }
  }
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    if (!data) {
      return null
    }
    const roles = extractRolesMapFromFirestoreDocument(data)
    const id = snapshot.id
    return new Assignment({
      ...data,
      id,
      roles,
    })
  }
}
