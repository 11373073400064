import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function addTeacherToOrganization(uid = null, orgId = null) {
  const addToOrgResult = await httpsCallable(
    functions,
    'addTeacherToOrganization',
  )({
    uid,
    orgId,
  })
  if (!addToOrgResult.data.success) {
    throw new Error(addToOrgResult.data.error)
  }
  return addToOrgResult.data.orgId
}
