import { getCourse } from './getCourse'
import { enrollStudentInCourse } from './enrollStudentInCourse'

export async function ensureStudentCourseEnrollment(courseId, uid) {
  console.log(
    'Ensuring student course enrollment for user ' +
      uid +
      ' in course ' +
      courseId +
      '...',
  )
  try {
    const course = await getCourse(courseId)

    if (course?.roles?.Learner?.includes(uid)) {
      console.log(
        'User ' + uid + ' is already enrolled in course ' + courseId + '.',
      )
      return
    } else {
      throw new Error('User is not enrolled in course')
    }
  } catch (e) {
    console.log(
      'Error getting course: ',
      e,
      ', proceeding to enroll student in course.',
    )
    await enrollStudentInCourse(courseId, uid)
  }
}
