/**
 * A class to represent an extension installation and select bits of its metadata.
 */
export class ExtensionInstallation {
  /**
   * Constructor for ExtensionInstallation.
   *
   * @param data {object} the data to initialize the ExtensionInstallation with
   * @param data.id {string} the arbitrary unique id of the installation
   * @param data.fcmRegistrationId {string} the last seen FCM registration id of the installation
   * @param data.extensionId {string} the chrome store id of the extension
   * @param data.userId {string} the last known user id logged in with the installation
   * @param data.version {string} the last known version of the extension
   * @param data.lastSeen {number} the last timestamp the installation was seen
   */

  constructor({
    id,
    fcmRegistrationId,
    extensionId,
    userId,
    version,
    lastSeen,
  }) {
    this.id = id || null
    this.fcmRegistrationId = fcmRegistrationId || null
    this.extensionId = extensionId || null
    this.userId = userId || null
    this.version = version || null
    this.lastSeen = lastSeen || +new Date()
  }

  toJson() {
    return {
      id: this.id,
      fcmRegistrationId: this.fcmRegistrationId,
      extensionId: this.extensionId,
      userId: this.userId,
      version: this.version,
      lastSeen: this.lastSeen || +new Date(),
    }
  }
}
