/**
 * Calculate the delta between two arrays, supporting 2 operations: insert and
 * delete.
 *
 * @example
 * const arrayA = ['a', 'b', 'c', 'd']
 * const arrayB = ['a', 'b', 'c', 'e']
 * const result = calculateArrayDelta(arrayA, arrayB)
 *
 * console.log('result:', result)
 * // prints [
 * //   { type: 'delete', index: 3 },
 * //   { type: 'insert', index: 3, element: 'e' }
 * // ]
 *
 * @param arrayA The array to transform
 * @param arrayB The target array
 * @param i The current index
 * @param equalityFunction The function to determine if two elements are equal
 * @return {*[]} The delta between the two arrays
 */

export function calculateArrayDelta(
  arrayA,
  arrayB,
  i = 0,
  equalityFunction = (a, b) => a === b,
) {
  const result = []
  if (i >= arrayA.length) {
    for (let j = i; j < arrayB.length; j++) {
      // We need to insert the remaining tags
      result.push({
        type: 'insert',
        index: j,
        element: arrayB[j],
      })
    }
  } else if (i >= arrayB.length) {
    for (let j = i; j < arrayA.length; j++) {
      // We need to delete the remaining elements
      result.push({
        type: 'delete',
        index: i,
      })
    }
  } else {
    const elementA = arrayA[i]
    const elementB = arrayB[i]
    if (!equalityFunction(elementA, elementB)) {
      // We can either insert an element or delete an element to reach the
      // desired state
      let newArrayA = [...arrayA]
      newArrayA.splice(i, 0, elementB)
      const insert = calculateArrayDelta(newArrayA, arrayB, i)
      insert.unshift({
        type: 'insert',
        index: i,
        element: elementB,
      })

      newArrayA = [...arrayA]
      newArrayA.splice(i, 1)
      const deleteTag = calculateArrayDelta(newArrayA, arrayB, i)
      deleteTag.unshift({
        type: 'delete',
        index: i,
      })
      if (insert.length < deleteTag.length) {
        result.push(...insert)
      } else {
        result.push(...deleteTag)
      }
    } else {
      result.push(...calculateArrayDelta(arrayA, arrayB, i + 1))
    }
  }
  return result
}
