/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { getAcl } from './getAcl'
import { getAclIfItExists } from './getAclIfItExists'
import { getAclsByParent } from './getAclsByParent'
import { toggleAclLocked } from './toggleAclLocked'
import { updateAclPublicData } from './updateAclPublicData'

const AclService = {
  getAcl,
  getAclIfItExists,
  getAclsByParent,
  toggleAclLocked,
  updateAclPublicData,
}

export default AclService
