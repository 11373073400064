import { getDocs, query } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getAllOrganizations(callback = async () => {}) {
  console.log('Loading ALL Organizations...')
  const orgs = {}
  const querySnapshot = await getDocs(query(refs.organizations()))
  for (const orgDoc of querySnapshot.docs) {
    const org = orgDoc.data()
    if (org) {
      orgs[orgDoc.id] = org
      console.log('Loaded Organization: ', org)
      await callback(org)
    }
  }
  return orgs
}
