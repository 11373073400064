/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { deleteAccessRequest } from './deleteAccessRequest'
import { getAccessRequest } from './getAccessRequest'
import { getAccessRequests } from './getAccessRequests'
import { getAccessRequestsForResource } from './getAccessRequestsForResource'
import { getAccessRequestsForUser } from './getAccessRequestsForUser'
import { requestAccessToResource } from './requestAccessToResource'
import { respondToAccessRequest } from './respondToAccessRequest'

const AccessRequestService = {
  deleteAccessRequest,
  getAccessRequest,
  getAccessRequests,
  getAccessRequestsForResource,
  getAccessRequestsForUser,
  requestAccessToResource,
  respondToAccessRequest,
}

export default AccessRequestService
