import { getDoc } from 'firebase/firestore/lite'
import { EventMetaInfo } from '@learnics/models/src/Organization/Course/Assignment/Submission/EventMetaInfo'
import refs from '../refsLite'

export async function getEventMetaInfo(sessionId) {
  const snap = await getDoc(refs.eventLogMetaInfo(sessionId))
  const meta = snap.data() || new EventMetaInfo({})

  return meta
}
