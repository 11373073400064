/**
 * A whitelist to override the blacklist of urls that should be tracked.
 *
 * For now, the one prefix here is for the learnics U tour, so that the user
 * can see the URL get updated in the extension.
 */

export const systemWhitelist = {
  prefixes: ['https://app.learnics.com/u/'],
}
