import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

/**
 * Get the OAuth2 token for the user.  This is a document that is only accessible
 * to the individual user who owns it.
 *
 * @param userId
 * @returns {Promise<unknown>}
 */
export async function getGapiOauth2Token(userId) {
  const tokenDoc = await getDoc(refs.gapiOauth2Token(userId))
  const docData = tokenDoc.data()
  if (!docData) return null
  return { ...tokenDoc.data() }
}
