import { getDoc, getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getAssignmentSessionLinks(
  assignmentId = null,
  userId = null,
) {
  const result = {}
  const q = []
  if (assignmentId) {
    q.push(where('assignmentId', '==', assignmentId))
  }
  if (userId) {
    q.push(where('userId', '==', userId))
  }
  const snaps = await getDocs(query(refs.assignmentSessionLinks(), ...q))
  for (const snap of snaps.docs) {
    const link = snap.data()
    if (link) {
      result[link.sessionId] = link
    }
  }
  return result
}
