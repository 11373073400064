import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getAssignmentsByCourseId(
  courseId,
  callback = null,
  includeArchived = false,
) {
  if (!callback) {
    callback = async () => {}
  }
  const assignments = {}
  const q = [, where('courseId', '==', courseId)]
  if (!includeArchived) {
    q.push(where('archived', '!=', true))
  }
  const querySnapshot = await getDocs(query(refs.assignments(), ...q))
  for (const assignmentDoc of querySnapshot.docs) {
    const assignment = assignmentDoc.data()
    if (assignment) {
      assignments[assignment.id] = assignment
      await callback?.(assignment)
    }
  }
  return assignments
}
