/**
 * RunningCalculator acts as an interface for running calculators.  You should
 * implement its methods and follow the pattern of the existing running calculators
 * to create new ones.
 *
 * There are only a few places that will need to be updated to add a new running
 * calculator.  First, you'll need to add the new calculator to the map of
 * calculators in the `./utils/calculatorClassMap.js` file.  Then, you'll need
 * to ensure it is added into the actual calculatorMap that gets passed into
 * `transitionCalculators`.
 */
export class RunningCalculator {
  calcId

  runBefore = []
  constructor(calcId, runBefore = []) {
    this.calcId = calcId
    this.runBefore = [...runBefore]
  }

  toJson() {
    return {
      calcId: this.calcId,
    }
  }

  /**
   * Transition to the next state based on the given event and stateData.
   *
   * @param event the next event in the event stream
   * @param stateData the state data.  This is an object containing arbitrary data fields.  It is used to complement and
   *                  inform the running calculator.  This data is passed in from the caller, so it is up to the caller
   *                  to ensure that the correct fields are present for the given RunningCalculator.  RunningCalculators
   *                  should throw errors if they are not provided with the correct stateData fields.
   * @returns {boolean} true if the state was updated, false otherwise.
   */
  transition(event, stateData) {
    if (true) throw new Error('transition not implemented')
    return false
  }
}
