export async function getCustomToken(
  idToken,
  uid = null,
  baseUrl = process.env.VUE_APP_BASE_URL || process.env.BASE_URL,
) {
  let url = `${baseUrl}/cloud/customToken`
  if (uid) {
    url += `?uid=${uid}`
  }
  const result = await fetch(url, {
    method: 'GET',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
  return await result.text()
}
