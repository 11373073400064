import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getSectionsByClassroomCourseId(
  classroomCourseId,
  callback = async () => {},
) {
  const sections = {}
  const querySnapshot = await getDocs(
    query(refs.sections(), where('classroomCourseId', '==', classroomCourseId)),
  )
  for (const sectionDoc of querySnapshot.docs) {
    const section = sectionDoc.data()
    if (section) {
      sections[section.id] = section
      await callback(section)
    }
  }
  return sections
}
