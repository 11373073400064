export function enhancePlainHtmlWithCustomExtensions(
  html,
  extensions,
  bucketName,
) {
  for (const extension of extensions) {
    if (extensionHandlers[extension.name]) {
      html = extensionHandlers[extension.name](html, bucketName)
    }
  }
  return html
}

const extensionHandlers = {
  bucketFile: (html, bucketName) => {
    const bucketUrl = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/`
    return html.replace(/<img.*?src="(?<imgSrc>.*?)".*?>/g, (match, imgSrc) => {
      // create a document fragment to hold the pasted image
      const fragment = document.createDocumentFragment()
      const div = document.createElement('div')
      div.innerHTML = match
      fragment.appendChild(div)
      const img = fragment.querySelector('img')
      let scale = img.getAttribute('scale') || 1
      let replacementImg

      if (imgSrc.startsWith(`${bucketUrl}`)) {
        const path = decodeURIComponent(
          imgSrc.slice(bucketUrl.length).split('?')[0],
        ).split('/')
        const fileId = path[0]
        replacementImg = `<bucket-file scale='${scale}' file-id='${fileId}'></bucket-file>`
      } else {
        replacementImg = `<pasted-image src='${imgSrc}'${
          scale ? ` scale='${scale}'` : ''
        }></pasted-image>`
      }
      return replacementImg
    })
  },
  noteBoardNote: (html) => {
    // This merely replaces the content of the note-board-note element with an
    // empty string.  It will be automatically filled in by the note's
    // component when it is rendered
    return html.replace(
      /<note-board-note\b([^>]*)>(.*?)<\/note-board-note>/gs,
      '<note-board-note$1></note-board-note>',
    )
  },
}
