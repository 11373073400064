import refs from '../refsLite'
import { dbLite as db } from '../coreConfig'
import { writeBatch } from 'firebase/firestore/lite'

export async function deleteUserSiteDatas(sessionId, urlHashes) {
  console.log('Delete urls', sessionId, urlHashes)
  try {
    const batch = writeBatch(db)
    for (const urlHash of urlHashes) {
      batch.delete(refs.userSiteData(sessionId, urlHash))
    }
    await batch.commit()
  } catch (err) {
    console.log('Error deleting urls', err)
    throw new Error(err)
  }
}
