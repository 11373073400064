/**
 * UrlCategoryDataBaseNode is a base class for UrlCategoryDataNode and UrlCategoryDataNode.
 *
 * It represents a node in a tree of categories, where each node has a set of urls and a set of children nodes.
 *
 * @abstract
 * @class UrlCategoryDataBaseNode
 */
export class UrlCategoryDataBaseNode {
  urls = new Set()
  children = {}

  constructor(storageObj) {
    this.urls = new Set(storageObj?.urls || [])
    this.children = Object.keys(storageObj?.children || {}).reduce(
      (acc, key) => {
        acc[key] = this.constructNode(storageObj.children[key])
        return acc
      },
      {},
    )
  }

  constructNode(nodeJson) {
    return new UrlCategoryDataBaseNode(nodeJson)
  }

  toJson() {
    return {
      urls: [...(this.urls || [])],
      children: Object.keys(this.children || {}).reduce((acc, key) => {
        acc[key] = this.children[key].toJson()
        return acc
      }, {}),
    }
  }

  getAllCategories(path = []) {
    const result = []
    if (this.urls.size > 0) result.push([...path])
    Object.keys(this.children).forEach((category) => {
      result.push(
        ...this.children[category].getAllCategories([...path, category]),
      )
    })
    return result
  }

  getNodesForUrl(url) {
    const result = []
    if (this.urls.has(url)) result.push(this)
    Object.keys(this.children).forEach((category) => {
      result.push(...this.children[category].getNodesForUrl(url))
    })
    return result
  }

  get totalUrlCount() {
    return this.allUrls.size
  }

  get allUrls() {
    const result = new Set([...this.urls])
    Object.keys(this.children).forEach((category) => {
      result.add(...this.children[category].allUrls)
    })
    return result
  }

  getCategoriesForUrl(url, path = []) {
    const result = []
    if (this.urls.has(url)) result.push([...path])
    Object.keys(this.children).forEach((category) => {
      result.push(
        ...this.children[category].getCategoriesForUrl(url, [
          ...path,
          category,
        ]),
      )
    })
    return result
  }

  ensureCategoryForUrl(categoryArray, url = null) {
    if (categoryArray.length === 0) {
      const needsAdded = url && !this.urls.has(url)
      if (needsAdded) {
        this.urls.add(url)
      }
      return !needsAdded
    }
    const fullPath = categoryArray.join('/')
    let nodeAlreadyExists = false
    const existingChildren = Object.keys(this.children)
    for (let i = 0; i < existingChildren.length; i++) {
      let existingChild = existingChildren[i]
      if (existingChild === fullPath) {
        nodeAlreadyExists = true
        break
      }
    }
    if (nodeAlreadyExists) {
      let needsAdded = url && !this.children[fullPath].urls.has(url)
      if (needsAdded) {
        this.children[fullPath].urls.add(url)
      }
      return !needsAdded
    }

    for (let i = 0; i < existingChildren.length; i++) {
      let existingCategoryArray = existingChildren[i].split('/')

      let count = 0
      for (; count < existingCategoryArray.length; count++) {
        if (existingCategoryArray[count] !== categoryArray[count]) {
          break
        }
      }
      if (count === existingCategoryArray.length) {
        return this.children[existingChildren[i]].ensureCategoryForUrl(
          categoryArray.slice(count),
          url,
        )
      } else if (count > 0) {
        // At least one category matches, but not all of them
        // We need to split this node into two nodes
        let newCategoryArrayParent = existingCategoryArray.slice(0, count)
        let newCategoryArrayChild = existingCategoryArray.slice(count)
        let childNode = this.children[existingCategoryArray.join('/')]
        let newParent = this.constructNode({})
        this.children[newCategoryArrayParent.join('/')] = newParent
        newParent.children[newCategoryArrayChild.join('/')] = childNode
        delete this.children[existingCategoryArray.join('/')]
        newParent.ensureCategoryForUrl(categoryArray.slice(count), url)
        return true
      }
    }

    // If we've made it this far, we just need to add the category in wholesale
    this.children[fullPath] = this.constructNode({})
    if (url) {
      this.children[fullPath].urls.add(url)
    }
    return true
  }

  print(categoryArray = [], currentPath = '') {
    let result =
      (categoryArray.join('/') || '[root]') + ' ' + this.urls.size + ' urls'
    const childIds = Object.keys(this.children).sort((a, b) => {
      return a.localeCompare(b)
    })
    for (let i = 0; i < childIds.length; i++) {
      result +=
        '\n' +
        currentPath +
        '├── ' +
        this.children[childIds[i]].print([childIds[i]], currentPath + '│   ')
    }
    return result
  }

  getNode(categoryArray) {
    if (categoryArray.length === 0) {
      return this
    }
    const nextNode = this.children[categoryArray[0]]
    if (nextNode) {
      return nextNode.getNode(categoryArray.slice(1))
    }
    return null
  }
}
