import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

/**
 * Send a message through Firebase Cloud Messaging to a user's installation.  This requires
 * Admin permissions over the user, or the user's id must be the same as the
 * authenticated user's id.
 *
 * @param {String[]} installationIds The installationIds to send the message to.
 * @param {Object} data The data to send.
 * @returns {Promise<Object>} The result.
 */
export async function sendCloudMessageToExtensions(installationIds, data) {
  const result = await httpsCallable(
    functions,
    'sendCloudMessageToExtensions',
  )({
    installationIds,
    data,
  })
  console.log(
    'CloudMessageService.sendCloudMessageToExtensions result: ',
    result,
  )
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.data
}
