import { functions } from '../coreConfig'
import { httpsCallable } from 'firebase/functions'

/**
 * Update the roles of a resource.
 *
 * @param resourceType {"organization"|"course"|"section"|"assignment"} The type of resource
 * @param resourceId {string} The id of the resource
 * @param rolesToAddByUid {object} A map of role names to arrays of uids
 * @param rolesToAddByEmail {object} A map of role names to arrays of emails
 * @param rolesToRemove {object} A map of role names to arrays of uids
 * @param notifyByEmail {boolean} Whether to notify users by email (only applies to users being added)
 * @param cascade {boolean} Whether to cascade downward (only applies to users being removed)
 * @returns {Promise<*>} A map of resource types to maps of resource ids to maps of role names to arrays of user ids for all resources affected by the update.  This allows for updating the client without having to make additional calls to the server.
 */
export async function updateResourceRoles(
  resourceType,
  resourceId,
  rolesToAddByUid,
  rolesToAddByEmail,
  rolesToRemove,
  notifyByEmail = true,
  cascade = true,
) {
  const result = await httpsCallable(
    functions,
    'updateResourceRoles',
  )({
    resourceType,
    resourceId,
    rolesToAddByUid,
    rolesToAddByEmail,
    rolesToRemove,
    notifyByEmail: !!notifyByEmail,
    cascade: !!cascade,
  })
  console.log('addUsersToResource result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.data
}
