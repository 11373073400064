/**
 * A class to simplify the process of monitoring progress of a long-running
 * task.
 *
 * This class has 3 main methods: `start`, `next`, and `finish`.
 *
 * Call `start` at the beginning of the task, `next` as you make progress, and
 * `finish` when you are done.  This class will keep track of the progress and
 * call a handler function whenever the progress changes.  The handler function
 * is passed the ratio of the progress as a decimal between 0 and 1.  It is
 * called with the ratio of the progress whenever the progress changes.
 *
 * When you finish writing the code, count up the number of times you call
 * `next` after calling `start` and pass that number to `start` as the first
 * argument.  This will tell the progress monitor how many times you will call
 * `next` before calling `finish`, and it will calculate the progress based on
 * that number.
 *
 * It also handles multiple layers of progress.  If you have a long-running
 * task that calls other long-running tasks, you can use the same progress
 * monitor. `start()` will create a new layer of progress on a stack, and
 * `finish()` will remove the layer from the stack.  This allows you to have
 * a progress monitor for the main task and sub-tasks.
 *
 * As long as every part of the task uses the same progress monitor, and follows
 * the same start/next/finish pattern, the progress will be updated correctly
 * across all the main tasks and sub-tasks.  Although the ratio complete will
 * not necessarily be 100% accurate at each step, this gives a good
 * approximation of the progress.
 *
 * @example
 *
 * ```javascript
 * const progressMonitor = new ProgressMonitor(async (ratioComplete) => {
 *  // do whatever with the progress
 *  console.log('Progress:', ratioComplete)
 * })
 *
 * await progressMonitor.start(3)
 * // do something asynchronous
 * await progressMonitor.next()
 * // do something asynchronous
 * await progressMonitor.next()
 * // do something asynchronous
 * await progressMonitor.next()
 * // do something asynchronous
 * await progressMonitor.finish()
 * ```
 *
 */

export class ProgressMonitor {
  progressLayers = []

  constructor(
    ratioCompleteHandler = async (ratioComplete) => {},
    finishedHandler = async () => {},
  ) {
    this.ratioCompleteHandler = ratioCompleteHandler
    this.finishedHandler = finishedHandler
  }

  /**
   * Start a new task.  This will add a new progress layer to the stack and
   * update the progress.
   *
   * @param maxNumberOfTimesYouWillCallNext The maximum number of times you will call next after calling start() and before calling finish().  Default is 1.
   * @return {Promise<void>}
   */
  async start(maxNumberOfTimesYouWillCallNext = 1) {
    this.progressLayers.push({
      task: 1,
      totalTasks: maxNumberOfTimesYouWillCallNext + 2, // Add 2 for the start and finish
    })
    await this.ratioCompleteHandler(this.calculateRatioComplete())
  }

  /**
   * Call next to increment the progress of the current task.
   *
   * @param nSteps The number of steps to increment the progress by.  Default is 1.
   * @return {Promise<void>}
   */
  async next(nSteps = 1) {
    const lastProgressLayer =
      this.progressLayers[this.progressLayers.length - 1]
    lastProgressLayer.task += nSteps
    await this.ratioCompleteHandler(this.calculateRatioComplete())
  }

  /**
   * When you are done with your particular task, call finish to remove the
   * progress layer from the stack and update the progress
   *
   * @return {Promise<void>}
   */
  async finish() {
    const lastProgressLayer =
      this.progressLayers[this.progressLayers.length - 1]
    lastProgressLayer.task = lastProgressLayer.totalTasks
    const ratioComplete = this.calculateRatioComplete()
    this.progressLayers.pop()

    await this.ratioCompleteHandler(ratioComplete)
    if (this.progressLayers.length === 0) {
      await this.finishedHandler()
    }
  }

  // Recursively calculate the ratio of the progress of the entire progress monitor
  calculateRatioComplete(progressLayerIndex = 0) {
    const progressLayer = this.progressLayers[progressLayerIndex]

    let ratio = progressLayer.task / progressLayer.totalTasks
    const singleStep = 1 / progressLayer.totalTasks
    if (progressLayerIndex < this.progressLayers.length - 1) {
      ratio += singleStep * this.calculateRatioComplete(progressLayerIndex + 1)
    }
    return ratio
  }
}
