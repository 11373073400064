import { nanoid } from 'nanoid'
import { isColorLight } from '../../../../utils/isColorLight'

/**
 * SessionTag is a class for defining a tag for a session.
 *
 * @deprecated prefer `Tag` when it is available
 */
export class SessionTag {
  static DEFAULT_COLOR = '#ff4949'
  static COLORS = [
    '#ff4949',
    '#ffcd49',
    '#49ff6c',
    '#49d9ff',
    '#4982ff',
    '#7a49ff',
    '#ff49a8',
  ]
  constructor(id = nanoid(), label = '', color) {
    this.id = id
    this.label = label
    this.color = color || SessionTag.DEFAULT_COLOR
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {{id: string, label: string, color: string}}
   */
  toJson() {
    return {
      id: this.id,
      label: this.label,
      color: this.color || SessionTag.DEFAULT_COLOR,
    }
  }

  static fromJson(json) {
    return new SessionTag(json.id, json.label, json.color)
  }

  isColorLight() {
    return isColorLight(this.color || SessionTag.DEFAULT_COLOR)
  }

  equals(other) {
    return (
      this.id === other.id &&
      this.label === other.label &&
      (this.color || SessionTag.DEFAULT_COLOR) ===
        (other.color || SessionTag.DEFAULT_COLOR)
    )
  }
}
