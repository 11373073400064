import { redact } from '../utils/redact'

/**
 * A class to represent a Google Drive folder link to a resource.  This is how
 * we link a resource to a folder in Google Drive.
 */
export class ResourceDriveFolder {
  /**
   * Constructor for ResourceDriveFolder.
   *
   * @param data {object} the data to initialize the DriveFolderLink with
   * @param data.resourceType {string} the Learnics-based type of the resource associated with the file
   * @param data.resourceId {string} the Learnics-based id of the resource associated with the file
   * @param data.driveFileId {string} the Google-Drive-assigned id of the folder
   * @param data.userId {string} the Learnics-assigned id of the user associated with the file
   * @param data.created {number} the timestamp of when this object was created
   *
   */
  constructor({
    resourceType,
    resourceId,
    driveFileId,
    userId,
    created = +new Date(),
  }) {
    this.resourceType = resourceType
    this.resourceId = resourceId
    this.driveFileId = driveFileId
    this.userId = userId
    this.created = created
  }

  get id() {
    return ResourceDriveFolder.getId(
      this.resourceType,
      this.resourceId,
      this.userId,
    )
  }
  static getId(resourceType, resourceId, userId) {
    // Take the hash of the resourceType + resourceId + userId,
    // ensuring that the same folder is always used for the same resource - user pair
    return redact(resourceType + resourceId + userId)
  }

  toJson() {
    return {
      id: this.id,
      resourceType: this.resourceType,
      resourceId: this.resourceId,
      driveFileId: this.driveFileId,
      userId: this.userId,
      created: this.created,
    }
  }
}
