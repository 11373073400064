export function truncateTipTapNode(
  rootNode,
  maxCharacters,
  maxBlocks = Number.MAX_VALUE,
  ellipsisBreak = 1,
) {
  if (maxCharacters <= 0) {
    return { type: 'doc', content: [] }
  }
  const result = { type: 'doc', content: [] }
  let currentLength = 0
  let currentBlocks = 0
  for (const node of rootNode.content) {
    if (currentLength >= maxCharacters || currentBlocks >= maxBlocks) {
      return result
    }
    const { length, truncatedNode, blocks } = truncateNode(
      node,
      maxCharacters - currentLength,
      maxBlocks - currentBlocks,
    )
    currentLength += length
    currentBlocks += blocks
    if (truncatedNode) {
      result.content.push(truncatedNode)
    }
  }
  return result
}

function truncateNode(node, maxCharacters, maxBlocks) {
  if (node.type === 'text') {
    return truncateTextNode(node, maxCharacters)
  }
  let currentLength = 0
  let currentBlocks = 0
  if (node.type === 'paragraph') {
    currentBlocks = 1
  }
  if (maxBlocks - currentBlocks < 0) {
    return { length: 0, truncatedNode: null, blocks: 0 }
  }
  if (node.content) {
    const result = { ...node, content: [] }

    for (const childNode of node.content) {
      if (currentLength >= maxCharacters || currentBlocks > maxBlocks) {
        break
      }
      const { truncatedNode, length, blocks } = truncateNode(
        childNode,
        maxCharacters - currentLength,
        maxBlocks - currentBlocks,
      )
      currentLength += length
      currentBlocks += blocks
      if (truncatedNode) {
        result.content.push(truncatedNode)
      }
    }
    return {
      length: currentLength,
      truncatedNode: result.content.length > 0 ? result : null,
      blocks: currentBlocks,
    }
  } else {
    return { length: 0, truncatedNode: node, blocks: currentBlocks }
  }
}

function truncateTextNode(node, maxLength) {
  return {
    truncatedNode: {
      ...node,
      text:
        node.text.slice(0, maxLength) +
        (node.text.length > maxLength ? '...' : ''),
    },
    length: Math.min(node.text.length, maxLength),
    blocks: 0,
  }
}
