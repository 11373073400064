import { eventTypeClassMap } from './eventTypeMaps'

/**
 * A utility function to instantiate an Event object based on the given JSON object.
 *
 * @param {object} input the JSON object to instantiate from
 * @returns {*} an AbstractEvent object instantiated from the given JSON object
 * @throws {Error} if the given JSON object does not have a valid eventType
 */
export function eventFromJson(input) {
  let EventClass = eventTypeClassMap[input.eventType]

  if (!EventClass) {
    EventClass = eventTypeClassMap['empty']
    console.warn(
      "Unrecognized event type: '" + input.eventType + "', using EmptyEvent",
    )
  }
  return new EventClass(input)
}
