import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { getAssignment } from './getAssignment'

export async function getAssignmentsByGoogleClassroomAssignmentId(
  classroomCourseId,
  classroomAssignmentId,
) {
  const assignments = {}
  const q = [, where('classroomAssignmentId', '==', classroomAssignmentId)]

  const querySnapshot = await getDocs(query(refs.assignments(), ...q))
  for (const assignmentDoc of querySnapshot.docs) {
    const assignment = assignmentDoc.data()
    if (assignment) {
      assignments[assignment.id] = assignment
    }
  }
  if (Object.keys(assignments).length === 0) {
    const assignment = await getAssignment(classroomAssignmentId)
    if (assignment) {
      assignment.classroomAssignmentId = classroomAssignmentId
      assignment.classroomCourseId = classroomCourseId
      assignments[assignment.id] = assignment
    }
  }
  return assignments
}
