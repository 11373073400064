export function tipTapNodeHasContent(jsonNode) {
  if (jsonNode.type === 'text') {
    return jsonNode.text.trim().length > 0
  }
  if (jsonNode.type === 'pastedImage' || jsonNode.type === 'bucketFile') {
    return true
  }
  if (jsonNode.content && jsonNode.content.length > 0) {
    return jsonNode.content.some((child) => tipTapNodeHasContent(child))
  }
  return false
}
