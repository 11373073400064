import { urlToSearchKeyword } from '../../../../../../utils/search/urlToSearchKeyword'
import { defaultUrlFilter } from './defaultUrlFilter'

export const coreTimelineFilters = {
  total: ({ browserRc }) => browserRc.logging,
  active: ({ browserRc }) => browserRc.logging && browserRc.active,
  idle: ({ browserRc }) => browserRc.logging && !browserRc.active,
  focused: ({ browserRc }) =>
    coreTimelineFilters.active({ browserRc }) && browserRc.focused,
  unfocused: ({ browserRc }) =>
    coreTimelineFilters.active({ browserRc }) && !browserRc.focused,
  deleted: ({ browserRc, keysDeleted }) =>
    coreTimelineFilters.focused({ browserRc }) &&
    keysDeleted[browserRc.focusedTab?.url] >= browserRc.time,
  blacklisted: ({ browserRc, keysBlacklisted }) =>
    coreTimelineFilters.focused({ browserRc }) &&
    keysBlacklisted[browserRc.focusedTab?.url] >= browserRc.time,

  systemIgnored: ({ browserRc, secrets, urlFilter }) => {
    if (!browserRc.logging || !browserRc.active || !browserRc.focused)
      return false
    const url = browserRc.focusedTab?.url
    urlFilter ||= defaultUrlFilter
    return !url || (secrets[url] && !urlFilter(secrets[url]))
  },
  focusedOnValidUrl: ({
    browserRc,
    secrets,
    keysDeleted,
    keysBlacklisted,
    urlFilter,
  }) => {
    return (
      coreTimelineFilters.focused({ browserRc }) &&
      !coreTimelineFilters.deleted({ browserRc, keysDeleted }) &&
      !coreTimelineFilters.blacklisted({ browserRc, keysBlacklisted }) &&
      !coreTimelineFilters.systemIgnored({ browserRc, secrets, urlFilter })
    )
  },
  searching: ({
    browserRc,
    secrets,
    keysDeleted,
    keysBlacklisted,
    urlFilter,
  }) => {
    if (
      !coreTimelineFilters.focusedOnValidUrl({
        browserRc,
        secrets,
        keysDeleted,
        keysBlacklisted,
        urlFilter,
      })
    )
      return false

    let url = browserRc.focusedTab?.url
    url = secrets[url] || url
    return !!urlToSearchKeyword(url)
  },
}
