import { nanoid } from 'nanoid'

/**
 * Course is class to represent the data model for a course.
 */
export class Course {
  static DEFAULT_CITATION_FORMAT = 'MLA 9th'
  /**
   * Constructor for Course.
   *
   * @param data the data to initialize this course with
   * @param [data.id] {string} the id of the course
   * @param [data.name] {string} the name of the course
   * @param [data.description] {string} the description of the course
   * @param [data.classroomCourseId] {string} the ID of the corresponding Google Classroom course, if any
   * @param [data.archived] {boolean} the archive status of the course
   * @param [data.googleClassroom] {boolean} whether the course is a Google-Classroom-based course
   * @param [data.created] {number} the timestamp of when the course was created, -1 if not known
   * @param [data.updated] {number} the timestamp of when the course was last updated, -1 if not known
   * @param [data.roles] { { [[role]]: string[] } } the roles of this course.  This is a map of role strings to arrays of user ids.
   * @param [data.preferredCitationFormat] {string} The preferred citation format for the course. Student bibliographies will default to this format.
   * @param [data.hasSections] {boolean} Whether the course has sections.
   */
  constructor({
    id = nanoid(),
    name = 'New Class',
    description = 'Enter a Description',
    archived = false,
    googleClassroom = false,
    classroomCourseId = null,
    orgId = null,
    created = -1,
    updated,
    roles = {},
    preferredCitationFormat = Course.DEFAULT_CITATION_FORMAT,
    hasSections = false,
  }) {
    this.created = created
    this.updated = updated || created
    this.id = id
    this.name = name
    this.description = description
    this.archived = archived
    this.googleClassroom = !!googleClassroom
    this.orgId = orgId
    this.roles = JSON.parse(JSON.stringify(roles))
    this.preferredCitationFormat = preferredCitationFormat
    this.classroomCourseId = classroomCourseId
    this.hasSections = !!hasSections

    if (!this.hasSections && !this.classroomCourseId && this.googleClassroom) {
      // Support the old format of courses, where googleClassroom served a
      // slightly different purpose.  In the old format, googleClassroom was
      // true if the course was a Google Classroom course.  In the new format,
      // `classroomCourseId`, if it is non-null, is the Google Classroom ID of
      // the course.  If `classroomCourseId` is null, then the course is not a
      // Google Classroom course.  This code supports the old format by shifting
      // it into the new format, where googleClassroom signifies courses that
      // have Google Classroom sections, and the course no longer necessarily
      // shares an ID with the Google Classroom course.
      this.classroomCourseId = this.id
    }
  }

  toJson() {
    return {
      created: this.created,
      updated: this.updated,
      id: this.id,
      name: this.name,
      orgId: this.orgId,
      classroomCourseId: this.classroomCourseId || null,
      description: this.description,
      archived: this.archived,
      googleClassroom: !!this.googleClassroom,
      roles: JSON.parse(JSON.stringify(this.roles)),
      preferredCitationFormat: this.preferredCitationFormat,
      hasSections: !!this.hasSections,
    }
  }
}
