import { getFunctions } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFirestore as getFirestoreLite } from 'firebase/firestore/lite'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

// Don't try to be clever here and use a ternary operator.  It will fail.  process.env[prefix + 'API_KEY'] will always
// evaluate to false, because this is low-level rewriting of the code.  It has to match something like this:
//  process.env.API_KEY
//  process.env.VUE_APP_API_KEY
//
// This is the only way to do it.  It's ugly, but it works.  It cannot be done in a single line.  If you don't do it
// this way, process.env will evaluate to "ENV VAR UNDEFINED" (or something like that, I forget), and your properties
// will be undefined.

let firebaseOptions
if (process.env.VUE_APP_API_KEY) {
  firebaseOptions = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
  }
} else {
  firebaseOptions = {
    apiKey: process.env.API_KEY,
    authDomain: process.env.AUTH_DOMAIN,
    databaseURL: process.env.DATABASE_URL,
    projectId: process.env.PROJECT_ID,
    storageBucket: process.env.STORAGE_BUCKET,
    messagingSenderId: process.env.MESSAGING_SENDER_ID,
    appId: process.env.APP_ID,
    measurementId: process.env.MEASUREMENT_ID,
  }
}

let _app
const existingApps = getApps()
if (existingApps.length === 0) {
  _app = initializeApp(firebaseOptions)
} else {
  _app = getApp()
}
export const app = _app
export const functions = getFunctions(_app)
export const auth = getAuth(_app)
export const db = getFirestore(_app)
export const dbLite = getFirestoreLite(_app)
export const storage = getStorage(_app)
export const storageHelpers = { getStorage, ref, uploadBytes, getDownloadURL }
