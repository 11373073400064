import { RunningCalculator } from './RunningCalculator'

/**
 * RecentCopiesRc stores key information from the last 20 CopyEvents
 */
export class RecentCopiesRc extends RunningCalculator {
  data = []
  constructor(storageObj) {
    super('recentCopiesRc', ['browserRc'])
    this.data = JSON.parse(JSON.stringify(storageObj?.data || []))
  }

  toJson() {
    return {
      ...super.toJson(),
      data: JSON.parse(JSON.stringify(this.data || [])),
    }
  }

  transition(event, stateData) {
    if (event.eventType !== 'copy') return false

    this.data.push({ url: event.url, text: event.text })
    if (this.data.length > 20) {
      this.data.shift()
    }

    return true
  }
}
