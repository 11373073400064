import { RunningCalculator } from './RunningCalculator'
import { urlIsLikelyAIPrompt } from '../../../../../utils/urlIsLikelyAIPrompt'

/**
 * IntegrityFlagRc stores simple booleans for whether the user was flagged as
 * using AI or bulk copying.
 */
export class IntegrityFlagRc extends RunningCalculator {
  aiDetected = false
  bulkPasteDetected = false
  constructor(storageObj) {
    super('IntegrityFlagRc', ['browserRc'])
    this.aiDetected = !!storageObj?.aiDetected
    this.bulkPasteDetected = !!storageObj?.bulkPasteDetected
  }

  toJson() {
    return {
      ...super.toJson(),
      aiDetected: this.aiDetected,
      bulkPasteDetected: this.bulkPasteDetected,
    }
  }

  transition(event, stateData) {
    if (this.aiDetected && this.bulkPasteDetected) {
      return false // can't possibly change
    }
    const { secrets } = stateData
    if (
      !this.aiDetected &&
      event.eventType === 'tabChange' &&
      urlIsLikelyAIPrompt(secrets[event.url])
    ) {
      this.aiDetected = true
      return true
    }
    if (
      !this.bulkPasteDetected &&
      event.eventType === 'paste' &&
      event.shouldBeFlagged()
    ) {
      this.bulkPasteDetected = true
      return true
    }
    return false
  }
}
