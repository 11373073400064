import UserService from '../../user'
import Vue from 'vue'

export const state = {
  userData: {},
}

export const getters = {
  userData: (state) => state.userData,
}

export const mutations = {
  setUserData(state, { compositeId, newVal, oldVal }) {
    const { userId } = compositeId
    if (newVal) {
      Vue.set(state.userData, userId, newVal)
    } else Vue.delete(state.userData, userId)
  },
}

export const actions = {
  async loadUserData(
    { state, dispatch, commit, rootState },
    args = { forced: false, userId: null },
  ) {
    const forced = args.forced || false
    const userId = args.userId
    if (!forced && state.userData[userId]) {
      return
    }

    const userData = await UserService.getUserData(userId)
    commit('setUserData', { newVal: userData, compositeId: { userId } })
    return userData
  },
}
