export function removeMissingTagIdsFromUserSiteData(session, siteData) {
  const sessionTags = session.tags || session.siteTags || {}
  // Double check that the siteData is valid
  const tagIds = siteData.tagIds || []
  for (let j = 0; j < tagIds.length; j++) {
    if (!sessionTags[tagIds[j]]) {
      // Missing tag id reference, just remove it from the list
      tagIds.splice(j, 1)
    }
  }
  const notes = siteData.notes || []
  for (let j = 0; j < notes.length; j++) {
    const noteTagIds = notes[j].tagIds || []
    for (let j = 0; j < noteTagIds.length; j++) {
      if (!sessionTags[noteTagIds[j]]) {
        // Missing tag id reference, just remove it from the list
        noteTagIds.splice(j, 1)
      }
    }
  }
}
