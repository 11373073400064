import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function backFillSessionAssignmentKeys(sessionIds) {
  const result = await httpsCallable(
    functions,
    'backFillSessionAssignmentKeys',
  )({ sessionIds })
  console.log('backFillSessionAssignmentKeys result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.data
}
