import { openGoogleApiLoginPopup } from "@/utils/gapi/openGoogleApiLoginPopup";
import { GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { auth } from "@learnics/services/src/analyticsConfig";
import { store } from "@/store";
import { broadcastMessage } from "@/services/extensionControllers";
import { createLoginArguments } from "@/utils/gapi/createLoginArguments";

export async function customLoginWithPopup(
  state,
  user,
  scopes,
  prompt = "none", // We always default to none.  If that fails, the popup will automatically upgrade to select_account.  If that fails, the call will be tried once more with "consent".
  linkingGoogleAccount = false,
  showWarning = false,
  warningMessage = "Please sign in with Google to continue.",
  customToken = null // A custom token to sign in with beforehand, if available
) {
  const loginArgs = createLoginArguments(
    state,
    user,
    scopes,
    prompt,
    false,
    linkingGoogleAccount,
    showWarning,
    warningMessage
  );

  console.warn("Scopes", scopes);
  return await new Promise((resolve, reject) => {
    const popupWindow = openGoogleApiLoginPopup(loginArgs, customToken);
    if (!popupWindow) {
      reject("Failed to open popup window");
      return;
    }

    let pollTimer, eventListener;

    eventListener = async (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { data } = event;
      console.log("Received data from popup", data);
      window.clearInterval(pollTimer);
      window.removeEventListener("message", eventListener);

      /** @deprecated - can be removed after 3.39.0 has been well established
       *  as a minimum expected version for users to have */
      if (data.tokens) {
        await broadcastMessage(
          {
            action: "appLogin",
            credential: GoogleAuthProvider.credential(
              data.tokens.id_token
            ).toJSON(),
            state: data.state,
          },
          (extension) => extension.isLessThan("3.39.0")
        );
      }
      /** end @deprecated */
      if (!auth.currentUser && data.tokens) {
        // At this point the user SHOULD be logged in with firebase... if they
        // aren't, then register an auth state listener to wait for it to occur,
        // THEN resolve the promise
        try {
          const authListener = onAuthStateChanged(auth, async (user) => {
            if (user) {
              authListener();
              const token = await user.getIdTokenResult();
              const claims = token.claims || {};
              await store.dispatch("updateUser", {
                user,
                claims,
              });
              resolve(data);
            }
          });
        } catch (error) {
          console.log("Error registering auth state listener", error);
          reject(error);
        }
      } else {
        resolve(data);
      }
    };
    window.addEventListener("message", eventListener, false);

    // Poll popupWindow until it is closed or a message is received - this is
    // the only way to reliably check if the user has closed the popup without
    // going through the redirect flow

    pollTimer = window.setInterval(() => {
      try {
        if (!popupWindow || popupWindow.closed !== false) {
          // !== is required for compatibility with Opera
          window.clearInterval(pollTimer);
          window.removeEventListener("message", eventListener);
          reject(new Error("Popup was closed by user"));
        }
      } catch (e) {}
    }, 200);
  });
}
