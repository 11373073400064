import { RunningCalculator } from './RunningCalculator'
import { coreTimelineFilters } from './utils/coreTimelineFilters'
import { UrlCategoryDataNode } from './utils/UrlCategoryDataNode'

/**
 * UrlCategoriesRc stores category metadata information about categories and uses
 * it to generate a timeline of time spent in each category.
 */
export class UrlCategoriesRc extends RunningCalculator {
  root
  constructor(storageObj) {
    super('urlCategoriesRc', ['browserRc'])
    this.root = new UrlCategoryDataNode(storageObj?.root || {})
  }

  toJson() {
    return {
      ...super.toJson(),
      root: (this.root || new UrlCategoryDataNode({})).toJson(),
    }
  }

  transition(event, stateData) {
    const { secrets } = stateData
    let stateChanged = false

    let categoryArray
    if (
      event.eventType === 'metadata' &&
      event.key === 'category' &&
      secrets[event.value] &&
      secrets[event.url]
    ) {
      let cat = secrets[event.value]
      let url = secrets[event.url]
      categoryArray = cat.split('/').filter((x) => !!x)
      stateChanged ||= this.root.ensureCategoryForUrl(categoryArray, url)
    }

    const timeRange = stateData.timeRange

    const browserTime = Math.max(timeRange.start, stateData.browserRc.time)
    const eventTime = Math.min(timeRange.stop, event.time)
    if (
      event.time >= timeRange.start &&
      event.time <= timeRange.stop &&
      coreTimelineFilters.focusedOnValidUrl(stateData) &&
      eventTime - browserTime > 0
    ) {
      let url = secrets[stateData.browserRc.focusedTab?.url]
      // The other half of this running calculator calculates timelines for each URL category
      this.root.getNodesForUrl(url).forEach((node) => {
        node.timeline.increment(browserTime, eventTime)
        stateChanged = true
      })
    }
    const newRoot = this.root
    this.root = null
    this.root = newRoot

    return stateChanged
  }
}
