import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'
import { loggerSessionFromJson } from '@learnics/models/src/Organization/Course/Assignment/Submission/loggerSessionFromJson'
import { Note } from '@learnics/models/src/Note/Note'
import { ResearchNotebook } from '@learnics/models/src/ResearchNotebook/ResearchNotebook'

export async function upgradeLoggerSessions(sessionIds) {
  const result = await httpsCallable(
    functions,
    'upgradeLoggerSessions',
  )({ sessionIds })
  console.log('Upgrade Logger Sessions result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  const finalResult = {}
  for (let i = 0; i < sessionIds.length; i++) {
    if (result.data.data[sessionIds[i]]) {
      const { session, selectedNoteIds, researchNotebook, notes } =
        result.data.data[sessionIds[i]]
      finalResult[sessionIds[i]] = {
        session: loggerSessionFromJson(session),
        selectedNoteIds,
        notes: Object.keys(notes).reduce((acc, noteId) => {
          acc[noteId] = new Note(notes[noteId])
          return acc
        }, {}),
        researchNotebook: new ResearchNotebook(researchNotebook),
      }
    } else {
      console.warn(
        'Failed to upgrade logger session #' +
          sessionIds[i] +
          ' to latest version.',
      )
    }
  }
  return finalResult
}
