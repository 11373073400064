import { getAssignment } from '../assignment/getAssignment'
import { getCourse } from '../course/getCourse'
import { getOrganization } from '../organization/getOrganization'
import { getSection } from '../section/getSection'
import { getLoggerSession } from '../logger/getLoggerSession'
import { getNote } from '../note/getNote'
import { getNoteBoard } from '../noteBoard/getNoteBoard'

import { getAcl } from '../acl/getAcl'
import { getResearchNotebook } from '../researchNotebook/getResearchNotebook'
import { getAclIfItExists } from '../acl/getAclIfItExists'
import { BasicAccessList } from '@learnics/models/src/BasicAccessList/BasicAccessList'
import { paths } from '@learnics/models/src/utils/paths'
import { getAssignmentDetail } from '../assignment/getAssignmentDetail'

export const resourceLoaders = {
  assignment: async (resourceId) => getAssignment(resourceId),
  assignmentDetail: async (resourceId) => getAssignmentDetail(resourceId),
  course: (resourceId) => getCourse(resourceId),
  organization: (resourceId) => getOrganization(resourceId),
  section: (resourceId) => getSection(resourceId),
  loggerSession: (resourceId) => getLoggerSession(resourceId),
  note: (resourceId) => getNote(resourceId),
  noteBoard: (resourceId) => getNoteBoard(resourceId),
  researchNotebook: (resourceId) => getResearchNotebook(resourceId),
}
export const resourceAclLoaders = {
  assignment: async (resourceId, resource) => {
    return new BasicAccessList({
      parents: {
        course: resource.sectionId ? [] : [resource.courseId],
        section: resource.sectionId ? [resource.sectionId] : [],
      },
      children: {
        loggerSession: [...(resource.keys || [])],
      },
      roles: Object.keys(resource.roles).reduce((acc, key) => {
        acc[key] = [...resource.roles[key]]
        return acc
      }, {}),
    })
  },
  assignmentDetail: async (resourceId, resource) => {
    const createdBy = resource.createdBy
    const roles = JSON.parse(JSON.stringify(resource.roles))
    if (createdBy) {
      roles.Owner ||= []
      if (!roles.Owner.includes(createdBy)) {
        roles.Owner.push(createdBy)
      }
    }
    return new BasicAccessList({
      parents: {
        assignment: [...(resource.assignmentIds || [])],
      },
      roles: Object.keys(resource.roles).reduce((acc, key) => {
        acc[key] = [...resource.roles[key]]
        return acc
      }, {}),
    })
  },
  course: async (resourceId, resource) => {
    return new BasicAccessList({
      parents: {
        organization: [resource.orgId],
      },

      roles: Object.keys(resource.roles).reduce((acc, key) => {
        acc[key] = [...resource.roles[key]]
        return acc
      }, {}),
    })
  },
  organization: async (resourceId, resource) => {
    const existingAcl = await getAclIfItExists(paths.organization(resourceId))
    if (existingAcl) return existingAcl

    return new BasicAccessList({
      roles: Object.keys(resource.roles).reduce((acc, key) => {
        acc[key] = [...resource.roles[key]]
        return acc
      }, {}),
    })
  },
  section: async (resourceId, resource) => {
    const existingAcl = await getAclIfItExists(paths.organization(resourceId))
    if (existingAcl) return existingAcl

    return new BasicAccessList({
      parents: {
        course: [resource.courseId],
      },
      roles: Object.keys(resource.roles).reduce((acc, key) => {
        acc[key] = [...resource.roles[key]]
        return acc
      }, {}),
    })
  },
  loggerSession: async (resourceId, resource) => {
    const acl = await getAcl(paths.loggerSession(resourceId))
    if (
      resource.assignmentId &&
      !acl.hasParent('assignment', resource.assignmentId)
    ) {
      acl.addParent('assignment', resource.assignmentId)
    }
    if (resource.userId) {
      acl.addRole('Owner', resource.userId)
    }
    return acl
  },
  note: async (resourceId, resource) => getAcl(paths.note(resourceId)),
  noteBoard: async (resourceId, resource) =>
    getAcl(paths.noteBoard(resourceId)),
  researchNotebook: async (resourceId, resource) =>
    getAcl(paths.researchNotebook(resourceId)),
}
