/**
 * LoggingSessionChunk is a serializable class that represents a discrete chunk of data for a logging session.
 */

import { eventFromCsvRow } from '../Event/utils'

export const CHUNK_SIZE_LIMIT = 450000

export class LoggingSessionChunk {
  /**
   * Create a new LoggingSessionChunk object from a plain JSON object.
   *
   * @param data {object} The plain JSON object.
   * @param data.id {string} The ID of this chunk.
   * @param data.csvEvents {string[]} The events in this chunk, as CSV strings.
   * @param data.session {object} The session that owns this chunk.
   * @param data.sessionId {string} The ID of the session that owns this chunk.
   * @param data.userKey {string} The user key of the user that owns this chunk.
   * @param data.optimized {boolean} Whether this chunk has been optimized - i.e. whether it has been cleaned up, with redundant/unneeded events removed.
   * @param data.size {number} The estimated size of this chunk, in bytes.
   *
   *
   */
  constructor({ id, csvEvents, userKey, optimized, size, session, sessionId }) {
    this.sessionId = sessionId || session?.userKey || userKey
    this.csvEvents = csvEvents
    this.id = id
    this.optimized = optimized
    this.size = size
  }

  get events() {
    return this.csvEvents.map(eventFromCsvRow)
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {{assignmentId: string, courseId: string, organizationId: string, id: string, startTime: number, endTime: number|null, userId: string}}
   */
  toJson() {
    return {
      id: this.id,
      csvEvents: [...this.csvEvents],
      sessionId: this.sessionId,
      optimized: !!this.optimized,
      size: this.size,
    }
  }

  static toFirestore(chunk) {
    return chunk.toJson()
  }

  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    return new LoggingSessionChunk(data)
  }
}
