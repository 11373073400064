import { RunningCalculator } from './RunningCalculator'
import { coreTimelineFilters } from './utils/coreTimelineFilters'
import { CommonUrlCategoryDataNode } from './utils/CommonUrlCategoryDataNode'

/**
 * Common UrlCategoriesRc stores category metadata information about categories across
 * multiple logging sessions.
 */
export class CommonUrlCategoriesRc extends RunningCalculator {
  root
  constructor(storageObj) {
    super('commonUrlCategoriesRc', ['browserRc', 'urlCategoriesRc'])
    this.root = new CommonUrlCategoryDataNode(storageObj?.root || {})
  }

  toJson() {
    return {
      ...super.toJson(),
      root: (this.root || new CommonUrlCategoryDataNode({})).toJson(),
    }
  }

  transition(event, stateData) {
    let stateChanged = false
    const { secrets, timeRange } = stateData
    if (event.eventType === 'metadata' && event.key === 'category') {
      // Half of this running calculator collects metadata about URL categories
      const category = secrets[event.value]
      const url = secrets[event.url]
      if (
        url &&
        category &&
        category.split('/').filter((x) => !!x).length > 0
      ) {
        const categoryArray = category.split('/').filter((x) => !!x)
        stateChanged ||= this.root.ensureCategoryForUrl(categoryArray, url)
      }
    }
    let url = secrets[stateData.browserRc.focusedTab?.url]

    if (event.time >= timeRange.start && event.time <= timeRange.stop) {
      const browserTime = Math.max(timeRange.start, stateData.browserRc.time)
      const eventTime = Math.min(timeRange.stop, event.time)
      if (
        url &&
        coreTimelineFilters.focusedOnValidUrl(stateData) &&
        eventTime - browserTime > 0
      ) {
        // The other half of this running calculator calculates times for each URL category
        this.root.getNodesForUrl(url).forEach((node) => {
          node.time += eventTime - browserTime
        })
        stateChanged = true
      }
    }

    const newRoot = this.root
    this.root = null
    this.root = newRoot

    return stateChanged
  }

  addSession(sessionCalcs, sessionSecrets, multiplier = 1) {
    const { urlCategoriesRc } = sessionCalcs
    if (urlCategoriesRc?.root) {
      this.root.add(urlCategoriesRc?.root, sessionSecrets, multiplier)
    } else {
      // No running calculator to draw from.
    }
  }
}
