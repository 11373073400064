import { functions } from '../coreConfig'
import { httpsCallable } from 'firebase/functions'

export async function issueAssignmentKey(assignmentId) {
  const result = await httpsCallable(
    functions,
    'issueAssignmentKey',
  )({ assignmentId })
  console.log('issueAssignmentKey result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.key
}
