import { sectionPortalBreadcrumbs } from "@/views/sections/routeBreadcrumbs";

export const assignmentBreadcrumbs = [
  ...sectionPortalBreadcrumbs,
  {
    dynamicLabel: "assignmentDetail",
  },
];

// Use the portal breadcrumbs if they are not the last item in the breadcrumbs array
export const assignmentPortalBreadcrumbs = [
  ...sectionPortalBreadcrumbs,
  {
    dynamicLabel: "assignmentDetail",
    toName: "AssignmentPortal",
  },
];
