import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

/**
 * Send a message through Firebase Cloud Messaging to all students for a given
 * resource (course, section, or assignment).  This requires either Instructor
 * or Admin permissions over the resource.  Any student IDs given that do not match
 * students in the resource will be ignored.
 *
 * @param {string} resourceType The resource type to query for student ids to send the message to - either 'course', 'section', or 'assignment'.
 * @param {string} resourceId The id of the resource
 * @param {Object} data The data to send.
 * @param {string[]} [studentIds] The ids of the students to send the message to, or null to send to all students found.
 * @returns {Promise<Object>} The result.
 */
export async function sendCloudMessageToStudents(
  resourceType,
  resourceId,
  data,
  studentIds = null,
) {
  const result = await httpsCallable(
    functions,
    'sendCloudMessageToStudents',
  )({
    resourceType,
    resourceId,
    data,
    studentIds,
  })
  console.log('CloudMessageService.sendCloudMessageToStudents result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.data
}
