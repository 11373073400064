import { nanoid } from 'nanoid'
import { Section } from '@learnics/models/src/Section/Section'
import { saveSection } from '../section/saveSection'
import { updateCourse } from './updateCourse'
import { getAssignmentsByCourseId } from '../assignment/getAssignmentsByCourseId'
import { updateAssignment } from '../assignment/updateAssignment'

export async function upgradeCourseToUseSections(
  courseId,
  newSectionName,
  forced = false,
) {
  const course = await this.getCourse(courseId)
  if (!course) throw new Error('Course not found')
  if (course.hasSections && !forced) {
    console.warn('Course ' + courseId + ' already upgraded, skipping upgrade')
    return
  }
  let classroomCourseId = course.classroomCourseId
  if (course.googleClassroom && !course.classroomCourseId) {
    // Support old courses that have googleClassroom set to true and no classroomCourseId.
    // They stored the id on the course itself.
    classroomCourseId = course.id
  }
  // Create a new section given the name from the user.
  const section = new Section({
    name: newSectionName,

    id: nanoid(),
    description: '',
    archived: false,
    orgId: course.orgId,
    courseId: course.id,
    created: course.created || +new Date(),
    updated: +new Date(),
    roles: {
      Learner: [...(course.roles.Learner || [])],
      Instructor: [],
      Administrator: [],
    },
    preferredCitationFormat: null,
    classroomCourseId,
  })

  console.log('Saving section: ', section)
  try {
    await saveSection(section)
  } catch (err) {
    console.log('Error saving section: ', err)
  }

  const allAssignments = await getAssignmentsByCourseId(courseId, null, true)
  const allAssignmentIds = Object.keys(allAssignments)
  for (let i = 0; i < allAssignmentIds.length; i++) {
    const assignment = allAssignments[allAssignmentIds[i]]
    console.log(
      'Updating assignment: ',
      allAssignmentIds[i],
      ' with sectionId: ',
      section.id,
    )
    try {
      await updateAssignment(assignment.id, {
        sectionId: section.id,
      })
    } catch (err) {
      console.log('Error updating assignment: ', err)
    }
  }
  console.log(
    'Updating course: ',
    courseId,
    ' with hasSections: true, classroomCourseId: null, and Learner: []',
  )
  try {
    await updateCourse(courseId, {
      classroomCourseId: null,
      hasSections: true,
      Learner: [],
    })
  } catch (err) {
    console.log('Error updating course: ', err)
  }
  course.roles.Learner = []
  course.hasSections = true
  return {
    course,
    section,
  }
}
