/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { addStudentCopyDriveFiles } from './addStudentCopyDriveFiles'
import { deleteStudentCopyDriveFiles } from './deleteStudentCopyDriveFiles'
import { ensureDriveFileAttachments } from './ensureDriveFileAttachments'
import { getFile } from './getFile'
import { getStudentCopyDriveFiles } from './getStudentCopyDriveFiles'
import { getStudentCopyDriveFilesByDriveFileId } from './getStudentCopyDriveFilesByDriveFileId'

const GoogleDriveService = {
  addStudentCopyDriveFiles,
  deleteStudentCopyDriveFiles,
  ensureDriveFileAttachments,
  getFile,
  getStudentCopyDriveFiles,
  getStudentCopyDriveFilesByDriveFileId,
}

export default GoogleDriveService
