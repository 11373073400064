import { getUserDocument } from '../user/getUserDocument'
import { getSubmissionEntries } from './getSubmissionEntries'
import { Entry } from '@learnics/models/src/Organization/Course/Assignment/Submission/Entry'
import { Timestamp } from 'firebase/firestore/lite'
import { getSitesForEntry } from './getSitesForEntry'

export async function loadUserSubmission(assignmentId, userId) {
  const submission = { id: userId, uid: userId }
  const user = await getUserDocument(userId)
  if (user) {
    submission.user = user
    submission.user.uid = userId
    submission.user.id = userId
  } else {
    submission.user = { uid: userId, id: userId }
  }
  const entries = []
  const allSites = []

  await getSubmissionEntries(assignmentId, userId, async (entry) => {
    entry = Entry.firestoreConverter(Timestamp.fromDate).toFirestore(entry)
    entry.startStopTimes = entry.startStopTimes.map((ts) => {
      return (ts.toDate ? ts.toDate() : new Date(ts)).toISOString()
    })
    // if there's no name, add it now
    if (!submission.user.name) {
      submission.user.name = entry.displayName
    }
    let siteArray = []
    // if site array document doesnt exist, get it from sites doc
    if (!entry.sites || !entry.sites.length) {
      const sites = await getSitesForEntry(assignmentId, userId, entry.id)

      Object.keys(sites).forEach((key) => {
        const site = sites[key]
        site.hostName = extractHostnameFromUrl(site.url)
        if (site.time > 1 && !site.url.startsWith('chrome://')) {
          allSites.push(site)
          siteArray.push(site)
        }
      })
    } else {
      entry.sites.forEach((s) => {
        if (s.time > 1 && !s.url.startsWith('chrome://')) {
          s.hostName = extractHostnameFromUrl(s.url)
          allSites.push(s)
        }
      })
    }
    entry.sites = siteArray
    entries.push(entry)
  })

  submission.entries = entries
  submission.sites = allSites
  return submission
}

/**
 * TODO: Is all this necessary? Can we not just use the URL constructor?  For example:
 *       ```js
 *       const url = new URL("https://www.example.com:3000/pathname/?search=test#hash");
 *       console.log(url.hostname); // "www.example.com"
 *       ```
 */
export function extractHostnameFromUrl(url) {
  let hostname
  //find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2]
  } else {
    hostname = url.split('/')[0]
  }
  //find & remove port number
  hostname = hostname.split(':')[0]
  //find & remove "?"
  hostname = hostname.split('?')[0]
  return hostname
}
