import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function respondToInvitation(invitationId, accepted = true) {
  if (!invitationId) throw new Error('invitationId is required')
  const cloudFuncResult = await httpsCallable(
    functions,
    'respondToInvitationOrAccessRequest',
  )({
    invitationId,
    accepted,
  })
  if (!cloudFuncResult.data.success) {
    throw new Error(cloudFuncResult.data.error)
  }
  return cloudFuncResult.data.data
}
