import { allCsvColumns } from '@learnics/models/src/Organization/Course/Assignment/Event/utils/eventTypeMaps'
import { getUserDocument } from '../user/getUserDocument'
import { loadEventDataForSessionIds } from './loadEventDataForSessionIds'
import * as Papa from 'papaparse'

export async function generateEventsCsv(assignmentId, sessionIdArrayMap) {
  const columns = ['userId', 'email', 'name', ...allCsvColumns()]
  const userIds = Object.keys(sessionIdArrayMap)
  const rows = []
  for (let i = 0; i < userIds.length; i++) {
    const userId = userIds[i]
    let sessionIds = sessionIdArrayMap[userId]
    if (!Array.isArray(sessionIds)) {
      sessionIds = [sessionIds]
    }

    let userDoc = (await getUserDocument(userId)) || {
      email: '',
      name: '',
    }
    const { composite } = await loadEventDataForSessionIds(sessionIds)
    let { events, secrets, keysBlacklisted, keysDeleted } = composite

    events
      .filter((e) => e.eventType !== 'empty')
      .forEach((event) => {
        event.reveal(secrets)
        const result = event.toJson()
        result.userId = userId
        result.email = userDoc.email
        result.name = userDoc.name
        rows.push(result)
      })
  }
  const csv = Papa.unparse(rows, {
    quotes: false,
    dynamicTyping: true,
    header: true,
    columns,
  })
  return csv
}
