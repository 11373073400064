/**
 * A blacklist of urls that should not be tracked.
 *
 */

export const systemBlacklist = {
  // If the URL starts with any of these strings, it should be blacklisted.
  prefixes: [
    'about:blank',
    'chrome://',
    'chrome-extension://',
    'https://app.learnics.com/',
    'https://classroom.google.com/',
  ],
  // If the URL contains any of these strings, it should be blacklisted.
  any: ['schoology.com'],
}
