var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g(_vm._b({
    ref: "mainContainer",
    staticStyle: {
      "position": "relative"
    }
  }, 'div', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    ref: "elementContainer"
  }, [_vm.renderElement && !_vm.hideElement ? _c('div', {
    staticStyle: {
      "padding": "1px 0"
    }
  }, [_c('div', {
    staticStyle: {
      "margin": "-1px 0"
    }
  }, [_vm._t("default")], 2)]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }