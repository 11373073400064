import { AbstractEvent } from './AbstractEvent'

/**
 * A class to represent a window blur event.  This event only ever occurs when
 * the students' browser window loses focus.  It has no associated tab ID.
 *
 *   @inheritDoc
 */
export class WindowBlurEvent extends AbstractEvent {
  static COLUMNS = [...AbstractEvent.COLUMNS]
  /**
   * Constructor for the WindowBlurEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain WindowBlurEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {boolean} [input.implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   * @constructor
   */

  constructor({ time, implicit }) {
    super('windowBlur', time, false, implicit)
  }
}
