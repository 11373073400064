export const state = {
  editingTagLabel: null,
  editingTagColor: null,
  editingTagNewLabel: null,
}

export const getters = {
  editingTagLabel: (state) => state.editingTagLabel,
  editingTagColor: (state) => state.editingTagColor,
  editingTagNewLabel: (state) => state.editingTagNewLabel,
}

export const mutations = {
  setEditingTag(state, { color, label }) {
    state.editingTagColor = color
    state.editingTagLabel = label
  },
  setEditingTagNewLabel(state, label) {
    state.editingTagNewLabel = label
  },
}

export const actions = {}
