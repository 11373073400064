export function getRunningCalculatorIdsForSession(
  session,
  assignment = null,
  assignmentDetail = null,
) {
  const runningCalcIds = [
    'browserRc',
    'coreRc',
    'integrityFlagRc',
    'urlDataRc',
    'urlTimelinesRc',
    'urlCategoriesRc',
  ]

  switch (session.type) {
    case 'assignment': {
      runningCalcIds.push('submissionsRc')
      runningCalcIds.push('studentCopiesRc')

      if (assignmentDetail.hasExpectations()) {
        runningCalcIds.push('expectationsRc')
        runningCalcIds.push('expectationRatiosRc')
        for (let expectation of assignmentDetail.expectations) {
          if (
            expectation.expectationType === 'externalUrls' &&
            expectation.ratio > 0
          ) {
            runningCalcIds.push('externalUrlsRc')
            break
          }
        }
      }
      if (assignmentDetail.attachments.length > 0) {
        runningCalcIds.push('attachmentsRc')
      }
      break
    }
    case 'oneClick':
      break
  }
  return runningCalcIds
}
