import { attachmentTypeClassMap } from './attachmentTypeClassMap'

/**
 * A utility function to instantiate an Attachment object based on the given JSON object.
 *
 * @param {object} input the JSON object to instantiate from
 * @returns {*} an AbstractAttachment object instantiated from the given JSON object
 * @throws {Error} if the given JSON object does not have a valid attachmentType
 * @
 */
export function attachmentFromJson(input) {
  const AttachmentClass = attachmentTypeClassMap[input.attachmentType]
  if (!AttachmentClass) {
    throw new Error(`Unknown attachment type: ${input.attachmentType}`)
  }
  return new AttachmentClass(input)
}
