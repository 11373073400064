import { AbstractEvent } from './AbstractEvent'

/**
 * A class to represent a TabBlurEvent for logging sessions.  This type of
 * event is recorded when the user changes focus away from a tab in the browser.
 *
 * @example
 * // Concrete initialization and usage
 *
 *
 *
 * // Instantiate the event on the fly, so you can use it to calculate
 * // time and other things
 * const event = new LoggingStartEvent({
 *   time: new Date().getTime(),
 *   eventType:'loggingStart'
 * });
 *
 * // Create a database-friendly version of the event.  This should be a
 * // clone of the concreteEventData
 * const savableEventData = event.toJson();
 *
 * @example
 * // Abstract initialization and usage
 *
 * const databaseEvents = [{
 *   eventType: "loggingStart",
 *   time: 1654887559641,
 * }, {
 *     // ... some other event
 * }];
 *
 * // Instantiate an array of serialized AbstractEvent JSON objects (from
 * // the database), in order to restore their functionality.
 * const events = databaseEvents.map(
 *     event => eventFromJson(event)
 *   );
 *
 *
 * // Create a database-friendly version of an array of events.  This
 * // should be a clone of the databaseEvents array.
 * const savableEventsCopy = events.map(
 *     event => event.toJson()
 *   )
 *
 *   @inheritDoc
 */
export class LoggingStartEvent extends AbstractEvent {
  static COLUMNS = [...AbstractEvent.COLUMNS, 'value']
  /**
   * Constructor for the LoggingStartEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain LoggingStartEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @constructor
   */
  constructor({ time, value }) {
    super('loggingStart', time, false, false)
    this.value = value
  }

  toJson() {
    return {
      ...super.toJson(),
      value: this.value,
    }
  }
}
