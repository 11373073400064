import SectionService from "@learnics/services/src/section";
import Vue from "vue";
import {
  administratorRoles,
  studentRoles,
  teacherRoles,
} from "@learnics/models/src/utils/roles";
import ErrorService from "@learnics/services/src/error";

export const state = () => {
  return {
    sections: {},
    currentSectionId: null,
    areSectionsLoadedForCourse: {},
    areInstructorAssignableSectionsLoaded: false,
    areSectionsLoadingForCourse: {},
    areSectionsLoadedForOrganization: {},
    areSectionsLoadingForOrganization: {},
  };
};

export const getters = {
  getSections: (state) => {
    return state.sections;
  },
  getCurrentSectionId: (state) => {
    return state.currentSectionId;
  },

  getAreSectionsLoadedForCourse: (state) => {
    return state.areSectionsLoadedForCourse;
  },
  getAreSectionsLoadingForCourse: (state) => {
    return state.areSectionsLoadingForCourse;
  },
  getAreInstructorAssignableSectionsLoaded: (state) => {
    return state.areInstructorAssignableSectionsLoaded;
  },

  // ******* COMPUTED PROPERTY GETTERS ******* DO NOT TRY TO MUTATE THESE VALUES ********
  getCurrentSection: (state) => {
    return state.currentSectionId && state.sections[state.currentSectionId];
  },
  getCurrentCourseSections: (state, getters, rootState) => {
    return Object.keys(state.sections)
      .filter(
        (sectionId) =>
          state.sections[sectionId].courseId + "" ===
          rootState.courses.currentCourseId + ""
      )
      .map((sectionId) => state.sections[sectionId])
      .sort((a, b) => {
        return b.created - a.created;
      });
  },
};

export const mutations = {
  addSection(state, payload) {
    Vue.set(state.sections, payload.id, payload);
  },
  setCurrentSectionId(state, payload) {
    Vue.set(state, "currentSectionId", payload);
  },
  updateInstructorAssignableSectionsLoadedStatus(state, payload) {
    Vue.set(state, "areInstructorAssignableSectionsLoaded", payload);
  },
  updateSectionCourseLoadedStatus(state, payload) {
    Vue.set(state.areSectionsLoadedForCourse, payload.courseId, payload.status);
  },
  updateSectionCourseLoadingStatus(state, payload) {
    Vue.set(
      state.areSectionsLoadingForCourse,
      payload.courseId,
      payload.status
    );
  },
  updateSectionOrganizationLoadedStatus(state, payload) {
    Vue.set(
      state.areSectionsLoadedForOrganization,
      payload.orgId,
      payload.status
    );
  },
  updateSectionOrganizationLoadingStatus(state, payload) {
    Vue.set(
      state.areSectionsLoadingForOrganization,
      payload.orgId,
      payload.status
    );
  },
  removeSection(state, payload) {
    Vue.delete(state.sections, payload.id);
  },

  updateSectionRoles(state, payload) {
    const { id, role, uids } = payload;
    const section = state.sections[id];
    if (!section) throw new Error("Section not found in store.");
    section.roles[role] = uids || [];
  },
};

export const actions = {
  async loadSections({ state, commit, rootState }, args = { forced: false }) {
    const courseId = args.courseId;
    let roles = args.roles;
    if (!roles) {
      roles = [...teacherRoles, ...administratorRoles, ...studentRoles];
    }
    let callback = args.callback || (async () => {});
    if (!courseId) throw new Error("No courseId provided");
    if (
      (!args.forced && state.areSectionsLoadedForCourse[courseId]) ||
      state.areSectionsLoadingForCourse[courseId]
    )
      return;
    commit("updateSectionCourseLoadingStatus", { courseId, status: true });

    let uid = rootState.auth.user.uid;
    console.log(
      "Actually fetching sections for user",
      uid,
      "in course",
      courseId,
      "with roles",
      roles
    );

    if (roles.includes("Administrator")) {
      try {
        console.warn("Loading all sections for course", courseId);
        await SectionService.getAllSectionsForCourse(courseId, (section) => {
          commit("addSection", section);
          callback(section);
          // commit("addSection", section)
        });
      } catch (err) {
        console.log("Error getting user sections", err);
        throw err;
      }
    } else {
      for (let role of roles) {
        try {
          await SectionService.getSections(courseId, uid, role, (section) => {
            commit("addSection", section);
            callback(section);
          });
        } catch (err) {
          console.log("Error getting user sections", err);
          throw err;
        }
      }
    }

    commit("updateSectionCourseLoadingStatus", { courseId, status: false });
    commit("updateSectionCourseLoadedStatus", { courseId, status: true });
  },
  async loadSectionsByOrgId(
    { state, commit, rootState },
    args = { forced: false }
  ) {
    const orgId = args.orgId;
    let roles = args.roles;
    if (!roles) {
      roles = [...teacherRoles, ...administratorRoles, ...studentRoles];
    }
    let callback = args.callback || (async () => {});
    if (!orgId) throw new Error("No orgId provided");
    if (
      (!args.forced && state.areSectionsLoadedForOrganization[orgId]) ||
      state.areSectionsLoadedForOrganization[orgId]
    )
      return;
    commit("updateSectionOrganizationLoadingStatus", { orgId, status: true });

    let uid = rootState.auth.user.uid;
    console.log(
      "Actually fetching sections for user",
      uid,
      "in organization",
      orgId,
      "with roles",
      roles
    );

    for (let role of roles) {
      try {
        await SectionService.getSectionsByOrgId(orgId, uid, role, (section) => {
          commit("addSection", section);
          callback(section);
        });
      } catch (err) {
        console.log("Error getting user sections", err);
        throw err;
      }
    }

    commit("updateSectionOrganizationLoadingStatus", {
      orgId,
      status: false,
    });
    commit("updateSectionOrganizationLoadedStatus", {
      orgId,
      status: true,
    });
  },
  async loadAssignableSectionsForInstructor(
    { state, commit, rootState },
    args = { forced: false }
  ) {
    let callback = args.callback || (async () => {});
    if (!args.forced && state.areInstructorAssignableSectionsLoaded) return;

    let uid = rootState.auth.user.uid;
    try {
      await SectionService.getAllAssignableSectionsForInstructor(
        uid,
        (section) => {
          commit("addSection", section);
          callback(section);
          // commit("addSection", section)
        }
      );
    } catch (err) {
      console.log("Error getting user sections", err);
      throw err;
    }

    commit("updateInstructorAssignableSectionsLoadedStatus", true);
  },

  async loadSection({ state, commit, rootState }, args = { forced: false }) {
    const sectionId = args.sectionId;
    if (!sectionId) throw new Error("No sectionId provided");
    if (!args.forced && state.sections[sectionId]) {
      console.log(
        "Course already loaded, skipping load",
        state.sections[sectionId]
      );
      return;
    }

    try {
      const sectionData = await SectionService.getSection(sectionId);
      if (sectionData) {
        commit("addSection", sectionData);
      }
    } catch (err) {
      console.log("Error loading section:", err);
      // throw err;
      await ErrorService.saveError2(
        JSON.parse(JSON.stringify(err)),
        "There was an unexpected error loading a section.",
        null,
        null,
        null,
        "VueApp",
        err.stack
      );
    }
  },

  async archiveSection({ state, dispatch, commit, rootState }, args = {}) {
    const sectionId = args.sectionId;
    if (!sectionId) throw new Error("No sectionId provided");
    await SectionService.archiveSection(sectionId, true, true);
    commit("removeSection", { id: sectionId });
  },
};
