import { UserData } from '../../UserData/UserData'

/**
 * A class to convert UserData to and from Firestore.
 */
export class UserDataFirestoreConverter {
  static toFirestore(user) {
    return user.toJson()
  }
  static fromFirestore(snapshot, options) {
    return new UserData({
      ...snapshot.data(options),
      id: snapshot.id,
      uid: snapshot.id,
    })
  }
}
