import { updateDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

/** @deprecated prefer updateCourseRoles2 */
export async function updateCourseRoles(courseId, roles) {
  console.log('Updating course roles... ', roles)
  await updateDoc(refs.course(courseId, false), {
    ...roles,
    updated: +new Date(),
  })
  console.log('Course roles set!')
}
