import { UrlDataRc } from '../UrlDataRc'
import { AttachmentsRc } from '../AttachmentsRc'
import { ExpectationsRc } from '../ExpectationsRc'
import { CoreRc } from '../CoreRc'
import { ExternalUrlsRc } from '../ExternalUrlsRc'
import { BrowserRc } from '../BrowserRc'
import { ExpectationRatiosRc } from '../ExpectationRatiosRc'
import { StudentCopiesRc } from '../StudentCopiesRc'
import { SubmissionsRc } from '../SubmissionsRc'
import { UrlCategoriesRc } from '../UrlCategoriesRc'
import { UrlTimelinesRc } from '../UrlTimelinesRc'
import { DomainDataRc } from '../DomainDataRc'
import { CommonDomainDataRc } from '../CommonDomainDataRc'
import { CommonSearchesRc } from '../CommonSearchesRc'
import { SearchesRc } from '../SearchesRc'
import { CommonSearchWordFrequenciesRc } from '../CommonSearchWordFrequenciesRc'
import { SearchWordFrequenciesRc } from '../SearchWordFrequenciesRc'
import { CommonUrlDataRc } from '../CommonUrlDataRc'
import { CommonUrlCategoriesRc } from '../CommonUrlCategoriesRc'
import { RecentCopiesRc } from '../RecentCopiesRc'
import { IntegrityFlagRc } from '../IntegrityFlagRc'

/**
 * A mapping of calculator IDs to calculator classes.
 */
export const calculatorClassMap = {
  attachmentsRc: AttachmentsRc,
  browserRc: BrowserRc,
  commonDomainDataRc: CommonDomainDataRc,
  commonSearchWordFrequenciesRc: CommonSearchWordFrequenciesRc,
  commonSearchesRc: CommonSearchesRc,
  commonUrlCategoriesRc: CommonUrlCategoriesRc,
  commonUrlDataRc: CommonUrlDataRc,
  coreRc: CoreRc,
  domainDataRc: DomainDataRc,
  expectationRatiosRc: ExpectationRatiosRc,
  expectationsRc: ExpectationsRc,
  externalUrlsRc: ExternalUrlsRc,
  integrityFlagRc: IntegrityFlagRc,
  recentCopiesRc: RecentCopiesRc,
  searchWordFrequenciesRc: SearchWordFrequenciesRc,
  searchesRc: SearchesRc,
  studentCopiesRc: StudentCopiesRc,
  submissionsRc: SubmissionsRc,
  urlCategoriesRc: UrlCategoriesRc,
  urlDataRc: UrlDataRc,
  urlTimelinesRc: UrlTimelinesRc,
}
