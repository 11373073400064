import { Timeline } from './Timeline'
import { UrlCategoryDataBaseNode } from './UrlCategoryDataBaseNode'

/**
 * UrlCategoryDataNode is a node in a tree of categories, where each node has a set of urls
 * and a set of children nodes.  It extends UrlCategoryDataBaseNode to add a timeline property
 * to the tree node. This is used to track the total time spent on a category of urls.
 *
 * @extends UrlCategoryDataBaseNode
 * @class UrlCategoryDataNode
 */
export class UrlCategoryDataNode extends UrlCategoryDataBaseNode {
  timeline = new Timeline({})

  constructor(storageObj) {
    super(storageObj)
    this.timeline = new Timeline(
      storageObj?.timeline || new Timeline({}).toJson(),
    )
  }

  toJson() {
    return {
      ...super.toJson(),
      timeline: (this.timeline || new Timeline({})).toJson(),
    }
  }

  get totalTime() {
    return (
      this.timeline.total +
      Object.values(this.children).reduce((acc, child) => {
        return acc + child.totalTime
      }, 0)
    )
  }

  constructNode(nodeJson = {}) {
    return new UrlCategoryDataNode(nodeJson)
  }
}
