var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "resizable-image",
    class: {
      selected: _vm.selected,
      highlighted: _vm.highlighted
    },
    style: {
      paddingLeft: _vm.dragOffsetLeft + 'px',
      paddingTop: _vm.dragOffsetTop + 'px',
      paddingBottom: _vm.dragOffsetBottom + 'px',
      paddingRight: _vm.dragOffsetRight + 'px'
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_vm._l(_vm.resizeHandles, function (handle) {
    return _c('div', {
      key: handle.klass,
      staticClass: "resize-handle",
      class: handle.klass,
      on: {
        "mousedown": function ($event) {
          return _vm.mouseDownHandler($event, handle.x, handle.y);
        },
        "contextmenu": function ($event) {
          var _vm$mouseUpWindowHand, _vm2;
          (_vm$mouseUpWindowHand = (_vm2 = _vm).mouseUpWindowHandler) === null || _vm$mouseUpWindowHand === void 0 ? void 0 : _vm$mouseUpWindowHand.call(_vm2, $event);
        }
      }
    });
  }), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('v-card', {
          staticClass: "pa-0 transition-swing img-wrapper",
          attrs: {
            "tile": "",
            "elevation": (hover ? 2 : 0) + (_vm.selected ? 1 : 0) + (_vm.highlighted ? 1 : 0)
          }
        }, [_c('img', _vm._b({
          ref: "img",
          staticStyle: {
            "display": "block"
          },
          style: _vm.imgStyle,
          attrs: {
            "src": _vm.src,
            "draggable": true,
            "data-drag-handle": ""
          }
        }, 'img', _vm.$attrs, false))])];
      }
    }])
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }