import {
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function* getAllLoggerSessions(pageSize = 25) {
  let lastVisible = null
  let hasMore = true

  let index = 0
  while (hasMore) {
    let q = [orderBy('id')]

    if (lastVisible) {
      q.push(startAfter(lastVisible))
    }
    q.push(limit(pageSize))

    const snap = await getDocs(query(refs.loggerSessions(), ...q))

    if (snap.docs.length === 0) {
      hasMore = false
      break
    }

    const result = {}
    for (const sessionDoc of snap.docs) {
      const session = sessionDoc.data()
      result[session.id] = session
    }
    yield { sessions: result, startingIndex: index }

    index += snap.docs.length
    lastVisible = snap.docs[snap.docs.length - 1]
  }
}
