import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

import { loadRosterFromRoles } from '../user/loadRosterFromRoles'

export async function getAssignmentDetailRoster(assignmentDetailId) {
  let assignmentDetail = await getDoc(refs.assignmentDetail(assignmentDetailId))
  if (!assignmentDetail.exists()) return null
  assignmentDetail = assignmentDetail.data()
  return loadRosterFromRoles(assignmentDetail.roles)
}
