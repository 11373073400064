import { UserSiteData } from '../../Organization/Course/Assignment/Submission/UserSiteData'

/** @deprecated */
export class UserSiteDataFirestoreConverter {
  static toFirestore(userSiteData) {
    if (!userSiteData) {
      return null
    }
    return userSiteData.toJson()
  }

  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    if (!data) {
      return null
    }
    return new UserSiteData(data)
  }
}
