import { coreTimelineFilters } from './coreTimelineFilters'

export const createAttachmentFilter =
  (attachmentId) =>
  ({
    browserRc,
    secrets,
    keysBlacklisted,
    keysDeleted,
    assignmentDetail,
    urlFilter,
  }) => {
    if (
      !coreTimelineFilters.focusedOnValidUrl({
        browserRc,
        secrets,
        keysBlacklisted,
        keysDeleted,
        urlFilter,
      })
    )
      return false
    const attachments = assignmentDetail.attachments
    let { url } = browserRc.focusedTab
    url = secrets[url] || url

    const attachment = attachments.find(
      (attachment) => attachment.id === attachmentId,
    )
    return attachment.matchesUrl(url)
  }
