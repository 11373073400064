import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function unlinkGoogleAccount(uid = null) {
  const result = await httpsCallable(functions, 'unlinkGoogleAccount')({ uid })
  console.log('unlinkGoogleAccount result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
}
