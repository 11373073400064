/**
 * A simple class to represent a link between a logging session, a user, and an
 * assignment.
 */
export class AssignmentSessionLink {
  constructor({ sessionId, assignmentId, userId } = {}) {
    this.sessionId = sessionId
    this.assignmentId = assignmentId
    this.userId = userId
  }

  toJson() {
    return {
      sessionId: this.sessionId,
      assignmentId: this.assignmentId,
      userId: this.userId,
    }
  }
}
