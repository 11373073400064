/**
 * BibliographyOptions is class to represent the data model for the settings of a course's Google Classroom integration.
 *
 * @class
 */
export class BibliographyOptions {
  /**
   * Constructor for BibliographyOptions.
   *
   * @param data {object} the data to initialize the BibliographyOptions with
   * @param [data.minTimePerSource] {number} the minimum time per source required for inclusion into the bibliography, in seconds
   * @param [data.minSources] {number} the minimum number of sources required for each students' bibliography
   *
   */
  constructor({ minTimePerSource = 60, minSources = 1 }) {
    this.minTimePerSource = minTimePerSource
    this.minSources = minSources
  }

  toJson() {
    return {
      minTimePerSource: this.minTimePerSource,
      minSources: this.minSources,
    }
  }
}
