import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function deleteStudentCopyDriveFiles(
  assignmentId,
  driveFileIds,
  deleteInGoogleDrive = false,
) {
  try {
    const result = await httpsCallable(
      functions,
      'deleteStudentCopyDriveFiles',
    )({
      assignmentId,
      driveFileIds,
      deleteInGoogleDrive,
    })
    console.log('deleteStudentCopyDriveFiles result: ', result)
    if (!result.data.success) {
      throw new Error(result.data.error)
    }
  } catch (error) {
    console.error(error)
  }
}
