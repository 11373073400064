var render = function render(){
  var _vm$bucketFile, _vm$bucketFile2, _vm$bucketFile3, _vm$bucketFile4, _vm$bucketFile5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('node-view-wrapper', {
    staticClass: "tip-tap-bucket-file",
    class: _vm.nodeClass
  }, [_c('div', {
    staticStyle: {
      "max-width": "100%"
    }
  }, [!_vm.bucketFile && !_vm.loadingBucketFile && !_vm.uploading ? _c('div', [_c('BucketFile', {
    attrs: {
      "file-id": _vm.fileId,
      "selected": _vm.selected,
      "highlighted": _vm.inTextSelection,
      "draggable": true,
      "data-drag-handle": ""
    }
  })], 1) : _vm.loading || !_vm.bucketFile && _vm.uploading ? _c('div', {
    staticClass: "d-flex align-center justify-center",
    staticStyle: {
      "width": "100px",
      "height": "100px"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1) : (_vm$bucketFile = _vm.bucketFile) !== null && _vm$bucketFile !== void 0 && _vm$bucketFile.dateUploaded && _vm.isImage ? _c('div', [_vm.src && (_vm$bucketFile2 = _vm.bucketFile) !== null && _vm$bucketFile2 !== void 0 && _vm$bucketFile2.width && (_vm$bucketFile3 = _vm.bucketFile) !== null && _vm$bucketFile3 !== void 0 && _vm$bucketFile3.height ? _c('ResizableImage', {
    attrs: {
      "width": (_vm$bucketFile4 = _vm.bucketFile) === null || _vm$bucketFile4 === void 0 ? void 0 : _vm$bucketFile4.width,
      "height": (_vm$bucketFile5 = _vm.bucketFile) === null || _vm$bucketFile5 === void 0 ? void 0 : _vm$bucketFile5.height,
      "src": _vm.src,
      "alt": _vm.node.attrs.alt,
      "scale": _vm.node.attrs.scale,
      "selected": _vm.selected,
      "highlighted": _vm.inTextSelection
    },
    on: {
      "resize-end": _vm.onResizeEnd
    }
  }) : _vm._e()], 1) : !_vm.bucketFile || _vm.bucketFile.dateUploaded && !_vm.isImage ? _c('div', [_c('BucketFile', {
    attrs: {
      "file-id": _vm.fileId,
      "selected": _vm.selected,
      "highlighted": _vm.inTextSelection,
      "draggable": true,
      "data-drag-handle": ""
    }
  })], 1) : _c('UploadProgressMeter', {
    attrs: {
      "file": _vm.bucketFile,
      "selected": _vm.selected,
      "highlighted": _vm.inTextSelection,
      "draggable": true,
      "percent-complete": _vm.percentComplete,
      "data-drag-handle": ""
    },
    on: {
      "cancel-upload": _vm.cancelUpload
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }