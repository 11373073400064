import { BucketFile } from '../../BucketFile/BucketFile'

/**
 * A class to convert a BucketFile object to and from Firestore.
 */
export class BucketFileFirestoreConverter {
  static toFirestore(bucketFile) {
    return bucketFile.toDbJson()
  }
  static fromFirestore(snapshot, options) {
    return new BucketFile({
      ...snapshot.data(options),
    })
  }
}
