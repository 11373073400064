import { Timeline } from './utils/Timeline'
import { RunningCalculator } from './RunningCalculator'
import { createExpectationFilter } from './utils/createExpectationFilter'

/**
 * ExpectationsTimelines stores timelines for each expectation of an assignment.
 *
 * This allows for us to calculate the total time spent on an expectation, as well as
 * a 2-dimensional timeline of time spent on each expectation.
 *
 * Excluding the ExternalUrlsExpectation, these timelines are all 100% faithful
 * to the expectations they represent.  The ExternalUrlsExpectation, however,
 * has a 30-second minimum requirement that is not amenable to a running calculator.
 *
 * The ExternalUrlsExpectation timeline is therefore calculated without this
 * requirement.
 *
 * For a running total time of the ExternalUrlsExpectation, please use the
 * ExternalUrlsRc class.  It will calculate the total time spent on URLs with
 * a 30-second minimum requirement as a plain integer (without the timeline), as
 * well as the maximum time across all URLs with time less than 30 seconds.
 *
 * Calculating these 2 figures allows us to visually display the progress for the
 * ExternalUrlsExpectation.
 */
export class ExpectationsRc extends RunningCalculator {
  dataMap = {}
  constructor(storageObj) {
    super('expectationsRc', ['browserRc', 'expectationRatiosRc'])
    const timelineData = storageObj?.dataMap || {}

    Object.keys(timelineData).forEach((expectationId) => {
      this.dataMap[expectationId] = new Timeline(timelineData[expectationId])
    })
  }

  toJson() {
    return {
      ...super.toJson(),
      dataMap: Object.keys(this.dataMap).reduce((acc, key) => {
        acc[key] = this.dataMap[key].toJson()
        return acc
      }, {}),
    }
  }

  transition(event, stateData) {
    let stateChanged = false

    const timeRange = stateData.timeRange
    if (event.time < timeRange.start || event.time > timeRange.stop)
      return stateChanged

    const browserTime = Math.max(timeRange.start, stateData.browserRc.time)
    const eventTime = Math.min(timeRange.stop, event.time)
    if (eventTime - browserTime <= 0) return stateChanged
    const expectations = stateData.assignmentDetail.expectations
    for (const expectation of expectations) {
      this.dataMap[expectation.id] ||= new Timeline()
      if (createExpectationFilter(expectation.id)(stateData)) {
        try {
          this.dataMap[expectation.id].increment(browserTime, eventTime)
          stateChanged = true
        } catch (e) {
          console.error(
            'Error incrementing attachment timeline for attachment',
            expectation,
            'with event',
            event,
            'and stateData',
            stateData,
            e,
          )
        }
      }
    }
    return stateChanged
  }
}
