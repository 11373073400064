import { setDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { nanoid } from 'nanoid'
import { ExtensionInstallation } from '@learnics/models/src/ExtensionInstallation/ExtensionInstallation'

export async function registerInstallation(
  extensionId,
  version,
  fcmRegistrationId,
  userId,
) {
  const installation = new ExtensionInstallation({
    id: nanoid(),
    extensionId,
    version,
    fcmRegistrationId,
    userId,
    lastSeen: +new Date(),
  })
  await setDoc(refs.extensionInstallation(installation.id), installation)
  return installation
}
