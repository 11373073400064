import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function ensureDriveFileAttachments(
  assignmentId,
  attachmentIds = null,
) {
  const result = await httpsCallable(
    functions,
    'ensureDriveFileAttachments',
  )({
    assignmentId,
    attachmentIds,
  })
  console.log('ensureDriveFileAttachments result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
}
