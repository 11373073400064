import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getOrganizationsByDomain(domain) {
  const querySnapshot = await getDocs(
    query(refs.organizations(), where('domain', '==', domain)),
  )
  const result = {}
  for (const orgDoc of querySnapshot.docs) {
    const org = orgDoc.data()
    if (org) {
      result[org.id] = org
    }
  }
  return result
}
