import refs from '../refsLite'
import { serverTimestamp, setDoc } from 'firebase/firestore/lite'
import { nanoid } from 'nanoid'

export function vueHierarchy(elem) {
  try {
    // this function creates a string of the hierarchy that a Vue element is in
    let s = elem.$el.outerHTML
    let cur = elem
    while (s.length < 1000 && cur.$parent) {
      s += cur.$el.outerHTML + '\n'
      cur = cur.$parent
    }
    return s
  } catch (ex) {
    console.warn('Unable to create vueHierarchy: ', ex)
    return ''
  }
}

/** @deprecated **/
export async function saveError(
  error,
  message,
  path = null,
  column = null,
  line = null,
  source = 'VueApp',
  stack = null,
  userEmail = null, // we don't use this yet, but putting it here for future use
) {
  const id = +new Date() + nanoid()
  console.warn('Logging error at /errors/' + id)
  await setDoc(refs.error(id), {
    id,
    error,
    source,
    message,
    path,
    column,
    line,
    created: serverTimestamp(),
    stack,
    userEmail,
  })
}
