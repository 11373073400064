import { nanoid } from 'nanoid'

/**
 * Organization is class to represent the data model for an organization.
 */
export class Organization {
  /**
   * Constructor for Organization.
   *
   * @param data the data to initialize this organization with
   * @param [data.id] {string} the id of this organization
   * @param [data.name] {string} the name of this organization
   * @param [data.description] {string} the description of this organization
   * @param [data.paymentTier] {string} the payment tier of this organization
   * @param [data.domain] {string} the domain of this organization
   * @param [data.roles] { { [[role]]: string[] } } the roles of this organization.  This is a map of role strings to arrays of user ids.
   * @param [data.created] {number} the timestamp of when the organization was created, -1 if not known
   * @param [data.updated] {number} the timestamp of when the organization was last updated, -1 if not known
   */
  constructor({
    id = nanoid(),
    name = 'New Organization',
    description = 'Enter a Description',
    paymentTier = 'free',
    domain = '',
    roles = {},
    created = -1,
    updated,
  }) {
    this.created = created
    this.updated = updated || created
    this.id = id
    this.name = name
    this.description = description
    this.paymentTier = paymentTier
    this.roles = JSON.parse(JSON.stringify(roles))
    this.domain = domain
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      paymentTier: this.paymentTier,
      roles: JSON.parse(JSON.stringify(this.roles)),
      domain: this.domain,
      created: this.created,
      updated: this.updated,
    }
  }
}
