/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { deleteInvitation } from './deleteInvitation'
import { getInvitation } from './getInvitation'
import { getInvitationsForUser } from './getInvitationsForUser'
import { getOpenInvitationsForResource } from './getOpenInvitationsForResource'
import { getOpenInvitationsForUser } from './getOpenInvitationsForUser'
import { inviteUsersToResource } from './inviteUsersToResource'
import { respondToInvitation } from './respondToInvitation'

const InvitationService = {
  deleteInvitation,
  getInvitation,
  getInvitationsForUser,
  getOpenInvitationsForResource,
  getOpenInvitationsForUser,
  inviteUsersToResource,
  respondToInvitation,
}

export default InvitationService
