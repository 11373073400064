import { updateDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { getInstallation } from './getInstallation'

export async function updateInstallation(installationId, updateObj = {}) {
  if (JSON.stringify(updateObj) !== '{}') {
    await updateDoc(refs.extensionInstallation(installationId, false), {
      ...updateObj,
      lastSeen: +new Date(),
    })
  }
  return await getInstallation(installationId)
}
