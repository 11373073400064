import { extractRolesMapFromFirestoreDocument } from '../roles'
import { AssignmentDetail } from '../../Organization/Course/Assignment/AssignmentDetail'

export class AssignmentDetailFirestoreConverter {
  static toFirestore(assignmentDetail) {
    if (!assignmentDetail) {
      return null
    }
    const storageObject = assignmentDetail.toJson()
    delete storageObject.assignments
    const roles = storageObject.roles
    delete storageObject.roles

    return {
      ...storageObject,
      ...roles,
    }
  }
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    if (!data) {
      return null
    }
    const roles = extractRolesMapFromFirestoreDocument(data)
    const id = snapshot.id
    const assignment = new AssignmentDetail({
      ...data,
      id,
      roles,
    })
    return assignment
  }
}
