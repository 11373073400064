import { ExternalUrlsExpectation } from '../Expectation/ExternalUrlsExpectation'
import { Assignment } from '../Assignment'
import { AssignmentDetail } from '../AssignmentDetail'
import { StudentOptions } from '../StudentOptions'
import { attachmentFromClassroomMaterial } from '../Attachment/utils/attachmentFromClassroomMaterial'
import { nanoid } from 'nanoid'

export function dateFromCourseWorkDateTime(courseWorkDate, courseWorkTime) {
  return new Date(
    courseWorkDate.year,
    courseWorkDate.month - 1,
    courseWorkDate.day,
    courseWorkTime.hours,
    courseWorkTime.minutes,
  )
}
export function assignmentFromClassroomCourseWork(
  courseWork,
  userId,
  orgId,
  courseId,
  sectionId,
) {
  const dueDate = courseWork.dueDate
    ? +dateFromCourseWorkDateTime(courseWork.dueDate, courseWork.dueTime)
    : null
  const assignmentId = nanoid()
  const assignment = new Assignment({
    id: assignmentId,
    orgId,
    courseId,
    sectionId,
    accessCode: assignmentId.toUpperCase().slice(-7),
    roles: {
      Instructor: [userId],
      Learner: [],
      Administrator: [userId],
    },
    name: courseWork.title,
    classroomAssignmentId: courseWork.id,
    classroomCourseId: courseWork.courseId,
    classroomDirectLink: courseWork.alternateLink,
    classroomStudentWorkFolderId:
      courseWork.assignment?.studentWorkFolder?.id || null,
    classroomState: 'PUBLISHED',
    createdWithLearnics: false,
    archived: false,
    version: 1,
  })
  const assignmentDetail = new AssignmentDetail({
    id: nanoid(),
    expectedTime: { min: null, max: null },
    createdBy: userId,
    dueDate,
    created: +new Date(),
    scheduleDate: null,
    name: courseWork.title,
    archived: false,
    attachments:
      courseWork.materials?.map(attachmentFromClassroomMaterial) || [],
    points: courseWork.maxPoints || null,
    description: courseWork.description || '',
    expectations: [
      new ExternalUrlsExpectation({ ratio: 1, min: 0, max: 0 }).toJson(),
    ],
    studentOptions: new StudentOptions({}).toJson(),
    googleClassroom: true,
    classroomData: null,
    bibliographyOptions: null,
    assignmentIds: [assignmentId],
  })
  assignment.assignmentDetailId = assignmentDetail.id
  assignmentDetail.assignments = {
    [assignmentId]: assignment,
  }
  return { assignment, assignmentDetail }
}
