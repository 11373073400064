import { systemBlacklist } from './systemBlacklist'
import { systemWhitelist } from './systemWhitelist'

export function defaultUrlFilter(plainUrl) {
  const url = plainUrl?.trim()
  if (!url) return false
  const blacklisted =
    systemBlacklist.prefixes.some((prefix) => url.startsWith(prefix)) ||
    systemBlacklist.any.some((str) => url.includes(str))

  const whitelisted = systemWhitelist.prefixes.some((prefix) =>
    url.startsWith(prefix),
  )
  return !blacklisted || whitelisted
}
