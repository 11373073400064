import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getInstallationsByExtensionId(extensionId) {
  const result = {}
  const querySnapshot = await getDocs(
    query(
      refs.extensionInstallations(),
      where('extensionId', '==', extensionId),
    ),
  )
  for (const doc of querySnapshot.docs) {
    const installation = doc.data()
    if (installation) {
      result[installation.id] = installation
    }
  }
  return result
}
