import { nanoid } from 'nanoid'

/**
 * Section is class to represent the data model for a section.
 */
export class Section {
  /**
   * Constructor for Section.
   *
   * @param data the data to initialize this section with
   * @param [data.id] {string} the id of the section
   * @param [data.name] {string} the name of the section
   * @param [data.description] {string} the description of the section
   * @param [data.archived] {boolean} the archive status of the section
   * @param [data.created] {number} the timestamp of when the section was created, -1 if not known
   * @param [data.updated] {number} the timestamp of when the section was last updated, -1 if not known
   * @param [data.roles] { { [[role]]: string[] } } the roles of this section.  This is a map of role strings to arrays of user ids.
   * @param [data.preferredCitationFormat] {string} The preferred citation format for the section. Student bibliographies will default to this format if non-null.
   * @param [data.classroomCourseId] {string} the ID of the corresponding Google Classroom course, if any
   * @param [data.createdWithLearnics] {boolean} whether the section was created with Learnics (applicable to Google Classroom classes)
   */
  constructor({
    id = nanoid(),
    name = 'New Section',
    description = '',
    archived = false,
    orgId = null,
    courseId = null,
    created = -1,
    updated,
    roles = {},
    preferredCitationFormat = null,
    classroomCourseId = null,
    createdWithLearnics = true,
  }) {
    this.created = created
    this.updated = updated || created
    this.id = id
    this.name = name
    this.description = description
    this.archived = archived
    this.orgId = orgId
    this.courseId = courseId
    this.roles = JSON.parse(JSON.stringify(roles))
    this.preferredCitationFormat = preferredCitationFormat
    this.classroomCourseId = classroomCourseId
    this.createdWithLearnics = !!createdWithLearnics
  }

  toJson() {
    return {
      created: this.created,
      updated: this.updated,
      id: this.id,
      name: this.name,
      orgId: this.orgId,
      courseId: this.courseId,
      description: this.description,
      archived: this.archived,
      roles: JSON.parse(JSON.stringify(this.roles)),
      preferredCitationFormat: this.preferredCitationFormat,
      classroomCourseId: this.classroomCourseId,
      createdWithLearnics: !!this.createdWithLearnics,
    }
  }
}
