import { Site } from '../../../Submission/Site'

/**
 * UrlData holds running calculations for a given url.
 *
 * `titleTimes` is a map from title string to time spent at that title (in ms)
 * `metadata` is a map from metadata key string to metadata value (arbitrary type)
 *
 * A few computed getters are provided:
 *
 * - `time` is the total time spent at this url
 * - `title` is the title of the url.  First from metadata, if it is available.
 *    Next the title with the most time as per chrome.  Otherwise, it is the url itself.
 */
export class UrlData {
  titleTimes
  metadata
  constructor(storageObj) {
    this.titleTimes = JSON.parse(JSON.stringify(storageObj?.titleTimes || {}))
    this.metadata = JSON.parse(JSON.stringify(storageObj?.metadata || {}))
  }

  toJson() {
    return {
      titleTimes: JSON.parse(JSON.stringify(this.titleTimes)),
      metadata: JSON.parse(JSON.stringify(this.metadata)),
    }
  }

  add(otherUrlData) {
    for (const title of Object.keys(otherUrlData.titleTimes)) {
      this.titleTimes[title] ||= 0
      this.titleTimes[title] += otherUrlData.titleTimes[title]
    }
    for (const key of Object.keys(otherUrlData.metadata)) {
      this.metadata[key] = otherUrlData.metadata[key]
    }
  }

  subtract(otherUrlData) {
    const titles = Object.keys(otherUrlData.titleTimes)
    for (const title of titles) {
      this.titleTimes[title] ||= 0
      this.titleTimes[title] -= otherUrlData.titleTimes[title]
      if (this.titleTimes[title] === 0) {
        delete this.titleTimes[title]
      }
    }
    // Subtracting metadata is not supported
  }
  reveal(secrets) {
    const newTitleTimes = {}
    for (const title of Object.keys(this.titleTimes)) {
      newTitleTimes[secrets[title] || title] = this.titleTimes[title]
    }
    this.titleTimes = newTitleTimes
    const newMetadata = {}
    for (const key of Object.keys(this.metadata)) {
      newMetadata[key] = secrets[this.metadata[key]] || this.metadata[key]
    }
    this.metadata = newMetadata
  }

  toSite(url, secrets = null) {
    return new Site({
      url: secrets?.[url] || url,
      title: secrets?.[this.title] || this.title,
      time: this.time,
      otherTitles: Object.keys(this.titleTimes)
        .filter((t) => t !== this.title)
        .map((title) => secrets?.[title] || title),
    })
  }

  get time() {
    return Object.values(this.titleTimes).reduce((acc, time) => acc + time, 0)
  }

  get title() {
    // If we have metadata, return the title from the metadata
    if (this.metadata?.title) {
      return this.metadata.title
    } else if (this.metadata?.extractedPdfTitle) {
      return this.metadata.extractedPdfTitle
    }
    // return the title with the most time spent
    const titles = Object.keys(this.titleTimes || {})
    if (titles.length === 0) return null
    return titles.reduce((acc, title) => {
      if (this.titleTimes[title] > this.titleTimes[acc]) {
        return title
      }
      return acc
    }, titles[0])
  }
}
