import { getFunctions, httpsCallable } from 'firebase/functions'

/** @deprecated prefer getCustomToken.  Even though this uses the standard way,
 * always calling to the base url is better because it works while running emulators. */
export async function getCustomToken2(uid = null) {
  const functions = getFunctions()
  const functionCall = httpsCallable(functions, 'customToken2')
  const result = await functionCall({ uid })
  if (result.data.success) {
    return result.data.customToken
  } else {
    throw new Error(result.data.error)
  }
}
