import { getKeysDeleted } from './getKeysDeleted'

export async function getAllKeysDeleted(sessionId, chunkCount) {
  const keysDeleted = {}

  for (let i = 0; i < chunkCount; i++) {
    const keyData = await getKeysDeleted(sessionId, i)
    Object.keys(keyData).forEach((key) => {
      keysDeleted[key] = Math.max(keysDeleted[key] || 0, keyData[key])
    })
  }
  return keysDeleted
}
