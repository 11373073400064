import { AbstractBrowserTabEvent } from './AbstractBrowserTabEvent'

/**
 * A class for an event to represent students submitting course work.  This can
 * be used to describe any kind of course work submission, be it Google Classroom
 * "Turn In", LTI submissions, plain Learnics submissions, or any other kind of
 * coursework submission.
 *
 *   @inheritDoc
 */
export class AssignmentSubmitEvent extends AbstractBrowserTabEvent {
  static COLUMNS = [...AbstractBrowserTabEvent.COLUMNS, 'value']
  /**
   * Constructor for the AssignmentSubmitEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain AssignmentSubmitEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {boolean} [input.redacted] whether or not this event contains redacted data.
   * @param {boolean} [input.implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   * @param {number} [input.tabId] the associated chrome-based window id
   * @param {boolean} [input.value] Whether or not the assignment was submitted.  If not provided, the default value is `true`.
   *
   * @constructor
   */

  constructor({ time, redacted, implicit, tabId, value }) {
    super('assignmentSubmit', time, redacted, implicit, tabId)
    if (typeof value === 'undefined' || typeof value !== 'boolean') {
      this.value = true
    } else {
      this.value = !!value
    }
  }

  toJson() {
    const result = super.toJson()
    result.value = !!this.value
    return result
  }
}
