import { tipTapExtensions } from './tipTapExtensions'

class ActiveTipTapExtensions {
  constructor() {
    this.extensions = [...tipTapExtensions]
  }

  get() {
    return [...this.extensions]
  }

  add(extension) {
    if (!this.extensions.includes(extension)) {
      this.extensions.push(extension)
    }
    return () => {
      this.remove(extension)
    }
  }

  remove(extension) {
    this.extensions = this.extensions.filter((ext) => ext !== extension)
  }
}

export const activeTipTapExtensions = new ActiveTipTapExtensions()
