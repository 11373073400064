import { dbLite as db } from './coreConfig'
import { collection, doc, Timestamp } from 'firebase/firestore/lite'
import { getRefs } from './getRefs'

/**
 * refsLite.js is a helper file to store functions returning refs for everything we do with the Firestore Lite library.
 *
 * Since service workers in extensions can't always use the full Firestore library, we try to default to using the
 * Firestore Lite library instead.
 *
 * There is, however, a full copy of this file, refs.js, which can be used as needed and exports references to
 * the full Firestore library instead.
 */

export default getRefs(db, doc, collection, Timestamp)
