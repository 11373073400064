import { getDocs, query, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getStudentCopyDriveFiles(
  assignmentId,
  userId = null,
  callback = async () => {},
) {
  const result = {}
  const qParams = [where('assignmentId', '==', assignmentId)]
  if (userId) {
    qParams.push(where('userId', '==', userId))
  }

  const querySnapshot = await getDocs(
    query(refs.studentCopyDriveFiles(), ...qParams),
  )
  for (const fileDoc of querySnapshot.docs) {
    const file = fileDoc.data()
    if (file) {
      result[fileDoc.id] = file
      await callback(file)
    }
  }
  return result
}
