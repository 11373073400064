import { nanoid } from 'nanoid'

/**
 * User is class to represent the data model for a user of the website.
 */
export class User {
  /**
   * Constructor for User.
   *
   * @param data {object} the data to initialize the User with
   * @param data.uid {string} the id of the user
   * @param data.customClaims {object} the custom claims of the user
   * @param data.displayName {string} the name of the user
   * @param data.name {string} the name of the user
   * @param data.email {string} the email of the user
   * @param data.created {number} the creation time of the user
   * @param data.lastSignIn {number} the last sign in time of the user
   * @param data.fcmRegistrationId {string} the last seen FCM registration id of the user
   * @param data.emailsSent {array} the emails sent to the user
   */
  constructor({
    uid = nanoid(),
    name = '',
    customClaims = {},
    displayName = '',
    email = '',
    photoURL = '',
    created = +new Date(),
    lastSignIn = +new Date(),
    fcmRegistrationId = null,
    emailsSent = [],
  }) {
    this.uid = uid
    this.displayName = displayName
    this.name = name
    this.email = email
    this.customClaims = JSON.parse(JSON.stringify(customClaims || {}))
    this.created = created
    this.lastSignIn = lastSignIn
    this.fcmRegistrationId = fcmRegistrationId
    this.photoURL = photoURL
    if (emailsSent) {
      this.emailsSent = [...emailsSent]
    } else {
      this.emailsSent = []
    }
  }

  toJson() {
    return {
      uid: this.uid,
      displayName: (this.displayName || this.name || this.email || '').trim(),
      name: (this.name || this.displayName || this.email || '').trim(),
      email: this.email || null,
      customClaims: JSON.parse(JSON.stringify(this.customClaims || {})),
      created: this.created || null,
      lastSignIn: this.lastSignIn || null,
      fcmRegistrationId: this.fcmRegistrationId || null,
      emailsSent: this.emailsSent || [],
      photoURL: this.photoURL || null,
    }
  }

  get displayCustomClaims() {
    return Object.keys(this.customClaims)
      .sort()
      .map((key) => {
        return `${key}: ${this.customClaims[key]}`
      })
      .join(', ')
  }

  get paymentTier() {
    return this.customClaims.payment_tier || 'free'
  }

  set paymentTier(newTier) {
    this.customClaims.payment_tier = newTier
  }

  get adminRole() {
    if (this.customClaims.root) return 'root'
    if (this.customClaims.dev) return 'dev'
    if (this.customClaims.admin) return 'admin'
    return 'none'
  }

  set adminRole(role) {
    const claims = ['root', 'dev', 'admin']
    let foundClaim = false

    claims.forEach((claim) => {
      if (foundClaim || claim === role) {
        this.customClaims[claim] = true
        foundClaim = true
      } else {
        delete this.customClaims[claim]
      }
    })
  }

  set linkedGoogleAccountId(linkedGoogleAccountId) {
    this.customClaims.linked_google_account_id = linkedGoogleAccountId || false
  }
  get linkedGoogleAccountId() {
    return this.customClaims.linked_google_account_id || false
  }
}
