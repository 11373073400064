/**
 * This file is generated automatically by scripts/generateIndexFiles.js. Do not
 * modify it manually.  This script treats each folder in src as a "Service" and
 * exports all named exports and default exports from each file in the folder.
 *
 * Folders nested deeper than one level are not considered here, and they should
 * be used for utility functions or other purposes that do not need exposed
 * through the service.
 */

import { addTeacherToOrganization } from './addTeacherToOrganization'
import { getAllOrganizations } from './getAllOrganizations'
import { getOrganization } from './getOrganization'
import { getOrganizations } from './getOrganizations'
import { getOrganizationsByDomain } from './getOrganizationsByDomain'
import { saveOrganization } from './saveOrganization'
import { setOrganizationPaymentTier } from './setOrganizationPaymentTier'
import { updateOrganization } from './updateOrganization'
import { updateOrganizationName } from './updateOrganizationName'
import { updateOrganizationRoles } from './updateOrganizationRoles'

const OrganizationService = {
  addTeacherToOrganization,
  getAllOrganizations,
  getOrganization,
  getOrganizations,
  getOrganizationsByDomain,
  saveOrganization,
  setOrganizationPaymentTier,
  updateOrganization,
  updateOrganizationName,
  updateOrganizationRoles,
}

export default OrganizationService
