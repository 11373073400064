/**
 * EventMetaInfo is a serializable class that represents the metadata of a logging session.
 */

export class EventMetaInfo {
  /**
   * Create a new EventMetaInfo object from a plain JSON object.
   *
   * @param data the JSON object to instantiate from
   * @param [data.chunkCount] the number of chunks that have been written to the database
   * @param [data.lastChunkSize] the size of the last chunk available to be written to - deprecated prefer eventsSize over lastChunkSize
   * @param [data.lastEventsSize] the size of the last chunk of events available to be written to
   * @param [data.lastSecretsSize] the size of the last chunk of secrets available to be written to
   * @param [data.lastChunkOptimized] whether the last chunk that has been been written is in an optimized state - i.e. it has been cleaned and redundant/unneeded events have been removed.
   */
  constructor({
    chunkCount = 1,
    lastChunkSize = 0 /** deprecated prefer eventsSize */,
    lastEventsSize = 0,
    lastSecretsSize = 0,
    lastChunkOptimized = true,
  }) {
    this.chunkCount = chunkCount
    this.lastEventsSize = lastEventsSize || lastChunkSize || 0
    this.lastSecretsSize = lastSecretsSize
    this.lastChunkOptimized = !!lastChunkOptimized
  }

  /**
   * @deprecated prefer eventsSize over lastChunkSize
   * @return {*|undefined}
   */
  get lastChunkSize() {
    return this.lastEventsSize
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {any} the JSON object
   */
  toJson() {
    return {
      chunkCount: this.chunkCount,
      lastEventsSize: this.lastEventsSize,
      lastSecretsSize: this.lastSecretsSize,
      lastChunkOptimized: this.lastChunkOptimized,
    }
  }

  static toFirestore(eventMetaInfo) {
    return eventMetaInfo.toJson()
  }

  static fromFirestore(snapshot, options) {
    return new EventMetaInfo({
      ...snapshot.data(options),
    })
  }

  get hasEvents() {
    return this.chunkCount > 1 || this.lastEventsSize > 0
  }
}
