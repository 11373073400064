import { functions } from '../coreConfig'
import { httpsCallable } from 'firebase/functions'

export async function issueAssignmentKeys(assignmentId, uids = []) {
  const result = await httpsCallable(
    functions,
    'issueAssignmentKeys',
  )({ assignmentId, uids })
  console.log('issueAssignmentKeys result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
  return result.data.keys
}
