import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function addTeacherToAssignmentDetail(
  assignmentDetailId,
  uid = null,
) {
  const addToAssignmentDetailResult = await httpsCallable(
    functions,
    'addTeacherToAssignmentDetail',
  )({
    uid,
    assignmentDetailId,
  })
  if (!addToAssignmentDetailResult.data.success) {
    throw new Error(addToAssignmentDetailResult.data.error)
  }
  return addToAssignmentDetailResult.data
}
