import { LoggerSession } from './LoggerSession'

export class OneClickLoggerSession extends LoggerSession {
  constructor({
    id,
    userId,
    researchNotebookId,
    name = 'Session',
    dateCreated = +new Date(),
    tags, // @deprecated - this will be removed after all sessions are successfully upgraded.  Keeping it here is a temporary fail-safe to ensure that we don't lose any tags if something goes wrong.
  }) {
    super(id, 'oneClick', researchNotebookId, name, dateCreated, tags)
    this.userId = userId
  }

  toJson() {
    return {
      ...super.toJson(),
      userId: this.userId,
    }
  }
}
