import Vue from 'vue'
import AssignmentService from '../../assignment'

export const state = {
  assignmentSessionLinks: {},
  loadedAssignmentSessionLinks: {},
  loadingAssignmentSessionLinks: {},
}

export const getters = {
  assignmentSessionLinks: (state) => state.assignmentSessionLinks,
  loadedAssignmentSessionLinks: (state) => state.loadedAssignmentSessionLinks,
  loadingAssignmentSessionLinks: (state) => state.loadingAssignmentSessionLinks,
}

export const mutations = {
  setAssignmentSessionLink: (state, { compositeId, newVal, oldVal }) => {
    const { sessionId } = compositeId
    if (newVal) {
      Vue.set(state.assignmentSessionLinks, sessionId, newVal)
    } else Vue.delete(state.assignmentSessionLinks, sessionId)
  },
  setLoadedAssignmentSessionLink: (state, { assignmentId, userId, value }) => {
    if (!state.loadedAssignmentSessionLinks[assignmentId]) {
      Vue.set(state.loadedAssignmentSessionLinks, assignmentId + '', {})
    }
    Vue.set(
      state.loadedAssignmentSessionLinks[assignmentId],
      userId + '',
      !!value,
    )
  },
  setLoadingAssignmentSessionLink: (state, { assignmentId, userId, value }) => {
    if (!state.loadingAssignmentSessionLinks[assignmentId]) {
      Vue.set(state.loadingAssignmentSessionLinks, assignmentId + '', {})
    }
    Vue.set(
      state.loadingAssignmentSessionLinks[assignmentId],
      userId + '',
      !!value,
    )
  },
}

export const actions = {
  /**
   * Load one or more NoteBoards into the store.
   *
   * NOTE:  This should not be used by the extension.  The extension should
   *        rely on service worker messages to update its entities, which will
   *        then cascade down to the store.
   *
   * @param state
   * @param assignment
   */
  async loadAssignmentSessionLinks(
    { state, dispatch, commit, rootState },
    args = { forced: false, userId: null, assignmentId: null },
  ) {
    const assignmentId = args.assignmentId || null
    const userId = args.userId || null
    const forced = args.forced || false
    console.log('LOADING ASSIGNMENT SESSION LINKS', assignmentId, userId)

    if (
      (state.loadedAssignmentSessionLinks[assignmentId]?.[userId] ||
        state.loadingAssignmentSessionLinks[assignmentId]?.[userId]) &&
      !forced
    )
      return
    commit('setLoadingAssignmentSessionLink', {
      assignmentId,
      userId,
      value: true,
    })
    try {
      const result = await AssignmentService.getAssignmentSessionLinks(
        assignmentId,
        userId,
      )

      console.log('RESULT', result)

      const sessionIds = Object.keys(result)
      for (const sessionId of sessionIds) {
        const link = result[sessionId]
        commit('setAssignmentSessionLink', {
          compositeId: { sessionId: link.sessionId },
          newVal: link,
        })
      }
      commit('setLoadedAssignmentSessionLink', {
        assignmentId,
        userId,
        value: true,
      })
    } catch (e) {
      console.error('Error loading assignment session links', e)
    } finally {
      commit('setLoadingAssignmentSessionLink', {
        assignmentId,
        userId,
        value: false,
      })
    }
  },
}
