/**
 * StudentOptions is class to represent options specific to student logger behavior.
 *
 * @class
 */
export class StudentOptions {
  /**
   * Constructor for StudentOptions.
   *
   * @param data the data to initialize the logger options with
   * @param [data.allowDelete] {boolean} whether the user can delete their logs
   * @param [data.allowPauseLogging] {boolean} whether the user can pause their logger on demand
   * @param [data.allowClassChart] {boolean} whether the user can see a class chart of their logs
   * @constructor
   */
  constructor({
    allowPauseLogging = true,
    allowDelete = true,
    allowClassChart = false,
  }) {
    this.allowPauseLogging = allowPauseLogging
    this.allowDelete = allowDelete
    this.allowClassChart = allowClassChart
  }

  toJson() {
    return {
      allowPauseLogging: this.allowPauseLogging,
      allowDelete: this.allowDelete,
      allowClassChart: this.allowClassChart,
    }
  }
}
