import { OldAssignmentLoggerSession } from './OldAssignmentLoggerSession'
import { OldOneClickLoggerSession } from './OldOneClickLoggerSession'
import { AssignmentLoggerSession } from '../../../../LoggerSession/AssignmentLoggerSession'
import { OneClickLoggerSession } from '../../../../LoggerSession/OneClickLoggerSession'

export function loggerSessionFromJson(json) {
  if (!json) return null
  if (!json.version) {
    switch (json.type) {
      case 'assignment':
        return new OldAssignmentLoggerSession(json)
      case 'oneClick':
        return new OldOneClickLoggerSession(json)
      default:
        throw new Error(`Unknown logger session type: ${json.type}`)
    }
  } else {
    switch (json.type) {
      case 'assignment':
        return new AssignmentLoggerSession(json)
      case 'oneClick':
        return new OneClickLoggerSession(json)
      default:
        throw new Error(`Unknown logger session type: ${json.type}`)
    }
  }
}
