/**
 * A class to represent an abstract browser event.  Every event has a timestamp,
 * an eventType, and a set of properties.
 *
 *
 */
export class AbstractEvent {
  static COLUMNS = ['eventType', 'time', 'redacted', 'implicit']

  /**
   * Abstract constructor for the abstract event.
   * @param {string} [eventType] the eventType of this event.  Subclasses should each supply their own unique type.
   * @param {number} [time] the Unix-based timestamp (e.g. `new Date().getTime()`)of this event.  If not provided, the current time will be used.
   * @param {boolean} [redacted] whether or not this event contains redacted data.
   * @param {boolean} [implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   */
  constructor(eventType, time, redacted = false, implicit = false) {
    if (this.constructor === AbstractEvent) {
      throw new Error(
        `AbstractEvent objects may not be directly instantiated.
                  You probably want to use one of the subclasses.`,
      )
    }
    this.eventType = eventType
    this.time = time ?? new Date().getTime()
    this.redacted = !!redacted
    this.implicit = !!implicit
  }

  /**
   * Convert this event to a plain JSON object.
   * @returns {object}
   */
  toJson() {
    const result = {
      eventType: this.eventType,
      time: this.time,
      redacted: !!this.redacted,
      implicit: !!this.implicit,
    }
    return result
  }

  /**
   * Redact this event.  Use the provided hash function to redact any sensitive data.  Be sure to
   * set the `redacted` property to true if you do redact any data, and return a map of the redacted
   * data to the original data.
   *
   * @param hashFunction a function that takes a string and returns a redacted string.
   * @returns {{}} a map of redacted values to their original values.
   */
  redact(hashFunction) {
    // Nothing to do here, but subclasses can override this method to redact sensitive data.
    // All the data here is non-sensitive.
    return {}
  }

  reveal(secrets) {
    // Nothing to do here, but subclasses can override this method to reveal sensitive data, given a
    // map of redacted values to their original values.
  }

  redactedValues() {
    return []
  }
}
