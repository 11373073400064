import { AbstractAttachment } from './AbstractAttachment'

/**
 *
 * A class to represent YouTube Video Attachment objects.  This class is used
 * to store metadata about YouTube videos attached to an assignment.  It is a
 * subclass of AbstractAttachment.
 *
 * @example
 * // Concrete initialization and usage
 * const concreteAttachmentData = {
 *   id: "some-learnics-based-youtube-video-attachment-id",
 *   formUrl: "https://some-form-url.com/my-form",
 *   responseUrl: "https://some-form-response-url.com/my-form-response",
 *   title: "some form title!",
 * }
 *
 * // Instantiate the attachment on the fly
 * const attachment = new YoutubeVideoAttachment(concreteAttachmentData);
 *
 * // Create a database-friendly version of the YoutubeVideoAttachment object.
 * // This should be a clone of the concreteAttachmentData (unless it has
 * // been changed in the meantime), except with an added attachmentType property
 * // set to "youtubeVideo".
 * const savableAbstractAttachmentData = attachment.toJson();
 *
 *
 * @example
 * // Abstract initialization and usage
 *
 * const abstractDatabaseAttachments = [{
 *   attachmentType: "youtubeVideo",
 *   id: "some-learnics-based-youtube-video-attachment-id",
 *   youtubeId: "some-youtube-based-id",
 *   alternateLink: "https://www.youtube.com/watch?v=some-youtube-based-id",
 *   title: "some youtube video title!",
 * }, {
 *     // ... some other AbstractAttachment data
 * }];
 *
 * // Instantiate an array of serialized AbstractAttachment JSON objects
 * // (from the database), in order to restore their functionality, without ever
 * // having to go through each one of them and individually do it
 * const attachments = abstractDatabaseAttachments.map(
 *     attachment => attachmentFromJson(attachment)
 *   );
 *
 * attachments.forEach((attachment) => {
 *   // Easily and concisely use it to check if a given url matches it, without
 *   // caring about the particulars
 *   const isUrlAMatch = attachment.matchesUrl(url);
 * });
 *
 * // Create a database-friendly version of an array of attachments.  This should
 * // be a clone of the abstractDatabaseAttachments array (unless it has been
 * // changed in the meantime).
 * const savableAbstractAttachmentsCopy = attachments.map(
 *     attachment => attachment.toJson()
 *   );
 *
 * @inheritDoc
 */
export class YoutubeVideoAttachment extends AbstractAttachment {
  /**
   * Constructor for a YoutubeVideoAttachment object.
   *
   * @param data {object} the data to initialize the FormAttachment with
   * @param data.id {string} the Learnics-based id of the attachment
   * @param data.title {string} the title of the video
   * @param data.youtubeId {string} the id of the video on YouTube
   * @param data.url {string} a url pointing to this video
   * @param data.alternateLink {string} the url to the video on YouTube, if any
   */
  constructor({ id, title, youtubeId, url, alternateLink }) {
    super('youtubeVideo', id, title, url)
    this.youtubeId = youtubeId
    this.alternateLink = alternateLink
  }

  /**
   * @returns {boolean} true if this attachment equals another attachment, false otherwise
   */

  equals(attachment) {
    return (
      attachment?.attachmentType === this.attachmentType &&
      (this.id === attachment.id ||
        this.url === attachment.url ||
        this.youtubeId === attachment.youtubeId)
    )
  }

  get icon() {
    return 'fa-youtube'
  }

  toJson() {
    const json = super.toJson()
    json.youtubeId = this.youtubeId
    json.alternateLink = this.alternateLink
    return json
  }

  /**
   * Returns true if the given url matches the url of the video on YouTube.
   *
   * @param {string} url the url to check
   * @returns {boolean} true if the url matches the url of the video on YouTube
   */
  matchesUrl(url) {
    return (
      (this.url && AbstractAttachment.areUrlsMostlyEqual(url, this.url)) ||
      (this.alternateLink &&
        AbstractAttachment.areUrlsMostlyEqual(url, this.alternateLink)) ||
      AbstractAttachment.areUrlsMostlyEqual(
        url,
        `https://www.youtube.com/watch?v=${this.youtubeId}`,
      ) ||
      false
    )
  }

  toClassroomMaterial() {
    return {
      youtubeVideo: {
        id: this.id,
      },
    }
  }
}
