import { getSecrets } from './getSecrets'

export async function getAllSecrets(sessionId, chunkCount) {
  const secrets = {}

  for (let i = 0; i < chunkCount; i++) {
    const chunkSecrets = await getSecrets(sessionId, i)
    Object.assign(secrets, chunkSecrets)
  }
  return secrets
}
