import { AbstractBrowserTabEvent } from './AbstractBrowserTabEvent'
import { middleTruncateText } from '../../../../utils/middleTruncateText'

/**
 * A class to represent a PasteEvent for logging sessions.
 * This type of event is recorded when a student pastes text into a Google Doc.
 */
export class PasteEvent extends AbstractBrowserTabEvent {
  static COLUMNS = [
    ...AbstractBrowserTabEvent.COLUMNS,
    'url',
    'text',
    'length',
    'attributedCopyTextSimilarity',
    'attributedCopyUrl',
    'attributedCopyText',
  ]
  /**
   * Constructor for the PasteEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain PasteEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {boolean} [input.redacted] whether or not this event contains redacted data.
   * @param {string} [input.url] the url of where the text was pasted.  This may be null if it's not needed.
   * @param {string} [input.text] the pasted text. This might be middle-truncated and ellipsized.
   * @param {number} [input.length] the length of the UNTRUNCATED pasted text.
   * @param {number} [input.attributedCopyTextSimilarity] the Jaro-Winkler text similarity between this PasteEvent's text and the attributed CopyEvent's text. This should be a float between 0 and 1. May be null.
   * @param {string} [input.attributedCopyUrl] the URL from the attributed CopyEvent. May be null. This is de-normalized data being stored here for performance & ease of use.
   * @param {string} [input.attributedCopyText] the text from the attributed CopyEvent. May be null.This is de-normalized data being stored here for performance & ease of use.
   * @constructor
   **/

  constructor({
    time,
    redacted,
    url,
    text,
    length,
    attributedCopyTextSimilarity,
    attributedCopyUrl,
    attributedCopyText,
  }) {
    super('paste', time, redacted, false)
    this.url = url
    this.text = middleTruncateText(text)
    this.length = length
    this.attributedCopyTextSimilarity = attributedCopyTextSimilarity
    this.attributedCopyUrl = attributedCopyUrl
    this.attributedCopyText = attributedCopyText
  }

  static MINIMUM_FLAGGABLE_PASTE_SIZE = 50

  toJson() {
    const result = super.toJson()
    result.url = this.url
    result.text = this.text
    result.length = this.length
    result.attributedCopyTextSimilarity = this.attributedCopyTextSimilarity
    result.attributedCopyUrl = this.attributedCopyUrl
    result.attributedCopyText = this.attributedCopyText
    return result
  }

  redactHelper(hashFunction, result, k) {
    if (this[k] && typeof this[k] === 'string') {
      const redactedValue = hashFunction(this[k])
      result[redactedValue] = this[k]
      this[k] = redactedValue
    }
  }

  redact(hashFunction) {
    const result = super.redact(hashFunction)
    if (this.redacted) return result

    this.redactHelper(hashFunction, result, 'url')
    this.redactHelper(hashFunction, result, 'text')
    this.redactHelper(hashFunction, result, 'attributedCopyUrl')
    this.redactHelper(hashFunction, result, 'attributedCopyText')
    this.redacted = true

    return result
  }

  reveal(secrets) {
    if (!this.redacted) return
    if (!secrets)
      throw new Error('Cannot reveal secrets without a secrets object')

    const urlRevealed = !this.url || this.url in secrets
    const textRevealed = !this.text || this.text in secrets

    if (urlRevealed && textRevealed) {
      this.url &&= secrets[this.url]
      this.text = secrets[this.text]
      this.attributedCopyUrl = secrets[this.attributedCopyUrl]
      this.attributedCopyText = secrets[this.attributedCopyText]
      this.redacted = false
    }
  }

  redactedValues() {
    if (!this.redacted) {
      return []
    }
    const values = []
    values.push(this.url)
    values.push(this.text)
    values.push(this.attributedCopyUrl)
    values.push(this.attributedCopyText)
    return values
  }

  shouldBeFlagged() {
    // this will work even if the event is redacted, because
    // this.length doesn't get redacted, and if
    // attributedCopyUrl and url are redacted, they will have the
    // same secrets code
    return (
      this.length >= PasteEvent.MINIMUM_FLAGGABLE_PASTE_SIZE &&
      this.attributedCopyUrl !== this.url
    )
  }
}
