/**
 * Entry is a serializable class that contains all the data loaded in from the web browser after
 * a student has "handed in their logs" to the teacher.
 */

import { Site } from './Site'

export class Entry {
  constructor({
    id = null,
    startStopTimes = [],
    sites = [],
    created = +new Date(),
    uid = null,
    deletedTime = 0,
    email = null,
    displayName = null,
  }) {
    this.id = id
    this.startStopTimes = startStopTimes
    this.sites = sites
    this.created = created
    this.uid = uid
    this.deletedTime = deletedTime
    this.email = email
    this.displayName = displayName
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {{assignmentId: string, courseId: string, organizationId: string, id: string, startTime: number, endTime: number|null, userId: string}}
   */
  toJson() {
    return {
      id: this.id,
      startStopTimes: this.startStopTimes,
      sites: this.sites.map((site) => site.toJson()),
      created: this.created,
      uid: this.uid,
      deletedTime: this.deletedTime,
      email: this.email,
    }
  }
  static firestoreConverter(dateToTimestamp, anonymized = false) {
    return {
      toFirestore(entry) {
        return {
          id: entry.id,
          startStopTimes: entry.startStopTimes.map((startStopTime) =>
            dateToTimestamp(new Date(startStopTime)),
          ),
          created: dateToTimestamp(new Date(entry.created)),
          sites: entry.sites.map((site) => Site.toFirestore(site)),
          deletedTime: entry.deletedTime + '',
          ...(anonymized
            ? {}
            : {
                uid: entry.uid,
                email: entry.email,
                displayName: entry.displayName,
              }),
        }
      },
      fromFirestore(snapshot, options) {
        if (!snapshot.exists) return null

        const data = snapshot.data(options)

        if (!data) return null

        if (data.sites.updatedSites) {
          // ???  This is some weirdness from the old extension, I'm pretty sure.  I see traces of a likely cause for some error like this in that code right now.
          data.sites = data.sites.updatedSites
        }
        return new Entry({
          id: data.id,
          startStopTimes: data.startStopTimes.map(
            (startStopTime) => +new Date(startStopTime.toDate()),
          ),
          created: +new Date(data.created.toDate()),
          sites:
            data.sites?.map(
              (site) =>
                new Site({
                  url: site.url,
                  firstFocus: site.firstFocus || +new Date(site.startTime),
                  lastFocus: site.firstFocus || site.endTime,
                  time: site.time,
                  title: site.title,
                  redacted: !!site.redacted,
                }),
            ) || [],
          deletedTime: +parseFloat(data.deletedTime),
          ...(anonymized
            ? {}
            : {
                uid: data.uid,
                email: data.email,
                displayName: data.displayName,
              }),
        })
      },
    }
  }
}
