import { getDocs } from 'firebase/firestore/lite'
import refs from '../refsLite'
import { getLoggerSession } from '../logger/getLoggerSession'
import { removeMissingTagIdsFromUserSiteData } from './removeMissingTagIdsFromUserSiteData'

export async function getUserSiteDatas(
  sessionId,
  validateTags = true,
  sessionToValidate = null,
) {
  const docs = await getDocs(refs.userSiteDatas(sessionId))
  const result = docs.docs.map((doc) => doc.data())

  if (validateTags) {
    sessionToValidate ||= await getLoggerSession(sessionId)
    if (!sessionToValidate) {
      console.warn('No session found for id: ', sessionId)
      return result
    }
    for (let i = 0; i < result.length; i++) {
      const siteData = result[i]
      removeMissingTagIdsFromUserSiteData(sessionToValidate, siteData)
    }
  }
  return result
}
