import Vue from 'vue'
import { CustomTextEditor } from '../components/RichTextEditor/customExtensions/CustomTextEditor'
import { activeTipTapExtensions } from '@learnics/models/src/utils/tiptap/activeTipTapExtensions'
import History from '@tiptap/extension-history'

export const state = {
  textEditors: {},
}

export const getters = {
  textEditors: (state) => state.textEditors,
}

export const mutations = {
  destroyTextEditor(state, id) {
    const editor = state.textEditors[id]
    if (editor) {
      editor.destroy()
      Vue.delete(state.textEditors, id)
    }
  },
  /**
   * Creates a tiptap text editor if one doesn't exist for the given id.
   *
   * Also ensures that there are no more than 20 text editors at a time.
   *
   */
  createTextEditorIfNeeded(
    state,
    {
      id,
      value,
      userFilesBucket,
      extensions = null, // If null, use the active extensions array, otherwise custom
      additionalExtensions = [], // Additional extensions to add to the active extensions array
      disableHistory = false,
      forced = false,
    },
  ) {
    extensions ||= activeTipTapExtensions.get()
    if (disableHistory) {
      // Collaboration already includes a form of history.  Editors that use it do not require this extension.
      extensions.splice(extensions.indexOf(History), 1)
    }

    if (forced && state.textEditors[id]) {
      const editor = state.textEditors[id]
      editor.destroy()
      Vue.delete(state.textEditors, id)
    }
    if (!state.textEditors[id]) {
      const TEXT_EDITOR_LIMIT = 20
      const currentEditorIds = Object.keys(state.textEditors).sort((a, b) => {
        return (
          state.textEditors[a].lastModified - state.textEditors[b].lastModified
        )
      })
      // First check if we've reached the limit of text editors.
      // If so, remove the oldest unmodified editor.
      if (currentEditorIds.length === TEXT_EDITOR_LIMIT) {
        const unmodifiedEditorIds = currentEditorIds
          .filter((id) => {
            return state.textEditors[id].lastModified === -1
          })
          .sort((a, b) => {
            return (
              state.textEditors[a].dateCreated -
              state.textEditors[b].dateCreated
            )
          })
        if (unmodifiedEditorIds.length > 0) {
          const editor = state.textEditors[unmodifiedEditorIds[0]]
          editor.destroy()
          Vue.delete(state.textEditors, unmodifiedEditorIds[0])
        } else {
          // If all editors are modified, remove the one with the earliest recorded lastModified date.
          const editor = state.textEditors[currentEditorIds[0]]
          editor.destroy()
          Vue.delete(state.textEditors, currentEditorIds[0])
        }
      }
      // Finally, create the new editor now that we've ensured there's room.
      const editor = new CustomTextEditor(id, userFilesBucket, value, [
        ...extensions,
        ...additionalExtensions,
      ])

      Vue.set(state.textEditors, id, editor)
    }
  },
}

export const actions = {}
