import { setDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function saveUserSiteData(userSiteDatas) {
  console.log('Saving site data', userSiteDatas)
  for (const siteData of userSiteDatas) {
    await setDoc(refs.userSiteData(siteData.sessionId, siteData.id), siteData, {
      merge: true,
    })
  }
}
