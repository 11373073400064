import refs from '../refsLite'
import { updateRoles } from '../utils/updateRoles'

export async function updateCourseRoles2(
  courseId,
  addRolesForUsers = null,
  removeRolesForUsers = null,
) {
  console.log(
    'Setting course roles... ',
    courseId,
    addRolesForUsers,
    removeRolesForUsers,
  )
  await updateRoles(
    refs.course(courseId, false),
    addRolesForUsers,
    removeRolesForUsers,
  )
  console.log('Course roles set!')
}
