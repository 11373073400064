import { getAccessCodeData } from '../assignment/getAccessCodeData'
import { loadAssignmentKeys } from '../assignment/loadAssignmentKeys'
import { generateEventsCsv } from './generateEventsCsv'

export async function generateAssignmentCsv(accessCode) {
  let accessCodeData
  accessCodeData = await getAccessCodeData(accessCode)
  if (!accessCodeData) throw new Error('Invalid access code')
  const { assignmentId } = accessCodeData
  const userKeys = await loadAssignmentKeys(assignmentId)
  return await generateEventsCsv(
    { type: 'assignment', id: assignmentId },
    userKeys.all,
  )
}
