import { getAssignmentDetail } from '../assignment/getAssignmentDetail'
import { getAssignment } from '../assignment/getAssignment'

export async function getStateDataForSession(session) {
  let result = {
    timeRange: {
      start: 0,
      stop: Infinity,
    },
    sessionId: session.id,
    sessionType: session.type,
  }
  switch (session.type) {
    case 'assignment':
      const assignment = await getAssignment(session.assignmentId)
      const assignmentDetail = await getAssignmentDetail(
        assignment.assignmentDetailId,
      )
      assignmentDetail.upgradeClassroomAssignmentIfNeeded(assignment)
      result = {
        ...result,
        assignment,
        assignmentDetail,
      }
  }
  return result
}
