import { recognizedSearchEngines } from './recognizedSearchEngines'

export function urlToSearchKeyword(url, annotated = false) {
  if (!url) {
    return null
  }
  const searchEngineIds = Object.keys(recognizedSearchEngines)
  for (let i = 0; i < searchEngineIds.length; i++) {
    const searchEngineId = searchEngineIds[i]
    const searchEngine = recognizedSearchEngines[searchEngineId]
    const parsers = searchEngine.parsers
    const parserIds = Object.keys(parsers)
    for (let j = 0; j < parserIds.length; j++) {
      const parserId = parserIds[j]
      const parser = parsers[parserId]
      if (url.indexOf(parserId) !== -1) {
        const result = parser(url)
        if (result) {
          if (annotated) {
            return { engine: searchEngineId, search: result }
          } else {
            return result
          }
        }
      }
    }
  }
  return null
}
