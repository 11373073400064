export const studentRoles = ['Learner']
export const teacherRoles = ['Instructor']
export const administratorRoles = ['Administrator']

export const otherRoles = ['Viewer', 'Editor', 'Owner']

export const allRoles = [
  'Owner',
  'Administrator',
  'Instructor',
  'Learner',
  'Viewer',
  'Editor',
]

/** @deprecated prefer allRoles */
export const fullySupportedRoles = [
  ...studentRoles,
  ...teacherRoles,
  ...administratorRoles,
]
export function isStudentIn(resource, uid) {
  const userRoles = getUserRolesOverResource(resource, uid)
  for (const role of userRoles) {
    if (studentRoles.includes(role)) return true
  }
  return false
}
export function isTeacherIn(resource, uid) {
  const userRoles = getUserRolesOverResource(resource, uid)
  for (const role of userRoles) {
    if (teacherRoles.includes(role)) return true
  }
  return false
}
export function isAdministratorIn(resource, uid) {
  const userRoles = getUserRolesOverResource(resource, uid)
  for (const role of userRoles) {
    if (administratorRoles.includes(role)) return true
  }
  return false
}

export function isStudentAdminOrTeacherIn(resource, uid) {
  return (
    isStudentIn(resource, uid) ||
    isTeacherIn(resource, uid) ||
    isAdministratorIn(resource, uid)
  )
}
export function isAnythingIn(resource, uid) {
  const userRoles = getUserRolesOverResource(resource, uid)
  for (const role of userRoles) {
    if (allRoles.includes(role)) return true
  }
  return false
}
export function getUserRolesOverResource(resource, uid) {
  if (!resource) throw new Error('Resource is null')
  if (!resource.roles) throw new Error('Resource.roles is null')
  return Object.keys(resource.roles).filter((role) =>
    resource.roles[role].includes(uid),
  )
}

export function extractRolesMapFromFirestoreDocument(storedData) {
  return allRoles.reduce((acc, role) => {
    const uids = (storedData[role] || []).map((userReferenceOrSomething) => {
      if (userReferenceOrSomething?.ref?.id) {
        return userReferenceOrSomething?.ref.id
      }
      if (userReferenceOrSomething?.id) {
        return userReferenceOrSomething?.id
      }
      return userReferenceOrSomething // Hopefully this is just ultimately always a uid string.
    })

    acc[role] = uids
    return acc
  }, {})
}
