import { httpsCallable } from 'firebase/functions'
import { functions } from '../coreConfig'

export async function addStudentCopyDriveFiles(
  assignmentId,
  driveFileIds,
  attachmentIds = [],
) {
  const result = await httpsCallable(
    functions,
    'addStudentCopyDriveFiles',
  )({
    assignmentId,
    driveFileIds,
    attachmentIds,
  })
  console.log('addStudentCopyDriveFiles result: ', result)
  if (!result.data.success) {
    throw new Error(result.data.error)
  }
}
