import { getDocs, query, updateDoc, where } from 'firebase/firestore/lite'
import refs from '../refsLite'

/**
 * A function to archive all the Assignments for a given AssignmentDetail.  AssignmentDetails are not actually
 * archived, but rather all the Assignments for that AssignmentDetail are archived.  This is because AssignmentDetails
 * are shared between multiple Assignments, and we don't want to lose the AssignmentDetail data when we archive an
 * Assignment.  When all assignments are archived, the AssignmentDetail will naturally be archived as well.
 *
 * @param {string} assignmentDetailId The id of the AssignmentDetail to archive.
 * @param {boolean} archive Whether to archive or un-archive the AssignmentDetail, defaults to true.
 **/
export async function archiveAssignmentDetail(
  assignmentDetailId,
  archive = true,
) {
  console.log(`Archiving AssignmentDetail: ${assignmentDetailId}...`)

  const assignmentIdsArchived = []

  const querySnapshot = await getDocs(
    query(
      refs.assignments(),
      where('assignmentDetailId', '==', assignmentDetailId),
    ),
  )
  for (const assignmentDoc of querySnapshot.docs) {
    const assignmentId = assignmentDoc.id
    console.log(`Archiving Assignment: ${assignmentId}...`)
    await updateDoc(refs.assignment(assignmentId, false), {
      archived: !!archive,
    })
    assignmentIdsArchived.push(assignmentId)
  }
  console.log(
    `Archived AssignmentDetail: ${assignmentDetailId}, returning ${assignmentIdsArchived.length} archived Assignment ids.`,
  )
  return assignmentIdsArchived
}
