import { generateRunningCalculators as genCalcs } from '@learnics/models/src/Organization/Course/Assignment/Event/utils/generateRunningCalculators'
import { loadChunks } from './loadChunks'
import { getSecrets } from './getSecrets'
import { getKeysDeleted } from './getKeysDeleted'

// This is merely an interface to the common loading routine in the models package.
export async function generateRunningCalculators(
  sessionIds,
  commonCalculators = {},
  runningCalculatorIds = [],
  additionalStateData = {},
  unsavedEvents = {},
) {
  return await genCalcs(
    sessionIds,
    {
      loadChunks,
      getSecrets,
      getKeysDeleted,
    },
    commonCalculators,
    runningCalculatorIds,
    additionalStateData,
    unsavedEvents,
  )
}
