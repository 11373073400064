import { AbstractEvent } from './AbstractEvent'

/**
 * A class to represent a TabBlurEvent for logging sessions.  This type of
 * event is recorded when the user changes focus away from a tab in the browser.
 *
 * @example
 * // Concrete initialization and usage
 *
 *
 *
 * // Instantiate the event on the fly, so you can use it to calculate
 * // time and other things
 * const event = new TabBlurEvent({
 *   time: new Date().getTime(),
 *   eventType:'tabBlur'
 * });
 *
 * // Create a database-friendly version of the event.  This should be a
 * // clone of the concreteEventData, except with the id and eventType
 * // properties both set to "attachments".
 * const savableEventData = event.toJson();
 *
 * @example
 * // Abstract initialization and usage
 *
 * const databaseEvents = [{
 *   id: "some-learnics-generated-id",
 *   eventType: "tabBlur",
 *   time: 1654887559641,
 * }, {
 *     // ... some other event
 * }];
 *
 * // Instantiate an array of serialized AbstractEvent JSON objects (from
 * // the database), in order to restore their functionality.
 * const events = databaseEvents.map(
 *     event => eventFromJson(event)
 *   );
 *
 *
 * // Create a database-friendly version of an array of events.  This
 * // should be a clone of the databaseEvents array.
 * const savableEventsCopy = events.map(
 *     event => event.toJson()
 *   )
 *
 *   @inheritDoc
 */
export class IdleStateChangeEvent extends AbstractEvent {
  static COLUMNS = [...AbstractEvent.COLUMNS, 'value']

  /**
   * Constructor for the TabBlurEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain TabBlurEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {string} [input.value] the chrome-based idle state string (e.g. "active", "idle", "locked")
   * @param {boolean} [input.implicit] whether or not this event is implicit (i.e. informational only, to provide context to event streams)
   * @constructor
   */
  constructor({ time, value, implicit }) {
    super('idleStateChange', time, false, implicit)
    this.value = value
  }

  /**
   * Convert this object into a simple Json object.
   *
   * This is currently a stub method for if properties are added to this event
   * later.  They should be added to the json object that is returned from
   * super.toJson(), ensuring that the json object is a clone of the original
   * object.  Properties specific to this event type should be added to the json
   * object here.  This creates a clean clone of the original object, one we may
   * even consider minimizing the size of, or changing from json to CSV, so we
   * can cut down on data storage.  The point here is solid serialization of the
   * object, through this method, and the ability to deserialize it back into a
   * usable class instance through the constructor.
   *
   * @returns {object} a JSON object representing this object.
   */
  toJson() {
    const json = super.toJson()
    json.value = this.value
    return json
  }
}
