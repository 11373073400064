import { generateJSON } from '@tiptap/html'
import { activeTipTapExtensions } from './activeTipTapExtensions'

export function getBucketFileIdsFromHtml(htmlString) {
  const jsonNode = generateJSON(htmlString, activeTipTapExtensions.get())
  const result = new Set()
  return getBucketFileIdsFromContent(jsonNode, result)
}

export function getBucketFileIdsFromContent(jsonNode, result = new Set()) {
  if (jsonNode.type === 'bucketFile') {
    // found a bucket file.
    const fileId = jsonNode.attrs['file-id']
    result.add(fileId)
  }
  if (jsonNode.content && jsonNode.content.length > 0) {
    jsonNode.content.forEach((child) =>
      getBucketFileIdsFromContent(child, result),
    )
  }
  return result
}
