/**
 * GoogleClassroomOptions is class to represent the data model for the settings of an assignment's Google Classroom integration.
 *
 * @deprecated this data is now stored on the `Assignment` class.  There is a corresponding property for each of these fields on the `Assignment` class.
 */
export class GoogleClassroomOptions {
  /**
   * Constructor for GoogleClassroomOptions.
   *
   * @param data {object} the data to initialize the GoogleClassroomOptions with
   * @param data.id {string} the id of the course
   * @param data.directLink {string} the direct link to the course
   * @param data.studentWorkFolderId {string} the id of the student work folder for the assignment
   * @param data.courseId {string} the id of the Google Classroom course
   * @param [data.createdWithLearnics] {boolean} whether the course was created with Learnics
   *
   */
  constructor({
    id,
    directLink,
    studentWorkFolderId,
    courseId,
    createdWithLearnics = false,
  }) {
    this.id = id
    this.courseId = courseId
    this.directLink = directLink
    this.studentWorkFolderId = studentWorkFolderId
    this.createdWithLearnics = createdWithLearnics
  }

  toJson() {
    return {
      id: this.id,
      courseId: this.courseId,
      directLink: this.directLink,
      studentWorkFolderId: this.studentWorkFolderId,
      createdWithLearnics: this.createdWithLearnics,
    }
  }
}
