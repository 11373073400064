import { nanoid } from 'nanoid'
import { htmlHasNonWhiteSpace } from '../../../../utils/tiptap/htmlHasNonWhiteSpace'
import { getBucketFileIdsFromHtml } from '../../../../utils/tiptap/getBucketFileIdsFromHtml'

/**
 * UrlNote is a class for defining a note or annotation.
 *
 * @deprecated prefer `Note` when it is available
 */
export class UrlNote {
  constructor(id = nanoid(), text, tagIds = [], fileIds = []) {
    if (!text || typeof text !== 'string') {
      text = ''
    }
    this.id = id
    this.text = text
    this.tagIds = tagIds
    this.fileIds = fileIds
  }

  isEmpty() {
    return (
      !htmlHasNonWhiteSpace(this.text) &&
      this.tagIds.length === 0 &&
      this.fileIds.length === 0
    )
  }

  /**
   * Convert this state to a plain JSON object.
   * @returns {{id: string, text: string, html: string}}
   */
  toJson() {
    return {
      id: this.id,
      text: this.text,
      tagIds: [...(this.tagIds || [])],
      fileIds: [...(this.fileIds || [])],
    }
  }

  static fromJson(json) {
    return new UrlNote(json.id, json.text, json.tagIds, json.fileIds)
  }

  getImageFileIds() {
    return getBucketFileIdsFromHtml(this.text)
  }
}
