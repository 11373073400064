import { extractRolesMapFromFirestoreDocument } from '../roles'
import { extractLinksMapFromFirestoreDocument } from '../resourceTypes'
import { BasicAccessList } from '../../BasicAccessList/BasicAccessList'

export class BasicAccessListFirestoreConverter {
  static toFirestore(acl) {
    if (!acl) {
      return null
    }
    const storageObject = acl.toJson()
    let rolesMap = storageObject.roles
    const roles = {}
    Object.keys(rolesMap).forEach((key) => {
      roles[key] = [...rolesMap[key]]
    })
    const links = {}
    const linkTypes = ['children', 'parents', 'relations']
    for (const linkType of linkTypes) {
      let linksMap = storageObject[linkType]
      Object.keys(linksMap || {}).forEach((key) => {
        links[linkType + '_' + key] = [...linksMap[key]]
      })
      delete storageObject[linkType]
    }
    delete storageObject.roles

    return {
      ...storageObject,
      ...roles,
      ...links,
    }
  }
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    if (!data) {
      return null
    }
    const roles = extractRolesMapFromFirestoreDocument(data)
    const children = extractLinksMapFromFirestoreDocument('children_', data)
    const parents = extractLinksMapFromFirestoreDocument('parents_', data)
    const relations = extractLinksMapFromFirestoreDocument('relations_', data)

    return new BasicAccessList({
      publicData: data.publicData,
      roles,
      children,
      relations,
      parents,
      locked: !!data.locked,
    })
  }
}
