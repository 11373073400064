import { AbstractBrowserTabEvent } from './AbstractBrowserTabEvent'
import { middleTruncateText } from '../../../../utils/middleTruncateText'

/**
 * A class to represent a CopyEvent for logging sessions.
 * This type of event is recorded when a student copies text from a page.
 */
export class CopyEvent extends AbstractBrowserTabEvent {
  static COLUMNS = [...AbstractBrowserTabEvent.COLUMNS, 'url', 'text', 'length']
  /**
   * Constructor for the CopyEvent class.  This instantiates the
   * event object.
   *
   * @param {any} input a plain CopyEvent JSON object
   * @param {number} [input.time] the Unix-based timestamp (e.g. `new Date().getTime()`) of this event.  If not provided, the current time will be used.
   * @param {boolean} [input.redacted] whether or not this event contains redacted data.
   * @param {string} [input.url] the url of from where the text was copied.  This may be null if it's not needed.
   * @param {string} [input.text] the copied text. This might be middle-truncated and ellipsized.
   * @param {number} [input.length] the length of the UNTRUNCATED copied text.
   * @constructor
   **/

  constructor({ time, redacted, url, text, length }) {
    super('copy', time, redacted, false)
    this.url = url
    this.text = middleTruncateText(text)
    this.length = length
  }

  toJson() {
    const result = super.toJson()
    result.url = this.url
    result.text = this.text
    result.length = this.length
    return result
  }

  redactHelper(hashFunction, result, k) {
    if (this[k] && typeof this[k] === 'string') {
      const redactedValue = hashFunction(this[k])
      result[redactedValue] = this[k]
      this[k] = redactedValue
    }
  }

  redact(hashFunction) {
    const result = super.redact(hashFunction)
    if (this.redacted) return result

    this.redactHelper(hashFunction, result, 'url')
    this.redactHelper(hashFunction, result, 'text')

    this.redacted = true

    return result
  }

  reveal(secrets) {
    if (!this.redacted) return
    if (!secrets)
      throw new Error('Cannot reveal secrets without a secrets object')

    const urlRevealed = !this.url || this.url in secrets
    const textRevealed = !this.text || this.text in secrets

    if (urlRevealed && textRevealed) {
      this.url &&= secrets[this.url]
      this.text = secrets[this.text]
      this.redacted = false
    }
  }

  redactedValues() {
    if (!this.redacted) {
      return []
    }
    const values = []
    values.push(this.url)
    values.push(this.text)
    return values
  }
}
