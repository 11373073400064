import { getDoc } from 'firebase/firestore/lite'
import refs from '../refsLite'

export async function getBucketFile(fileId) {
  const snap = await getDoc(refs.bucketFile(fileId))
  if (snap.exists()) {
    return snap.data()
  }
  return null
}
