/**
 * A function to act as a basic converter for a class-based Firestore object.
 *
 * This function expects to receive a class that has a constructor that takes
 * an object as its only parameter. The object should be a plain-JSON representation
 * of the class.  The class should also have a toJson() method that returns a
 * plain-JSON representation of the class.
 */
export function getBasicClassBasedFirestoreConverter(klass) {
  return {
    toFirestore(resourceDriveFolder) {
      return resourceDriveFolder.toJson()
    },
    fromFirestore(snapshot, options) {
      return new klass(snapshot.data(options))
    },
  }
}
