import { eventTypeClassMap, eventTypeFromId } from './eventTypeMaps'
import { eventFromJson } from './eventFromJson'
import * as Papa from 'papaparse'

/**
 * A utility function to instantiate an Event object based on the given CSV row string.  Each row
 * is expected to being with the numerical id for the class of event, followed by a comma, followed
 * by csv values for the event's properties, in the order specified by the event class's static
 * `COLUMNS` property.
 *
 * @param {object} input the JSON object to instantiate from
 * @returns {*} an AbstractEvent object instantiated from the given JSON object
 * @throws {Error} if the given JSON object does not have a valid eventType
 */
export function eventFromCsvRow(csvString) {
  const eventId = csvString.split(',')[0]
  let eventType = eventTypeFromId(eventId)
  let EventClass = eventTypeClassMap[eventType]

  if (!EventClass) {
    console.warn(
      "Unrecognized event type: '" + eventType + "', using EmptyEvent",
    )
    eventType = 'empty'
    EventClass = eventTypeClassMap[eventType]
  }

  const parseResult = Papa.parse(csvString, {
    header: false,
    columns: EventClass.COLUMNS,
    dynamicTyping: true,
    quotes: false,
  })

  const mapped = parseResult.data.map((e) => {
    const json = EventClass.COLUMNS.reduce((acc, column, index) => {
      acc[column] = e[index]
      return acc
    }, {})
    json.eventType = eventType
    json.redacted = !!json.redacted
    json.implicit = !!json.implicit
    return eventFromJson(json)
  })

  return mapped[0]
}
